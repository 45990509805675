import md5 from 'md5';
import { useEffect, useState } from 'react';

export type IconType = 'mp' | 'identicon' | '404' | 'monsterid' | 'wavatar' | 'retro' | 'robohash' | 'blank' | undefined;

export const useEmailHashIcon = (email?: string | null, iconType?: IconType) => {
  const [image, setImage] = useState<string>();

  useEffect(() => {
    if (email) {
      const hash = md5(email || 'user@wrecruiting.com');
      const avatar = `https://www.gravatar.com/avatar/${hash}?d=${iconType || 'mp'}`;
      setImage(avatar);
    }
  }, [email]);

  return { image };
};

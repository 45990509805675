import { CreatePlatformDTO, PlatformsService, UpdatePlatformDTO } from '@API/services/platforms&places/PlatformsService';
import { AfterActionProps, BaseAsyncThunkOptions, ListResponse } from '@app/store/interface';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { PLATFORMS } from '@slices/platform/interface';

export const getPlatforms = createAsyncThunk<ListResponse<PLATFORMS.Platform[]>, string | undefined, BaseAsyncThunkOptions>('platforms/getAll', async (query, thunkApi) => {
  const response = await PlatformsService.getAll(query);

  if (response.ok) {
    return {
      data: response.data.data,
      total: response.data.total
    };
  } else {
    return thunkApi.rejectWithValue(response.error);
  }
});

export const getPlatform = createAsyncThunk<PLATFORMS.Platform, string, BaseAsyncThunkOptions>('platforms/getOne', async (id, thunkApi) => {
  const response = await PlatformsService.getOne(id);

  if (response.ok) {
    return response.data.data;
  } else {
    return thunkApi.rejectWithValue(response.error);
  }
});

export const createPlatform = createAsyncThunk<PLATFORMS.Platform, CreatePlatformDTO & AfterActionProps<string>, BaseAsyncThunkOptions>(
  'platforms/create',
  async ({ afterActionWithParams, ...data }, thunkApi) => {
    const response = await PlatformsService.create(data);

    if (response.ok) {
      afterActionWithParams && afterActionWithParams(response.data.data.id);
      return response.data.data;
    } else {
      return thunkApi.rejectWithValue(response.error);
    }
  }
);

export const updatePlatform = createAsyncThunk<PLATFORMS.Platform, UpdatePlatformDTO & AfterActionProps, BaseAsyncThunkOptions>(
  'platforms/update',
  async ({ afterAction, ...data }, thunkApi) => {
    const response = await PlatformsService.update(data);

    if (response.ok) {
      afterAction && afterAction();
      return response.data.data;
    } else {
      return thunkApi.rejectWithValue(response.error);
    }
  }
);

export const deletePlatform = createAsyncThunk<PLATFORMS.Platform, Record<'id', string> & AfterActionProps, BaseAsyncThunkOptions>(
  'platforms/delete',
  async ({ id, afterAction }, thunkApi) => {
    const response = await PlatformsService.delete(id);

    if (response.ok) {
      afterAction && afterAction();
      return response.data.data;
    } else {
      return thunkApi.rejectWithValue(response.error);
    }
  }
);

import { useAppDispatch } from '@app/store/store';
import { useModal } from '@hooks/useModal';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import Info from '@mui/icons-material/Info';
import { Divider, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { deletePlatform } from '@slices/platform/thunks';
import { FC } from 'react';
import { Link } from 'react-router-dom';

interface PlatformsListActionsPopoverProps {
  id: string;
}

export const PlatformsListActionsPopover: FC<PlatformsListActionsPopoverProps> = ({ id }) => {
  const dispatch = useAppDispatch();
  const { openModal, closeModal } = useModal();

  const handleDelete = () => {
    openModal({
      modalName: 'CONFIRM_MODAL',
      modalProps: {
        title: 'Delete this platform?',
        subtitle: 'You will not be able to undo this action',

        successAction: () => {
          dispatch(
            deletePlatform({
              id,
              afterAction: () => {
                closeModal('CONFIRM_MODAL');
              }
            })
          );
        }
      }
    });
  };

  return (
    <List dense className="min-w-[160px]">
      <ListItemButton component={Link} to={`${id}/edit`} className="mx-2 whitespace-nowrap px-2">
        <ListItemIcon className="min-w-fit mr-2">
          <Edit fontSize="small" />
        </ListItemIcon>
        <ListItemText className="uppercase font-bold">Edit</ListItemText>
      </ListItemButton>

      <ListItemButton component={Link} to={`${id}`} className="mx-2 whitespace-nowrap px-2">
        <ListItemIcon className="min-w-fit mr-2">
          <Info fontSize="small" />
        </ListItemIcon>
        <ListItemText className="uppercase font-bold">View</ListItemText>
      </ListItemButton>

      <Divider className="my-2" />

      <ListItemButton className="text-red-500 mx-2 whitespace-nowrap px-2" onClick={handleDelete}>
        <ListItemIcon className="text-red-500 min-w-fit mr-2">
          <Delete fontSize="small" />
        </ListItemIcon>
        <ListItemText className="uppercase font-bold">Delete</ListItemText>
      </ListItemButton>
    </List>
  );
};

import { FieldWrapperProps } from '@components/Fields/interface';
import { Autocomplete, CircularProgress, TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

interface OptionBaseType {
  id: string | number;
  [key: string]: any;
}

interface InputSelectProps<Option> extends FieldWrapperProps {
  options: Option[];
  optionLabelRefName: keyof Option;

  returnValue?: keyof Option;
  textFieldProps?: TextFieldProps;
  disabled?: boolean;
  disableClearable?: true;
  onSelect?: (option: Option) => void;
  className?: string;
  loading?: boolean;
  defaultValue?: Option;
  getOptionDisabled?: (option: Option) => boolean
}

export const InputSelect = <O extends OptionBaseType>({
  options,
  name,
  label,
  className,
  loading,
  defaultValue,
  onSelect,
  disabled,
  textFieldProps = {},
  returnValue,
  disableClearable,
  optionLabelRefName,
  getOptionDisabled
}: InputSelectProps<O>) => {
  const methods = useFormContext();

  if (!methods) {
    console.warn('"InputSelect" component must be nested of "FormWrapper" component');
    return null;
  }

  const { control } = methods;

  return (
    methods && (
      <Controller
        name={name}
        control={control}
        defaultValue={returnValue ? defaultValue?.[returnValue] ?? null : defaultValue ?? null}
        render={({ field, fieldState }) => (
          <Autocomplete
            {...field}
            disabled={disabled}
            disableClearable={disableClearable}
            loading={loading}
            className={className}
            getOptionDisabled={getOptionDisabled}
            getOptionLabel={(option) => option[optionLabelRefName] ?? ''}
            isOptionEqualToValue={(option: O, value: O | string | number | null) => {
              if (typeof value === 'string' || typeof value === 'number') {
                return option.id === value;
              }
              return option?.id === value?.id;
            }}
            value={returnValue ? options.find((item) => item[returnValue] === field.value) ?? null : field.value}
            options={options}
            onChange={(e, option) => {
              field.onChange((returnValue ? option?.[returnValue] ?? null : option) ?? null);
              onSelect && onSelect(option ?? null);
            }}
            clearText="Clear"
            openText=""
            renderInput={(params) => (
              <TextField
                {...params}
                {...textFieldProps}
                label={label}
                error={!!fieldState.error}
                helperText={fieldState.error?.message ?? ''}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                  ...textFieldProps?.InputProps
                }}
              />
            )}
          />
        )}
      />
    )
  );
};

import { Order, TableHeadCell } from '@app/store/interface';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import React, { ReactNode } from 'react';

export interface TableSortHeaderProps<T> {
  columns: TableHeadCell<T>[];
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  order: Order;
  orderBy: string;
  extraColumns?: ReactNode;
}

export const TableSortHeader = <T extends {}>({ onRequestSort, columns, extraColumns, order, orderBy }: TableSortHeaderProps<T>) => {
  const createSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className="bg-gray-100">
      <TableRow className="bg-gray-100">
        {/*<TableCell padding="checkbox">*/}
        {/*  <Checkbox*/}
        {/*    color="primary"*/}
        {/*    indeterminate={numSelected > 0 && numSelected < rowCount}*/}
        {/*    checked={rowCount > 0 && numSelected === rowCount}*/}
        {/*    onChange={onSelectAllClick}*/}
        {/*    inputProps={{*/}
        {/*      'aria-label': 'select all desserts',*/}
        {/*    }}*/}
        {/*  />*/}
        {/*</TableCell>*/}
        {columns.map((headCell, index, arr) => (
          <TableCell
            key={index}
            aria-colindex={index + 1}
            className="uppercase py-1  whitespace-nowrap bg-gray-100"
            align={arr.length - 1 === index ? 'right' : headCell.numeric ? 'right' : 'left'}
            // padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.disableSorting && <>{headCell.label}</>}

            {!headCell.disableSorting && (
              <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id as keyof T)}>
                {headCell.label}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
        {extraColumns}
        <TableCell colSpan={1} size="small" align="right" className="font-bold w-10 bg-gray-100"></TableCell>
      </TableRow>
    </TableHead>
  );
};

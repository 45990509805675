import { FormWrapper } from '@components/Forms/FormWrapper';
import { DepartmentsSearchFormProps, DepartmentsSearchProps } from '@modules/departments/interface';
import { useAppDispatch, useAppSelector } from '@app/store/store';
import { InputTextField } from '@components/Fields/InputText/InputTextField';
import Search from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import { departmentsSlice } from '@slices/departments/departmentsSlice';
import classNames from 'classnames';
import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';

export const DepartmentsSearch: FC<DepartmentsSearchProps> = ({ className }) => {
  const dispatch = useAppDispatch();
  const { search, sort } = useAppSelector((store) => store.departments);

  const methods = useForm<DepartmentsSearchFormProps>({
    defaultValues: {
      search,
      sort
    }
  });

  useEffect(() => {
    const subscription = methods.watch(({ search }) => {
      dispatch(departmentsSlice.actions.touchSearchActions({ search: search || '' }));
    });
    return () => subscription.unsubscribe();
  }, [methods.watch]);

  return (
    <FormWrapper className={classNames('mb-4 flex gap-2 flex-wrap', className)} methods={methods}>
      <InputTextField
        className="flex-1 min-w-[200px]"
        name="search"
        placeholder="Search"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          )
        }}
      />
    </FormWrapper>
  );
};

import { useAppDispatch, useAppSelector } from '@app/store/store';
import { InputSelect } from '@components/Fields/InputSelect/InputSelect';
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  MeasuringStrategy,
  MouseSensor,
  pointerWithin,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { KanbanBoard } from '@modules/kanban/components/Board/KanbanBoard';
import { KanbanStepContainer } from '@modules/kanban/components/Container/KanbanStepContainer';
import { KanbanColumnTicket } from '@modules/kanban/components/Ticket/KanbanColumnTicket';
import { ArrowBack, Search } from '@mui/icons-material';
import { Box, IconButton, InputAdornment } from '@mui/material';
import { KANBAN } from '@slices/kanban/interface';
import { kanbanSlice } from '@slices/kanban/kanbanSlice';
import { changeCandidateKanbanStep, changeKanbanStepOrder } from '@slices/kanban/thunks';
import React, { FC, ReactElement, ReactNode, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const KanbanBoardPage: FC = (): ReactElement => {
  const { vacancies: kanban_vacancies, status: kanban_status } = useAppSelector((store) => store.kanban);
  const {
    list: { data: vacancies_list },
    status: vacancies_status
  } = useAppSelector((store) => store.vacancies.vacanciesList);
  const { vacancy_id } = useParams();
  const [SelectedItem, setSelectedItem] = useState<ReactNode | null>(null);
  const [initialTicketData, setInitialTicketData] = useState<(KANBAN.Candidates & { container_id: string }) | null>(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10
      }
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5
      }
    })
  );

  const handleDragStart = ({ active }: DragStartEvent) => {
    if (!vacancy_id) {
      return;
    }

    const isContainer: string | false = active.data.current?.sortable?.containerId ?? false;
    if (isContainer) {
      const containerIndex: number = active.data.current?.sortable.index;
      const step: KANBAN.Steps = kanban_vacancies[vacancy_id].steps[containerIndex];

      setSelectedItem(<KanbanStepContainer vacancy_id={vacancy_id} isClone columnData={step} key={step.id} />);
      return;
    }

    const isCard: string | false = active.data.current?.type !== 'container' ? active.id : false;
    if (isCard && active.data.current) {
      setInitialTicketData(active.data.current as KANBAN.Candidates & { container_id: string });
      setSelectedItem(<KanbanColumnTicket container_id="clone" isClone data={active.data.current as KANBAN.Candidates} />);
      return;
    }
  };

  const handleDragOver = ({ over, active }: DragOverEvent) => {
    if (!over || !active || over?.id === active.id || !vacancy_id || !active.data.current || active.data.current.container_id === over?.id) {
      return;
    }

    const activeIsContainer: boolean = active.data.current?.type === 'container';
    if (activeIsContainer) {
      return;
    }

    const activeIsCard: string | false = active.data.current?.type !== 'container' ? active.id : false;

    if (!activeIsCard) {
      return;
    }

    const activeContainerIndex = kanban_vacancies[vacancy_id].steps.findIndex((step) => step.id === active.data.current?.container_id);
    const overContainerIndex = kanban_vacancies[vacancy_id].steps.findIndex((step) => step.id === over?.id);
    dispatch(
      kanbanSlice.actions.moveTicketBetweenContainers({
        ticketID: activeIsCard,
        activeContainerIndex,
        overContainerIndex,
        vacancyID: vacancy_id
      })
    );
    return;
  };

  const handleDragEnd = ({ over, active }: DragEndEvent) => {
    const isContainers = over?.data.current?.type === 'container' && active?.data.current?.type === 'container';

    if (isContainers && vacancy_id && active.data.current?.sortable && over.data.current?.sortable && active.data.current?.sortable.index !== over.data.current?.sortable.index) {
      dispatch(
        changeKanbanStepOrder({
          vacancy_id,
          active_index: active.data.current.sortable.index,
          over_index: over.data.current.sortable.index,
          cached_data: kanban_vacancies[vacancy_id].steps,
          ordering: kanban_vacancies[vacancy_id].steps[over.data.current.sortable.index].ordering,
          step_id: kanban_vacancies[vacancy_id].steps[active.data.current.sortable.index].id
        })
      );
    }

    if (vacancy_id && initialTicketData && over?.data && over?.data.current?.type === 'container') {
      const containerID = over.id;

      dispatch(
        changeCandidateKanbanStep({
          candidate_id: active.id,
          vacancy_id,
          container_id: containerID,
          steps: [
            {
              step_id: initialTicketData.container_id,
              status: 'success'
            },
            {
              step_id: containerID,
              status: 'pending'
            }
          ]
        })
      );
    }

    setInitialTicketData(null);
    setSelectedItem(null);
  };

  const navigateBack = () => {
    navigate('/kanban');
  };

  return (
    <>
      <Box className="flex gap-2 p-4 pb-0">
        {vacancy_id && (
          <IconButton onClick={navigateBack} className="">
            <ArrowBack />
          </IconButton>
        )}
        <InputSelect
          textFieldProps={{
            placeholder: 'Select the vacancy',
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }
          }}
          className="min-w-[280px] grow"
          returnValue="id"
          label="Vacancy"
          options={vacancies_list ?? []}
          loading={vacancies_status === 'loading'}
          optionLabelRefName="name"
          name="vacancy"
        />
      </Box>
      <Box className="relative flex-grow">
        <Box className="absolute px-4 pb-2 inset-0 flex overflow-x-auto overflow-y-hidden h-full">
          <DndContext
            collisionDetection={pointerWithin}
            sensors={sensors}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragOver={handleDragOver}
            measuring={{
              droppable: {
                strategy: MeasuringStrategy.Always
              }
            }}
          >
            <KanbanBoard status={kanban_status} columns={kanban_vacancies} />
            <DragOverlay>{SelectedItem ?? null}</DragOverlay>
          </DndContext>
        </Box>
      </Box>
    </>
  );
};

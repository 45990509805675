import { useAppSelector } from '@app/store/store';
import { Box, Card, CardHeader, Divider, Link, List, ListItem, ListItemText, Typography } from '@mui/material';
import { FC } from 'react';

export const TemplatesInfoPage: FC = () => {
  const { data } = useAppSelector((store) => store.platforms.platform);

  return (
    <Box>
      <Card className="shadow my-4">
        <CardHeader title={<Typography variant="h6">Main information</Typography>} />
        <Divider />

        <List>
          <ListItem className="flex flex-wrap">
            <ListItemText className="w-full max-w-[180px] mr-4">
              <Typography variant="subtitle1">Name</Typography>
            </ListItemText>
            <ListItemText>
              <Typography variant="subtitle2" className="text-right lg:text-left opacity-70">
                {data?.name ?? ''}
              </Typography>
            </ListItemText>
          </ListItem>
          <Divider />

          <ListItem className="flex flex-wrap">
            <ListItemText className="w-full max-w-[180px] mr-4">
              <Typography variant="subtitle1">URL</Typography>
            </ListItemText>
            <ListItemText>
              <Typography variant="subtitle2" className="text-right lg:text-left opacity-70" target="_blank" rel="nooper" component={Link} href={data?.url ?? '/'}>
                {data?.url ?? ''}
              </Typography>
            </ListItemText>
          </ListItem>
          <Divider />

          <ListItem className="flex flex-wrap">
            <ListItemText className="w-full max-w-[180px] mr-4">
              <Typography variant="subtitle1">Vacancies</Typography>
            </ListItemText>
            <ListItemText>
              <Typography variant="subtitle2" className="text-right lg:text-left opacity-70">
                {data?.vacancy_count ?? 0}
              </Typography>
            </ListItemText>
          </ListItem>
          <Divider />

          <ListItem className="flex flex-wrap">
            <ListItemText className="w-full max-w-[180px] mr-4">
              <Typography variant="subtitle1">Resumes</Typography>
            </ListItemText>
            <ListItemText>
              <Typography variant="subtitle2" className="text-right lg:text-left opacity-70">
                {data?.resume_count ?? 0}
              </Typography>
            </ListItemText>
          </ListItem>
          <Divider />

          <ListItem className="flex flex-wrap">
            <ListItemText className="w-full max-w-[180px] mr-4">
              <Typography variant="subtitle1">Comment</Typography>
            </ListItemText>
            <ListItemText>
              <Typography variant="subtitle2" className="text-right lg:text-left opacity-70">
                {data?.comment ?? ''}
              </Typography>
            </ListItemText>
          </ListItem>
        </List>
      </Card>
    </Box>
  );
};

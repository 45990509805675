import { createTheme } from '@mui/material';

export const materialTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#5048e5'
    }
  },
  typography: {
    fontFamily: 'Inter'
  },
  shape: {
    borderRadius: 10
  },
  components: {
    MuiPopover: {
      defaultProps: {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left'
        }
      }
    },

    MuiContainer: {
      styleOverrides: {
        root: {
          borderRadius: 10
        }
      }
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: 10
        }
      }
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 10
        }
      }
    },

    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: 10
        }
      }
    },

    MuiButton: {
      styleOverrides: {
        contained: {
          // boxShadow: "0px 1px 3px 0px rgb(0 0 0 / 12%)",
          boxShadow: 'none'
        },
        root: {
          padding: '0.5em 1rem',
          minWidth: '120px',
          borderRadius: 10
        }
      },
      defaultProps: {
        disableElevation: true
      }
    },

    MuiButtonGroup: {
      styleOverrides: {
        contained: {
          boxShadow: 'none'
        }
      }
    },

    MuiTableCell: {
      styleOverrides: {},
      defaultProps: {}
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          // background: blue['700'],
          boxSizing: 'border-box',
          width: '280px',
          paddingLeft: '16px',
          paddingRight: '16px'
        }
      }
    },

    MuiTypography: {
      styleOverrides: {
        root: {
          overflowWrap: 'anywhere'
        }
      }
    }
  }
});

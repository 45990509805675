import { useAppDispatch, useAppSelector } from '@app/store/store';
import { PasswordField } from '@components/Fields/Password/PasswordField';
import { FormWrapper } from '@components/Forms/FormWrapper';
import { ResetPasswordFormProps, ResetPasswordFormValues } from '@components/Forms/interface';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Divider, Stack, Typography } from '@mui/material';
import { resetPassword } from '@slices/auth/thunks';
import React, { FC, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

const schema = yup
  .object({
    password: yup.string().required('Username is required field'),
    confirm_password: yup
      .string()
      .test('match', 'Passwords are not equal', function(new_pass) {
        const { password } = this.parent;
        return password === new_pass;
      })
      .required()
  })
  .required();

export const ResetPasswordForm: FC<ResetPasswordFormProps> = ({ className }) => {
  const { status } = useAppSelector((store) => store.auth);
  const [token, setToken] = useState<string | undefined>();
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const param = searchParams.get('refreshToken');
    if (!param) {
      navigate('/auth/login');
    } else {
      setToken(param);
    }
  }, [searchParams]);

  const methods = useForm<ResetPasswordFormValues>({
    defaultValues: { confirm_password: '', password: '' },
    resolver: yupResolver(schema),
    mode: 'onSubmit'
  });

  const submitRecipe: SubmitHandler<ResetPasswordFormValues> = async (data) => {
    if (token) {
      dispatch(
        resetPassword({
          new_password: data.password,
          token,
          afterAction: () => {
            setTimeout(() => {
              navigate('/auth/login');
            }, 2000);
          }
        })
      );
    }
  };

  return (
    <FormWrapper
      methods={methods}
      className={className}
      formProps={{
        onSubmit: methods.handleSubmit(submitRecipe)
      }}
    >
      <Stack spacing={2} direction="column">
        <PasswordField label="Password" name="password" />

        <PasswordField label="Confirm password" name="confirm_password" />

        <LoadingButton loading={status === 'loading'} type="submit" className="py-3 mt-8" variant="contained">
          Restore Password
        </LoadingButton>
      </Stack>
      <Divider className="my-5" />
      <Typography className="text-gray-500 text-sm">
        <NavLink className="no-underline hover:underline text-inherit" to="../login">
          Login
        </NavLink>
      </Typography>
    </FormWrapper>
  );
};

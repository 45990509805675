import { useAppSelector } from '@app/store/store';
import { useNotifications } from '@hooks/useNotifications';
import { candidatesSlice } from '@slices/candidates/candidatesSlice';
import { dashboardSlice } from '@slices/dashboard/dashboardSlice';
import { departmentsSlice } from '@slices/departments/departmentsSlice';
import { eventsSlice } from '@slices/events/eventsSlice';
import { kanbanSlice } from '@slices/kanban/kanbanSlice';
import { platformsSlice } from '@slices/platform/platformSlice';
import { profileSlice } from '@slices/profile/profileSlice';
import { skillsSlice } from '@slices/skills/skillsSlice';
import { stepsSlice } from '@slices/steps/stepsSlice';
import { tagsSlice } from '@slices/tags/tagsSlice';
import { usersSlice } from '@slices/users/usersSlice';
import { vacanciesSlice } from '@slices/vacancies/vacanciesSlice';
import { DefaultComponentProps } from 'custom';
import { FC } from 'react';

interface NotificationModuleProps extends DefaultComponentProps {}

export const NotificationModule: FC<NotificationModuleProps> = () => {
  const {
    steps: { responseMessage: steps_response },
    kanban: { responseMessage: kanban_response },
    candidates: { responseMessage: candidates_response },
    events: { responseMessage: events_response },
    profile: { responseMessage: profile_response },
    dashboard: { responseMessage: dashboard_response },
    platforms: { responseMessage: platforms_response },
    users: { responseMessage: users_response },
    vacancies: { responseMessage: vacancies_response },
    skills: { responseMessage: skills_response },
    tags: { responseMessage: tags_response },
    departments: { responseMessage: department_response }
  } = useAppSelector((store) => store);

  useNotifications(steps_response, stepsSlice.actions.clearResponseMessages);
  useNotifications(kanban_response, kanbanSlice.actions.clearResponseMessages);
  useNotifications(candidates_response, candidatesSlice.actions.clearResponseMessages);
  useNotifications(events_response, eventsSlice.actions.clearResponseMessages);
  useNotifications(profile_response, profileSlice.actions.clearResponseMessages);
  useNotifications(dashboard_response, dashboardSlice.actions.clearResponseMessages);
  useNotifications(platforms_response, platformsSlice.actions.clearResponseMessages);
  useNotifications(users_response, usersSlice.actions.clearResponseMessages);
  useNotifications(vacancies_response, vacanciesSlice.actions.clearResponseMessages);
  useNotifications(skills_response, skillsSlice.actions.clearResponseMessages);
  useNotifications(tags_response, tagsSlice.actions.clearResponseMessages);
  useNotifications(department_response, departmentsSlice.actions.clearResponseMessages);

  return <></>;
};

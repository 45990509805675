import { useAppSelector } from '@app/store/store';
import { ProfileHeader } from '@components/ProfileHeader/ProfileHeader';
import { TabContext, TabContextContentType } from '@components/Tabs/TabContext/TabContext';
import { useEmailHashIcon } from '@hooks/useEmailHashIcon';
import { Box, Container, Skeleton } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { ChangePasswordTab } from './Tabs/ChangePasswordTab';
import { InformationTab } from './Tabs/InformationTab';
import { PlanAndBillingTab } from './Tabs/PlanAndBillingTab';

const tabs: TabContextContentType[] = [
  {
    tabHeader: {
      label: 'Information',
      id: 'information'
    },
    tabBody: <InformationTab />
  },
  {
    tabHeader: {
      label: 'Change password',
      id: 'changePassword'
    },
    tabBody: <ChangePasswordTab />
  },
  {
    tabHeader: {
      label: 'Plan & Billing',
      id: 'planAndBilling'
    },
    tabBody: <PlanAndBillingTab />
  }
];

export const ProfileModule: FC = () => {
  const { credentials, status } = useAppSelector((store) => store.profile);
  const { image } = useEmailHashIcon(credentials?.email, 'robohash');

  const isAdmin = useMemo(() => credentials?.role === 'admin', [credentials?.role]);

  const data = useMemo<TabContextContentType[]>(() => (isAdmin ? tabs : tabs.filter((item) => item.tabHeader.id !== 'planAndBilling')), [isAdmin]);

  return (
    <Box>
      <Container className="max-w-[1200px]">
        {credentials && (
          <ProfileHeader
            className="mb-5"
            subtitleIsID
            title={`${credentials.first_name} ${credentials.last_name || ''}`}
            photo={image}
            email={credentials.contacts?.find((contact) => contact.type === 'email')?.value}
            subtitle={credentials.id}
          />
        )}
        {!credentials && status === 'loading' && (
          <Box className="flex flex-wrap mb-5">
            <Skeleton variant="circular" className="mr-4 mb-2 w-[64px] h-[64px]" />
            <Box>
              <Skeleton variant="text" width={210} height={40} className="mb-2 text-gray-900 font-black" />
              <Skeleton variant="text" width={280} height={24} className="text-gray-900 font-black" />
            </Box>
          </Box>
        )}

        <TabContext content={data} />
      </Container>
    </Box>
  );
};

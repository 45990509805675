import { KanbanVacancyCardProps } from '@modules/kanban/interface';
import { Avatar, Box, Card, Divider, Typography } from '@mui/material';
import classNames from 'classnames';
import { format as formatFns, isValid } from 'date-fns';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

export const KanbanVacancyCard = ({ className, name, candidates, id, steps, created_at, updated_at }: KanbanVacancyCardProps): ReactElement => {
  return (
    <Card
      component={Link}
      to={id}
      className={classNames(className, 'no-underline p-4 shadow max-w-1/3 min-w-[260px] flex-1 hover:shadow-xl transition-shadow h-[300px] flex flex-col justify-between')}
    >
      <Box>
        <Avatar className={classNames('bg-brand-100 text-brand font-bold w-14 h-14 mb-2')}>{name?.[0] ?? ''}</Avatar>
        <Typography className="font-bold text-gray-500 mb-2" variant="subtitle1">
          {name}
        </Typography>
        <Box className="flex flex-col">
          <Typography className="mb-2" variant="body2">
            <span className="text-gray-400">Candidates: </span>
            <span className="text-gray-500 font-bold">{candidates ?? 0}</span>
          </Typography>
        </Box>
        <Box className="overflow-y-auto max-h-[66px] overflow-hidden scrollbar pr-1 ">
          <Typography className="inline" variant="body2">
            <span className="text-gray-400">Steps: </span>
          </Typography>
          <Typography className="inline" variant="caption">
            <span className="text-gray-500">
              {steps.map((step, index, arr) => (
                <span key={step.id} className="text-gray-500">{`${step.name}${index === arr.length - 1 ? '' : ' - '}`}</span>
              ))}
            </span>
          </Typography>
        </Box>
        {/* <Box className="relative"> */}
        {/*   {steps.map(({ color, id, name }, index, arr) => ( */}
        {/*     <Box */}
        {/*       style={{ zIndex: index }} */}
        {/*       key={id} */}
        {/*       className={classNames( */}
        {/*         'py-1 px-2 w-fit relative rounded-full overflow-hidden whitespace-nowrap border border-solid border-white text-white transition-all font-bold', */}
        {/*         'hover:shadow-xl hover:z-10', */}
        {/*         `bg-${color}-500`, */}
        {/*         'hover:mb-3 mt-0', */}
        {/*         { '-mt-3': index } */}
        {/*       )} */}
        {/*     > */}
        {/*       <Typography className="overflow-hidden" variant="caption"> */}
        {/*         {name} */}
        {/*       </Typography> */}
        {/*     </Box> */}
        {/*   ))} */}
        {/* </Box> */}
      </Box>
      <Box className="flex flex-col">
        <Divider className="my-4" />
        <Typography className="text-gray-500" variant="caption">
          <span className="text-gray-400">Create: </span>
          {isValid(new Date(created_at)) && formatFns(new Date(created_at), 'dd MMMM yyyy, HH:mm')}
        </Typography>
        <Typography className="text-gray-500" variant="caption">
          <span className="text-gray-400">Update: </span>
          {isValid(new Date(created_at)) && formatFns(new Date(updated_at), 'dd MMMM yyyy, HH:mm')}
        </Typography>
      </Box>
    </Card>
  );
};

import { createSlice } from '@reduxjs/toolkit';
import { TEMPLATES } from '@slices/templates/interface';

const initialState: TEMPLATES.TemplatesSlice = {
  status: 'unset',
  responseMessage: null
};

export const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    clearResponseMessages: (state) => {
      state.responseMessage = null;
    }
  }
});

import { InputTextField } from '@components/Fields/InputText/InputTextField';
import { Box, CardHeader, Divider } from '@mui/material';
import React, { FC } from 'react';

export const CandidateAdditionalInfoSection: FC = () => {
  return (
    <>
      <CardHeader title="Additional information" className="uppercase text-gray-600 pb-2" />
      <Divider />

      <Box className="px-4 py-8 grid gap-4 grid-cols-1">
        <InputTextField className="col-span-full" multiline minRows={3} name="comment" label="Commentaries" />
      </Box>
    </>
  );
};

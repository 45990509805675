import { Checkbox, FormControlLabel, FormControlLabelProps } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';

interface InputCheckboxFieldProps extends Omit<FormControlLabelProps, 'control'> {
  name: string;
  unregister?: boolean;
  validationOptions?: RegisterOptions;
}

export const InputCheckboxField: FC<InputCheckboxFieldProps> = ({ name, label, validationOptions, unregister, ...formControlProps }) => {
  const methods = useFormContext();
  const { onChange, ...register } = methods?.register(name, validationOptions || {}) || {};
  const [state, setState] = useState(false);

  useEffect(() => {
    unregister && methods?.unregister(name);
  }, []);

  useEffect(() => {
    setState(!!methods.getValues(name));
  }, [methods]);

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    methods.setValue(name, e.target.checked);
    setState(e.target.checked);
  };

  return <FormControlLabel {...formControlProps} label={label} control={<Checkbox checked={state} onChange={changeHandler} {...register} />} />;
};

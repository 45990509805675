import { LoginForm } from '@components/Forms/Auth/LoginForm';
import { Box, Paper, Typography } from '@mui/material';
import React, { FC } from 'react';

export const LoginPage: FC = () => {
  return (
    <Paper className="shadow overflow-hidden">
      <Box className="text-start bg-brand text-white mb-10 px-6 pt-6 pb-4">
        <Typography variant="h4" fontWeight="bold" textTransform="uppercase" className="font-black">
          Login
        </Typography>
        <Typography className="opacity-50" variant="caption">
          Login in the internal platform
        </Typography>
      </Box>
      <LoginForm className="px-6 pb-6" />
    </Paper>
  );
};

import { GoBackButton } from '@components/Buttons/GoBack/GoBackButton';
import { CreateAndEditCandidateForm } from '@components/Forms/Candidates/CreateAndEditCandidateForm';
import { Box, Container, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useParams } from 'react-router';

export const CandidateEditPage: FC = () => {
  const params = useParams();
  return (
    <Box>
      <Container className="p-0 max-w-[1200px]">
        <GoBackButton to={`/candidates/${params.id}`} text="Back to candidate" />
        <Box className="flex flex-wrap mb-5">
          <Box>
            <Typography className="uppercase mb-2 text-gray-300 font-black" fontWeight="bold" variant="h4">
              Edit Candidate
            </Typography>
          </Box>
        </Box>
        <CreateAndEditCandidateForm mode="edit" />
      </Container>
    </Box>
  );
};

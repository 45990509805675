import { useAppDispatch, useAppSelector } from '@app/store/store';
import { FormWrapper } from '@components/Forms/FormWrapper';
import { kanbanSlice } from '@slices/kanban/kanbanSlice';
import { VACANCIES } from '@slices/vacancies/interface';
import React, { FC, ReactElement, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Outlet } from 'react-router';
import { useNavigate, useParams } from 'react-router-dom';

export const KanbanModule: FC = (): ReactElement => {
  const methods = useForm<{ vacancy: VACANCIES.VacancyListItem['id'] }>();
  const dispatch = useAppDispatch();
  const selected_vacancy_id = useAppSelector((store) => store.kanban.selected_vacancy_id);
  const { vacancy_id } = useParams();
  const navigate = useNavigate();

  const init = (): void => {
    selected_vacancy_id && navigate(selected_vacancy_id);
    methods.setValue('vacancy', (vacancy_id || selected_vacancy_id) ?? '');
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    const subscribe = methods.watch((fields) => {
      if (fields.vacancy) {
        navigate(fields.vacancy);
        dispatch(kanbanSlice.actions.setVacancyID(fields.vacancy));
      } else {
        navigate('/kanban');
        dispatch(kanbanSlice.actions.setVacancyID(''));
      }
    });

    return () => subscribe.unsubscribe();
  }, [methods.watch]);

  return (
    <FormWrapper className="flex h-full flex-col flex-1 absolute inset-0" methods={methods}>
      <Outlet />
    </FormWrapper>
  );
};

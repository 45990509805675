import { CreateStepDTO, StepsService } from '@API/services/steps/stepsService';
import { AfterActionProps, BaseAsyncThunkOptions, ListResponse } from '@app/store/interface';
import { filterKanbanCards } from '@app/store/reduxHelpers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { STEPS } from '@slices/steps/interface';

export const getAllSteps = createAsyncThunk<ListResponse<STEPS.Step[]>, undefined, BaseAsyncThunkOptions>('steps/getAll', async (_, thunkApi) => {
  const response = await StepsService.getAll();

  if (response.ok) {
    const sortCandidates = (candidates: STEPS.CandidatesInStep[]): STEPS.CandidatesInStep[] => {
      return [...candidates].sort((a, b) => (a.ordering < b.ordering ? -1 : 1));
    };
    const sortSteps = (steps: STEPS.Step[]): STEPS.Step[] => {
      return [...steps]
        .sort((a, b) => (a.ordering < b.ordering ? -1 : 1))
        .map((step) => ({
          ...step,
          candidates: sortCandidates(step.candidates || [])
        }));
    };
    const filteredData = response.data.data.map((step) => {
      const candidates = filterKanbanCards(step.candidates, step.candidate_step, 'pending');
      return {
        ...step,
        candidates
      };
    });
    return {
      data: sortSteps(filteredData),
      total: response.data.total
    };
  } else {
    return thunkApi.rejectWithValue(response.error);
  }
});

export const getOneStep = createAsyncThunk<STEPS.Step, string, BaseAsyncThunkOptions>('steps/getOne', async (id, thunkApi) => {
  const response = await StepsService.getOne(id);

  if (response.ok) {
    return response.data.data;
  } else {
    return thunkApi.rejectWithValue(response.error);
  }
});

export const createStep = createAsyncThunk<STEPS.Step, CreateStepDTO & AfterActionProps, BaseAsyncThunkOptions>('steps/createTag', async ({ afterAction, ...data }, thunkApi) => {
  const response = await StepsService.create(data);

  if (response.ok) {
    afterAction && afterAction();
    return response.data.data;
  } else {
    return thunkApi.rejectWithValue(response.error);
  }
});

export const updateStep = createAsyncThunk<STEPS.Step, Partial<CreateStepDTO> & { id: string } & AfterActionProps, BaseAsyncThunkOptions>(
  'steps/update',
  async ({ id, afterAction, ...data }, thunkApi) => {
    const response = await StepsService.updateOne(id, data);

    if (response.ok) {
      afterAction && afterAction();
      return response.data.data;
    } else {
      return thunkApi.rejectWithValue(response.error);
    }
  }
);

import { CashCurrencyType } from '@app/store/interface';

export const ROLES = [
  { id: 'recruiter', value: 'recruiter', label: 'Recruiter' },
  { id: 'admin', value: 'admin', label: 'Admin' }
];

export const USER_STATUSES = [
  { id: 'active', value: 'active', label: 'Active' },
  { id: 'blocked', value: 'blocked', label: 'Blocked' }
];

export const VACANCY_STATUSES = [
  { id: 'open', value: 'open', label: 'Open' },
  { id: 'close', value: 'close', label: 'Close' }
];

export const CURRENCIES: { value: CashCurrencyType; label: string, id: string }[] = [
  { id: 'USD', value: 'USD', label: '$' },
  { id: 'UAH', value: 'UAH', label: '₴' },
  { id: 'GBP', value: 'GBP', label: '£' },
  { id: 'EUR', value: 'EUR', label: '€' },
  { id: 'KZT', value: 'KZT', label: '₸' },
  { id: 'ILS', value: 'ILS', label: '₪' },
  { id: 'JPY', value: 'JPY', label: '¥' },
  { id: 'POTATOES', value: 'POTATOES', label: '₽' },
  { id: 'WASHING MACHINES', value: 'WASHING MACHINES', label: '₽' }
];

export const EXPERIENCE_VARIANTS = [
  { id: 0, value: 0, label: 'No experience' },
  { id: 0.5, value: 0.5, label: '6 month' },
  { id: 1, value: 1, label: '1 year' },
  { id: 1.5, value: 1.5, label: '1.5 years' },
  { id: 2, value: 2, label: '2 years' },
  { id: 3, value: 3, label: '3 years' },
  { id: 4, value: 4, label: '4 years' },
  { id: 5, value: 5, label: '5 years' }
];

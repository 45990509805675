import { API } from '@API/constants';
import { ResponseBaseType, ResponseListBaseType } from '@API/services/interfaces';
import { fetchData } from '@helpers/fetchData/fetchData';
import { TAGS } from '@slices/tags/interface';

const path = `${API.origin}/tags`;

export class TagsService {
  public static create<T = TAGS.Tag>(dto: Omit<TAGS.Tag, 'id'>) {
    return fetchData<ResponseBaseType<T>>(path, 'POST', dto);
  }

  public static getAll<T = TAGS.Tag[]>() {
    return fetchData<ResponseListBaseType<T>>(path);
  }

  public static getOne<T = TAGS.Tag>(id: string) {
    return fetchData<ResponseBaseType<T>>(`${path}/${id}`);
  }

  public static updateOne<T = TAGS.Tag>(id: string, dto: Omit<Partial<TAGS.Tag>, 'id'>) {
    return fetchData<ResponseBaseType<T>>(`${path}/${id}`, 'PATCH', dto);
  }

  public static deleteOne<T = TAGS.Tag>(id: string) {
    return fetchData<ResponseBaseType<T>>(`${path}/${id}`, 'DELETE');
  }
}

import { useAppDispatch, useAppSelector } from '@app/store/store';
import { CenterLoader } from '@components/Loaders/CenterLoader';
import { TagsListActionsPopover } from '@components/Popovers/TagsActions/TagsListActionsPopover';
import { TagsListComponent } from '@modules/tags/components/ListComponent/TagsListComponent';
import { TagsSearch } from '@modules/tags/components/Search/TagsSearch';
import Add from '@mui/icons-material/Add';
import HelpOutline from '@mui/icons-material/HelpOutline';
import { Box, Button, List, Paper, Popover, Typography } from '@mui/material';
import { TAGS } from '@slices/tags/interface';
import { getAllTags } from '@slices/tags/thunks';
import { FC, MouseEvent, useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';

export const TagsListPage: FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { tags, status, search } = useAppSelector((store) => store.tags);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllTags());
  }, [dispatch]);

  const filterHandler = (item: TAGS.Tag) => {
    if (search === '') {
      return item;
    }

    if (item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
      return item;
    }
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };
  const list = useMemo(() => tags.data.filter(filterHandler), [search, tags]);

  return (
    <Box className="max-h-full flex flex-col overflow-hidden">
      <Box className="flex mb-4 flex-wrap items-center gap-4 justify-between">
        <Typography variant="h4" fontWeight="bold" textTransform="uppercase" className="text-gray-300 font-black">
          Tags
        </Typography>
        <Button startIcon={<Add />} variant="contained" component={NavLink} to="new">
          create
        </Button>
      </Box>
      <Paper className="p-4 mb-2 shadow max-h-full flex flex-col overflow-hidden">
        <TagsSearch />
        <Box className="overflow-y-auto relative">
          <List className="relative flex flex-col gap-4 py-0 min-h-[100px]">
            {list.map((item) => (
              <TagsListComponent item={item} key={item.id} onActionClick={handleClick} />
            ))}

            {status !== 'loading' && !list.length && (
              <Box className="bg-white w-full h-full inset-0 flex overflow-hidden justify-center items-center z-20">
                <Box className="relative w-full h-full justify-center items-center flex">
                  <HelpOutline className="w-[80px] -mr-5 h-full text-gray-300" />
                  <Typography className="relative z-10 bg-white text-gray-300 font-bold uppercase" variant="h5">
                    No data...
                  </Typography>
                </Box>
              </Box>
            )}
          </List>
          {status === 'loading' && <CenterLoader />}
        </Box>

        <Popover className="mt-2" open={!!anchorEl} onClose={handleClose} onClick={handleClose} anchorEl={anchorEl}>
          {anchorEl && anchorEl.ariaLabel && <TagsListActionsPopover id={anchorEl.ariaLabel} />}
        </Popover>
      </Paper>
    </Box>
  );
};

import { useAppSelector } from '@app/store/store';
import { DatePickerField } from '@components/Fields/DatePicker/DatePickerField';
import { InputMultiselect } from '@components/Fields/InputMultiselect/InputMultiselect';
import { InputTextField } from '@components/Fields/InputText/InputTextField';
import { TagsMultiselect } from '@components/Fields/TagsMultiselect/TagsMultiselect';
import { Box, CardHeader, Divider } from '@mui/material';
import React, { FC } from 'react';

export const CandidateMainInfoSection: FC = () => {
  const {
    status: skills_status,
    skills: { data: skills }
  } = useAppSelector((store) => store.skills);

  return (
    <>
      <CardHeader title="Main information" className="uppercase text-gray-600 pb-2" />
      <Divider />
      <Box className="px-4 py-8 grid gap-4 xl:grid-cols-2 grid-cols-1">
        <InputTextField name="first_name" label="First name" />

        <InputTextField name="last_name" label="Last name" />

        <InputTextField name="email" label="Email" />

        <DatePickerField name="birth_date" label="Birth day" />

        <InputTextField name="country" label="Country" />

        <InputTextField name="city" label="City" />

        <InputMultiselect
          className="col-span-full"
          label="Candidate skills"
          loading={skills_status === 'loading'}
          name="skills"
          options={skills}
          optionLabelRefName="name"
          optionValueRefName="id"
        />

        <TagsMultiselect className="col-span-full" name="tags" />
      </Box>
    </>
  );
};

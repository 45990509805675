import { useAppDispatch, useAppSelector } from '@app/store/store';
import { useModal } from '@hooks/useModal';
import Delete from '@mui/icons-material/Delete';
import { Divider, FormControlLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Switch } from '@mui/material';
import { deleteUser, updateUser } from '@slices/users/thunks';
import { FC, SyntheticEvent } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

export const UserActionsPopover: FC = () => {
  const { data, status } = useAppSelector((store) => store.users.user);
  const params = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { openModal, closeModal } = useModal();

  const handleDelete = () => {
    openModal({
      modalName: 'CONFIRM_MODAL',
      modalProps: {
        title: `Delete user ${data?.first_name || ''} ${data?.last_name || ''}?`,
        subtitle: 'You will not be able to undo this action',

        successAction: () => {
          if (params.id) {
            dispatch(
              deleteUser({
                id: params.id,
                afterAction: () => {
                  closeModal('CONFIRM_MODAL');
                  navigate('/users');
                }
              })
            );
          }
        }
      }
    });
  };

  const handleBlocked = () => {
    if (params.id) {
      dispatch(
        updateUser({
          id: params.id,
          status: 'blocked',
          afterAction: () => {
            closeModal('CONFIRM_MODAL');
          }
        })
      );
    }
  };

  const handleActivate = () => {
    if (params.id) {
      dispatch(
        updateUser({
          id: params.id,
          status: 'active',
          afterAction: () => {
            closeModal('CONFIRM_MODAL');
          }
        })
      );
    }
  };

  const handleSwitch = (_: SyntheticEvent, checked: boolean): void => {
    if (checked) {
      handleActivate();
    } else {
      handleBlocked();
    }
  };

  return (
    <List dense className="min-w-[160px]">
      <ListItem className="px-4">
        <FormControlLabel disabled={status === 'loading'} onChange={handleSwitch} checked={data?.status === 'active'} control={<Switch size="small" />} label="Active" />
      </ListItem>
      <Divider className="my-1" />
      <ListItemButton onClick={handleDelete} className="text-red-500 mx-2 px-0">
        <ListItemIcon className="text-red-500 min-w-fit mr-2">
          <Delete />
        </ListItemIcon>
        <ListItemText className="uppercase font-bold">Delete</ListItemText>
      </ListItemButton>
    </List>
  );
};

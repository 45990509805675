import { CandidatesListActions } from '@modules/candidates/components/CandidatesListActions/CandidatesListActions';
import { CandidateListTable } from '@modules/candidates/components/Tables/CandidateListTable';
import { Box, Paper, Typography } from '@mui/material';
import React, { FC } from 'react';

export const CandidatesListPage: FC = () => {
  return (
    <>
      <Box className="flex mb-4 flex-wrap items-center gap-4 justify-between">
        <Typography variant="h4" fontWeight="bold" textTransform="uppercase" className="text-gray-300 font-black">
          Candidates
        </Typography>
        <CandidatesListActions />
      </Box>

      <Paper className="p-4 mb-2 shadow max-h-full flex flex-col overflow-hidden">
        <CandidateListTable />
      </Paper>
    </>
  );
};

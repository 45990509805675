import ArrowBack from '@mui/icons-material/ArrowBack';
import { Typography } from '@mui/material';
import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface GoBackButtonProps {
  to: string;
  text: ReactNode;
  className?: string;
}

export const GoBackButton: FC<GoBackButtonProps> = ({ text, to, className }) => {
  return (
    <Link to={to} className={classNames('inline-flex items-center justify-center text-gray-300 hover:text-gray-400 no-underline uppercase transition-all', className)}>
      <ArrowBack fontSize="small" className="mr-1" />
      <Typography variant="subtitle1" fontWeight="bold">
        {text}
      </Typography>
    </Link>
  );
};

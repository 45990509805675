import { StorageItemsKeys, StorageItemsValues } from '@helpers/localStorage/interface';

export const addStorageItem = <K extends StorageItemsKeys>(key: K, value: StorageItemsValues[K]): void => {
  localStorage.setItem(key.toString(), typeof value === 'string' ? value : JSON.stringify(value));
};

export const getStorageItem = <K extends StorageItemsKeys>(key: K): StorageItemsValues[K] | null | string => {
  const data = localStorage.getItem(key);
  if (!data) return null;

  try {
    return JSON.parse(data);
  } catch (e) {
    return data;
  }
};

export const removeStorageItem = (key: StorageItemsKeys | StorageItemsKeys[]): void => {
  if (Array.isArray(key)) {
    key.forEach((item) => localStorage.removeItem(item));
  } else {
    localStorage.removeItem(key);
  }
};

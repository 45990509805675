import { API } from '@API/constants';
import { ResponseBaseType, ResponseListBaseType } from '@API/services/interfaces';
import { fetchData } from '@helpers/fetchData/fetchData';
import { STEPS } from '@slices/steps/interface';

const path = `${API.origin}/steps`;

export interface CreateStepDTO {
  name: string;
  comment: string;
  color?: string;
  default?: boolean;
  closing?: boolean;
}

export class StepsService {
  public static create<T = STEPS.Step>(dto: CreateStepDTO) {
    return fetchData<ResponseBaseType<T>>(path, 'POST', dto);
  }

  public static getAll<T = STEPS.Step[]>() {
    return fetchData<ResponseListBaseType<T>>(path);
  }

  public static getAllForDashboard<T = STEPS.Step[]>(period: string) {
    return fetchData<ResponseListBaseType<T>>(`${path}/dashboard`, 'POST', { period });
  }

  public static getOne<T = STEPS.Step>(id: string, query?: string) {
    return fetchData<ResponseBaseType<T>>(`${path}/${id}${query}`);
  }

  public static updateOne<T = STEPS.Step>(id: string, dto: Partial<CreateStepDTO>) {
    return fetchData<ResponseBaseType<T>>(`${path}/${id}`, 'PATCH', dto);
  }

  public static updateOrder<T = STEPS.Step[]>(id: string, dto: Pick<STEPS.Step, 'ordering'> & { return_all: boolean }) {
    return fetchData<ResponseListBaseType<T>>(`${path}/${id}`, 'PATCH', dto);
  }

  public static deleteOne<T = STEPS.Step>(id: string) {
    return fetchData<ResponseBaseType<T>>(`${path}/${id}`, 'DELETE');
  }
}

import { useAppSelector } from '@app/store/store';
import AdditionalContactsList from '@components/AdditionalContactInfo/AdditionalContactsList';
import { EventsSection } from '@components/Events/SectionCard/EventsSection';
import AdminPanelSettingsOutlined from '@mui/icons-material/AdminPanelSettingsOutlined';
import ChatOutlined from '@mui/icons-material/ChatOutlined';
import MailOutlined from '@mui/icons-material/MailOutlined';
import NotificationsActiveOutlined from '@mui/icons-material/NotificationsActiveOutlined';
import PersonOutlined from '@mui/icons-material/PersonOutlined';
import { Box, Card, CardHeader, Divider, Link as ALink, ListItem, ListItemText, Typography } from '@mui/material';
import React, { FC } from 'react';

export const UserInfoPage: FC = () => {
  const { data } = useAppSelector((store) => store.users.user);
  return (
    <Box>
      {!!data && (
        <Box className="flex gap-4 items-start flex-col xl:flex-row">
          <Box className="flex-1 w-full">
            <Card className="shadow my-4">
              <CardHeader title="Main information" className="uppercase text-gray-600 pb-2" />
              <Divider />
              <ListItem className="flex flex-wrap hover:bg-gray-100/75 transition-all">
                <ListItemText className="md:w-full md:max-w-[180px] mr-4">
                  <Box className="flex items-center">
                    <PersonOutlined className="fill-gray-500 mr-2" />
                    <Typography variant="subtitle1">Name</Typography>
                  </Box>
                </ListItemText>
                <ListItemText>
                  <Typography variant="subtitle2" className="text-right md:text-left opacity-70">
                    {`${data?.first_name ?? ''} ${data?.last_name ?? ''}` ?? '-'}
                  </Typography>
                </ListItemText>
              </ListItem>

              <Divider />

              <ListItem className="flex flex-wrap hover:bg-gray-100/75 transition-all">
                <ListItemText className="w-full max-w-[180px] mr-4">
                  <Box className="flex items-center">
                    <MailOutlined className="fill-gray-500 mr-2" />
                    <Typography variant="subtitle1">Email</Typography>
                  </Box>
                </ListItemText>
                <ListItemText>
                  <Typography variant="subtitle2" className="text-right lg:text-left opacity-70">
                    <ALink underline="hover" className="mr-2 hover:opacity-70 transition-all text-black whitespace-nowrap" type="email" href={`mailto:${data.email}`}>
                      {data.email}
                    </ALink>
                  </Typography>
                </ListItemText>
              </ListItem>

              <Divider />

              <ListItem className="flex flex-wrap hover:bg-gray-100/75 transition-all">
                <ListItemText className="w-full max-w-[180px] mr-4">
                  <Box className="flex items-center">
                    <AdminPanelSettingsOutlined className="fill-gray-500 mr-2" />
                    <Typography variant="subtitle1">Role</Typography>
                  </Box>
                </ListItemText>
                <ListItemText>
                  <Typography variant="subtitle2" className="text-right lg:text-left opacity-70">
                    {data.role ?? '-'}
                  </Typography>
                </ListItemText>
              </ListItem>

              <Divider />

              <ListItem className="flex flex-wrap hover:bg-gray-100/75 transition-all">
                <ListItemText className="w-full max-w-[180px] mr-4">
                  <Box className="flex items-center">
                    <NotificationsActiveOutlined className="fill-gray-500 mr-2" />
                    <Typography variant="subtitle1">Status</Typography>
                  </Box>
                </ListItemText>
                <ListItemText>
                  <Typography variant="subtitle2" className="text-right lg:text-left opacity-70">
                    {data.status ?? '-'}
                  </Typography>
                </ListItemText>
              </ListItem>

              <Divider />

              <ListItem className="flex flex-wrap max-h-[200px] overflow-y-auto hover:bg-gray-100/75 transition-all">
                <ListItemText className="w-full max-w-[180px] mr-4">
                  <Box className="flex items-center">
                    <ChatOutlined className="fill-gray-500 mr-2" />
                    <Typography variant="subtitle1">Comment</Typography>
                  </Box>
                </ListItemText>
                <ListItemText>
                  <Typography variant="subtitle2" className="opacity-70">
                    {data.comment ?? '-'}
                  </Typography>
                </ListItemText>
              </ListItem>
            </Card>

            {!!data.contacts?.length && (
              <Card className="shadow my-4">
                <CardHeader title="Additional information" className="uppercase text-gray-600 pb-2" />
                <Divider />

                <AdditionalContactsList contacts={data.contacts ?? []} />
              </Card>
            )}

            <Card className="shadow my-4">
              <ListItem className="flex flex-wrap">
                <ListItemText className="w-full max-w-[180px] mr-4">
                  <Typography variant="subtitle1">Registration date</Typography>
                </ListItemText>
                <ListItemText>
                  <Typography variant="subtitle2" className="text-right lg:text-left opacity-70">
                    {data.created_at ? new Date(data.created_at)?.toLocaleString('ru-Ru') : 'No data'}
                  </Typography>
                </ListItemText>
              </ListItem>
              <Divider />

              <ListItem className="flex flex-wrap">
                <ListItemText className="w-full max-w-[180px] mr-4">
                  <Typography variant="subtitle1">Last update date</Typography>
                </ListItemText>
                <ListItemText>
                  <Typography variant="subtitle2" className="text-right lg:text-left opacity-70">
                    {data.updated_at ? new Date(data.updated_at)?.toLocaleString('ru-Ru') : 'No data'}
                  </Typography>
                </ListItemText>
              </ListItem>
            </Card>
          </Box>

          <Box className="flex-1 xl:my-4 mb-4 w-full xl:max-w-[360px] flex xl:flex-col gap-4 flex-col md:flex-row items-start">
            <EventsSection events={data.events ?? []} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

import { CandidatesModule } from '@modules/candidates/CandidatesModule';
import { DashboardModule } from '@modules/dashboard/DashboardModule';
import { DepartmentsModule } from '@modules/departments/DepartmentsModule';
import { KanbanModule } from '@modules/kanban/KanbanModule';
import { PlatformsModule } from '@modules/platfom/PlatformsModule';
import { SkillsModule } from '@modules/skills/SkillsModule';
import { StepsModule } from '@modules/steps/StepsModule';
import { TagsModule } from '@modules/tags/TagsModule';
import { UsersModule } from '@modules/users/UsersModule';
import { VacanciesModule } from '@modules/vacancies/VacanciesModule';
import AirlineStops from '@mui/icons-material/AirlineStops';
import ContactPage from '@mui/icons-material/ContactPage';
import Dashboard from '@mui/icons-material/Dashboard';
import FactCheck from '@mui/icons-material/FactCheck';
import Group from '@mui/icons-material/Group';
import GroupWork from '@mui/icons-material/GroupWork';
import LocalOffer from '@mui/icons-material/LocalOffer';
import ViewKanban from '@mui/icons-material/ViewKanban';
import Web from '@mui/icons-material/Web';
import Work from '@mui/icons-material/Work';

export const APP_ROUTES_LIST = [
  {
    path: '/',
    element: DashboardModule,
    icon: Dashboard,
    title: 'Dashboard'
  },
  {
    path: 'users',
    element: UsersModule,
    icon: Group,
    title: 'Users'
  },
  {
    path: 'candidates',
    element: CandidatesModule,
    icon: ContactPage,
    title: 'Candidates'
  },
  {
    path: 'vacancies',
    element: VacanciesModule,
    icon: Work,
    title: 'Vacancies'
  },
  {
    path: 'kanban',
    element: KanbanModule,
    icon: ViewKanban,
    title: 'Kanban'
  },
  // {
  //   path: 'templates',
  //   element: TemplatesModule,
  //   icon: Assignment,
  //   title: 'Templates'
  // },
  {
    path: 'tags',
    element: TagsModule,
    icon: LocalOffer,
    title: 'Tags'
  },
  {
    path: 'skills',
    element: SkillsModule,
    icon: FactCheck,
    title: 'Skills'
  },
  {
    path: 'steps',
    element: StepsModule,
    icon: AirlineStops,
    title: 'Steps'
  },
  {
    path: 'platforms',
    element: PlatformsModule,
    icon: Web,
    title: 'Platforms'
  },
  {
    path: 'departments',
    element: DepartmentsModule,
    icon: GroupWork,
    title: 'Departments'
  }
] as const;

import { StepsFieldsStatuses } from '@API/services/candidates/candidatesService';
import { RequestStatus } from '@app/store/interface';
import { useAppDispatch } from '@app/store/store';
import { CandidateStepConfirmationModalParams } from '@components/Modals/CandidateStepComment/interface';
import StepIcon from '@components/Steps/StepsViewer/StepIcon';
import { useModal } from '@hooks/useModal';
import CheckCircle from '@mui/icons-material/CheckCircle';
import DoDisturbOn from '@mui/icons-material/DoDisturbOn';
import FmdGoodOutlined from '@mui/icons-material/FmdGoodOutlined';
import UndoOutlined from '@mui/icons-material/UndoOutlined';
import { Alert, AlertTitle, Box, Button, ButtonGroup, Step, StepContent, StepLabel, Stepper, Tooltip, Typography } from '@mui/material';
import { updateCandidateStep } from '@slices/candidates/thunks';
import { STEPS } from '@slices/steps/interface';
import classNames from 'classnames';
import { FC, useEffect, useMemo, useState } from 'react';

interface StepsViewerProps {
  steps: StepsFields[];
  update_status?: RequestStatus;
  vacancy_id?: string;
  onlyView?: boolean;
  loading?: boolean;
  className?: string;
  candidate_id?: string;
}

export interface StepsFields extends STEPS.Step {
  status?: StepsFieldsStatuses;
  candidate_comment?: string;
}

export const StepsViewer: FC<StepsViewerProps> = ({ steps: defaultSteps, className, onlyView, vacancy_id, candidate_id, update_status }) => {
  const { openModal, closeModal } = useModal();
  const steps = useMemo(() => [...defaultSteps].sort((a, b) => (a.ordering < b.ordering ? -1 : 1)), [defaultSteps]);
  const currentStep = useMemo(() => {
    const pendingStatus = steps.find((step) => step.status === 'pending');
    return pendingStatus ?? [...steps].reverse().find((step) => step.status === 'success' || step.status === 'reject');
  }, [steps]);
  const pendingStep = useMemo(() => steps.find((step) => step.status === 'pending'), [steps]);
  const [selectedStep, setSelectedStep] = useState<number | undefined>(undefined);
  const dispatch = useAppDispatch();

  useEffect(() => {
    handleInitialStep();
  }, []);

  const handleInitialStep = (): void => {
    if (currentStep) {
      const index = steps.findIndex((step) => step.id === currentStep.id);
      setSelectedStep(index === -1 ? 0 : index);
    }
  };

  const handlePendingStep = (): void => {
    if (pendingStep) {
      const index = steps.findIndex((step) => step.id === pendingStep.id);
      setSelectedStep(index);
    }
  };

  const handleStatus = (step: StepsFields, type: StepsFieldsStatuses) => {
    if (candidate_id && vacancy_id && update_status !== 'loading') {
      dispatch(
        updateCandidateStep({
          candidate_id,
          steps: [
            {
              status: step.status !== type ? type : 'unset',
              step_id: step.id
            }
          ],
          vacancy_id
        })
      );
    }
  };

  const handleOpenModal = (step: StepsFields, modalMode: CandidateStepConfirmationModalParams['modalProps']['mode']) => {
    if (modalMode === 'closing') {
      openModal({
        modalName: 'CANDIDATE_STEP_CONFIRMATION_MODAL',
        modalProps: {
          step,
          mode: 'closing',
          content: {
            title: `The "${step.name}" step is the final one. After confirmation, the vacancy will be considered closed for this candidate.`,
            subtitle: 'You can always open a vacancy again undoing the closing step.'
          },
          successAction: (comment) => {
            if (candidate_id && vacancy_id) {
              dispatch(
                updateCandidateStep({
                  candidate_id,
                  steps: [
                    {
                      status: 'pending',
                      step_id: step.id,
                      comment
                    }
                  ],
                  vacancy_id,
                  afterAction: () => closeModal('CANDIDATE_STEP_CONFIRMATION_MODAL')
                })
              );
            }
          }
        }
      });
    }

    if (modalMode === 'rejected') {
      openModal({
        modalName: 'CANDIDATE_STEP_CONFIRMATION_MODAL',
        modalProps: {
          step,
          mode: 'rejected',
          content: {
            title: 'You may specify the reason of rejection',
            subtitle: 'You can always return this step to its original state'
          },
          successAction: (comment) => {
            if (candidate_id && vacancy_id) {
              dispatch(
                updateCandidateStep({
                  candidate_id,
                  steps: [
                    {
                      status: 'reject',
                      step_id: step.id,
                      comment
                    }
                  ],
                  vacancy_id,
                  afterAction: () => closeModal('CANDIDATE_STEP_CONFIRMATION_MODAL')
                })
              );
            }
          }
        }
      });
    }
  };

  return (
    <Box className={classNames(className, 'flex flex-col')}>
      {!onlyView ? (
        <Stepper activeStep={selectedStep} className="my-6" orientation="vertical">
          {steps.map((step, index) => (
            <Step className="cursor-pointer" onClick={() => selectedStep !== index && setSelectedStep(index)} completed={step.status === 'success'} key={step.id}>
              <StepLabel
                StepIconProps={{
                  icon: <StepIcon step={step} />
                }}
                error={step.status === 'reject'}
              >
                <Box className="flex justify-between w-full items-center">
                  {step.name}
                  {step.finished && (
                    <Typography
                      variant="caption"
                      fontSize="smaller"
                      className="ml-2 opacity-50 hover:opacity-75 transition-all rounded-xl bg-gray-100 border border-solid border-gray-300 px-2 py-1 text-xs"
                    >
                      Closing step
                    </Typography>
                  )}
                </Box>
              </StepLabel>

              <StepContent className="pr-0">
                <Box>
                  {step.comment && (
                    <Typography variant="body2" className="text-left text-gray-500 mb-2">
                      {step.comment}
                    </Typography>
                  )}

                  {step.candidate_comment && (
                    <Alert className="text-xs mb-2 cursor-auto" severity="info" variant="outlined">
                      <AlertTitle className="text-sm mb-0">Rejection reason:</AlertTitle>
                      {step.candidate_comment}
                    </Alert>
                  )}

                  {pendingStep && pendingStep.id !== step.id && !(step.status === 'success' || step.status === 'reject') ? null : (
                    <>
                      {(step.status === 'unset' || step.status === 'pending' || !step.status) && !step.finished && (
                        <ButtonGroup className="opacity-70 hover:opacity-100 transition-opacity flex" fullWidth size="small">
                          <Tooltip title="Success this step">
                            <Button size="small" className="" onClick={() => handleStatus(step, 'success')} color="success">
                              <CheckCircle />
                            </Button>
                          </Tooltip>

                          <Button
                            size="small"
                            className="flex gap-2 shrink-0 max-w-fit"
                            variant="outlined"
                            onClick={() => handleStatus(step, step.status === 'pending' ? 'unset' : 'pending')}
                            color="warning"
                          >
                            {step.status === 'pending' ? <UndoOutlined /> : <FmdGoodOutlined />}
                            {step.status === 'pending' ? 'Reset step' : 'Activate step'}
                          </Button>

                          <Tooltip title="Reject this step">
                            <Button size="small" onClick={() => handleOpenModal(step, 'rejected')} color="error">
                              <DoDisturbOn />
                            </Button>
                          </Tooltip>
                        </ButtonGroup>
                      )}

                      {(step.status === 'success' || step.status === 'reject') && !pendingStep?.finished && (
                        <Button size="small" className="flex gap-2" variant="outlined" fullWidth onClick={() => handleStatus(step, 'unset')} color="warning">
                          <UndoOutlined />
                          Reset step
                        </Button>
                      )}

                      {(step.status === 'success' || step.status === 'reject') && pendingStep?.finished && (
                        <Alert onClick={handlePendingStep} severity="warning" variant="outlined" className="bg-transparent flex items-start transition-all text-xs">
                          <AlertTitle className="text-sm mb-0">Editing disabled</AlertTitle>
                          <>
                            Because set closing step <span className="font-bold">"{pendingStep.name}"</span>
                          </>
                        </Alert>
                      )}

                      {step.finished && (
                        <ButtonGroup fullWidth className="opacity-70 hover:opacity-100 transition-opacity" size="small">
                          <Button
                            size="small"
                            className="mt-1"
                            fullWidth
                            onClick={step.status === 'pending' ? () => handleStatus(step, 'unset') : () => handleOpenModal(step, 'closing')}
                            color={step.status === 'pending' ? 'warning' : 'success'}
                          >
                            {step.status === 'pending' ? <UndoOutlined /> : <CheckCircle />}
                            {step.status === 'pending' ? 'Undo' : 'complete'}
                          </Button>
                        </ButtonGroup>
                      )}
                    </>
                  )}

                  {/* for pending step */}
                  {pendingStep && step.id !== pendingStep.id && step.status !== 'success' && step.status !== 'reject' && (
                    <Alert onClick={handlePendingStep} severity="warning" variant="outlined" className="bg-transparent transition-all text-xs">
                      {pendingStep.finished ? (
                        <>
                          Set final step <span className="font-bold">"{pendingStep.name}"</span>
                        </>
                      ) : (
                        <>
                          There is an incomplete <span className="font-bold">"{pendingStep.name}"</span>, to continue - complete it
                        </>
                      )}
                    </Alert>
                  )}
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      ) : (
        <Stepper orientation="vertical" className="my-6">
          {steps.map((step) => (
            <Step active key={step.id}>
              <StepLabel>{step.name}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
    </Box>
  );
};

export type ColorsTypes = Record<keyof typeof TAGS_COLORS, string>;

export const TAGS_COLORS = {
  no_color: 'no_color',
  gray: 'gray',
  amber: 'amber',
  orange: 'orange',
  lime: 'lime',
  sky: 'sky',
  purple: 'purple',
  fuchsia: 'fuchsia',
  rose: 'rose',
  emerald: 'emerald',
  indigo: 'indigo',
  yellow: 'yellow',
  teal: 'teal',
  red: 'red'
} as const;

export const COLORS_NAMES: ColorsTypes = {
  no_color: 'No color',
  gray: 'Space Gray',
  amber: 'Amber',
  orange: 'Orange',
  lime: 'Limy',
  sky: 'Clear Sky',
  purple: 'Purple',
  fuchsia: 'Fuchsia',
  rose: 'Rose',
  emerald: 'Emerald',
  indigo: 'Indigo',
  yellow: 'Sunny Yellow',
  teal: 'Teal',
  red: 'Danger'
} as const;

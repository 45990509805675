import { useAppDispatch, useAppSelector } from '@app/store/store';
import { CenterLoader } from '@components/Loaders/CenterLoader';
import { queryStringBuilder } from '@helpers/queryStringBuilder/queryStringBuilder';
import { useDebouncedRequest } from '@hooks/useDebouncedRequest';
import { TemplateSearch } from '@modules/templates/components/Search/TemplateSearch';
import { TemplatesTableBody } from '@modules/templates/components/Tables/TemplatesTableBody';
import { TemplatesTableHeader } from '@modules/templates/components/Tables/TemplatesTableHeader';
import HelpOutline from '@mui/icons-material/HelpOutline';
import { Box, Popover, Table, TableContainer, Typography } from '@mui/material';
import { getPlatforms } from '@slices/platform/thunks';
import React, { FC, MouseEvent, useMemo, useState } from 'react';

export const TemplatesTable: FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const {
    platforms: {
      search,
      list: { data: list }
    },
    status
  } = useAppSelector((store) => store.platforms);
  const query = useMemo(() => queryStringBuilder({ search }), [search]);
  const dispatch = useAppDispatch();
  useDebouncedRequest(query, () => dispatch(getPlatforms(query)), 500);

  const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <TemplateSearch />

      <Box className="min-h-[100px] flex flex-col relative">
        <TableContainer className="flex flex-1">
          <Table stickyHeader>
            <TemplatesTableHeader />
            <TemplatesTableBody list={[]} onActionsClick={handleClick} />
          </Table>
        </TableContainer>

        <Popover className="mt-2" open={!!anchorEl} onClose={handleClose} onClick={handleClose} anchorEl={anchorEl}>
          {/* {anchorEl && anchorEl.ariaLabel && <PlatformsListActionsPopover id={anchorEl.ariaLabel} />} */}
        </Popover>

        {status === 'loading' && <CenterLoader className="bg-white" />}
        {status !== 'loading' && !list.length && (
          <Box className="absolute bg-white w-full h-full inset-0 flex overflow-hidden justify-center items-center z-20">
            <Box className="relative w-full h-full justify-center items-center flex">
              <HelpOutline className="w-[80px] -mr-5 h-full text-gray-300" />
              <Typography className="relative z-10 bg-white text-gray-300 font-bold uppercase" variant="h5">
                No data...
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

import { ROLES } from '@app/store/constants';
import { useAppDispatch, useAppSelector } from '@app/store/store';
import { InputCheckboxField } from '@components/Fields/InputCheckbox/InputCheckboxField';
import { InputSelect } from '@components/Fields/InputSelect/InputSelect';
import { yupResolver } from '@hookform/resolvers/yup';
import { useModal } from '@hooks/useModal';
import Mail from '@mui/icons-material/Mail';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Collapse, Paper, TextField, Typography } from '@mui/material';
import { inviteUser } from '@slices/users/thunks';
import { usersSlice } from '@slices/users/usersSlice';
import React, { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { InputTextField } from '../../Fields/InputText/InputTextField';
import { FormWrapper } from '../../Forms/FormWrapper';
import { BaseModalProps } from '../RootModal/interface';
import { ModalWrapper } from '../RootModal/RootModal';

interface InviteModalProps extends BaseModalProps {}

interface FormData {
  email?: string;
  role: string;
  sendToEmail?: boolean;
}

const schema = yup
  .object({
    role: yup.string().required('Full name is required field'),
    email: yup
      .string()
      .email('Email must be a valid email')
      .when('sendToEmail', {
        is: true,
        then: yup.string().required('Must enter email address')
      })
  })
  .required();

export const InviteModal: FC<InviteModalProps> = ({ ...modalProps }) => {
  const { status, link } = useAppSelector((store) => store.users.inviteUser);
  const dispatch = useAppDispatch();
  const { closeModal } = useModal();
  const [linkExpanded, setLinkExpanded] = useState(false);
  const methods = useForm<FormData>({
    mode: 'all',
    resolver: yupResolver(schema)
  });

  const checkbox = methods.watch('sendToEmail');

  const handleSubmit: SubmitHandler<FormData> = async (data) => {
    if (data.sendToEmail) {
      dispatch(inviteUser({ ...data, afterAction: () => closeModal('SEND_INVITE_MODAL') }));
    } else {
      delete data.email;

      dispatch(
        inviteUser({
          ...data,
          afterAction: () => {
            setLinkExpanded(true);
          }
        })
      );
    }
  };

  return (
    <ModalWrapper {...modalProps}>
      <Paper className="max-w-[600px] mx-2 shadow-2xl overflow-hidden">
        <Box className="bg-brand relative text-white p-6">
          <Typography className="font-bold uppercase opacity-80" variant="h4">
            {checkbox ? 'Send invite' : 'Get link to invitation'}
          </Typography>
          <Typography className="opacity-70" variant="subtitle2">
            {checkbox ? 'Send the user an email invitation with the specified role' : 'Get the link to invite a user with the specified role'}
          </Typography>
          <Mail className="absolute right-0 top-0 opacity-5 w-fit h-full transform rotate-45" />
        </Box>

        <Collapse in={!linkExpanded}>
          <FormWrapper
            className="flex flex-col p-6"
            methods={methods}
            formProps={{
              onSubmit: methods.handleSubmit(handleSubmit)
            }}
          >
            <Box className="grid mb-6">
              <InputSelect returnValue="value" optionLabelRefName="label" className="mb-1" options={ROLES} name="role" label="Role" />

              <Collapse in={checkbox}>
                <InputTextField name="email" id="email" label="Email" className="mb-1" fullWidth />
              </Collapse>

              <InputCheckboxField className="w-fit" name="sendToEmail" label="Send to email" />
            </Box>

            <LoadingButton className="py-2 px-4" loading={status === 'loading'} type="submit" variant="contained" fullWidth>
              {checkbox ? 'Send invite' : 'Give link'}
            </LoadingButton>
          </FormWrapper>
        </Collapse>

        <Collapse in={linkExpanded}>
          <Box className="grid gap-4 p-6">
            <TextField
              multiline
              InputProps={{
                readOnly: true
              }}
              fullWidth
              name="link"
              label="Invite link"
              value={link}
            />

            <LoadingButton
              className="py-2 px-4"
              loading={status === 'loading'}
              onClick={async () => {
                await navigator.clipboard.writeText(link);
                dispatch(usersSlice.actions.copyLinkNotification());
              }}
              variant="contained"
            >
              Copy link
            </LoadingButton>
          </Box>
        </Collapse>
      </Paper>
    </ModalWrapper>
  );
};

import { FilesService } from '@API/services/files/FilesService';
import { FileFormat, RequestStatus, UploadFileMeta } from '@app/store/interface';
import { FileCard } from '@components/Cards/File/FileCard';
import { CenterLoader } from '@components/Loaders/CenterLoader';
import { Cancel, UploadFileRounded } from '@mui/icons-material';
import Upload from '@mui/icons-material/Upload';
import UploadFile from '@mui/icons-material/UploadFile';
import { Box, Button, ButtonProps, Collapse, Divider, IconButton, LinearProgress, Tooltip, Typography } from '@mui/material';
import classNames from 'classnames';
import { sortBy, uniqBy, without } from 'lodash';
import React, { ChangeEvent, FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';

interface FileUploadProps {
  name: string;
  addedFiles?: FileFormat[];
  addedFilesRemoveHandler?: (id: string) => void;
  className?: string;
  accept?: string;
  buttonText?: ReactNode;
  fileUploadProgressData?: UploadFileMeta | null;
  fileUploadStatus?: RequestStatus;
  uploadAction?: () => void;
  submitButtonProps?: ButtonProps;
  enableSubmit?: boolean;
}

const BYTES_IN_KB = 1024;

export const FileUpload: FC<FileUploadProps> = ({
  name,
  buttonText,
  accept,
  className,
  addedFiles,
  addedFilesRemoveHandler,
  fileUploadProgressData,
  fileUploadStatus,
  uploadAction,
  enableSubmit,
  submitButtonProps
}) => {
  const { total, loaded } = fileUploadProgressData || {};
  const [files, setFiles] = useState<File[]>([]);
  const methods = useFormContext();

  useEffect(() => {
    setFiles([]);
  }, [addedFiles]);

  useEffect(() => {
    if (files && files.length) {
      uploadAction && uploadAction();
    }
  }, [files]);

  const fileHandler = (e: ChangeEvent<HTMLInputElement>, field: ControllerRenderProps): void => {
    e.preventDefault();
    const { files: newFiles } = e.target;
    if (newFiles && !!newFiles.length) {
      //TODO: add check on valid dock files
      const uniqueFiles = uniqBy(Array.from(newFiles).concat(files), 'name');

      if (uniqueFiles.length) {
        setFiles(uniqueFiles);
        field.onChange(uniqueFiles);
        return;
      }
    }
    e.target.files = null;
  };

  const abortUploading = useCallback(() => {
    FilesService.fileUploadAbortController.abort();
  }, []);

  const percentCalculate = useMemo(() => {
    if (total !== undefined && loaded !== undefined) {
      return Math.round((loaded / total) * 100);
    }
    return 0;
  }, [total, loaded]);

  return (
    <Box className={classNames('relative overflow-hidden', className, { 'px-4 py-6': !fileUploadProgressData })}>
      <Collapse in={!fileUploadProgressData}>
        <Box className="mb-4">
          {addedFiles && !!addedFiles.length && (
            <Box className="flex gap-2 items-center">
              <Divider className="w-full flex flex-1" />
              <Typography>Uploaded files</Typography>
              <Divider className="w-full flex flex-1" />
            </Box>
          )}
          {addedFiles &&
            sortBy(addedFiles, (value) => value.created_at)
              .reverse()
              .map((file) => (
                <FileCard
                  key={file.id}
                  filename={`${file.name}${file.format}`}
                  size={file.size}
                  created_at={file.created_at}
                  removable
                  removeHandler={() => addedFilesRemoveHandler && addedFilesRemoveHandler(file.id)}
                  format={file.format}
                />
              ))}

          {!!files.length && (
            <Box className="flex gap-2 items-center">
              <Divider className="w-full flex flex-1" />
              <Typography>New files</Typography>
              <Divider className="w-full flex flex-1" />
            </Box>
          )}
          {files.map((file) => (
            <FileCard
              key={file.name}
              filename={file.name}
              size={file.size}
              created_at={new Date(Date.now())}
              removable
              removeHandler={() => setFiles(without(files, file))}
              format={`.${file.name.split('.')[file.name.split('.').length - 1]}`}
            />
          ))}

          {!files.length && !addedFiles?.length && (
            <Box>
              <Typography className="text-gray-500">Files list is empty...</Typography>
            </Box>
          )}
        </Box>
        <Box className={classNames({ 'flex gap-2 justify-end': enableSubmit })}>
          <Button variant={enableSubmit ? 'outlined' : 'contained'} disableElevation component="label" startIcon={<Upload />}>
            {buttonText ?? 'Select files'}
            <Controller
              name={name}
              control={methods?.control}
              defaultValue={[]}
              render={({ field }) => <input accept={accept} hidden multiple type="file" onChange={(e) => fileHandler(e, field)} />}
            />
          </Button>
          {enableSubmit && (
            <Button {...(submitButtonProps || {})} type="submit">
              Upload
            </Button>
          )}
        </Box>
        {!files.length && !addedFiles?.length && !enableSubmit && <UploadFile className="absolute right-0 opacity-10 w-1/4 h-full top-0 " />}
        {fileUploadStatus === 'loading' && <CenterLoader className="bg-white/50" spinnerClasses="w-6 h-6" />}
      </Collapse>
      <Collapse in={!!fileUploadProgressData}>
        <Box className="flex flex-col items-center relative">
          <Box className="flex justify-center items-center py-6 text-gray-700">
            <UploadFileRounded fontSize="large" className="animate-bounce mt-2 flex" />
            <Typography variant="h6">Uploading... {percentCalculate} %</Typography>
            <Tooltip title="Cancel uploading">
              <IconButton size="small" onClick={abortUploading} className="absolute right-1 top-1">
                <Cancel />
              </IconButton>
            </Tooltip>
          </Box>
          <Typography className="mb-1" variant="caption">
            {!!total && !!loaded && (
              <span className="text-gray-400">
                {(loaded / BYTES_IN_KB).toFixed(1)}
                <span> / </span>
                {(total / BYTES_IN_KB).toFixed(1)} Kb
              </span>
            )}
          </Typography>
        </Box>
        <LinearProgress variant="determinate" className="-mb-[1px] h-2" value={percentCalculate} />
      </Collapse>
    </Box>
  );
};

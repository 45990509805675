import { ROLES, USER_STATUSES } from '@app/store/constants';
import { useAppDispatch, useAppSelector } from '@app/store/store';
import { InputSelect } from '@components/Fields/InputSelect/InputSelect';
import { RegistrationFormDataProps, RegistrationFormProps } from '@components/Forms/interface';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Checkbox, Typography } from '@mui/material';
import { registration } from '@slices/auth/thunks';
import { addNewUser } from '@slices/users/thunks';
import classNames from 'classnames';
import React, { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { InputTextField } from 'src/components/Fields/InputText/InputTextField';
import { PasswordField } from 'src/components/Fields/Password/PasswordField';
import { FormWrapper } from 'src/components/Forms/FormWrapper';
import * as yup from 'yup';

const schema = yup
  .object({
    first_name: yup.string().min(2, 'Username must be at least 2 characters').required('First name is required field'),
    last_name: yup.string().nullable(),
    password: yup.string().required('Password is required field'),
    email: yup.string().email('Email must be a valid email').required('Email is required field')
  })
  .required();

export const RegistrationForm: FC<RegistrationFormProps> = ({ className, inviteToken }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const fieldClasses = classNames({ 'xl:col-span-2': location.pathname === '/auth/registration' });
  const buttonClasses = classNames(fieldClasses, { 'xl:max-w-fit xl:col-span-2': location.pathname !== '/auth/registration' });

  const [termsIsRead, setTermsIsRead] = useState(location.pathname !== '/auth/registration');
  const { status } = useAppSelector((store) => store.auth);

  const methods = useForm<RegistrationFormDataProps>({
    resolver: yupResolver(schema),
    mode: 'onSubmit'
  });

  const submitRecipe: SubmitHandler<RegistrationFormDataProps> = async (data) => {
    data.last_name.trim();
    data.first_name.trim();
    data.email.trim();

    if (location.pathname === '/auth/registration') {
      dispatch(registration({ ...data, inviteToken }));
    } else {
      dispatch(
        addNewUser({
          ...data,
          status: data.status!,
          role: data.role!,
          afterActionWithParams: (id) => {
            navigate(`/users/${id}`);
          }
        })
      );
    }
  };

  const termsCheckboxHandler = (): void => {
    setTermsIsRead(!termsIsRead);
  };
  return (
    <FormWrapper
      methods={methods}
      className={className}
      formProps={{
        onSubmit: methods.handleSubmit(submitRecipe)
      }}
    >
      <InputTextField className={classNames(fieldClasses)} inputMode="email" name="email" label="Email" variant="outlined" />

      <InputTextField className={classNames(fieldClasses)} inputMode="text" name="first_name" label="First name" variant="outlined" />

      <InputTextField className={classNames(fieldClasses)} inputMode="text" name="last_name" label="Last name" variant="outlined" />

      <PasswordField className={classNames(fieldClasses)} name="password" label="Password" />

      {location.pathname !== '/auth/registration' && (
        <InputSelect returnValue="value" optionLabelRefName="label" options={ROLES} className={classNames(fieldClasses)} name="role" label="Role" />
      )}

      {location.pathname !== '/auth/registration' && (
        <InputSelect returnValue="value" optionLabelRefName="label" options={USER_STATUSES} className={classNames(fieldClasses)} name="status" label="Status" />
      )}

      {location.pathname === '/auth/registration' && (
        <Box className={classNames(fieldClasses, 'flex items-center xl:col-span-2')}>
          <Checkbox onChange={termsCheckboxHandler} checked={termsIsRead} />
          <Typography>
            I have read the &nbsp;
            <NavLink className="no-underline hover:underline text-gray-500" to="/terms-and-conditions">
              Terms and Conditions
            </NavLink>
          </Typography>
        </Box>
      )}

      <LoadingButton loading={status === 'loading'} disabled={!termsIsRead} type="submit" className={classNames(buttonClasses, 'py-2 px-8 mt-4')} variant="contained">
        {location.pathname === '/auth/registration' ? 'Registration' : 'Create'}
      </LoadingButton>
    </FormWrapper>
  );
};

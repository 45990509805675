import { AdditionalContactsType } from '@app/store/interface';
import { InputSelect } from '@components/Fields/InputSelect/InputSelect';
import { InputTextField } from '@components/Fields/InputText/InputTextField';

import AbcOutlined from '@mui/icons-material/AbcOutlined';
import Add from '@mui/icons-material/Add';
import Cancel from '@mui/icons-material/Cancel';
import EmailOutlined from '@mui/icons-material/EmailOutlined';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Instagram from '@mui/icons-material/Instagram';
import LinkedIn from '@mui/icons-material/LinkedIn';
import PhoneOutlined from '@mui/icons-material/PhoneOutlined';
import Telegram from '@mui/icons-material/Telegram';
import { Alert, Box, Button, Divider, IconButton } from '@mui/material';
import classNames from 'classnames';
import React, { FC, ReactNode, useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

interface AdditionalContactInfoProps {
  name?: string;
  className?: string;
  fieldBoxClasses?: string;
}

type OptionIdType = AdditionalContactsType | 'other';

interface OptionType {
  id: OptionIdType;
  label: string;
}

const selectOptions: OptionType[] = [
  {
    id: 'other',
    label: 'Other'
  },
  {
    id: 'phone',
    label: 'Phone'
  },
  {
    id: 'email',
    label: 'Email'
  },
  {
    id: 'telegram',
    label: 'Telegram'
  },
  {
    id: 'instagram',
    label: 'Instagram'
  },
  {
    id: 'viber',
    label: 'Viber'
  },
  {
    id: 'linkedin',
    label: 'Linkedin'
  },
  {
    id: 'skype',
    label: 'Skype'
  }
];

interface FieldsGroupProps {
  rootName: string;
  removeHandler: (index: number) => void;
  index: number;
  className?: string;
}

const FieldsGroup = ({ rootName, index, removeHandler, className }: FieldsGroupProps) => {
  const { watch } = useFormContext();
  const type: OptionIdType | undefined = watch(`${rootName}.${index}.type`, null);

  const processIcon = useMemo<ReactNode>(() => {
    if (type === 'email') {
      return <EmailOutlined className="mr-2 fill-gray-500" />;
    }

    if (type === 'phone') {
      return <PhoneOutlined className="mr-2 fill-gray-500" />;
    }

    if (type === 'telegram') {
      return <Telegram className="mr-2 fill-gray-500" />;
    }

    if (type === 'instagram') {
      return <Instagram className="mr-2 fill-gray-500" />;
    }

    if (type === 'linkedin') {
      return <LinkedIn className="mr-2 fill-gray-500" />;
    }

    if (type === 'other') {
      return <AbcOutlined className="mr-2 fill-gray-500" />;
    }
    return null;
  }, [type]);

  const processLabel = useMemo(() => {
    if (type === 'email') {
      return 'Additional email';
    }

    if (type === 'phone') {
      return 'Phone';
    }

    if (type === 'telegram') {
      return 'Telegram nickname';
    }

    return 'Contact';
  }, [type]);

  return (
    <Box className={classNames('flex gap-4 w-full items-center', className)}>
      <InputSelect
        disableClearable
        returnValue="id"
        className="w-1/4 min-w-[180px]"
        label="Contact type"
        options={selectOptions}
        optionLabelRefName="label"
        name={`${rootName}.${index}.type`}
      />
      <Divider className="hidden lg:min-w-[20px] lg:block" />
      <Box className="flex items-center w-3/4 gap-2">
        <InputTextField
          className="w-full"
          label={processLabel}
          name={`${rootName}.${index}.value`}
          InputProps={{
            startAdornment: processIcon
          }}
        />
        <IconButton size="small" className="shrink-0" onClick={() => removeHandler(index)}>
          <Cancel fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
};

export const AdditionalContactInfo: FC<AdditionalContactInfoProps> = ({ name = 'contacts', fieldBoxClasses, className }) => {
  const { fields, append, remove } = useFieldArray({ name });

  const addFieldHandler = (): void => {
    append({ type: 'other', value: '' });
  };

  const removeFieldHandler = (index: number): void => {
    remove(index);
  };

  return (
    <Box className={classNames('flex flex-col gap-2', className)}>
      {!fields.length && (
        <Alert className="border-gray-300 border-solid text-gray-500 bg-gray-100" icon={<InfoOutlined className="fill-gray-500" />} variant="outlined">
          Now this candidate does not have any additional contact. You can add them manually
        </Alert>
      )}
      {!!fields.length && (
        <Box className="grid gap-4">
          {fields.map((item, index) => (
            <FieldsGroup className={fieldBoxClasses} key={index} removeHandler={() => removeFieldHandler(index)} rootName={name} index={index} />
          ))}
        </Box>
      )}
      <Button fullWidth className="border mt-4 border-dashed border-brand-200 py-3" variant="text" onClick={addFieldHandler} startIcon={<Add />}>
        Add field
      </Button>
    </Box>
  );
};

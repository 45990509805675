import { StepsFields } from '@components/Steps/StepsViewer/StepsViewer';
import CircleOutlined from '@mui/icons-material/CircleOutlined';
import ArrowCircleRightRounded from '@mui/icons-material/ArrowCircleRightRounded';
import CancelRounded from '@mui/icons-material/CancelRounded';
import CheckCircleRounded from '@mui/icons-material/CheckCircleRounded';
import Icon from '@mui/material/Icon';
import classNames from 'classnames';
import { FC } from 'react';

interface Props {
  step: StepsFields;
}

const StepIcon: FC<Props> = ({ step }) => {
  if (step.status === 'success') {
    return (
      <Icon>
        <CheckCircleRounded className={classNames('fill-lime-600')} />
      </Icon>
    );
  }

  if (step.finished && step.status === 'pending') {
    if (step.color === 'red') {
      return (
        <Icon>
          <CancelRounded className={classNames('text-red-600')} />
        </Icon>
      );
    }
    return (
      <Icon>
        <CheckCircleRounded className={classNames(`text-${step.color}-600`)} />
      </Icon>
    );
  }

  if (step.status === 'reject') {
    return (
      <Icon>
        <CancelRounded className={classNames('fill-red-600')} />
      </Icon>
    );
  }

  if (step.status === 'pending') {
    return (
      <Icon className="relative overflow-visible">
        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-amber-500 opacity-40"></span>
        <ArrowCircleRightRounded className="fill-amber-500" />
      </Icon>
    );
  }

  return (
    <Icon>
      <CircleOutlined className="fill-gray-500" />
    </Icon>
  );
};

export default StepIcon;

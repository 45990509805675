import { Box } from '@mui/material';
import { FC } from 'react';
import { Outlet } from 'react-router';

export const VacanciesModule: FC = () => {
  return (
    <Box className="flex h-full flex-col flex-1">
      <Outlet />
    </Box>
  );
};

import { TextField, TextFieldProps } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { Controller, Path, PathValue, UnpackNestedValue, useFormContext } from 'react-hook-form';
import { FieldWrapperProps } from '../interface';

interface FieldProps extends FieldWrapperProps, Omit<TextFieldProps, 'name' | 'label'> {
  unregister?: boolean;
}

export const InputTextField: FC<FieldProps> = ({ name, validationOptions, id, unregister, ...fieldProps }) => {
  const { unregister: unsubscribe, control } = useFormContext();
  useEffect(() => {
    unregister && unsubscribe(name);
  }, [unregister, name, unsubscribe]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={'' as UnpackNestedValue<PathValue<string | number, Path<string | number>>>}
      render={({ field, fieldState }) => <TextField {...fieldProps} {...field} id={name} error={!!fieldState.error} helperText={fieldState.error?.message ?? ''} />}
    />
  );
};

import { useAppSelector } from '@app/store/store';
import { InputTextField } from '@components/Fields/InputText/InputTextField';
import { FormWrapper } from '@components/Forms/FormWrapper';
import { FiltersFormData, PlatformsSearchProps } from '@modules/platfom/interface';
import Search from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import classNames from 'classnames';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';

export const TemplateSearch: FC<PlatformsSearchProps> = ({ className }) => {
  const { filters, search } = useAppSelector((store) => store.platforms.platforms);
  const methods = useForm<FiltersFormData>({
    mode: 'onChange',
    defaultValues: {
      filters,
      search
    }
  });

  return (
    <FormWrapper className={classNames(className, 'flex gap-2 flex-wrap mb-4')} methods={methods}>
      <InputTextField
        className="flex-1 min-w-[200px]"
        name="search"
        placeholder="Search templates"
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          )
        }}
      />
    </FormWrapper>
  );
};

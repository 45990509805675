import { useAppDispatch, useAppSelector } from '@app/store/store';
import { InputTextField } from '@components/Fields/InputText/InputTextField';
import { FormWrapper } from '@components/Forms/FormWrapper';
import { RestorePasswordFormProps } from '@components/Forms/interface';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Divider, Stack, Typography } from '@mui/material';
import { restorePassword } from '@slices/auth/thunks';
import React, { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import * as yup from 'yup';

const schema = yup
  .object({
    email: yup.string().email('Email must be a valid email').required('Email is required field')
  })
  .required();

export const RestorePasswordForm: FC<RestorePasswordFormProps> = ({ className }) => {
  const { status } = useAppSelector((store) => store.auth);
  const dispatch = useAppDispatch();

  const methods = useForm<{ email: string }>({
    resolver: yupResolver(schema),
    mode: 'onSubmit'
  });

  const submitRecipe: SubmitHandler<{ email: string }> = async ({ email }) => {
    dispatch(restorePassword({ email }));
  };

  return (
    <FormWrapper className={className} methods={methods} formProps={{ onSubmit: methods.handleSubmit(submitRecipe) }}>
      <Stack spacing={2} direction="column">
        <InputTextField inputMode="email" label="Email" name="email" variant="outlined" />
        <LoadingButton loading={status === 'loading'} type="submit" className="py-3 mt-8" variant="contained">
          Restore Password
        </LoadingButton>
      </Stack>
      <Divider className="my-5" />
      <Typography className="text-gray-500 text-sm">
        <NavLink className="no-underline hover:underline text-gray-500" to="../login">
          Login
        </NavLink>
      </Typography>
    </FormWrapper>
  );
};

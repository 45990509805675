import { useAppDispatch, useAppSelector } from '@app/store/store';
import { CenterLoader } from '@components/Loaders/CenterLoader';
import { TableSortHeader } from '@components/Tables/TableSortHeader';
import { queryStringBuilder } from '@helpers/queryStringBuilder/queryStringBuilder';
import { useDebouncedRequest } from '@hooks/useDebouncedRequest';
import { VacanciesListActionsPopover } from '@modules/vacancies/components/Popovers/VacanciesListActionsPopover';
import { VacanciesTableBody } from '@modules/vacancies/components/Tables/VacanciesTableBody';
import { VacanciesListFiltersAndSorts } from '@modules/vacancies/components/VacanciesListFiltersAndSorts/VacanciesListFiltersAndSorts';
import { VacanciesColumns } from '@modules/vacancies/interface';
import HelpOutline from '@mui/icons-material/HelpOutline';
import { Box, Popover, Table, TableContainer, TablePagination, Typography } from '@mui/material';
import { getVacancies } from '@slices/vacancies/thunks';
import { vacanciesSlice } from '@slices/vacancies/vacanciesSlice';
import React, { FC, MouseEvent, useMemo, useState } from 'react';

export const VacanciesListTable: FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const {
    sort,
    search,
    status,
    order,
    page,
    rowsCount,
    list: { data: list, total }
  } = useAppSelector((store) => store.vacancies.vacanciesList);

  const query = useMemo(
    () =>
      queryStringBuilder({
        search,
        order: (order || '').toUpperCase(),
        sort_field: sort,
        page: page + 1,
        perPage: rowsCount
      }),
    [search, sort, order, page, rowsCount]
  );

  const dispatch = useAppDispatch();
  useDebouncedRequest(query, () => dispatch(getVacancies(query)), 500);

  const handleRequestSort = (_: React.MouseEvent<unknown>, property: keyof VacanciesColumns): void => {
    const isAsc = sort === property && order === 'asc';
    dispatch(vacanciesSlice.actions.touchSearchActions({ order: isAsc ? 'desc' : 'asc', sort: property }));
    dispatch(vacanciesSlice.actions.touchPagination({ page: 0 }));
  };

  const handleChangePage = (_: unknown, page: number): void => {
    dispatch(vacanciesSlice.actions.touchPagination({ page }));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(vacanciesSlice.actions.touchPagination({ page: 0, rowsCount: parseInt(event.target.value, 10) }));
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <VacanciesListFiltersAndSorts />

      <Box className="min-h-[100px] flex flex-col relative">
        <TableContainer className="flex flex-1">
          <Table stickyHeader>
            <TableSortHeader
              columns={[
                {
                  id: 'name',
                  label: 'Name'
                },
                {
                  id: 'status',
                  label: 'Status'
                },
                {
                  id: 'department',
                  label: 'Department'
                },
                {
                  id: 'steps',
                  label: 'Steps',
                  disableSorting: true
                },
                {
                  id: 'feedbacks_count',
                  label: 'Feedbacks',
                  disableSorting: true
                },
                {
                  id: 'user_id',
                  label: 'Author'
                },
                {
                  id: 'updated_at',
                  label: 'Last update',
                  numeric: true
                },
                {
                  id: 'created_at',
                  label: 'Created at',
                  numeric: true
                }
              ]}
              onRequestSort={handleRequestSort}
              order={order}
              orderBy={sort}
            />
            <VacanciesTableBody list={list} onActionsClick={handleClick} />
          </Table>
        </TableContainer>

        <TablePagination
          className="flex-shrink-0"
          component="div"
          variant="footer"
          count={total}
          page={page}
          rowsPerPage={rowsCount}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />

        <Popover className="mt-2" open={!!anchorEl} onClose={handleClose} onClick={handleClose} anchorEl={anchorEl}>
          {anchorEl && anchorEl.ariaLabel && <VacanciesListActionsPopover id={anchorEl.ariaLabel} />}
        </Popover>

        {status === 'loading' && <CenterLoader className="bg-white" />}
        {status !== 'loading' && !list.length && (
          <Box className="absolute bg-white w-full h-full inset-0 flex overflow-hidden justify-center items-center z-20">
            <Box className="relative w-full h-full justify-center items-center flex">
              <HelpOutline className="w-[80px] -mr-5 h-full text-gray-300" />
              <Typography className="relative z-10 bg-white text-gray-300 font-bold uppercase" variant="h5">
                No data...
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

import { CandidatesService } from '@API/services/candidates/candidatesService';
import { ResponseBaseType } from '@API/services/interfaces';
import { StepsService } from '@API/services/steps/stepsService';
import { VacanciesService } from '@API/services/vacancies/vacanciesService';
import { BaseAsyncThunkOptions } from '@app/store/interface';
import { queryStringBuilder } from '@helpers/queryStringBuilder/queryStringBuilder';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { KANBAN } from '@slices/kanban/interface';
import { VACANCIES } from '@slices/vacancies/interface';

export const getKanbanVacancy = createAsyncThunk<VACANCIES.Vacancy, KANBAN.THUNKS.GetKanbanVacancy, BaseAsyncThunkOptions>(
  'kanban/getVacancy',
  async ({ vacancy_id }, { rejectWithValue }) => {
    const response = await VacanciesService.getOne(vacancy_id);
    if (response.ok) {
      return response.data.data ?? [];
    } else {
      return rejectWithValue(response.error);
    }
  }
);

export const getKanbanVacancyStepCandidates = createAsyncThunk<KANBAN.Candidates[], KANBAN.THUNKS.GetKanbanVacancyStepCandidates, BaseAsyncThunkOptions>(
  'kanban/getVacancyStep',
  async ({ step_id, query }, { rejectWithValue }) => {
    const response = await StepsService.getOne<ResponseBaseType<KANBAN.Candidates[]>>(step_id, query);

    if (response.ok) {
      return response.data.data.data;
    } else {
      return rejectWithValue(response.error);
    }
  }
);

export const changeCandidateKanbanStep = createAsyncThunk<unknown, KANBAN.THUNKS.ChangeCandidateKanbanStep, BaseAsyncThunkOptions>(
  'kanban/changeCandidateStep',
  async ({ candidate_id, vacancy_id, steps }, { rejectWithValue, dispatch }) => {
    const response = await CandidatesService.update({
      vacancy_id,
      id: candidate_id,
      steps
    });

    if (response.ok) {
    } else {
      steps?.forEach(({ step_id }) => {
        dispatch(
          getKanbanVacancyStepCandidates({
            query: queryStringBuilder({
              vacancy: vacancy_id
            }),
            vacancy_id,
            step_id
          })
        );
      });
      return rejectWithValue(response.error);
    }
  }
);

export const changeKanbanStepOrder = createAsyncThunk<KANBAN.Steps[], KANBAN.THUNKS.ChangeKanbanStepOrderProps, BaseAsyncThunkOptions>(
  'kanban/changeOrder',
  async ({ step_id, afterAction, ...data }, thunkApi) => {
    // TODO: remove return_all field

    const response = await StepsService.updateOrder(step_id, {
      ordering: data.ordering,
      return_all: false
    });
    if (response.ok) {
      afterAction && afterAction();
      return response.data.data;
    } else {
      return thunkApi.rejectWithValue(response.error);
    }
  }
);

import { AppLayoutModule } from '@modules/appLayout/AppLayoutModule';
import { AuthModule } from '@modules/auth/AuthModule';
import { LoginPage } from '@modules/auth/pages/Login/LoginPage';
import { RegistrationPage } from '@modules/auth/pages/Registration/RegistrationPage';
import { ResetPasswordPage } from '@modules/auth/pages/ResetPassword/ResetPasswordPage';
import { RestorePasswordPage } from '@modules/auth/pages/RestorePassword/RestorePasswordPage';
import { CandidatesModule } from '@modules/candidates/CandidatesModule';
import { CandidateContainer } from '@modules/candidates/components/CandidateContainer/CandidateContainer';
import { CandidateAddPage } from '@modules/candidates/pages/create/CandidateAddPage';
import { CandidateEditPage } from '@modules/candidates/pages/edit/CandidateEditPage';
import { CandidateInfoPage } from '@modules/candidates/pages/info/CandidateInfoPage';
import { CandidatesListPage } from '@modules/candidates/pages/list/CandidatesListPage';
import { DashboardModule } from '@modules/dashboard/DashboardModule';
import { DepartmentsModule } from '@modules/departments/DepartmentsModule';
import { DepartmentsAddPage } from '@modules/departments/pages/create/DepartmentsAddPage';
import { DepartmentsEditPage } from '@modules/departments/pages/edit/DepartmentsEditPage';
import { DepartmentsListPage } from '@modules/departments/pages/list/DepartmentsListPage';
import { KanbanModule } from '@modules/kanban/KanbanModule';
import { KanbanBoardPage } from '@modules/kanban/pages/board/KanbanBoardPage';
import { KanbanVacanciesPage } from '@modules/kanban/pages/vacancies/KanbanVacanciesPage';
import { PlatformContainer } from '@modules/platfom/components/PlatformContainer/PlatformContainer';
import { PlatformsAddPage } from '@modules/platfom/pages/create/PlatformsAddPage';
import { PlatformsEditPage } from '@modules/platfom/pages/edit/PlatformsEditPage';
import { PlatformsInfoPage } from '@modules/platfom/pages/info/PlatformsInfoPage';
import { PlatformsListPage } from '@modules/platfom/pages/list/PlatformsListPage';
import { PlatformsModule } from '@modules/platfom/PlatformsModule';
import { ProfileModule } from '@modules/profile/ProfileModule';
import { SkillsAddPage } from '@modules/skills/pages/create/SkillsAddPage';
import { SkillsEditPage } from '@modules/skills/pages/edit/SkillsEditPage';
import { SkillsListPage } from '@modules/skills/pages/list/SkillsListPage';
import { SkillsModule } from '@modules/skills/SkillsModule';
import { StepsAddPage } from '@modules/steps/pages/create/StepsAddPage';
import { StepsEditPage } from '@modules/steps/pages/edit/StepsEditPage';
import { StepsListPage } from '@modules/steps/pages/list/StepsListPage';
import { StepsModule } from '@modules/steps/StepsModule';
import { TagsAddPage } from '@modules/tags/pages/create/TagsAddPage';
import { TagsEditPage } from '@modules/tags/pages/edit/TagsEditPage';
import { TagsListPage } from '@modules/tags/pages/list/TagsListPage';
import { TagsModule } from '@modules/tags/TagsModule';
import { TemplatesContainer } from '@modules/templates/components/TemplatesContainer/TemplatesContainer';
import { TemplatesAddPage } from '@modules/templates/pages/create/TemplatesAddPage';
import { TemplateEditPage } from '@modules/templates/pages/edit/TemplatesEditPage';
import { TemplatesInfoPage } from '@modules/templates/pages/info/TemplatesInfoPage';
import { TemplatesListPage } from '@modules/templates/pages/list/TemplatesListPage';
import { TemplatesModule } from '@modules/templates/TemplatesModule';
import { UserContainer } from '@modules/users/components/UserContainer/UserContainer';
import { NewUserPage } from '@modules/users/pages/create/NewUserPage';
import { EditUserPage } from '@modules/users/pages/edit/EditUserPage';
import { UserInfoPage } from '@modules/users/pages/info/UserInfoPage';
import { UsersListPage } from '@modules/users/pages/list/UsersListPage';
import { UsersModule } from '@modules/users/UsersModule';
import { VacancyContainer } from '@modules/vacancies/components/VacancyContainer/VacancyContainer';
import { VacancyAddPage } from '@modules/vacancies/pages/create/VacancyAddPage';
import { VacancyEditPage } from '@modules/vacancies/pages/edit/VacancyEditPage';
import { VacancyInfoPage } from '@modules/vacancies/pages/info/VacancyInfoPage';
import { VacanciesListPage } from '@modules/vacancies/pages/list/VacanciesListPage';
import { VacanciesModule } from '@modules/vacancies/VacanciesModule';
import { ErrorsPage404 } from '@pages/errors/ErrorsPages';
import { TermsAndConditions } from '@pages/terms&conditions/TermsAndConditions';
import { FC, ReactElement } from 'react';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import App from '../App';

import { useAppSelector } from '../store/store';

interface PrivateRouteProps {
  condition: boolean;
  redirectTo: string;
  children: ReactElement;
}

const PrivateRoute: FC<PrivateRouteProps> = ({ condition, redirectTo, children }) => {
  const { pathname } = useLocation();
  return condition ? children : <Navigate to={redirectTo} state={{ from: pathname }} />;
};

const AppRoutes = () => {
  const isAuth = useAppSelector((store) => !!store.auth.token);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          {/*AUTH MODULE*/}
          <Route path="auth" element={<AuthModule />}>
            <Route path="login" element={<LoginPage />} />
            <Route path="registration" element={<RegistrationPage />} />
            <Route path="password-restore" element={<RestorePasswordPage />} />
            <Route path="password-reset" element={<ResetPasswordPage />} />
          </Route>

          {/*APP*/}
          <Route
            path="/"
            element={
              <PrivateRoute condition={isAuth} redirectTo="/auth/login">
                <AppLayoutModule />
              </PrivateRoute>
            }
          >
            <Route path="profile" element={<ProfileModule />} />

            {/*PAGES IN LEFT NAVBAR*/}
            {/*DASHBOARD*/}
            <Route index element={<DashboardModule />} />

            {/*USERS*/}
            <Route path="users" element={<UsersModule />}>
              <Route index element={<UsersListPage />} />
              <Route element={<UserContainer />}>
                <Route path="new" element={<NewUserPage />} />
                <Route path=":id" element={<UserInfoPage />} />
                <Route path=":id/edit" element={<EditUserPage />} />
              </Route>
            </Route>

            {/*PLATFORMS*/}
            <Route path="platforms" element={<PlatformsModule />}>
              <Route index element={<PlatformsListPage />} />
              <Route path="new" element={<PlatformsAddPage />} />
              <Route path=":id" element={<PlatformContainer />}>
                <Route index element={<PlatformsInfoPage />} />
                <Route path="edit" element={<PlatformsEditPage />} />
              </Route>
            </Route>

            {/*TAGS*/}
            <Route path="tags" element={<TagsModule />}>
              <Route index element={<TagsListPage />} />
              <Route path="new" element={<TagsAddPage />} />
              <Route path=":id/edit" element={<TagsEditPage />} />
            </Route>

            {/*SKILLS*/}
            <Route path="skills" element={<SkillsModule />}>
              <Route index element={<SkillsListPage />} />
              <Route path="new" element={<SkillsAddPage />} />
              <Route path=":id/edit" element={<SkillsEditPage />} />
            </Route>

            {/*STEPS*/}
            <Route path="steps" element={<StepsModule />}>
              <Route index element={<StepsListPage />} />
              <Route path="new" element={<StepsAddPage />} />
              <Route path=":id/edit" element={<StepsEditPage />} />
            </Route>

            {/*DEPARTMENTS*/}
            <Route path="departments" element={<DepartmentsModule />}>
              <Route index element={<DepartmentsListPage />} />
              <Route path="new" element={<DepartmentsAddPage />} />
              <Route path=":id/edit" element={<DepartmentsEditPage />} />
            </Route>

            {/*VACANCIES*/}
            <Route path="vacancies" element={<VacanciesModule />}>
              <Route index element={<VacanciesListPage />} />
              <Route element={<VacancyContainer />}>
                <Route path="new" element={<VacancyAddPage />} />
                <Route path=":id" element={<VacancyInfoPage />} />
                <Route path=":id/edit" element={<VacancyEditPage />} />
              </Route>
            </Route>

            {/*CANDIDATES*/}
            <Route path="candidates" element={<CandidatesModule />}>
              <Route index element={<CandidatesListPage />} />
              <Route element={<CandidateContainer />}>
                <Route path="new" element={<CandidateAddPage />} />
                <Route path=":id" element={<CandidateInfoPage />} />
                <Route path=":id/edit" element={<CandidateEditPage />} />
              </Route>
            </Route>

            {/*KANBAN*/}
            <Route path="kanban" element={<KanbanModule />}>
              <Route index element={<KanbanVacanciesPage />} />
              <Route path=":vacancy_id" element={<KanbanBoardPage />} />
            </Route>

            {/*TEMPLATES*/}
            <Route path="templates" element={<TemplatesModule />}>
              <Route index element={<TemplatesListPage />} />
              <Route path="new" element={<TemplatesAddPage />} />
              <Route path=":id" element={<TemplatesContainer />}>
                <Route index element={<TemplatesInfoPage />} />
                <Route path="edit" element={<TemplateEditPage />} />
              </Route>
            </Route>
          </Route>

          {/*TERMS AND CONDITIONS*/}
          <Route path="terms-and-conditions" element={<TermsAndConditions />} />

          {/*NOT FOUND PAGE*/}
          <Route path="*" element={<ErrorsPage404 />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;

import Add from '@mui/icons-material/Add';
import { Box, Button } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';

export const VacanciesListActions: FC = () => {
  return (
    <Box className="flex gap-4">
      <Button component={Link} variant="contained" to="new" startIcon={<Add />}>
        Create
      </Button>
    </Box>
  );
};

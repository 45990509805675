import { FilesService } from '@API/services/files/FilesService';
import { RequestStatus, UploadFileMeta } from '@app/store/interface';
import { Stop, UploadFileRounded } from '@mui/icons-material';
import Close from '@mui/icons-material/Close';
import { LinearProgress, Tooltip } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import { useSnackbar } from 'notistack';
import React, { FC, ReactNode, useCallback, useEffect, useMemo } from 'react';

interface FileUploadingToastProps {
  message?: ReactNode;
  status: RequestStatus;
  fileUploadProgressData?: UploadFileMeta | null;
  id: string | number;
}

export const FileUploadingToast: FC<FileUploadingToastProps> = ({ id, fileUploadProgressData, status }) => {
  const { closeSnackbar } = useSnackbar();
  const { total, loaded } = fileUploadProgressData ?? {};

  useEffect(() => {
    if (loaded === total && loaded !== undefined && total !== undefined) {
      setTimeout(() => {
        closeSnackbar(id);
      });
    }
  }, [loaded, total]);

  const handleCancel = useCallback(() => {
    if (status === 'loading') {
      FilesService.fileUploadAbortController.abort();
    }

    closeSnackbar(id);
  }, [id, closeSnackbar]);

  const percentCalculate = useMemo(() => {
    if (total !== undefined && loaded !== undefined) {
      return Math.round((loaded / total) * 100);
    }
    return 0;
  }, [total, loaded]);

  return (
    <Card className="shadow-xl w-full group flex justify-between items-start relative">
      <CardContent className="z-10">
        <Typography variant="subtitle2" className="text-white flex items-center">
          <UploadFileRounded fontSize="small" className="mb-1.5 mr-1" />
          {status === 'success' && 'Uploading done'}
          {status === 'error' && 'Uploading failed'}
          {status === 'loading' && `Uploading... ${percentCalculate}%`}
          {!status || (status === 'unset' && 'Uploading...')}
        </Typography>
        <Typography className="mb-1" variant="caption">
          {!!total && !!loaded && (
            <span className="text-white">
              {(loaded / 1024).toFixed(1)}
              <span> / </span>
              {(total / 1024).toFixed(1)} Kb
            </span>
          )}
        </Typography>
      </CardContent>
      <CardActions className="z-10">
        <IconButton
          className={classNames('group-hover:opacity-100 opacity-0 transition-all', { 'text-white': status !== 'loading' })}
          size="small"
          color={status !== 'loading' ? 'inherit' : 'error'}
          onClick={handleCancel}
        >
          {status === 'loading' ? (
            <Tooltip arrow title="Cancel uploading?">
              <Stop fontSize="small" />
            </Tooltip>
          ) : (
            <Close fontSize="small" />
          )}
        </IconButton>
      </CardActions>
      <LinearProgress className="h-full z-0 w-full absolute " variant="determinate" color="info" value={percentCalculate} />
    </Card>
  );
};

import { StepsService } from '@API/services/steps/stepsService';
import { AfterActionProps, BaseAsyncThunkOptions } from '@app/store/interface';
import { arrayMove } from '@dnd-kit/sortable';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { STEPS } from '@slices/steps/interface';
import { createStep, getAllSteps, getOneStep, updateStep } from '@slices/steps/thukns';

const initialState: STEPS.StepsSlice = {
  status: 'unset',
  responseMessage: null,

  search: '',
  sort: '',
  ordering: false,

  steps: {
    data: [],
    total: 0
  },

  selectedStep: {
    status: 'unset',
    data: null
  }
};

export const changeStepOrder = createAsyncThunk<STEPS.Step[], STEPS.ChangeStepOrderProps, BaseAsyncThunkOptions>('steps/changeOrder', async ({ id, afterAction, ...data }, thunkApi) => {
  //TODO: remove return_all field
  const response = await StepsService.updateOrder(id, {
    ordering: data.ordering,
    return_all: data.return_all ?? true
  });
  if (response.ok) {
    afterAction && afterAction();
    return response.data.data;
  } else {
    return thunkApi.rejectWithValue(response.error);
  }
});

export const deleteOneStep = createAsyncThunk<STEPS.Step, Pick<STEPS.Step, 'id'> & AfterActionProps, BaseAsyncThunkOptions>(
  'steps/deleteOne',
  async ({ id, afterAction }, thunkApi) => {
    const response = await StepsService.deleteOne(id);

    if (response.ok) {
      afterAction && afterAction();
      return response.data.data;
    } else {
      return thunkApi.rejectWithValue(response.error);
    }
  }
);

export const stepsSlice = createSlice({
  name: 'steps',
  initialState,
  reducers: {
    touchSearchActions: (store, { payload }: Record<'payload', Record<'search', string>>) => {
      store.search = payload.search;
    },
    setStepsFromAll: (store, { payload }: Record<'payload', STEPS.Step>) => {
      store.selectedStep.data = payload;
      store.selectedStep.status = 'success';
    },
    clearResponseMessages: (state) => {
      state.responseMessage = null;
    },
    clearSelected: (store) => {
      store.selectedStep.data = null;
      store.selectedStep.status = 'unset';
    },
    toggleOrdering: (store) => {
      store.ordering = !store.ordering;
    },
    reorder: (store, { payload }: { payload: STEPS.Step[] }) => {
      store.steps.data = payload;
    }
  },
  extraReducers: (builder) => {
    //GET ALL
    builder.addCase(getAllSteps.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(getAllSteps.fulfilled, (state, { payload }) => {
      state.steps = payload;
      state.status = 'success';
    });
    builder.addCase(getAllSteps.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.status = 'error';
    });

    //GET ONE
    builder.addCase(getOneStep.pending, (state) => {
      state.selectedStep.status = 'loading';
    });
    builder.addCase(getOneStep.fulfilled, (state, { payload }) => {
      state.selectedStep.data = payload;
      state.selectedStep.status = 'success';
    });
    builder.addCase(getOneStep.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.selectedStep.status = 'error';
    });

    //CREATE
    builder.addCase(createStep.pending, (state) => {
      state.status = 'loading';
      state.selectedStep.status = 'loading';
    });
    builder.addCase(createStep.fulfilled, (state) => {
      state.status = 'success';
      state.selectedStep.status = 'success';
      state.responseMessage = {
        type: 'success',
        data: [
          {
            message: 'Step successfully added'
          }
        ]
      };
    });
    builder.addCase(createStep.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.status = 'error';
      state.selectedStep.status = 'error';
    });

    //UPDATE ONE
    builder.addCase(updateStep.pending, (state) => {
      state.status = 'loading';
      state.selectedStep.status = 'loading';
    });
    builder.addCase(updateStep.fulfilled, (state, { payload }) => {
      state.selectedStep.data = payload;
      state.steps.data = [...state.steps.data.filter((skill) => skill.id !== skill.id), payload];
      state.responseMessage = {
        type: 'success',
        data: [
          {
            message: 'Step successfully edited!'
          }
        ]
      };
      state.status = 'unset';
      state.selectedStep.status = 'success';
    });
    builder.addCase(updateStep.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.selectedStep.status = 'error';
    });

    //UPDATE ORDER
    builder.addCase(changeStepOrder.pending, (state, { meta }) => {
      state.status = 'loading';

      if (meta.arg.active_index !== undefined && meta.arg.over_index !== undefined) {
        const temp_index = state.steps.data[meta.arg.active_index].ordering;
        state.steps.data[meta.arg.active_index].ordering = state.steps.data[meta.arg.over_index].ordering;
        state.steps.data[meta.arg.over_index].ordering = temp_index;

        state.steps.data = arrayMove(state.steps.data, meta.arg.active_index, meta.arg.over_index);
      }
    });
    builder.addCase(changeStepOrder.fulfilled, (state, { payload, meta }) => {
      state.status = 'success';

      if (Array.isArray(payload) && meta.arg.resortArray) {
        state.steps.data = payload;
      }
    });
    builder.addCase(changeStepOrder.rejected, (state, { payload, meta }) => {
      state.status = 'error';
      state.steps.data = meta.arg.cached_data;
      state.responseMessage = {
        type: 'error',
        data: payload
      };
    });

    //DELETE ONE
    builder.addCase(deleteOneStep.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(deleteOneStep.fulfilled, (state, { payload }) => {
      state.selectedStep.data = null;
      state.steps.data = state.steps.data.filter((tag) => tag.id !== payload.id);
      state.steps.total--;
      state.responseMessage = {
        type: 'success',
        data: [
          {
            message: 'Step successfully deleted'
          }
        ]
      };
      state.selectedStep.status = 'unset';
      state.status = 'success';
    });
    builder.addCase(deleteOneStep.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.status = 'error';
    });
  }
});

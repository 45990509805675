import { TabContext, TabContextContentType } from '@components/Tabs/TabContext/TabContext';
import { DashboardEventsCard } from '@modules/dashboard/components/Events/DashboardEventsCard';
import { DashboardHistoryStepper } from '@modules/dashboard/components/Histories/DashboardHistoryStepper';
import { DashboardStepsCounter } from '@modules/dashboard/components/Steps/DashboardStepsCounter';

import { Box, useMediaQuery } from '@mui/material';
import React, { FC } from 'react';

const tabsContent: TabContextContentType[] = [
  {
    tabHeader: {
      label: 'Steps',
      id: 'steps-tab'
    },
    tabBody: (
      <Box className="relative h-full w-full">
        <Box className="absolute inset-0 flex gap-4 overflow-x-hidden overflow-y-auto h-full w-full">
          <DashboardStepsCounter className="overflow-hidden" />
        </Box>
      </Box>
    )
  },
  {
    tabHeader: {
      label: 'Events',
      id: 'events-tab'
    },
    tabBody: (
      <Box className="w-full h-full max-h-full relative">
        <Box className="absolute inset-0 flex gap-4 flex-col gap-4 h-full w-full">
          <DashboardEventsCard className="flex-1 overflow-hidden" />
        </Box>
      </Box>
    )
  },
  {
    tabHeader: {
      label: 'History',
      id: 'history-tab'
    },
    tabBody: (
      <Box className="w-full h-full max-h-full relative">
        <Box className="absolute inset-0 flex gap-4 flex-col gap-4 h-full w-full">
          <DashboardHistoryStepper className="flex-1 overflow-hidden" />
        </Box>
      </Box>
    )
  }
];
export const DashboardModule: FC = () => {
  const XLBrake = useMediaQuery('(max-width: 900px)');

  if (!XLBrake) {
    return (
      <Box className="flex w-full gap-4 h-full flex-col xl:flex-row overflow-auto">
        <Box className="relative xl:w-1/2 w-full">
          <Box className="absolute inset-0 flex gap-4 overflow-x-hidden overflow-y-auto h-full w-full">
            <DashboardStepsCounter className="max-h-1/2 overflow-hidden" />
          </Box>
        </Box>
        <Box className="xl:w-1/2 w-full h-full max-h-full relative">
          <Box className="absolute inset-0 flex gap-4 flex-col gap-4 h-full w-full">
            <DashboardEventsCard className="flex-1 overflow-hidden" />
            <DashboardHistoryStepper className="flex-1 overflow-hidden h-full" />
          </Box>
        </Box>
      </Box>
    );
  } else {
    return (
      <Box className="flex w-full h-full flex-col relative">
        <TabContext
          tabsProps={{
            variant: 'fullWidth'
            // selectionFollowsFocus: true
          }}
          divider
          tabsHeaderClassNames="absolute -mx-4 z-100 inset-0 -top-4"
          className="absolute inset-0 flex flex-col h-full w-full"
          tabsContainerClassNames="flex w-full h-full gap-4 flex-col mt-14"
          content={tabsContent}
        />
      </Box>
    );
  }
};

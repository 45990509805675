import { ErrorType } from '@app/store/interface';
import store from '@app/store/store';
import { logout } from '@slices/auth/thunks';
import download from 'downloadjs';
import { getStorageItem } from 'src/utils/helpers/localStorage/localStorageService';
import { ContentType, RequestMethods, ResponseBody } from './interfaces';

const errorEntity: ErrorType[] = [
  {
    message: ['Uncaught server error, please try again later'],
    name: 'Error'
  }
];
export const incorrectErrorFormatHandler = (payload: any): ErrorType[] => {
  return payload.statusCode >= 500 || !payload || !Array.isArray(payload) ? errorEntity : payload;
};

const fetchWrapper = async <S>(url: string, options: RequestInit, contentType: ContentType): Promise<ResponseBody<S>> => {
  const [link, filename] = url.split('?filename=');
  const response = await fetch(link, options);

  if (response && response.status === 401) {
    store.dispatch(logout());
  }

  if (response.ok) {
    let data;

    if (response.status !== 204) {
      switch (contentType) {
        case 'file': {
          const blob = await response.blob();
          await download(blob, filename);
          break;
        }
        case 'formData': {
          data = response;
          break;
        }
        default: {
          data = await response.json();
        }
      }
    } else {
      data = null;
    }

    return {
      ok: response.ok,
      status: response.status,
      data,
      error: null
    };
  } else {
    return {
      ok: response.ok,
      status: response.status,
      data: null,
      error: incorrectErrorFormatHandler(await response.json())
    };
  }
};

export const fetchData = async <S, D = unknown>(
  url: string,
  method: RequestMethods = 'GET',
  data?: D,
  contentType: ContentType = 'json',
  extraHeaders?: HeadersInit
// eslint-disable-next-line max-params
): Promise<ResponseBody<S>> => {
  const token = getStorageItem('token');
  const authorized = token ?? '';
  const headers = new Headers(extraHeaders);

  headers.set('Authorization', `Bearer ${authorized}`);
  if (contentType === 'json') {
    headers.set('Accept', 'application/json');
    headers.set('Content-Type', 'application/json');
  }

  const options: RequestInit | undefined =
    method !== 'GET'
      ? {
          method,
          headers,
          mode: 'cors',
          body:
            contentType !== 'formData'
              ? JSON.stringify({
                  ...data
                })
              : data as unknown as string
        }
      : {
          headers,
          mode: 'cors'
        };
  return fetchWrapper<S>(url, options, contentType);
};

import { useAppDispatch, useAppSelector } from '@app/store/store';
import AdditionalContactsList from '@components/AdditionalContactInfo/AdditionalContactsList';
import { SystemInfoCard } from '@components/Cards/SystemInfo/SystemInfoCard';
import { EventsSection } from '@components/Events/SectionCard/EventsSection';
import { CenterLoader } from '@components/Loaders/CenterLoader';
import { CandidateInfoModalProps } from '@components/Modals/CandidateInfo/interface';
import { ModalWrapper } from '@components/Modals/RootModal/RootModal';
import { ProfileHeader } from '@components/ProfileHeader/ProfileHeader';
import { useModal } from '@hooks/useModal';
import { CandidateInfoActions } from '@modules/candidates/components/CandidateInfoActions/CandidateInfoActions';
import { AttachmentsSection } from '@modules/candidates/pages/info/sections/AttachmentsSection';
import { ImportantInformationSection } from '@modules/candidates/pages/info/sections/ImportantInformationSection';
import { MainInfoSection } from '@modules/candidates/pages/info/sections/MainInfoSection';
import { StepsSection } from '@modules/candidates/pages/info/sections/StepsSection';
import { Close, Edit } from '@mui/icons-material';
import { Box, Button, Card, CardHeader, Divider, IconButton, Paper, Skeleton } from '@mui/material';
import { candidatesSlice } from '@slices/candidates/candidatesSlice';
import { getCandidate } from '@slices/candidates/thunks';
import React, { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';

export const CandidateInfoModal: FC<CandidateInfoModalProps> = ({ data: modalData, ...modalProps }) => {
  const { data } = useAppSelector((store) => store.candidates.candidate);
  const { closeModal } = useModal();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCandidate(modalData.candidate_id));
  }, []);

  const handleCloseModal = () => {
    closeModal('CANDIDATE_INFO_MODAL');
    dispatch(candidatesSlice.actions.clearSelectedCandidate());
  };

  return (
    <ModalWrapper {...modalProps}>
      {data ? (
        <Paper className="max-w-[900px] px-6 py-8 mx-2 shadow-2xl overflow-hidden bg-gray-100">
          <Box className="flex items-end justify-between flex-wrap gap-4 relative">
            <Box className="flex flex-col gap-4 mb-4">
              <ProfileHeader subtitleIsID email={data.email} title={`${data.first_name ?? ''} ${data.last_name ?? ''}`} subtitle={data.id} />
            </Box>
            <Box className="flex gap-2 mb-4 lg:">
              <Button onClick={handleCloseModal} disabled={!data} variant="outlined" component={Link} to={`/candidates/${data?.id}/edit`} startIcon={<Edit />}>
                Edit
              </Button>
              <CandidateInfoActions disabled={!data} />
            </Box>
            <IconButton className="absolute -top-4 -right-2" onClick={handleCloseModal}>
              <Close />
            </IconButton>
          </Box>

          <Box className="flex gap-4 items-start flex-col xl:flex-row">
            <Box className="flex-1 w-full">
              {!!(data.tags?.length || data.skills?.length) && <ImportantInformationSection data={data} />}

              <MainInfoSection data={data} />

              {!!data.contacts?.length && (
                <Card className="shadow my-4">
                  <CardHeader title="Additional information" className="uppercase text-gray-600 pb-2" />
                  <Divider />

                  <AdditionalContactsList contacts={data.contacts ?? []} />
                </Card>
              )}

              <SystemInfoCard created_at={data.created_at} updated_at={data.updated_at} />
            </Box>
            <Box className="flex-1 xl:my-4 mb-4 w-full xl:max-w-[360px] flex xl:flex-col gap-4 flex-col md:flex-row md:flex-wrap items-start">
              {data.events && <EventsSection events={data.events} />}
              <AttachmentsSection files={data.files ?? []} />
              <StepsSection />
            </Box>
          </Box>
        </Paper>
      ) : (
        <Paper className="relative max-w-[900px] w-full px-6 py-8 mx-2 shadow-2xl min-h-1/2 overflow-hidden bg-gray-100">
          <Box className="flex flex-wrap mb-6">
            <Skeleton variant="circular" className="mr-4 mb-2 w-[64px] h-[64px]" />
            <Box>
              <Skeleton variant="text" width={210} height={40} className="mb-2 text-gray-900 font-black" />
              <Skeleton variant="text" width={280} height={24} className="text-gray-900 font-black" />
            </Box>
            <IconButton className="absolute top-2 right-2" onClick={handleCloseModal}>
              <Close />
            </IconButton>
          </Box>

          <CenterLoader className="relative" />
        </Paper>
      )}
    </ModalWrapper>
  );
};

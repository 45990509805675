import { useAppDispatch, useAppSelector } from '@app/store/store';
import { GoBackButton } from '@components/Buttons/GoBack/GoBackButton';
import { CenterLoader } from '@components/Loaders/CenterLoader';
import { ProfileHeader } from '@components/ProfileHeader/ProfileHeader';
import { useCurrentLocation } from '@hooks/useCurrentLocation';
import { UserInfoActions } from '@modules/users/components/UserInfoActions/UserInfoActions';
import Edit from '@mui/icons-material/Edit';
import { Box, Button, Container, Skeleton, Typography } from '@mui/material';
import { getUser } from '@slices/users/thunks';
import { usersSlice } from '@slices/users/usersSlice';
import { FC, useEffect } from 'react';
import { Outlet, useParams } from 'react-router';
import { Link } from 'react-router-dom';

export const UserContainer: FC = () => {
  const { status, data } = useAppSelector((store) => store.users.user);
  const params = useParams();
  const dispatch = useAppDispatch();
  const currentLocation = useCurrentLocation('users');

  useEffect(() => {
    if (params.id && params.id !== data?.id) {
      dispatch(getUser(params.id));
    }
  }, [dispatch, params, data]);

  useEffect(() => {
    return () => {
      dispatch(usersSlice.actions.clearSelectedUser());
    };
  }, []);

  return (
    <Container className="p-0 max-w-[1200px] min-h-[200px] relative">
      {currentLocation !== 'new' && <GoBackButton text={currentLocation === 'info' ? 'Users' : 'User info'} to={currentLocation === 'info' ? '/users' : `/users/${params.id}`} />}

      {currentLocation === 'new' && (
        <>
          <GoBackButton to=".." text="Users" />
          <Box className="flex mb-4">
            <Typography variant="h4" fontWeight="bold" textTransform="uppercase" className="text-gray-300 font-black">
              Create user
            </Typography>
          </Box>
        </>
      )}

      <Box>
        {currentLocation !== 'new' && (
          <Box className="flex items-start justify-between flex-wrap">
            {!!data && <ProfileHeader subtitleIsID email={data.email} title={`${data.first_name ?? ''} ${data.last_name ?? ''}`} subtitle={data.id} />}

            {!data && status === 'loading' && (
              <Box className="flex flex-wrap mb-5">
                <Skeleton variant="circular" className="mr-4 mb-2 w-[64px] h-[64px]" />
                <Box>
                  <Skeleton variant="text" width={210} height={40} className="mb-2 text-gray-900 font-black" />
                  <Skeleton variant="text" width={280} height={24} className="text-gray-900 font-black" />
                </Box>
              </Box>
            )}

            {currentLocation === 'info' && (
              <Box className="flex gap-2 mb-4 lg:">
                <Button variant="outlined" component={Link} to={`../${params.id}/edit`} startIcon={<Edit />}>
                  Edit
                </Button>

                <UserInfoActions />
              </Box>
            )}
          </Box>
        )}

        <Outlet />
      </Box>

      {status === 'loading' && !data && <CenterLoader />}
    </Container>
  );
};

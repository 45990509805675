import { API } from '@API/constants';
import { ResponseBaseType, ResponseListBaseType } from '@API/services/interfaces';
import { fetchData } from '@helpers/fetchData/fetchData';
import { VACANCIES } from '@slices/vacancies/interface';

const path = `${API.origin}/vacancies`;

export interface CreateVacancyDTO {
  name: string;
  status: 'open' | 'close';
  experience: number[];
  city: string;
  country: string;
  description: string;
  department_id: string;
  comment?: string;

  steps: string[];
  platforms: string[];
}

export interface UpdateVacancyDTO extends Partial<CreateVacancyDTO> {
  id: string;
}

export class VacanciesService {
  public static async getOne<T = VACANCIES.Vacancy>(id: string) {
    return fetchData<ResponseBaseType<T>>(`${path}/${id}`);
  }

  public static async getMany<T = VACANCIES.VacancyListItem[]>(query?: string) {
    return fetchData<ResponseListBaseType<T>>(`${path}${query ?? ''}`);
  }

  public static async create<T = VACANCIES.Vacancy>(payload: CreateVacancyDTO) {
    return fetchData<ResponseBaseType<T>>(path, 'POST', payload);
  }

  public static async update<T = VACANCIES.Vacancy>(id: string, payload: Partial<CreateVacancyDTO>) {
    return fetchData<ResponseBaseType<T>>(`${path}/${id}`, 'PATCH', payload);
  }

  public static async delete<T = Omit<VACANCIES.Vacancy, 'steps' | 'user' | 'department' | 'platforms'>>(id: string) {
    return fetchData<ResponseBaseType<T>>(`${path}/${id}`, 'DELETE');
  }
}

import { useAppSelector } from '@app/store/store';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { Box, Card, Typography } from '@mui/material';
import { PROFILE } from '@slices/profile/interface';
import classNames from 'classnames';
import { Dispatch, FC, SetStateAction, useState } from 'react';

interface CardProps {
  active: PROFILE.ClientPayment['activePlan'];
  setSelected: Dispatch<SetStateAction<PROFILE.ClientPayment['activePlan']>>;
  title: string;
  activePlan: PROFILE.ClientPayment['activePlan'];
  plan: Exclude<PROFILE.ClientPayment['activePlan'], null>;
  subTitle?: string;
  price?: string;
}

export const PlanCards: FC = () => {
  const {
    payment: { activePlan }
  } = useAppSelector((store) => store.profile);
  const [active, setActive] = useState<PROFILE.ClientPayment['activePlan']>(null);

  return (
    <Box className="grid-cols-3 grid gap-6 p-4 pb-8 border">
      <PlanCard active={active} setSelected={setActive} plan="basic" activePlan={activePlan} title="Basic" subTitle="Started plan for individuals" price="$10" />
      <PlanCard active={active} setSelected={setActive} plan="team" activePlan={activePlan} title="Team" subTitle="Started plan for individuals" price="$10" />
      <PlanCard active={active} setSelected={setActive} plan="enterprise" activePlan={activePlan} title="Enterprise" subTitle="Started plan for individuals" price="$10" />
    </Box>
  );
};

const PlanCard: FC<CardProps> = ({ active, setSelected, subTitle, title, price, plan, activePlan }) => {
  const selectHandler = () => {
    setSelected(plan);
  };

  return (
    <Card
      onClick={selectHandler}
      className={classNames('transition border flex shadow-none justify-between flex-col p-4 relative min-h-[200px] border-solid cursor-pointer', {
        'border-sky-500': active === plan,
        'border-gray-300 hover:border-sky-300': active !== plan
      })}
    >
      {plan === activePlan && <CheckCircle className="absolute right-4 top-4 fill-sky-500" />}
      <Box className="">
        <Typography className="font-bold" textTransform="uppercase" variant="h5">
          {title}
        </Typography>
        {subTitle && <Typography variant="subtitle2">{subTitle}</Typography>}
      </Box>
      {price && (
        <Typography className="font-bold" variant="subtitle1">
          {price} <span className="opacity-50">/ month</span>
        </Typography>
      )}
    </Card>
  );
};

import { UpdateProfileDTO } from '@API/services/profile/profileService';
import { useAppDispatch, useAppSelector } from '@app/store/store';
import { AdditionalContactInfo } from '@components/AdditionalContactInfo/AdditionalContactInfo';
import { InputTextField } from '@components/Fields/InputText/InputTextField';
import { FormWrapper } from '@components/Forms/FormWrapper';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Card, CardHeader, Divider } from '@mui/material';
import { updateSelfInfo } from '@slices/profile/thunks';
import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

const schema = yup
  .object({
    email: yup.string().email('Email must be a valid email').required('Email is required field'),
    first_name: yup.string().min(2, 'First name must be at least 2 characters').required('First Name is required field'),
    last_name: yup.string().min(2, 'Last name must be at least 2 characters').required('Last Name is required field')
  })
  .required();

export const InformationTab: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { status, credentials } = useAppSelector((store) => store.profile);

  const methods = useForm<UpdateProfileDTO>({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (status === 'success' && credentials) {
      methods.reset({
        first_name: credentials.first_name ?? '',
        last_name: credentials.last_name ?? '',
        email: credentials.email ?? '',
        contacts: credentials.contacts?.map(({ value, type }) => ({ type, value }))
      });
    }
  }, [status, credentials, methods]);

  const navigateHandler = (): void => {
    navigate('/', { replace: true });
  };

  const submitRecipe: SubmitHandler<UpdateProfileDTO> = (data) => {
    dispatch(
      updateSelfInfo({
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        contacts: data.contacts?.filter((item) => item?.type || item?.value)
      })
    );
  };

  return (
    <Card className="shadow">
      <CardHeader title="Main information" className="uppercase text-gray-600 pb-2" />

      <Divider />

      {credentials && (
        <FormWrapper
          methods={methods}
          className=""
          formProps={{
            onSubmit: methods.handleSubmit(submitRecipe)
          }}
        >
          <Box className="px-4 py-8 grid gap-4 xl:grid-cols-2 grid-cols-1">
            <InputTextField className="" name="first_name" label="First name" />
            <InputTextField className="" name="last_name" label="Last name" />
            <InputTextField className="" name="email" label="Email" />
          </Box>

          <CardHeader title="Additional contacts" className="uppercase text-gray-600 pb-2" />
          <Divider />

          <AdditionalContactInfo className="p-4 col-span-full" />

          <Divider />

          <Box className="p-4 gap-4 flex flex-row-reverse flex-wrap">
            <LoadingButton loading={status === 'loading'} className="lg:w-fit w-full py-2 px-8" variant="contained" type="submit">
              Save
            </LoadingButton>
            <Button className="lg:w-fit w-full py-2 px-8" variant="outlined" onClick={navigateHandler} color="error">
              Cancel
            </Button>
          </Box>
        </FormWrapper>
      )}
    </Card>
  );
};

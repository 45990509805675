import { CreateAndEditeEventForm } from '@components/Forms/Events/CreateAndEditeEventForm';
import { CreateCandidateEventModalParams } from '@components/Modals/CreateAndEditEvent/interface';
import { BaseModalProps } from '@components/Modals/RootModal/interface';
import { ModalWrapper } from '@components/Modals/RootModal/RootModal';
import { useModal } from '@hooks/useModal';
import DateRangeRounded from '@mui/icons-material/DateRangeRounded';
import { Box, Paper, Typography } from '@mui/material';
import React, { FC } from 'react';

interface Props extends BaseModalProps {
  data: CreateCandidateEventModalParams['modalProps'];
}
export const CreateAndEditEventModal: FC<Props> = ({ data, ...modalProps }) => {
  const { closeModal } = useModal();

  const closeModalHandler = (): void => {
    closeModal('CREATE_EVENT_MODAL');
  };
  return (
    <ModalWrapper {...modalProps}>
      <Paper className="max-w-[600px] bg-transparent mx-2 shadow-2xl overflow-hidden">
        <Box className="bg-brand relative text-white p-6">
          <Typography className="font-bold uppercase opacity-80" variant="h4">
            Create event
          </Typography>
          <Typography className="opacity-70" variant="subtitle2">
            You may create event for candidate and invite members for a joint meeting
          </Typography>
          <DateRangeRounded className="absolute right-0 top-0 opacity-5 w-fit h-full transform rotate-45" />
        </Box>
        <CreateAndEditeEventForm sliceType={data.sliceType} mode="create" className="p-6" cancelAction={closeModalHandler} />
      </Paper>
    </ModalWrapper>
  );
};

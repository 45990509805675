import { FilesService } from '@API/services/files/FilesService';
import { FileFormat, RequestStatus } from '@app/store/interface';
import { useAppDispatch } from '@app/store/store';
import { FileCard } from '@components/Cards/File/FileCard';
import { RoundedLoader } from '@components/Loaders/RoundedLoader';
import { useModal } from '@hooks/useModal';
import AddRounded from '@mui/icons-material/AddRounded';
import { Card, CardHeader, Divider, IconButton, List } from '@mui/material';
import { removeCandidateFile } from '@slices/candidates/thunks';
import { DefaultComponentProps } from 'custom';
import { sortBy } from 'lodash';
import React, { FC } from 'react';

interface AttachmentsSectionProps extends DefaultComponentProps {
  files: FileFormat[];
  candidate_id?: string;
  status?: RequestStatus;
}

export const AttachmentsSection: FC<AttachmentsSectionProps> = ({ files, candidate_id, status }) => {
  const { openModal } = useModal();
  const dispatch = useAppDispatch();

  const uploadFileHandler = (): void => {
    if (candidate_id) {
      openModal({
        modalName: 'CANDIDATE_UPLOAD_FILE_MODAL',
        modalProps: {
          candidate_id
        }
      });
    }
  };

  const removeHandler = (file_id: string): (() => void) => {
    return () => dispatch(removeCandidateFile({ file_id }));
  };

  return (
    <Card className="shadow w-full relative">
      <CardHeader
        action={
          <IconButton onClick={uploadFileHandler}>
            <AddRounded />
          </IconButton>
        }
        title={<span>Files</span>}
        className="uppercase text-gray-600 py-3"
      />
      {!!files.length && (
        <>
          <Divider />

          <List className="py-0">
            {files &&
              sortBy(files, (value) => value.created_at)
                .reverse()
                .map((file) => (
                  <FileCard
                    className="border-none mx-1 px-2"
                    key={file.id}
                    filename={`${file.name}${file.format}`}
                    size={file.size}
                    downloadable
                    downloadHandler={() =>
                      FilesService.downloadCandidateFile({
                        file_id: file.id,
                        filename: `${file.name}${file.format}`
                      })
                    }
                    removable
                    removeHandler={removeHandler(file.id)}
                    created_at={file.created_at}
                    format={file.format}
                  />
                ))}
          </List>
        </>
      )}
      {status === 'loading' && <RoundedLoader />}
    </Card>
  );
};

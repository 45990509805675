import { Box, CircularProgress } from '@mui/material';
import classNames from 'classnames';
import React, { FC } from 'react';

interface CenterLoaderProps {
  className?: string;
  spinnerClasses?: string;
}

export const CenterLoader: FC<CenterLoaderProps> = ({ className, spinnerClasses }) => {
  return (
    <Box className={classNames('absolute w-full h-full inset-0 flex justify-center items-center z-20', className)}>
      <CircularProgress className={spinnerClasses ?? 'w-10 h-10'} />
    </Box>
  );
};

import { RequestStatus } from '@app/store/interface';
import ArticleIcon from '@mui/icons-material/Article';
import Close from '@mui/icons-material/Close';
import DownloadRounded from '@mui/icons-material/DownloadRounded';
import FilePresent from '@mui/icons-material/FilePresent';
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Box, Card, CardProps, IconButton, Typography } from '@mui/material';
import classNames from 'classnames';
import { DefaultComponentProps } from 'custom';
import { format as formatFns, isValid } from 'date-fns';
import { FC, useCallback } from 'react';

interface FileCardProps extends DefaultComponentProps, CardProps {
  filename: string;
  size: number;
  created_at: Date;
  removable?: boolean;
  downloadable?: boolean;
  downloadHandler?: () => void;
  removeHandler?: () => void;
  format?: string;
  status?: RequestStatus;
}

export const FileCard: FC<FileCardProps> = ({ created_at, filename, size, removeHandler, removable, className, downloadable, downloadHandler, format, ...cardProps }) => {
  const icon = useCallback((format: string | undefined) => {
    switch (format) {
      case '.pdf':
        return <PictureAsPdfIcon className="mr-2 fill-gray-500" />;

      case '.png':
      case '.jpg':
      case '.jpeg':
        return <ImageIcon className="mr-2 fill-gray-500" />;

      case '.doc':
      case '.docx':
      case '.txt':
      case '.html':
      case '.htm':
      case '.fb2':
        return <ArticleIcon className="mr-2 fill-gray-500" />;

      default:
        return <FilePresent className="mr-2 fill-gray-500" />;
    }
  }, []);

  return (
    <Card className={classNames('flex my-2 py-1 px-4 items-center justify-between shadow-none border border-solid border-gray-200', className)} {...cardProps}>
      <Box className="flex items-center">
        {icon(format)}
        <Box>
          <Typography>{filename}</Typography>
          <Typography variant="caption" className="text-gray-500">
            {`${Math.round(size / 1000)} Kb`}, &nbsp;
            {isValid(new Date(created_at)) && `Add: ${formatFns(new Date(created_at), 'dd MMMM yyyy, HH:mm')}`}
          </Typography>
        </Box>
      </Box>
      {downloadable && (
        <IconButton size="small" onClick={downloadHandler}>
          <DownloadRounded />
        </IconButton>
      )}
      {removable && (
        <IconButton size="small" onClick={removeHandler}>
          <Close />
        </IconButton>
      )}
    </Card>
  );
};

import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import { Button, Popover } from '@mui/material';
import { FC, MouseEvent, useState } from 'react';
import { UserActionsPopover } from '@components/Popovers/UserActions/UserActionsPopover';

export const UserInfoActions: FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button endIcon={anchorEl ? <ArrowDropUp /> : <ArrowDropDown />} variant="contained" onClick={handleClick} aria-describedby="user-actions">
        Actions
      </Button>
      <Popover
        className="mt-2"
        id="user-actions"
        open={!!anchorEl}
        onClose={handleClose}
        // onClick={handleClose}
        anchorEl={anchorEl}
      >
        <UserActionsPopover />
      </Popover>
    </>
  );
};

import useDebounce from '@hooks/useDebounce';
import { useEffect, useState } from 'react';

export const useDebouncedRequest = (query: string, action: Function, delay: number = 300): void => {
  const debounceMiddleware = useDebounce(query, delay);
  const [debounceQuery, setDebounceQuery] = useState(debounceMiddleware || query);

  useEffect(() => {
    setDebounceQuery(query || '');
  }, [debounceMiddleware]);

  useEffect(() => {
    action();
  }, [debounceQuery]);
};

import { CreateUserDTO, CreateUserResponseData, UsersService } from '@API/services/users/usersService';
import { AfterActionProps, BaseAsyncThunkOptions, ListResponse } from '@app/store/interface';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { USERS } from '@slices/users/interface';

export const addNewUser = createAsyncThunk<CreateUserResponseData, CreateUserDTO & AfterActionProps<string>, BaseAsyncThunkOptions>(
  'users/addNewUser',
  async ({ afterActionWithParams, ...payload }, thunkApi) => {
    const response = await UsersService.addNewUser<CreateUserResponseData>(payload);

    if (response.ok) {
      afterActionWithParams && afterActionWithParams(response.data.data.id);
      return response.data.data;
    } else {
      return thunkApi.rejectWithValue(response.error);
    }
  }
);

export const getUsers = createAsyncThunk<ListResponse<Omit<USERS.User, 'last_visit_at' | 'created_at_at' | 'updated_at'>[]>, string | undefined, BaseAsyncThunkOptions>(
  'users/getUsers',
  async (query, thunkApi) => {
    const response = await UsersService.getUsers(query);

    if (response.ok) {
      return {
        data: response.data.data,
        total: response.data.total
      };
    } else {
      return thunkApi.rejectWithValue(response.error);
    }
  }
);

export const getUser = createAsyncThunk<USERS.User, string, BaseAsyncThunkOptions>('users/getUser', async (id, thunkApi) => {
  const response = await UsersService.getUser(id);

  if (response.ok) {
    return response.data.data;
  } else {
    return thunkApi.rejectWithValue(response.error);
  }
});

export const updateUser = createAsyncThunk<USERS.User, Partial<CreateUserDTO> & { id: string } & AfterActionProps, BaseAsyncThunkOptions>(
  'users/updateUser',
  async ({ id, afterAction, ...rest }, thunkApi) => {
    const response = await UsersService.updateUser(id, rest);

    if (response.ok) {
      afterAction && afterAction();
      return response.data.data;
    } else {
      return thunkApi.rejectWithValue(response.error);
    }
  }
);

export const deleteUser = createAsyncThunk<USERS.User, { id: string } & AfterActionProps, BaseAsyncThunkOptions>('users/deleteUser', async ({ id, afterAction }, thunkApi) => {
  const response = await UsersService.deleteUser(id);

  if (response.ok) {
    afterAction && afterAction();
    return response.data.data;
  } else {
    return thunkApi.rejectWithValue(response.error);
  }
});

export const inviteUser = createAsyncThunk<
  { link: string; expiry: string } | undefined,
  Pick<USERS.User, 'role'> & AfterActionProps & { sendToEmail?: boolean },
  BaseAsyncThunkOptions
  >('users/inviteUser', async ({ afterAction, ...data }, thunkApi) => {
  const response = await UsersService.invite<{ link: string; expiry: string } | undefined>(data);

  if (response.ok) {
    afterAction && afterAction();

    if (!data.sendToEmail) {
      return response.data.data;
    }
  } else {
    return thunkApi.rejectWithValue(response.error);
  }
});

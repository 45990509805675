import { useEmailHashIcon } from '@hooks/useEmailHashIcon';
import { Avatar, Box, Typography } from '@mui/material';
import classNames from 'classnames';
import React, { FC } from 'react';

interface ProfileHeaderProps {
  photo?: string | null;
  title?: string;
  subtitle?: string | number;
  subtitleIsID?: boolean;
  email?: string | null;
  className?: string;
}

export const ProfileHeader: FC<ProfileHeaderProps> = ({ subtitle, photo, title, subtitleIsID, email, className }) => {
  const { image } = useEmailHashIcon(email, 'robohash');

  return (
    <Box className={classNames('flex flex-wrap', className)}>
      <Avatar className="mr-4 mb-2 w-[64px] h-[64px]" src={photo || image || undefined} />
      <Box>
        <Typography className="mb-2 text-gray-900 font-black" variant="h4">
          {title ?? ''}
        </Typography>
        <Typography variant="subtitle2">
          {subtitleIsID && 'ID: '}
          <span className="rounded-full bg-gray-200 py-1 px-2">{subtitle ?? ''}</span>
        </Typography>
      </Box>
    </Box>
  );
};

import { StyledChip } from '@components/Chips/StyledChip/StyledChip';
import Close from '@mui/icons-material/Close';
import { Autocomplete, AutocompleteProps, Checkbox, CircularProgress, ListItemIcon, ListItemText, MenuItem, TextField, TextFieldProps } from '@mui/material';
import { AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete/Autocomplete';
import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export interface InputMultiselectProps<O> extends Omit<AutocompleteProps<O, true, true, false>, 'renderInput'> {
  name: string;
  options: O[];
  optionLabelRefName: keyof O;
  optionValueRefName: keyof O;

  optionComponent?: (data: O) => ReactNode;
  chipComponent?: (data: O, chipControlProps: ReturnType<AutocompleteRenderGetTagProps>) => ReactNode;
  checkboxOption?: boolean;
  textFieldProps?: TextFieldProps;
  label?: string;
  className?: string;
}

export const InputMultiselect = <TOption,>(props: InputMultiselectProps<TOption>) => {
  const {
    className,
    name,
    label,
    options,
    loading,
    optionLabelRefName,
    optionValueRefName,
    filterSelectedOptions,
    checkboxOption,
    optionComponent,
    chipComponent,
    textFieldProps = {}
  } = props;
  const methods = useFormContext();
  return (
    <Controller
      name={name}
      defaultValue={[]}
      control={methods.control}
      render={({ field, fieldState }) => (
        <Autocomplete
          {...field}
          openOnFocus
          multiple
          filterSelectedOptions={filterSelectedOptions}
          disableCloseOnSelect
          className={className}
          loading={loading}
          options={options}
          isOptionEqualToValue={(option, value) => {
            if (typeof value === 'string') {
              return option[optionValueRefName] === value;
            }
            return option[optionValueRefName] === value[optionValueRefName];
          }}
          getOptionLabel={(option) => option[optionLabelRefName] ?? ''}
          onChange={(e, option) => {
            field.onChange(option ?? []);
          }}
          clearText=""
          openText=""
          renderInput={(params) => (
            <TextField
              {...params}
              {...textFieldProps}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
              error={!!fieldState.error}
              helperText={fieldState.error?.message ?? ''}
              label={label}
            />
          )}
          renderOption={(props, option, { selected }) => {
            return (
              <MenuItem
                {...props}
                selected={selected}
                key={option[optionValueRefName]}
                value={option[optionValueRefName]}
                className={classNames('flex items-center justify-between')}
              >
                {checkboxOption && (
                  <ListItemIcon>
                    <Checkbox size="small" checked={selected} />
                  </ListItemIcon>
                )}
                <ListItemText>{optionComponent ? optionComponent(option) : option[optionLabelRefName]}</ListItemText>
              </MenuItem>
            );
          }}
          renderTags={(value: TOption[], getTagProps) => {
            return value.map((chip, index) => {
              if (chipComponent) {
                return chipComponent(chip, getTagProps({ index }));
              } else {
                return <StyledChip {...getTagProps({ index })} deleteIcon={<Close />} variant="outlined" label={chip[optionLabelRefName]} />;
              }
            });
          }}
        />
      )}
    />
  );
};

import { Chip, ChipProps } from '@mui/material';
import classNames from 'classnames';
import React from 'react';

export interface StyledChipProps extends Omit<ChipProps, 'label'> {
  //TODO: Temp any. problem in InputMultiselect.jsx in renderTag option
  label: any;
}

export const StyledChip = ({ label, className, ...chipProps }: StyledChipProps) => {
  return (
    <Chip
      {...chipProps}
      component="span"
      className={classNames('m-0.5 px-2  rounded-full border border-solid transition-all text-gray-500 transition-all border-gray-500', className)}
      label={label}
    />
  );
};

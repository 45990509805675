import { useAppDispatch, useAppSelector } from '@app/store/store';
import { AnimateLayoutChanges, defaultAnimateLayoutChanges, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { queryStringBuilder } from '@helpers/queryStringBuilder/queryStringBuilder';
import { KanbanColumnTicket } from '@modules/kanban/components/Ticket/KanbanColumnTicket';
import { KanbanStepContainerProps } from '@modules/kanban/interface';
import DragIndicator from '@mui/icons-material/DragIndicator';
import { Box, CircularProgress, Paper, Typography } from '@mui/material';
import { getKanbanVacancyStepCandidates } from '@slices/kanban/thunks';
import classNames from 'classnames';
import React, { CSSProperties, FC, useEffect, useMemo } from 'react';

const animateLayoutChanges: AnimateLayoutChanges = (args) => defaultAnimateLayoutChanges({ ...args, wasDragging: true });

export const KanbanStepContainer: FC<KanbanStepContainerProps> = ({ columnData, isClone, vacancy_id, className }) => {
  const dispatch = useAppDispatch();
  const { vacancies, loading_column_ids } = useAppSelector((store) => store.kanban);
  const candidates = useMemo(() => {
    return vacancies[vacancy_id].steps.find((step) => step.id === columnData.id)?.candidates ?? [];
  }, [vacancies[vacancy_id]]);
  const { attributes, listeners, setNodeRef, transform, transition, isDragging, isOver } = useSortable({
    id: columnData.id,
    animateLayoutChanges,
    data: {
      type: 'container',
      children: candidates
    }
  });

  useEffect(() => {
    !isClone &&
      dispatch(
        getKanbanVacancyStepCandidates({
          step_id: columnData.id,
          vacancy_id,
          query: queryStringBuilder({ vacancy: vacancy_id })
        })
      );
  }, []);

  const style: CSSProperties = {
    transition,
    transform: isClone ? 'rotate(1deg)' : CSS.Transform.toString(transform)
  };

  return (
    <Box style={style} ref={setNodeRef} className={classNames('h-full w-[280px] px-2 min-w-[280px] inline-flex items-start', className)}>
      <Paper
        className={classNames(
          'bg-gray-200 shadow-none px-2 pt-2 pb-2 w-full flex flex-col max-h-full relative',
          { 'opacity-25 border-none': isDragging },
          { 'shadow-xl z-10 touch-none': isClone }
        )}
      >
        <Box className="mb-2 mt-2 flex justify-between">
          <Box className="">
            <Typography className="text-gray-500">
              {columnData.name}
              {loading_column_ids.includes(columnData.id) && <CircularProgress className="w-3 h-3 ml-2" />}
            </Typography>
            <Typography variant="caption" className="text-gray-400">{`${columnData.candidates?.length ?? 0} candidate(s)`}</Typography>
          </Box>
          <DragIndicator {...attributes} {...listeners} className="text-gray-500 touch-none cursor-grab active:cursor-grabbing" />
        </Box>

        <Box className=" z-10 flex-1 basis-auto overflow-y-auto min-h-0 scrollbar">
          {candidates &&
            candidates.map((candidate) => (
              <KanbanColumnTicket disabled={loading_column_ids.includes(columnData.id)} isOver={isOver} container_id={columnData.id} key={candidate.id} data={candidate} />
            ))}
        </Box>
        {isDragging && <Paper className="absolute z-10 inset-0 shadow-none bg-gray-300" />}
        <span className={classNames('w-full mt-2 h-1 flex rounded-full transition-all', `bg-${columnData.color}-500`)} />
      </Paper>
    </Box>
  );
};

import { useAppDispatch } from '@app/store/store';
import { GlobalLoader } from '@components/Loaders/GlobalLoader';
import { Header } from '@modules/header/Header';
import { LeftNavbar } from '@modules/navbar/LeftNavbar';
import { NotificationModule } from '@modules/notification/NotificationModule';
import { Box, useMediaQuery } from '@mui/material';
import { init } from '@slices/global/thunks';
import classNames from 'classnames';
import { FC, useCallback, useEffect, useState } from 'react';
import { Outlet } from 'react-router';
import { useLocation, useNavigate } from 'react-router-dom';

export const AppLayoutModule: FC = () => {
  const [leftDrawerIsOpen, setLeftDrawerIsOpen] = useState(() => window.innerWidth >= 1200);
  const matches = useMediaQuery('(min-width: 1200px)');
  const dispatch = useAppDispatch();
  const leftDrawerWidth = 280;

  const { state }: { state: any } = useLocation();
  const navigate = useNavigate();

  const handleDrawerOpen = (): void => {
    setLeftDrawerIsOpen(!leftDrawerIsOpen);
  };

  const preloadData = useCallback((): void => {
    dispatch(init());
    if (state && state.from) {
      navigate(state.from);
    }
  }, []);

  useEffect(() => {
    preloadData();
  }, [preloadData]);

  return (
    <Box className="flex w-full">
      <Header leftDrawerWidth={leftDrawerWidth} leftDrawerIsOpen={leftDrawerIsOpen} setLeftDrawerIsOpen={handleDrawerOpen} />

      <LeftNavbar leftDrawerWidth={leftDrawerWidth} leftDrawerIsOpen={leftDrawerIsOpen} setLeftDrawerIsOpen={handleDrawerOpen} />

      <Box className={classNames('flex w-full pt-[56px] lg:pt-[64px] h-[100vh]')} sx={{ paddingLeft: matches ? `${leftDrawerWidth}px` : 0 }}>
        <Box className="p-4 w-full overflow-auto relative">
          <Outlet />
        </Box>
      </Box>
      <NotificationModule />
      <GlobalLoader />
    </Box>
  );
};

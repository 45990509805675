import { FileFormat, RequestStatus, UploadFileMeta } from '@app/store/interface';
import { FileUpload } from '@components/Fields/FileUpload/FileUpload';
import { CandidateSalarySelect } from '@components/Forms/Candidates/sections/components/CandidateSalarySelect';
import { CandidateVacancyPlatform } from '@components/Forms/Candidates/sections/components/CandidateVacancyPlatform';
import { Box, CardHeader, Divider } from '@mui/material';
import React, { FC } from 'react';

interface Props {
  mode: 'edit' | 'create';
  addedFiles?: FileFormat[];
  addedFilesRemoveHandler?: (id: string) => void;
  fileUploadProgressData?: UploadFileMeta | null;
  fileUploadStatus?: RequestStatus;
}

export const CandidateJobSection: FC<Props> = ({ mode, addedFiles, addedFilesRemoveHandler, fileUploadProgressData, fileUploadStatus }) => {
  return (
    <>
      <CardHeader title="Job information" className="uppercase text-gray-600 pb-2" />
      <Divider />

      <Box className="px-4 py-8 grid gap-4 xl:grid-cols-2 grid-cols-1">
        <Box className="flex md:items-center md:gap-2 gap-4 md:flex-row flex-col col-span-full">
          <CandidateSalarySelect />
          <CandidateVacancyPlatform mode={mode} />
        </Box>

        <FileUpload
          fileUploadStatus={fileUploadStatus}
          fileUploadProgressData={fileUploadProgressData}
          addedFilesRemoveHandler={addedFilesRemoveHandler}
          addedFiles={addedFiles}
          accept="image/*,.pdf,.txt,.doc,.docx,.html,.htm,.fb2"
          className="bg-gray-100/50 rounded-xl border border-solid border-gray-300 col-span-full"
          name="files"
        />
      </Box>
    </>
  );
};

import { SkillsSearchAndSortsProps } from '@modules/skills/interface';
import { useAppDispatch, useAppSelector } from '@app/store/store';
import { InputTextField } from '@components/Fields/InputText/InputTextField';
import { FormWrapper } from '@components/Forms/FormWrapper';
import Search from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import { skillsSlice } from '@slices/skills/skillsSlice';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';

export const SkillsSearch: FC<SkillsSearchAndSortsProps> = ({ className }) => {
  const dispatch = useAppDispatch();
  const { search } = useAppSelector((store) => store.skills);

  const methods = useForm<{ search: string }>({
    defaultValues: {
      search
    }
  });

  useEffect(() => {
    const subscription = methods.watch(({ search }) => {
      dispatch(skillsSlice.actions.touchSearchActions({ search: search || '' }));
    });
    return () => subscription.unsubscribe();
  }, [methods.watch]);

  return (
    <FormWrapper className={classNames('mb-4 flex gap-2 flex-wrap', className)} methods={methods}>
      <InputTextField
        className="flex-1 min-w-[200px]"
        name="search"
        placeholder="Search"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          )
        }}
      />
    </FormWrapper>
  );
};

import { useAppSelector } from '@app/store/store';
import { FileUploadingToast } from '@components/Notifications/FileUploadingToast';
import { SnackbarContent } from 'notistack';
import React, { forwardRef } from 'react';

export const CandidateFileUploadToastHOC = forwardRef<HTMLDivElement, { key: string | number; message: any }>(({ key, message }, ref) => {
  const { files_upload_meta, files_upload_status } = useAppSelector((store) => store.candidates.candidate);
  return (
    <SnackbarContent ref={ref}>
      <FileUploadingToast status={files_upload_status} fileUploadProgressData={files_upload_meta} id={key} key={key} message={message} />
    </SnackbarContent>
  );
});

CandidateFileUploadToastHOC.displayName = 'CandidateFileUploadToastHOC';

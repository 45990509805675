import { useAppDispatch, useAppSelector } from '@app/store/store';
import { GoBackButton } from '@components/Buttons/GoBack/GoBackButton';
import { CenterLoader } from '@components/Loaders/CenterLoader';
import { useCurrentLocation } from '@hooks/useCurrentLocation';
import { TemplateActions } from '@modules/templates/components/Actions/TemplateActions';
import Edit from '@mui/icons-material/Edit';
import { Box, Button, Container, Typography } from '@mui/material';
import { platformsSlice } from '@slices/platform/platformSlice';
import { getPlatform } from '@slices/platform/thunks';
import React, { FC, useEffect } from 'react';
import { Outlet, useParams } from 'react-router';
import { Link } from 'react-router-dom';

export const TemplatesContainer: FC = () => {
  const { status, data } = useAppSelector((store) => store.platforms.platform);
  const params = useParams();
  const dispatch = useAppDispatch();
  const currentLocation = useCurrentLocation('templates');

  useEffect(() => {
    if (params.id && params.id !== data?.id) {
      dispatch(getPlatform(params.id));
    }
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(platformsSlice.actions.clearSelectedPlatform());
    };
  }, []);

  return (
    <Container className="p-0 max-w-[1200px] min-h-[200px] relative">
      {currentLocation !== 'new' && (
        <GoBackButton text={currentLocation === 'info' ? 'Platforms' : 'Platform info'} to={currentLocation === 'info' ? '/platforms' : `/platforms/${params.id}`} />
      )}

      <Box>
        {!!data && (
          <Box className="flex items-start justify-between flex-wrap">
            <Box className="flex flex-wrap mb-5">
              <Box className="mr-2">
                <Typography className="mb-2 text-gray-300 uppercase font-black " fontWeight="bold" variant="h4">
                  {currentLocation === 'info' && 'Template information'}
                  {currentLocation === 'edit' && 'Template edit'}
                  {currentLocation === 'new' && 'Create template'}
                </Typography>
              </Box>
            </Box>

            {currentLocation === 'info' && (
              <Box className="flex gap-2 mb-4 lg:">
                <Button variant="outlined" component={Link} to={`../${params.id}/edit`} startIcon={<Edit />}>
                  Edit
                </Button>

                <TemplateActions />
              </Box>
            )}
          </Box>
        )}
        <Outlet />
      </Box>

      {status === 'loading' && !data && <CenterLoader className={data ? 'bg-white/50' : 'bg-transparent'} />}
    </Container>
  );
};

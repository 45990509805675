import { useAppDispatch, useAppSelector } from '@app/store/store';
import { CenterLoader } from '@components/Loaders/CenterLoader';
import { Box } from '@mui/material';
import { candidatesSlice } from '@slices/candidates/candidatesSlice';
import { getCandidate } from '@slices/candidates/thunks';
import { getVacancies } from '@slices/vacancies/thunks';
import React, { FC, useCallback, useEffect } from 'react';
import { Outlet, useParams } from 'react-router';

export const CandidateContainer: FC = () => {
  const { data: candidate_data, status } = useAppSelector((store) => store.candidates.candidate);
  const params = useParams();
  const dispatch = useAppDispatch();

  const fetchData = useCallback(() => {
    if (params.id && params.id !== candidate_data?.id) {
      dispatch(getCandidate(params.id));
    }
  }, [params, dispatch]);

  useEffect(() => {
    fetchData();
  }, [dispatch, fetchData]);

  useEffect(() => {
    dispatch(getVacancies());

    return () => {
      dispatch(candidatesSlice.actions.clearSelectedCandidate());
    };
  }, []);

  return (
    <Box>
      <Outlet />
      {status === 'loading' && !candidate_data && <CenterLoader />}
    </Box>
  );
};

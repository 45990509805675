import { inMomentSelector } from '@helpers/date/datesHelpers';
import { Box, Typography } from '@mui/material';
import { DefaultComponentProps } from 'custom';
import { formatDistanceToNow } from 'date-fns';
import React, { FC, useMemo } from 'react';

interface EventDateSectionProps extends DefaultComponentProps {
  start: any;
  end: any;
}

export const EventDateSection: FC<EventDateSectionProps> = ({ start, end }) => {
  const moment = useMemo(() => inMomentSelector(start, end), []);
  return (
    <Box className="flex gap-2 items-center">
      <Typography className="w-full flex flex-wrap items-center">
        {moment === 'now' && end && (
          <span className="w-full justify-between flex flex-wrap gap-2 items-center">
            <span className="py-1 px-4 text-lime-600 border border-solid border-lime-600 bg-lime-100 rounded-xl">Now is passing</span>
            <span className="text-gray-500">
              <span className="mr-1">Until the end</span>
              <span>{formatDistanceToNow(new Date(end), { addSuffix: true, includeSeconds: true })}</span>
            </span>
          </span>
        )}
        {moment === 'today' && start && (
          <span className="w-full justify-between flex flex-wrap gap-2 items-center">
            <span className="py-1 px-4 text-sky-500 border border-solid border-sky-600 bg-sky-100 rounded-xl">Today</span>
            <span className="text-gray-500">
              <span className="mr-1">Start</span>
              <span>
                {formatDistanceToNow(new Date(start), {
                  addSuffix: true,
                  includeSeconds: true
                })}
              </span>
            </span>
          </span>
        )}
        {moment === 'future' && start && (
          <span className="w-full justify-between flex flex-wrap gap-2 items-center">
            <span className="py-1 px-4 text-sky-500 border border-solid border-sky-600 bg-sky-100 rounded-xl">Future</span>
            <span className="text-gray-500">
              {formatDistanceToNow(new Date(start), {
                addSuffix: true,
                includeSeconds: true
              })}
            </span>
          </span>
        )}
        {moment === 'past' && end && (
          <span className="w-full justify-between flex flex-wrap gap-2 items-center">
            <span className="py-1 px-4 text-gray-500 border border-solid border-gray-500 bg-gray-100 rounded-xl">Past</span>
            <span className="text-gray-500">
              <span className="mr-1">Ended</span>
              <span>{formatDistanceToNow(new Date(end), { addSuffix: true, includeSeconds: true })}</span>
            </span>
          </span>
        )}
      </Typography>
    </Box>
  );
};

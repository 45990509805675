import { StepsSectionsProps } from '@modules/vacancies/interface';
import { Box, Tooltip, Typography } from '@mui/material';
import classNames from 'classnames';
import { FC } from 'react';

export const StepsSections: FC<StepsSectionsProps> = ({ steps }) => {
  return (
    <Box className="flex gap-1">
      {[...steps]
        .sort((a, b) => (a.ordering > b.ordering ? 1 : -1))
        .map((step) => {
          return (
            <Tooltip arrow key={step.id} title={step.name}>
              <Box className="inline-flex flex-col items-center">
                <Typography className="" variant="caption">
                  {step.candidate_step}
                </Typography>
                <Box
                  className={classNames(
                    'w-[20px] h-[20px] bg-gray-300 rounded-sm ',
                    //Самая плохое дизайнерское решение в мире
                    { [`bg-${step.color}-600`]: !!step.candidate_step },
                    { [`bg-${step.color}-600`]: step.candidate_step && step.finished }
                  )}
                />
              </Box>
            </Tooltip>
          );
        })}
    </Box>
  );
};

import { GoBackButton } from '@components/Buttons/GoBack/GoBackButton';
import { SkillsAddAndEditForm } from '@components/Forms/Skills/SkillsAddAndEditForm';
import { Box, Container, Paper, Typography } from '@mui/material';
import { FC } from 'react';

export const SkillsAddPage: FC = () => {
  return (
    <Box>
      <Container className="p-0 max-w-[1200px]">
        <GoBackButton to=".." text="Back to Skills" />
        <Box>
          <Box className="flex flex-wrap mb-5">
            <Typography className="mb-1 uppercase mb-2 text-gray-300 uppercase font-black" variant="h4">
              new skill
            </Typography>
          </Box>
          <Paper className="p-4">
            <SkillsAddAndEditForm className="grid gap-4 grid-cols-2" mode="create" />
          </Paper>
        </Box>
      </Container>
    </Box>
  );
};

import { StepsDroppableListProps } from '@modules/steps/interface';
import { useAppDispatch, useAppSelector } from '@app/store/store';
import { CenterLoader } from '@components/Loaders/CenterLoader';
import { DndContext, DragEndEvent, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { StepsDraggableElement } from '@modules/steps/components/Drag&Drop/StepsDraggableElement';
import HelpOutline from '@mui/icons-material/HelpOutline';
import { Box, List, Typography } from '@mui/material';
import { STEPS } from '@slices/steps/interface';
import { changeStepOrder } from '@slices/steps/stepsSlice';
import { FC, useCallback, useMemo } from 'react';

export const StepsDroppableList: FC<StepsDroppableListProps> = ({ popoverOpenAction }) => {
  const { steps, ordering, search, status } = useAppSelector((store) => store.steps);
  const dispatch = useAppDispatch();

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10
      }
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5
      }
    })
  );

  const filterHandler = useCallback(
    (item: STEPS.Step) => {
      if (search === '') {
        return item;
      }

      if (item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
        return item;
      }
    },
    [search]
  );

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (over && active.id !== over.id) {
      const oldIndex = steps.data.findIndex((item) => item.id === active.id);
      const newIndex = steps.data.findIndex((item) => item.id === over.id);

      dispatch(
        changeStepOrder({
          id: active.id,
          active_index: oldIndex,
          over_index: newIndex,
          ordering: steps.data[newIndex].ordering,
          cached_data: steps.data,
          return_all: true,
          resortArray: true
        })
      );
    }
  };

  const list = useMemo(() => steps.data.filter(filterHandler), [search, steps]);

  return (
    <List className="relative flex flex-col gap-4 py-0 min-h-[100px]">
      <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={handleDragEnd}>
        <SortableContext items={steps.data} strategy={verticalListSortingStrategy}>
          {list.map((item) => (
            <StepsDraggableElement orderingIsActive={ordering} item={item} key={item.id} onActionClick={popoverOpenAction} />
          ))}
        </SortableContext>
      </DndContext>

      {status === 'loading' && <CenterLoader className="bg-white/50" />}
      {status !== 'loading' && !list.length && (
        <Box className="absolute bg-white w-full h-full inset-0 flex overflow-hidden justify-center items-center z-20">
          <Box className="relative w-full h-full justify-center items-center flex">
            <HelpOutline className="w-[80px] -mr-5 h-full text-gray-300" />
            <Typography className="relative z-10 bg-white text-gray-300 font-bold uppercase" variant="h5">
              No data...
            </Typography>
          </Box>
        </Box>
      )}
    </List>
  );
};

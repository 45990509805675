import { useAppSelector } from '@app/store/store';
import { RoundedLoader } from '@components/Loaders/RoundedLoader';
import { StepsViewer } from '@components/Steps/StepsViewer/StepsViewer';
import { Box, Card, CardHeader, Divider, ListItem, ListItemText, Typography } from '@mui/material';
import { DefaultComponentProps } from 'custom';
import React, { FC } from 'react';

interface StepsSectionProps extends DefaultComponentProps {}

export const StepsSection: FC<StepsSectionProps> = () => {
  const { data, step_status } = useAppSelector((store) => store.candidates.candidate);

  return (
    <Card className="shadow relative w-full">
      <CardHeader title="Steps" className="uppercase text-gray-600 py-3" />
      <Divider />

      {data?.steps && !!data.steps.length && (
        <Box className="px-4 relative overflow-hidden">
          <StepsViewer
            update_status={step_status}
            candidate_id={data.id}
            vacancy_id={data.vacancy?.id}
            steps={[...data.steps].sort((a, b) => (a.ordering < b.ordering ? 1 : -1))}
          />
        </Box>
      )}

      {!data?.steps?.length && (
        <ListItem className="flex flex-wrap">
          <ListItemText className="w-full">
            <Typography variant="subtitle1">No information</Typography>
          </ListItemText>
        </ListItem>
      )}
      {step_status === 'loading' && <Box className="absolute inset-0 bg-transparent" />}
      {step_status === 'loading' && <RoundedLoader />}
    </Card>
  );
};

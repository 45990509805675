import MoreVert from '@mui/icons-material/MoreVert';
import { IconButton, TableBody, TableCell, TableCellProps, TableRow } from '@mui/material';
import React, { MouseEvent, ReactNode } from 'react';

interface Row extends Record<string, any> {
  id: string;
}

interface CellProps<K> {
  name: keyof K | string;
  label: ReactNode;
  cellProps?: TableCellProps;
}

export interface BaseTableBodyProps<T> {
  list: T[];
  onActionsClick: (event: MouseEvent<HTMLButtonElement>) => void;
  renderRowItems: (data: T) => CellProps<T>[];
}

export const BaseTableBody = <T extends Row>({ list = [], onActionsClick, renderRowItems }: BaseTableBodyProps<T>) => {
  return (
    <TableBody>
      {list.map((item) => (
        <TableRow className="hover:bg-gray-100" key={item.id} hover tabIndex={-1}>
          {renderRowItems(item).map(({ name, label, cellProps = {} }, index) => (
            <TableCell aria-colindex={index + 1} key={name as string} {...cellProps}>
              {label}
            </TableCell>
          ))}
          <TableCell align="right">
            <IconButton aria-label={item.id} onClick={onActionsClick}>
              <MoreVert />
            </IconButton>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

import { CandidateInfoModal } from '@components/Modals/CandidateInfo/CandidateInfoModal';
import { CandidateInfoModalParams } from '@components/Modals/CandidateInfo/interface';
import { CandidateStepConfirmationModal } from '@components/Modals/CandidateStepComment/CandidateStepComment';
import { CandidateStepConfirmationModalParams } from '@components/Modals/CandidateStepComment/interface';
import { ConfirmModalParams } from '@components/Modals/Confirm/interface';
import { CreateAndEditEventModal } from '@components/Modals/CreateAndEditEvent/CreateAndEditEventModal';
import { CreateCandidateEventModalParams } from '@components/Modals/CreateAndEditEvent/interface';
import { EventModal } from '@components/Modals/Event/EventModal';
import { EventModalParams } from '@components/Modals/Event/interface';
import { CandidateFileUploadModal } from '@components/Modals/FileUpload/CandidateFileUploadModal';
import { CandidateUploadFileModalParams } from '@components/Modals/FileUpload/interface';
import { InviteModalParams } from '@components/Modals/Invite/interface';
import { ConfirmModal } from '../Confirm/ConfirmModal';
import { InviteModal } from '../Invite/InviteModal';

export const modalsList = {
  CONFIRM_MODAL: ConfirmModal,
  SEND_INVITE_MODAL: InviteModal,
  CANDIDATE_STEP_CONFIRMATION_MODAL: CandidateStepConfirmationModal,
  CREATE_EVENT_MODAL: CreateAndEditEventModal,
  EVENT_VIEW_MODAL: EventModal,
  CANDIDATE_INFO_MODAL: CandidateInfoModal,
  CANDIDATE_UPLOAD_FILE_MODAL: CandidateFileUploadModal
} as const;

export type OpenModalType =
  | ConfirmModalParams
  | InviteModalParams
  | CandidateStepConfirmationModalParams
  | CreateCandidateEventModalParams
  | EventModalParams
  | CandidateInfoModalParams
  | CandidateUploadFileModalParams;

import { CreateAndEditUserForm } from '@components/Forms/Users/CreateAndEditUserForm';
import { Box, Card, CardHeader, Divider } from '@mui/material';
import { FC } from 'react';

export const EditUserPage: FC = () => {
  return (
    <Box className="relative min-h-[200px] py-4">
      <Card className="shadow">
        <CardHeader title="Main information" className="uppercase text-gray-600 pb-2" />

        <Divider />
        <CreateAndEditUserForm mode="edit" />
      </Card>
    </Box>
  );
};

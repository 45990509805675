import { useAppDispatch } from '@app/store/store';
import { useModal } from '@hooks/useModal';
import { VacanciesListActionsPopoverProps } from '@modules/vacancies/interface';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import Info from '@mui/icons-material/Info';
import { Divider, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { deleteVacancy } from '@slices/vacancies/thunks';
import { FC } from 'react';
import { Link } from 'react-router-dom';

export const VacanciesListActionsPopover: FC<VacanciesListActionsPopoverProps> = ({ id }) => {
  const dispatch = useAppDispatch();
  const { openModal, closeModal } = useModal();

  const handleDelete = () => {
    openModal({
      modalName: 'CONFIRM_MODAL',
      modalProps: {
        title: 'Delete this vacancy?',
        subtitle: 'You will not be able to undo this action',

        successAction: () => {
          dispatch(
            deleteVacancy({
              id,
              afterAction: () => {
                closeModal('CONFIRM_MODAL');
              }
            })
          );
        }
      }
    });
  };

  return (
    <List dense className="min-w-[160px]">
      <ListItemButton component={Link} to={`${id}/edit`} className="mx-2 whitespace-nowrap px-2">
        <ListItemIcon className=" min-w-fit mr-2">
          <Edit />
        </ListItemIcon>
        <ListItemText className="uppercase font-bold">
          <Typography>Edit</Typography>
        </ListItemText>
      </ListItemButton>

      <ListItemButton component={Link} to={`${id}`} className="mx-2 whitespace-nowrap px-2">
        <ListItemIcon className=" min-w-fit mr-2">
          <Info />
        </ListItemIcon>
        <ListItemText className="uppercase font-bold">
          <Typography>View</Typography>
        </ListItemText>
      </ListItemButton>

      <Divider className="my-1" />

      <ListItemButton className="text-red-500 mx-2 whitespace-nowrap px-2" onClick={handleDelete}>
        <ListItemIcon className="text-red-500 min-w-fit mr-2">
          <Delete />
        </ListItemIcon>
        <ListItemText className="uppercase font-bold">Delete</ListItemText>
      </ListItemButton>
    </List>
  );
};

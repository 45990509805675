import { StepsFieldsStatuses } from '@API/services/candidates/candidatesService';
import { CANDIDATES } from '@slices/candidates/interface';
import { STEPS } from '@slices/steps/interface';

export const compareSteps = (steps: STEPS.Step[], candidateSteps: CANDIDATES.CandidateSteps[]): CANDIDATES.Candidate['steps'] => {
  return [
    ...(steps.map((step) => {
      const updatedStep = candidateSteps.find((newStep) => newStep.step_id === step.id);
      if (updatedStep) {
        return {
          ...step,
          status: updatedStep.status,
          candidate_comment: updatedStep.comment
        };
      }
      return step;
    }) ?? [])
  ];
};

type FilterKanbanCardsReturn = STEPS.CandidatesInStep & { status: StepsFieldsStatuses };

export const filterKanbanCards = (
  candidates: STEPS.Step['candidates'],
  candidateSteps: STEPS.Step['candidate_step'],
  closingStepFormat: StepsFieldsStatuses = 'pending'
): FilterKanbanCardsReturn[] => {
  const normalizedCandidates = candidates?.reduce(
    (acc, { id, ...item }) => ({
      ...acc,
      [id]: { id, ...item }
    }),
    {}
  ) as { [key: string]: STEPS.Step['candidates'] };

  return (
    candidateSteps?.reduce<FilterKanbanCardsReturn[]>((acc, { candidate_id, ...item }) => {
      const candidate: FilterKanbanCardsReturn | null =
        item.status === closingStepFormat && normalizedCandidates && normalizedCandidates[candidate_id]
          ? ({
              ...normalizedCandidates[candidate_id],
              status: item.status,
              ordering: item.ordering
            } as unknown as FilterKanbanCardsReturn)
          : null;

      if (candidate) {
        return [...acc, candidate];
      } else {
        return [...acc];
      }
    }, []) ?? []
  );
};

import { CreateCandidateDTO } from '@API/services/candidates/candidatesService';
import { useAppDispatch, useAppSelector } from '@app/store/store';
import { AdditionalContactInfo } from '@components/AdditionalContactInfo/AdditionalContactInfo';
import { CandidateAdditionalInfoSection } from '@components/Forms/Candidates/sections/CandidateAdditionalInfoSection';
import { CandidateJobSection } from '@components/Forms/Candidates/sections/CandidateJobSection';
import { CandidateMainInfoSection } from '@components/Forms/Candidates/sections/CandidateMainInfoSection';
import { FormWrapper } from '@components/Forms/FormWrapper';
import { CreateAndEditCandidateFormProps, CreateAndEditCandidateFormValues } from '@components/Forms/interface';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Card, CardHeader, Divider } from '@mui/material';
import { createCandidate, getCandidate, removeCandidateFile, updateCandidate } from '@slices/candidates/thunks';
import { format, isValid } from 'date-fns';
import { debounce } from 'lodash';
import React, { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

const schema = yup.object({
  email: yup.string().email('Email must be a valid email').required('Candidate email is required'),
  first_name: yup.string().required('Candidate first name is required')
});

export const CreateAndEditCandidateForm: FC<CreateAndEditCandidateFormProps> = ({ mode }) => {
  const { data: candidate_data, status: candidate_status, files_upload_status, files_upload_meta } = useAppSelector((store) => store.candidates.candidate);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const methods = useForm<CreateAndEditCandidateFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      files: []
    }
  });

  useEffect(() => {
    if (candidate_data && mode === 'edit' && candidate_status !== 'loading') {
      methods.reset({
        first_name: candidate_data.first_name ?? '',
        email: candidate_data.email ?? '',
        birth_date: candidate_data.birth_date ? new Date(candidate_data.birth_date ?? '') : null,
        contacts: candidate_data.contacts?.map(({ value, type }) => ({ type, value })),
        last_name: candidate_data.last_name ?? '',
        country: candidate_data.country ?? '',
        city: candidate_data.city ?? '',
        comment: candidate_data.comment ?? '',
        skills: candidate_data.skills ?? [],
        tags: candidate_data.tags ?? [],
        platform: candidate_data.platform,
        vacancy: candidate_data.vacancy,
        cash_currency: candidate_data.cash_currency ?? '',
        cash_salary: candidate_data.cash_salary ?? ''
      });
    }
  }, [candidate_status, methods, candidate_data, mode]);

  const navigateHandler = (): void => {
    navigate(mode === 'edit' && candidate_data ? `/candidates/${candidate_data?.id}` : '/candidates', { replace: true });
  };

  const removeFileHandler = (id: string): void => {
    dispatch(removeCandidateFile({ file_id: id }));
  };

  const submitRecipe: SubmitHandler<CreateAndEditCandidateFormValues> = async (data) => {
    const debouncedNavigate = debounce((id: string) => navigate(`/candidates/${id}`), 300);
    const preRequest: CreateCandidateDTO = {
      contacts: data.contacts?.filter((item) => item?.type) || [],
      email: data.email || '',
      cash_currency: data.cash_currency || null,
      cash_salary: data.cash_salary || null,
      comment: data.comment || null,
      city: data.city || null,
      country: data.country || null,
      first_name: data.first_name,
      last_name: data.last_name || null,
      platform_id: data.platform?.id || null,
      skills: data.skills?.map((skill) => skill.id),
      vacancy_id: data.vacancy?.id || null,
      birth_date: data.birth_date && isValid(new Date(data.birth_date)) ? format(data.birth_date, 'yyyy-MM-dd') : null,
      tags: data.tags?.map((tag) => tag.id),
      files: data.files ?? []
    };

    if (mode === 'create') {
      dispatch(
        createCandidate({
          afterActionWithParams: (id) => {
            dispatch(getCandidate(id));
            debouncedNavigate(id);
          },
          ...preRequest
        })
      );
    }

    if (mode === 'edit' && candidate_data) {
      dispatch(
        updateCandidate({
          ...preRequest,
          id: candidate_data.id,
          afterActionWithParams: (id) => {
            dispatch(getCandidate(id));
            debouncedNavigate(id);
          }
        })
      );
    }
  };

  return (
    <FormWrapper
      className="flex gap-4"
      methods={methods}
      formProps={{
        onSubmit: methods.handleSubmit(submitRecipe)
      }}
    >
      <Card className="mb-4 shadow w-full">
        <CandidateMainInfoSection />

        <CardHeader title="Contact information" className="uppercase text-gray-600 pb-2" />
        <Divider />

        <AdditionalContactInfo className="mb-4 py-8 px-4" />
        <CandidateJobSection
          fileUploadStatus={files_upload_status}
          fileUploadProgressData={files_upload_meta}
          addedFilesRemoveHandler={removeFileHandler}
          addedFiles={candidate_data?.files}
          mode={mode}
        />
        <CandidateAdditionalInfoSection />

        <Divider />

        <Box className="p-4 gap-4 flex flex-row-reverse flex-wrap">
          <LoadingButton loading={candidate_status === 'loading' || files_upload_status === 'loading'} className="lg:w-fit w-full py-2 px-8" variant="contained" type="submit">
            Save
          </LoadingButton>
          <Button className="lg:w-fit w-full py-2 px-8" variant="outlined" onClick={navigateHandler} color="error">
            Cancel
          </Button>
        </Box>
      </Card>
    </FormWrapper>
  );
};

import { GoBackButton } from '@components/Buttons/GoBack/GoBackButton';
import { Box, Container, Paper, Typography } from '@mui/material';
import { FC } from 'react';

export const TemplatesAddPage: FC = () => {
  return (
    <Box>
      <Container className="p-0 max-w-[1200px]">
        <GoBackButton to=".." text="Back to platforms" />
        <Box>
          <Box className="flex flex-wrap mb-5">
            <Box>
              <Typography className="mb-2 text-gray-300 uppercase font-black" variant="h4">
                new template
              </Typography>
            </Box>
          </Box>
          <Paper className="p-4">
            TemplateCreateAndEditForm.tsx
            {/* <PlatformAddAndEditForm className="grid gap-4 grid-cols-2" mode="create" /> */}
          </Paper>
        </Box>
      </Container>
    </Box>
  );
};

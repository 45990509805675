import { useAppSelector } from '@app/store/store';
import { TagChip } from '@components/Chips/TagChip/TagChip';
import Close from '@mui/icons-material/Close';
import { Autocomplete, CircularProgress, ListItemText, MenuItem, TextField, TextFieldProps } from '@mui/material';
import { TAGS } from '@slices/tags/interface';
import classNames from 'classnames';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export interface TagsMultiselectProps {
  name: string;

  textFieldProps?: TextFieldProps;
  className?: string;
}

export const TagsMultiselect = (props: TagsMultiselectProps) => {
  const {
    status,
    tags: { data: tags }
  } = useAppSelector((store) => store.tags);
  const { className, name, textFieldProps = {} } = props;
  const methods = useFormContext();

  return (
    <Controller
      name={name}
      defaultValue={[]}
      control={methods.control}
      render={({ field, fieldState }) => (
        <Autocomplete
          {...field}
          multiple
          filterSelectedOptions
          disableCloseOnSelect
          className={className}
          loading={status === 'loading'}
          options={tags || []}
          isOptionEqualToValue={(option, value) => {
            if (typeof value === 'string') {
              return option.id === value;
            }
            return option.id === value.id;
          }}
          getOptionLabel={(option) => option.label ?? ''}
          onChange={(e, option) => {
            field.onChange(option ?? []);
          }}
          clearText=""
          openText=""
          renderInput={(params) => (
            <TextField
              {...params}
              {...textFieldProps}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {status === 'loading' ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
              error={!!fieldState.error}
              helperText={fieldState.error?.message ?? ''}
              label="Tags"
            />
          )}
          renderOption={(props, option) => (
            <MenuItem {...props} key={option.id} value={option.id} className={classNames('flex items-center justify-between', `hover:bg-${option.color}-500/10`)}>
              <div className={classNames('w-4 h-4 mr-4 rounded-full', `bg-${option.color}-500`)}></div>
              <ListItemText className="capitalize">{option.name}</ListItemText>
            </MenuItem>
          )}
          renderTags={(value: TAGS.Tag[], getTagProps) =>
            value.map((chip, index) => <TagChip {...getTagProps({ index })} deleteIcon={<Close className={`text-${chip.color}-500`} />} variant="outlined" tag={chip} />)
          }
        />
      )}
    />
  );
};

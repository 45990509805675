import { useAppDispatch, useAppSelector } from '@app/store/store';
import { InputTextField } from '@components/Fields/InputText/InputTextField';
import { FormWrapper } from '@components/Forms/FormWrapper';
import Search from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import { usersSlice } from '@slices/users/usersSlice';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { SearchUsersFormFields, UsersListFiltersAndSortsProps } from '@modules/users/interface';

export const UsersListFiltersAndSorts: FC<UsersListFiltersAndSortsProps> = ({ className }) => {
  const dispatch = useAppDispatch();
  const { search } = useAppSelector((store) => store.users.usersList);
  const methods = useForm<SearchUsersFormFields>({
    mode: 'onChange',
    defaultValues: {
      search
    }
  });

  useEffect(() => {
    const subscription = methods.watch(({ search }) => {
      dispatch(usersSlice.actions.touchSearchActions({ search }));
      dispatch(usersSlice.actions.touchPagination({ page: 0 }));
    });
    return () => subscription.unsubscribe();
  }, [methods.watch]);

  return (
    <FormWrapper methods={methods} className={'mb-4 flex gap-2 flex-wrap ' + className || ''}>
      <InputTextField
        className="flex-1 min-w-[200px]"
        name="search"
        placeholder="Search users"
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          )
        }}
      />
    </FormWrapper>
  );
};

import { API } from '@API/constants';
import { ResponseBaseType, ResponseListBaseType } from '@API/services/interfaces';
import { fetchData } from '@helpers/fetchData/fetchData';
import { PLATFORMS } from '@slices/platform/interface';
import { RequiredIn } from 'custom';

const path = `${API.origin}/platforms`;

export interface CreatePlatformDTO extends Pick<PLATFORMS.Platform, 'name' | 'url' | 'comment'> {}

export interface UpdatePlatformDTO extends RequiredIn<Partial<Pick<PLATFORMS.Platform, 'id' | 'name' | 'url' | 'comment'>>, 'id'> {}

export class PlatformsService {
  public static async getAll<T = PLATFORMS.Platform[]>(query: string | undefined) {
    return fetchData<ResponseListBaseType<T>>(`${path}${query ?? ''}`);
  }

  public static async getOne<T = PLATFORMS.Platform>(id: string) {
    return fetchData<ResponseBaseType<T>>(`${path}/${id}`);
  }

  public static async create<T = PLATFORMS.Platform>(data: CreatePlatformDTO) {
    return fetchData<ResponseBaseType<T>>(path, 'POST', data);
  }

  public static async update<T = PLATFORMS.Platform>({ id, ...name }: UpdatePlatformDTO) {
    return fetchData<ResponseBaseType<T>>(`${path}/${id}`, 'PATCH', name);
  }

  public static async delete<T = PLATFORMS.Platform>(id: string) {
    return fetchData<ResponseBaseType<T>>(`${path}/${id}`, 'DELETE');
  }
}

import { GoBackButton } from '@components/Buttons/GoBack/GoBackButton';
import { TagsAddAndEditForm } from '@components/Forms/Tags/TagsAddAndEditForm';
import { Box, Container, Paper, Typography } from '@mui/material';
import { FC } from 'react';

export const TagsAddPage: FC = () => {
  return (
    <Container className="p-0 max-w-[1200px]">
      <GoBackButton to=".." text="Back to tags" />
      <Box>
        <Box className="flex flex-wrap mb-5">
          <Typography className="mb-1 uppercase mb-2 text-gray-300 uppercase font-black" variant="h4">
            new tag
          </Typography>
        </Box>
        <Paper className="p-4">
          <TagsAddAndEditForm className="grid gap-4 grid-cols-2" mode="create" />
        </Paper>
      </Box>
    </Container>
  );
};

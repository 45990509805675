import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { StepsDraggableElementProps } from '@modules/steps/interface';
import { DragIndicator } from '@mui/icons-material';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Lock from '@mui/icons-material/Lock';
import MoreVert from '@mui/icons-material/MoreVert';
import { Box, Card, Collapse, IconButton, Tooltip, Typography, useMediaQuery } from '@mui/material';
import classNames from 'classnames';
import React, { FC, MouseEvent, useState } from 'react';

export const StepsDraggableElement: FC<StepsDraggableElementProps> = ({ item, onActionClick, orderingIsActive }) => {
  const match = useMediaQuery('(max-width: 540px)');
  const [collapsed, setCollapsed] = useState(!match);
  const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({
    id: item.id,
    disabled: !orderingIsActive
  });
  const style = {
    transition,
    transform: CSS.Transform.toString(transform)
  };

  const handleEdit = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onActionClick(event);
  };

  const handleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Card
      onClick={handleCollapsed}
      ref={setNodeRef}
      style={style}
      elevation={0}
      className={classNames('group flex ml-4 flex-col overflow-visible border-none relative mx-2 cursor-pointer', {
        'transition-all shadow-none': !isDragging,
        'shadow-xl z-20': isDragging,
        [`hover:bg-${item.color}-100`]: !orderingIsActive,
        'bg-gray-100': orderingIsActive
      })}
    >
      <Box
        className={classNames('py-2 pr-2 flex justify-between', {
          'items-start': !orderingIsActive,
          'items-center': orderingIsActive
        })}
      >
        <span className={classNames('w-1 h-full -left-4 min-h-full inset-y-0 flex z-10 absolute rounded-full transition-all group-hover:w-2', `bg-${item.color}-500`)} />
        <Box className="pl-4">
          <Typography className="flex items-center" variant="subtitle1">
            <span className="mr-2">{item.name}</span>
            {item.default && (
              <Tooltip arrow title="Show selection by default">
                <CheckCircle className="fill-gray-700" fontSize="small" />
              </Tooltip>
            )}
            {item.finished && (
              <Tooltip arrow title="Step is closing for a vacancy">
                <Lock className="fill-gray-700" fontSize="small" />
              </Tooltip>
            )}
          </Typography>
          {!orderingIsActive && (
            <Collapse in={collapsed}>
              <Typography className="flex" variant="caption">
                {item.comment}
              </Typography>
            </Collapse>
          )}
        </Box>
        <Box className="flex">
          {orderingIsActive ? (
            <DragIndicator {...listeners} {...attributes} fontSize="small" className="fill-gray-500 cursor-grab active:cursor-grabbing" />
          ) : (
            <IconButton size="small" aria-label={item.id} onClick={handleEdit}>
              <MoreVert fontSize="small" />
            </IconButton>
          )}
        </Box>
      </Box>
    </Card>
  );
};

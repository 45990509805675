import { RequestStatus } from '@app/store/interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GLOBAL } from '@slices/global/interface';
import { init } from '@slices/global/thunks';

const initialState: GLOBAL.GlobalSlice = {
  responseMessage: null,
  status: 'loading',
  loadingProgress: 0
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    updateLoadingProgress(state, { payload }: PayloadAction<Record<'actionsCount', number>>) {
      state.loadingProgress += Math.round(100 / payload.actionsCount);
    },
    updateStatus(state, { payload }: PayloadAction<RequestStatus>) {
      state.status = payload;
    },
    clearLoadingProcess(state, { payload }: PayloadAction<number>) {
      state.loadingProgress = payload;
    }
  },
  extraReducers: ({ addCase }) => {
    addCase(init.pending, (state) => {
      state.status = 'loading';
    });
    addCase(init.fulfilled, (state) => {
      state.status = 'success';
    });
    addCase(init.rejected, (state) => {
      state.status = 'error';
    });
  }
});

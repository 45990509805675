import { HeaderRightSection } from '@components/Header/HeaderRightSection';
import { HeaderProps } from '@modules/header/interface';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, IconButton, Toolbar, useMediaQuery } from '@mui/material';
import classNames from 'classnames';
import { FC } from 'react';

export const Header: FC<HeaderProps> = (props) => {
  const { leftDrawerIsOpen, setLeftDrawerIsOpen } = props;
  const matches = useMediaQuery('(min-width: 1200px)');

  return (
    <AppBar color="default" className={classNames('shadow-none border-b border-t-0 border-x-0 border-solid border-b-gray-300', { 'pl-[280px]': matches })}>
      <Toolbar>
        <IconButton className={classNames('', { hidden: leftDrawerIsOpen || matches })} aria-label="open drawer" edge="start" onClick={setLeftDrawerIsOpen}>
          <MenuIcon />
        </IconButton>
        <Box className="grow" />
        <HeaderRightSection />
      </Toolbar>
    </AppBar>
  );
};

import { useAppDispatch, useAppSelector } from '@app/store/store';
import { EventListItem } from '@components/Events/ListItem/ListItem';
import { RoundedLoader } from '@components/Loaders/RoundedLoader';
import { NoDataPlug } from '@components/Plugs/NoDataPlug';
import { queryStringBuilder } from '@helpers/queryStringBuilder/queryStringBuilder';
import { DashboardEventsCardProps } from '@modules/dashboard/interface';
import { Box, Divider, Paper, Tab, Tabs, Typography } from '@mui/material';
import { getEventsFromDate } from '@slices/dashboard/thunks';
import classNames from 'classnames';
import { eachDayOfInterval, format } from 'date-fns';
import { FC, SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';

const week: { [key: string]: string } = {
  mon: 'Пн',
  tue: 'Вт',
  wed: 'Ср',
  thu: 'Чт',
  fri: 'Пт',
  sat: 'Cб',
  sun: 'Вс'
};

export const DashboardEventsCard: FC<DashboardEventsCardProps> = ({ className }) => {
  const [valueWatsUpToday, setValueWatsUpToday] = useState(new Date().getDate().toString());
  const [fullDate, setFullDate] = useState<string>(() => format(new Date(), 'yyyy-MM-dd'));
  const { list: events_list, status } = useAppSelector((store) => store.dashboard.events);
  const ref = useRef<HTMLDivElement>(null);
  const getWeek = useMemo(
    () =>
      eachDayOfInterval({
        start: new Date().getTime(),
        end: new Date().getTime() + 7 * 24 * 60 * 60 * 1000
      }).map((item) => ({
        weekDay: `${week[item.toString().split(' ')[0].toLowerCase()]}`,
        day: `${item.toString().split(' ')[2]}`,
        fullDate: format(item, 'yyyy-MM-dd')
      })),
    []
  );
  const dispatch = useAppDispatch();

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValueWatsUpToday(newValue);
  };

  useEffect(() => {
    dispatch(getEventsFromDate({ query: queryStringBuilder({ date: fullDate }) }));
  }, [dispatch, fullDate]);

  return (
    <Box className={classNames('inline-flex items-start', className)}>
      <Paper className={classNames('shadow gap-4 pb-4 w-full flex flex-col max-h-full h-full relative')}>
        <Tabs className="shrink-0" value={valueWatsUpToday} onChange={handleChange} variant="scrollable" scrollButtons="auto" textColor="primary" indicatorColor="primary">
          {getWeek.map(({ day, weekDay, fullDate }, index) => {
            return (
              <Tab
                onClick={() => setFullDate(fullDate)}
                key={`${fullDate}-${index}`}
                className={classNames({ 'text-red-700': weekDay === 'Cб' || weekDay === 'Вс' })}
                value={day.toString().split('')[0] === '0' ? day.toString().split('')[1] : day}
                label={
                  <Box className="flex">
                    <Typography variant="subtitle2">{day.toString().split('')[0] === '0' ? day.toString().split('')[1] : day}</Typography>
                    <Typography className="mx-0.5" variant="subtitle2">
                      ·
                    </Typography>
                    <Typography variant="subtitle2">{weekDay}</Typography>
                  </Box>
                }
              />
            );
          })}
        </Tabs>
        <Divider className="-mt-4 -mx-2 relative -top-[1px]" />

        <Box ref={ref} className={classNames('z-10 flex-1 basis-auto overflow-y-auto')}>
          {events_list[fullDate] && events_list[fullDate].map((event) => <EventListItem key={event.id} event_date={fullDate} className="mx-0" sliceType="dashboard" {...event} />)}
          {((events_list[fullDate] && !events_list[fullDate].length) || !events_list[fullDate]) && <NoDataPlug className="relative" />}
        </Box>
        {status === 'loading' && <RoundedLoader />}
      </Paper>
    </Box>
  );
};

import { Box, CircularProgress, CircularProgressProps } from '@mui/material';
import classNames from 'classnames';
import { FC } from 'react';
import { DefaultComponentProps } from 'custom';

interface RoundedLoaderProps extends DefaultComponentProps {
  circularProgressProps?: CircularProgressProps;
}

export const RoundedLoader: FC<RoundedLoaderProps> = ({ className, circularProgressProps }) => (
  <Box className={classNames('flex shadow bg-white rounded-full absolute bottom-2 z-30 right-2 p-1', className)}>
    <CircularProgress size={20} {...circularProgressProps} />
  </Box>
);

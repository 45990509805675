import QuestionMark from '@mui/icons-material/QuestionMark';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Paper, Typography } from '@mui/material';
import { FC } from 'react';
import { BaseModalProps } from '../RootModal/interface';
import { ModalWrapper } from '../RootModal/RootModal';
import { ConfirmModalParams } from './interface';

export interface ConfirmModalProps extends BaseModalProps {
  data?: Partial<ConfirmModalParams['modalProps']>;
}

const initialData: ConfirmModalParams['modalProps'] = {
  title: 'Confirm',
  subtitle: 'Subtitle text',
  loading: 'unset',
  successAction: () => {}
};

export const ConfirmModal: FC<ConfirmModalProps> = ({ data = initialData, ...modalProps }) => {
  return (
    <ModalWrapper {...modalProps}>
      <Paper className="max-w-[600px] mx-2 shadow-2xl overflow-hidden">
        <Box className="bg-brand relative text-white p-6">
          <Typography className="font-bold uppercase opacity-80" variant="h4">
            {data.title}
          </Typography>
          <QuestionMark className="absolute right-0 top-0 opacity-5 w-fit h-full transform rotate-45" />
        </Box>
        <Box className="p-6 flex flex-col">
          {data?.subtitle && (
            <Typography className="pb-4" variant="body1">
              {data.subtitle}
            </Typography>
          )}

          <LoadingButton loading={data?.loading === 'loading'} className="py-2 px-4 mt-4" variant="contained" onClick={data.successAction}>
            Confirm
          </LoadingButton>
        </Box>
      </Paper>
    </ModalWrapper>
  );
};

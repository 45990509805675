import { API } from '@API/constants';
import { ResponseBaseType, ResponseListBaseType } from '@API/services/interfaces';
import { fetchData } from '@helpers/fetchData/fetchData';
import { DEPARTMENTS } from '@slices/departments/interface';

const path = `${API.origin}/departments`;

export class DepartmentsService {
  public static create<T = DEPARTMENTS.Department>(dto: Omit<DEPARTMENTS.Department, 'id'>) {
    return fetchData<ResponseBaseType<T>>(path, 'POST', dto);
  }

  public static getAll<T = DEPARTMENTS.Department[]>() {
    return fetchData<ResponseListBaseType<T>>(path);
  }

  public static getOne<T = DEPARTMENTS.Department>(id: string) {
    return fetchData<ResponseBaseType<T>>(`${path}/${id}`);
  }

  public static updateOne<T = DEPARTMENTS.Department>(id: string, dto: Omit<Partial<DEPARTMENTS.Department>, 'id'>) {
    return fetchData<ResponseBaseType<T>>(`${path}/${id}`, 'PATCH', dto);
  }

  public static deleteOne<T = DEPARTMENTS.Department>(id: string) {
    return fetchData<ResponseBaseType<T>>(`${path}/${id}`, 'DELETE');
  }
}

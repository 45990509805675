import { inMomentSelector } from '@helpers/date/datesHelpers';
import { Typography } from '@mui/material';
import classNames from 'classnames';
import { DefaultComponentProps } from 'custom';
import { formatDistanceToNow } from 'date-fns';
import { FC, useEffect, useMemo, useState } from 'react';

interface MomentIndicatorProps extends DefaultComponentProps {
  startDate?: Date;
  endDate?: Date;
}

export const MomentIndicator: FC<MomentIndicatorProps> = ({ endDate, startDate }) => {
  const [momentChecker, setMomentChecker] = useState<ReturnType<typeof inMomentSelector> | undefined>();
  const moment = useMemo(() => inMomentSelector(startDate, endDate), [momentChecker]);

  useEffect(() => {
    const interval = setInterval(() => {
      setMomentChecker(inMomentSelector(startDate, endDate));
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  if (startDate && endDate) {
    return (
      <Typography
        component="span"
        variant="caption"
        className={classNames('rounded-full relative transition-all', {
          'px-2 py-1 bg-sky-100 border border-solid border-sky-500 text-sky-500': moment === 'today',
          'px-2 py-1 bg-lime-100 border border-solid border-lime-600 text-lime-600': moment === 'now',
          'text-gray-500': moment === 'past',
          'px-2 py-1 bg-gray-100 text-gray-500': moment === 'future' || !moment
        })}
      >
        <span className={classNames({ 'animate-pulse': moment === 'today' || moment === 'now' })}>
          {formatDistanceToNow(new Date(moment === 'past' ? endDate : startDate), {
            addSuffix: true,
            includeSeconds: true
          })}
        </span>
      </Typography>
    );
  } else {
    return null;
  }
};

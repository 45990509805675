import { API } from '@API/constants';
import { ResponseBaseType, ResponseListBaseType } from '@API/services/interfaces';
import { ContactsItemDTO } from '@app/store/interface';
import { fetchData } from '@helpers/fetchData/fetchData';
import { USERS } from '@slices/users/interface';

const path = `${API.origin}/users`;

export interface InviteUserDTO {
  email?: string;
  role: string;
  giveLink?: boolean;
}

export interface CreateUserDTO {
  email: string;
  first_name: string;
  last_name: string;
  password: string;
  status: USERS.Status;
  role: string;
  contacts?: ContactsItemDTO[];
  comment?: string;
}

export interface CreateUserResponseData extends USERS.User {}

export class UsersService {
  public static async getUser<T = USERS.User>(id: string) {
    return fetchData<ResponseBaseType<T>>(`${path}/${id}`);
  }

  public static async getUsers<T = Omit<USERS.User, 'last_visit_at' | 'register_at' | 'update_at'>[]>(query?: string) {
    return fetchData<ResponseListBaseType<T>>(`${path}${query ?? ''}`);
  }

  public static async addNewUser<T>(payload: CreateUserDTO) {
    return fetchData<ResponseBaseType<T>>(path, 'POST', payload);
  }

  public static async updateUser<T = USERS.User>(id: string, payload: Partial<CreateUserDTO>) {
    return fetchData<ResponseBaseType<T>>(`${path}/${id}`, 'PATCH', payload);
  }

  public static async deleteUser<T = USERS.User>(id: string) {
    return fetchData<ResponseBaseType<T>>(`${path}/${id}`, 'DELETE');
  }

  public static async invite<T>(payload: InviteUserDTO) {
    return fetchData<ResponseBaseType<T>>(`${path}/invite`, 'POST', payload);
  }
}

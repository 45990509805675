import { useAppDispatch, useAppSelector } from '@app/store/store';
import { ColorSelect } from '@components/Fields/ColorSelect/ColorSelect';
import { TagsAddAndEditFormProps, TagsAddAndEditFormValues } from '@components/Forms/interface';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button } from '@mui/material';
import { createTag, updateTag } from '@slices/tags/thunks';
import { debounce } from 'lodash';
import React, { FC, FormEvent, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { InputTextField } from 'src/components/Fields/InputText/InputTextField';
import { FormWrapper } from 'src/components/Forms/FormWrapper';
import * as yup from 'yup';

const schema = yup.object({
  name: yup.string().required('Platform name is required field'),
  comment: yup.string().nullable()
});

export const TagsAddAndEditForm: FC<TagsAddAndEditFormProps> = ({ mode, className }) => {
  const { data: selectedTag, status } = useAppSelector((store) => store.tags.selectedTag);

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const methods = useForm<TagsAddAndEditFormValues>({
    resolver: yupResolver(schema)
  });

  const handleReset = (e?: FormEvent): void => {
    e && e.preventDefault();
    navigate('/tags');
  };

  const submitRecipe: SubmitHandler<TagsAddAndEditFormValues> = (data) => {
    const debouncedNavigate = debounce(() => navigate('/tags'), 300);

    if (mode === 'create') {
      dispatch(
        createTag({
          name: data.name,
          comment: data.comment,
          color: data.color,
          afterAction: () => debouncedNavigate()
        })
      );
    }

    if (mode === 'edit') {
      if (params.id) {
        dispatch(
          updateTag({
            id: params.id,
            name: data.name,
            color: data.color,
            comment: data.comment,
            afterAction: () => debouncedNavigate()
          })
        );
      }
    }
  };

  useEffect(() => {
    if (mode === 'edit' && selectedTag) {
      methods.reset({
        name: selectedTag.name,
        comment: selectedTag.comment || '',
        color: selectedTag.color || 'gray'
      });
    }
  }, [mode, selectedTag, methods]);

  return (
    <FormWrapper
      className={className}
      methods={methods}
      formProps={{
        onSubmit: methods.handleSubmit(submitRecipe),
        onReset: handleReset
      }}
    >
      <InputTextField className="col-span-2 lg:col-span-1" name="name" label="Name" />
      <ColorSelect className="col-span-2 lg:col-span-1" label="Color" name="color" />
      <InputTextField className="col-span-2" multiline minRows={5} name="comment" label="Comment" />
      <Box className="flex col-span-full gap-2 lg:gap-4 flex-wrap flex-row-reverse">
        <LoadingButton loading={status === 'loading'} className="py-2 px-8 w-full lg:w-fit" variant="contained" type="submit">
          Save
        </LoadingButton>
        <Button className="py-2 px-8 w-full lg:w-fit" variant="outlined" color="error" type="reset">
          Cancel
        </Button>
      </Box>
    </FormWrapper>
  );
};

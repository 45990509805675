import { UserAvatarBlock } from '@components/AvatarBlock/AvatarBlock';
import { TagChip } from '@components/Chips/TagChip/TagChip';
import { useDraggable } from '@dnd-kit/core';
import { useModal } from '@hooks/useModal';
import { KanbanColumnTicketProps } from '@modules/kanban/interface';
import { Box, Collapse, Link, Paper, Typography } from '@mui/material';
import classNames from 'classnames';
import React, { FC, ReactElement, useCallback, useState } from 'react';
import { NavLink } from 'react-router-dom';

export const KanbanColumnTicket: FC<KanbanColumnTicketProps> = ({ className, data, isClone, isOver, container_id, disabled }): ReactElement => {
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: data.id,
    disabled,
    data: {
      ...data,
      container_id
    }
  });

  const { openModal } = useModal();
  const [commentIsCollapsed, setCommentIsCollapsed] = useState(true);

  const OpenModalHandler = useCallback(() => {
    openModal({
      modalName: 'CANDIDATE_INFO_MODAL',
      modalProps: {
        candidate_id: data.id
      }
    });
  }, [data.id]);

  const collapseHandler = (): void => {
    setCommentIsCollapsed(!commentIsCollapsed);
  };

  return (
    <Box ref={setNodeRef} {...attributes} {...listeners} className={classNames('py-1', className)}>
      <Paper
        className={classNames(
          'flex gap-2 flex-col px-1 py-2 min-h-[20px] relative bg-white shadow cursor-grab active:cursor-grabbing overflow-hidden',
          { 'opacity-25 border-none': isDragging },
          { 'opacity-50 shadow-none transition-all': isOver },
          { 'shadow-xl z-10 touch-none': isClone }
        )}
      >
        <UserAvatarBlock
          email={data.email}
          className=""
          mainText={
            <Link onClick={OpenModalHandler} component={NavLink} underline="hover" className="text-gray-700 text-sm overflow-ellipsis whitespace-nowrap" to="">{`${
              data.first_name ?? ''
            } ${data.last_name ?? ''}`}</Link>
          }
          secondaryText={
            <Typography className="text-gray-700 overflow-ellipsis whitespace-nowrap" variant="caption">
              {data.email}
            </Typography>
          }
        />
        <Box>
          {data.tags?.map((tag) => (
            <TagChip key={tag.id} tag={tag} className="text-xs" label={<Typography variant="caption">{tag.name}</Typography>} />
          ))}
        </Box>
        {data.comment && (
          <Box className="px-2">
            <Collapse in={!commentIsCollapsed} collapsedSize={20}>
              <Box className="relative">
                <Typography className={classNames('text-gray-400 relative transition-all')} variant="caption">
                  {data.comment}
                  <br />
                  <span onClick={collapseHandler} className=" cursor-pointer hover:underline transition-all text-gray-300 hover:text-gray-700">
                    hide comment
                  </span>
                </Typography>
                {commentIsCollapsed && (
                  <Typography
                    component="span"
                    variant="caption"
                    onClick={collapseHandler}
                    className="cursor-pointer text-gray-400 absolute top-0 pl-3 right-0 bg-white hover:underline transition-all text-gray-300 hover:text-gray-700"
                  >
                    ...more
                  </Typography>
                )}
              </Box>
            </Collapse>
          </Box>
        )}
        {isDragging && <Paper className="absolute z-10 inset-0 shadow-none bg-gray-300 border border-solid border-gray-400" />}
      </Paper>
    </Box>
  );
};

import { DashboardService } from '@API/services/dashboard/dashboardService';
import { EventsService } from '@API/services/events/EventsService';
import { StepsService } from '@API/services/steps/stepsService';
import { AfterActionProps, BaseAsyncThunkOptions } from '@app/store/interface';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { DASHBOARD } from '@slices/dashboard/interface';
import { EVENTS } from '@slices/events/interface';
import { STEPS } from '@slices/steps/interface';

export const getAllHistory = createAsyncThunk<DASHBOARD.Histories[], { period: string } & AfterActionProps, BaseAsyncThunkOptions>(
  'history/getAllHistory',
  async ({ period, afterAction }, thunkAPI) => {
    const response = await DashboardService.getAll(period);

    if (response.ok) {
      return response.data.data;
    } else {
      return thunkAPI.rejectWithValue(response.error);
    }
  }
);

export const getEventsFromDate = createAsyncThunk<(EVENTS.Event & EVENTS.EventRelations)[], { query: string } & AfterActionProps, BaseAsyncThunkOptions>(
  'dashboard/getEvents',
  async ({ query }, { rejectWithValue }) => {
    const response = await EventsService.getMany<(EVENTS.Event & EVENTS.EventRelations)[]>(query);

    if (response.ok) {
      return response.data.data;
    } else {
      return rejectWithValue(response.error);
    }
  }
);

export const getAllStepsDashboard = createAsyncThunk<STEPS.Step[], { period: string } & AfterActionProps, BaseAsyncThunkOptions>(
  'history/getAllSteps',
  async ({ period, afterAction }, thunkAPI) => {
    const response = await StepsService.getAllForDashboard(period);

    if (response.ok) {
      return response.data.data;
    } else {
      return thunkAPI.rejectWithValue(response.error);
    }
  }
);

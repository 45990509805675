import { DateTimePickerFieldProps } from '@components/Fields/DateTimePicker/interface';
import { TextField } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export const DateTimePickerField = ({
  name,
  disableFuture,
  disablePast,
  shouldDisableDate,
  minDateTime,
  onSetDate,
  minTime,
  maxDateTime,
  maxTime,
  defaultValue,
  textFieldProps = {}
}: DateTimePickerFieldProps) => {
  const methods = useFormContext();
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        name={name}
        control={methods.control}
        render={({ field, fieldState }) => (
          <DateTimePicker
            maxTime={maxTime}
            minTime={minTime}
            maxDateTime={maxDateTime}
            minDateTime={minDateTime}
            disableFuture={disableFuture}
            disablePast={disablePast}
            shouldDisableDate={shouldDisableDate}
            ampm={false}
            value={field.value ?? defaultValue ?? null}
            onChange={(value) => {
              field.onChange(value);
              onSetDate && onSetDate(value);
            }}
            renderInput={(props) => (
              <TextField
                {...field}
                {...props}
                {...textFieldProps}
                error={!!fieldState.error || props.error}
                helperText={(fieldState.error?.message || (props.error && 'Incorrect date format')) ?? ''}
              />
            )}
          />
        )}
      />
    </LocalizationProvider>
  );
};

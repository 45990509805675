import { PlatformAddAndEditForm } from '@components/Forms/Platform/PlatformAddAndEditForm';
import { Box, Card, CardHeader, Divider, Typography } from '@mui/material';
import { FC } from 'react';

export const PlatformsEditPage: FC = () => {
  return (
    <Box className="relative min-h-[200px] mb-4">
      <Box>
        <Card className="shadow">
          <CardHeader title={<Typography variant="h6">Main information</Typography>} />
          <Divider />
          <PlatformAddAndEditForm className="grid gap-4 grid-cols-2 p-4" mode="edit" />
        </Card>
      </Box>
    </Box>
  );
};

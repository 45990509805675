import { createSlice } from '@reduxjs/toolkit';
import { EVENTS } from '@slices/events/interface';
import { USERS } from './interface';
import { addNewUser, deleteUser, getUser, getUsers, inviteUser, updateUser } from './thunks';

const initialState: USERS.UsersSlice = {
  status: 'unset',
  responseMessage: null,

  createUser: {
    status: 'unset'
  },
  inviteUser: {
    status: 'unset',
    link: '',
    expiry: ''
  },
  user: {
    status: 'unset',
    data: null
  },
  usersList: {
    status: 'unset',

    search: '',
    sort: 'first_name',
    order: 'asc',

    usersList: {
      data: [],
      total: 0,
      rowsCount: 25,
      page: 0
    },

    invitation: {
      status: 'unset'
    }
  }
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    touchSearchActions: (store, { payload }: { payload: { sort?: USERS.Sort; search?: string; order?: USERS.Order } }) => {
      store.usersList.search = payload.search ?? '';
      if (payload.sort) {
        store.usersList.sort = payload.sort;
      }
      if (payload.order) {
        store.usersList.order = payload.order;
      }
    },
    touchPagination: (store, { payload }: { payload: Partial<Record<'page' | 'rowsCount', number>> }) => {
      if (typeof payload.page === 'number') {
        store.usersList.usersList.page = payload.page;
      }
      if (typeof payload.rowsCount === 'number') {
        store.usersList.usersList.rowsCount = payload.rowsCount;
      }
    },
    clearResponseMessages: (state) => {
      state.responseMessage = null;
    },
    clearSelectedUser: (state) => {
      state.user.data = null;
      state.user.status = 'unset';
    },
    editEvent: (state, { payload }: { payload: EVENTS.Event & EVENTS.EventRelations }) => {
      if (state.user?.data?.events) {
        state.user.data.events = state.user.data.events.map((event) => (event.id === payload.id ? { ...event, ...payload } : event));
      }
    },
    deleteEvent: (state, { payload }: { payload: string }) => {
      if (state.user?.data?.events) {
        state.user.data.events = state.user.data.events.filter((event) => event.id !== payload);
      }
    },
    addEvent: (state, { payload }: { payload: EVENTS.Event & EVENTS.EventRelations }) => {
      if (state.user?.data?.events) {
        state.user.data.events.push(payload);
      }
    },
    copyLinkNotification: (state) => {
      state.responseMessage = {
        type: 'success',
        data: [
          {
            name: 'Success',
            message: 'Link copied!'
          }
        ]
      };
    }
  },
  extraReducers: (builder) => {
    //GET USERS
    builder.addCase(getUsers.pending, (state) => {
      state.usersList.status = 'loading';
    });
    builder.addCase(getUsers.fulfilled, (state, { payload }) => {
      state.usersList.usersList.data = payload.data;
      state.usersList.usersList.total = payload.total;
      state.usersList.status = 'success';
    });
    builder.addCase(getUsers.rejected, (state, { payload }) => {
      state.usersList.status = 'error';
      state.responseMessage = {
        type: 'error',
        data: payload
      };
    });

    //GET USER
    builder.addCase(getUser.pending, (state) => {
      state.user.status = 'loading';
      state.user.data = null;
    });
    builder.addCase(getUser.fulfilled, (state, { payload }) => {
      state.user.data = payload;
      state.user.status = 'success';
    });
    builder.addCase(getUser.rejected, (state, { payload }) => {
      state.user.status = 'error';
      state.responseMessage = {
        type: 'error',
        data: payload
      };
    });

    //ADD USER
    builder.addCase(addNewUser.pending, (state) => {
      state.createUser.status = 'loading';
    });
    builder.addCase(addNewUser.fulfilled, (state, { payload: { first_name, last_name } }) => {
      state.responseMessage = {
        type: 'success',
        data: [
          {
            message: `The User "${first_name} ${last_name ?? ''}" is successfully created`
          }
        ]
      };
      state.createUser.status = 'success';
    });
    builder.addCase(addNewUser.rejected, (state, { payload }) => {
      state.createUser.status = 'error';
      state.responseMessage = {
        type: 'error',
        data: payload
      };
    });

    //UPDATE USER
    builder.addCase(updateUser.pending, (state) => {
      state.user.status = 'loading';
    });
    builder.addCase(updateUser.fulfilled, (state, { payload }) => {
      state.responseMessage = {
        type: 'success',
        data: [
          {
            message: `The User "${payload.first_name} ${payload.last_name ?? ''}" is successfully edited`
          }
        ]
      };
      state.user.data = {
        ...state.user.data,
        ...payload
      };
      state.user.status = 'success';
    });
    builder.addCase(updateUser.rejected, (state, { payload }) => {
      state.user.status = 'error';
      state.responseMessage = {
        type: 'error',
        data: payload
      };
    });

    //DELETE USER
    builder.addCase(deleteUser.pending, (state) => {
      state.user.status = 'loading';
      state.usersList.status = 'loading';
    });
    builder.addCase(deleteUser.fulfilled, (state, { payload }) => {
      state.responseMessage = {
        type: 'success',
        data: [
          {
            message: `The User "${payload.first_name} ${payload.last_name ?? ''}" is successfully deleted`
          }
        ]
      };
      state.usersList.usersList.data = state.usersList.usersList.data.filter((user) => user.id !== payload.id);
      state.user.status = 'success';
      state.usersList.status = 'success';
    });
    builder.addCase(deleteUser.rejected, (state, { payload }) => {
      state.user.status = 'error';
      state.usersList.status = 'error';
      state.responseMessage = {
        type: 'error',
        data: payload
      };
    });

    //INVITE USER
    builder.addCase(inviteUser.pending, (state) => {
      state.inviteUser.status = 'loading';
    });
    builder.addCase(inviteUser.fulfilled, (state, { payload }) => {
      if (!payload) {
        state.responseMessage = {
          type: 'success',
          data: [
            {
              message: 'The mail is successfully send!'
            }
          ]
        };
      }

      if (payload) {
        state.inviteUser = { ...state.inviteUser, ...payload };
      }

      state.inviteUser.status = 'success';
    });
    builder.addCase(inviteUser.rejected, (state, { payload }) => {
      state.inviteUser.status = 'error';
      state.responseMessage = {
        type: 'error',
        data: payload
      };
    });
  }
});

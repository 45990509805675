import { useAppDispatch, useAppSelector } from '@app/store/store';
import { LoginFormProps } from '@components/Forms/interface';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { login } from '@slices/auth/thunks';
import { DefaultComponentProps } from 'custom';
import React, { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import { InputTextField } from 'src/components/Fields/InputText/InputTextField';
import { PasswordField } from 'src/components/Fields/Password/PasswordField';
import { FormWrapper } from 'src/components/Forms/FormWrapper';
import * as yup from 'yup';

const schema = yup
  .object({
    email: yup.string().email('Email must be a valid email').required('Email is required field'),
    password: yup.string().required('Username is required field')
  })
  .required();

export const LoginForm: FC<DefaultComponentProps> = ({ className }) => {
  const dispatch = useAppDispatch();
  const status = useAppSelector((store) => store.auth.status);

  const methods = useForm<LoginFormProps>({
    //TODO: temp.  mocked

    defaultValues: {
      email: process.env.REACT_APP_DEFAULT_EMAIL ?? '',
      password: process.env.REACT_APP_DEFAULT_PASSWORD ?? ''
    },
    resolver: yupResolver(schema),
    mode: 'onSubmit'
  });

  const submitRecipe: SubmitHandler<LoginFormProps> = async (data) => {
    data.email.trim();
    dispatch(login(data));
  };

  return (
    <FormWrapper
      methods={methods}
      formProps={{
        onSubmit: methods.handleSubmit(submitRecipe)
      }}
      className={className}
    >
      <Stack spacing={2} direction="column">
        <InputTextField inputMode="email" name="email" label="Email" variant="outlined" />

        <PasswordField name="password" label="Password" />

        <LoadingButton type="submit" className="py-3 mt-8" variant="contained" loading={status === 'loading'}>
          Login
        </LoadingButton>
      </Stack>
      <Divider className="my-5" />
      <Box className="flex gap-2 flex-col">
        <Typography className="text-gray-500 text-sm">
          <NavLink className="no-underline hover:underline text-gray-500" to="../password-restore">
            Forgot password?
          </NavLink>
        </Typography>
      </Box>
    </FormWrapper>
  );
};

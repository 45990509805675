import HelpOutline from '@mui/icons-material/HelpOutline';
import { Box, CircularProgress, Typography } from '@mui/material';
import classNames from 'classnames';
import { FC } from 'react';
import { DefaultComponentProps } from 'custom';

interface NoDataPlugProps extends DefaultComponentProps {
  loading?: boolean;
}

export const NoDataPlug: FC<NoDataPlugProps> = ({ className, loading }) => {
  return (
    <Box className={classNames('absolute bg-white w-full h-full inset-0 flex overflow-hidden justify-center items-center z-20', className)}>
      <Box className="relative w-full h-full justify-center items-center flex">
        <HelpOutline className="w-[80px] h-[80px] -mr-5 h-full text-gray-300" />
        <Typography className="relative z-10 bg-white text-gray-300 font-bold uppercase" variant="h5">
          No data...
        </Typography>
      </Box>
      {loading && <CircularProgress size={20} className="absolute bottom-0 right-0" />}
    </Box>
  );
};

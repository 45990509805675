import { configureStore } from '@reduxjs/toolkit';
import { authSlice } from '@slices/auth/authSlice';
import { candidatesSlice } from '@slices/candidates/candidatesSlice';
import { dashboardSlice } from '@slices/dashboard/dashboardSlice';
import { departmentsSlice } from '@slices/departments/departmentsSlice';
import { eventsSlice } from '@slices/events/eventsSlice';
import { globalSlice } from '@slices/global/globalSlice';
import { kanbanSlice } from '@slices/kanban/kanbanSlice';
import { platformsSlice } from '@slices/platform/platformSlice';
import { profileSlice } from '@slices/profile/profileSlice';
import { skillsSlice } from '@slices/skills/skillsSlice';
import { stepsSlice } from '@slices/steps/stepsSlice';
import { tagsSlice } from '@slices/tags/tagsSlice';
import { templatesSlice } from '@slices/templates/templatesSlice';
import { usersSlice } from '@slices/users/usersSlice';
import { vacanciesSlice } from '@slices/vacancies/vacanciesSlice';

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

const store = configureStore({
  reducer: {
    [authSlice.name]: authSlice.reducer,
    [profileSlice.name]: profileSlice.reducer,
    [candidatesSlice.name]: candidatesSlice.reducer,
    [usersSlice.name]: usersSlice.reducer,
    [tagsSlice.name]: tagsSlice.reducer,
    [platformsSlice.name]: platformsSlice.reducer,
    [skillsSlice.name]: skillsSlice.reducer,
    [stepsSlice.name]: stepsSlice.reducer,
    [departmentsSlice.name]: departmentsSlice.reducer,
    [vacanciesSlice.name]: vacanciesSlice.reducer,
    [dashboardSlice.name]: dashboardSlice.reducer,
    [candidatesSlice.name]: candidatesSlice.reducer,
    [eventsSlice.name]: eventsSlice.reducer,
    [kanbanSlice.name]: kanbanSlice.reducer,
    [templatesSlice.name]: templatesSlice.reducer,
    [globalSlice.name]: globalSlice.reducer
  }
});

export const clearAllResponseMessages = (): void => {
  store.dispatch(profileSlice.actions.clearResponseMessages());
  store.dispatch(usersSlice.actions.clearResponseMessages());
  store.dispatch(tagsSlice.actions.clearResponseMessages());
  store.dispatch(platformsSlice.actions.clearResponseMessages());
  store.dispatch(skillsSlice.actions.clearResponseMessages());
  store.dispatch(stepsSlice.actions.clearResponseMessages());
  store.dispatch(departmentsSlice.actions.clearResponseMessages());
  store.dispatch(vacanciesSlice.actions.clearResponseMessages());
  store.dispatch(candidatesSlice.actions.clearResponseMessages());
  store.dispatch(dashboardSlice.actions.clearResponseMessages());
  store.dispatch(templatesSlice.actions.clearResponseMessages());
};

export type AppDispatchType = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch = () => useDispatch<AppDispatchType>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;

import { UserColumns } from '@app/modules/users/interface';
import { useAppDispatch, useAppSelector } from '@app/store/store';
import { CenterLoader } from '@components/Loaders/CenterLoader';
import { ListActionPopover } from '@components/Popovers/UserActions/ListActionPopover';
import { TableSortHeader } from '@components/Tables/TableSortHeader';
import { queryStringBuilder } from '@helpers/queryStringBuilder/queryStringBuilder';
import { useDebouncedRequest } from '@hooks/useDebouncedRequest';
import { UsersTableBody } from '@modules/users/components/Tables/UsersTableBody';
import { UsersListFiltersAndSorts } from '@modules/users/components/UsersListFiltersAndSorts/UsersListFiltersAndSorts';
import HelpOutline from '@mui/icons-material/HelpOutline';
import { Box, Popover, Table, TableContainer, TablePagination, Typography } from '@mui/material';
import { getUsers } from '@slices/users/thunks';
import { usersSlice } from '@slices/users/usersSlice';
import { ChangeEvent, FC, MouseEvent, useMemo, useState } from 'react';

export const UsersListTable: FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const {
    sort,
    search,
    status,
    order,
    usersList: { data: list, total, page, rowsCount }
  } = useAppSelector((store) => store.users.usersList);

  const query = useMemo(
    () =>
      queryStringBuilder({
        search,
        order: (order || '').toUpperCase(),
        sort_field: sort as string,
        page: page + 1,
        perPage: rowsCount
      }),
    [search, sort, order, page, rowsCount]
  );

  const dispatch = useAppDispatch();
  useDebouncedRequest(query, () => dispatch(getUsers(query)), 500);

  const handleRequestSort = (_: MouseEvent<unknown>, property: keyof UserColumns): void => {
    const isAsc = sort === property && order === 'asc';
    dispatch(usersSlice.actions.touchSearchActions({ order: isAsc ? 'desc' : 'asc', sort: property }));
    dispatch(usersSlice.actions.touchPagination({ page: 0 }));
  };

  const handleChangePage = (_: unknown, page: number): void => {
    dispatch(usersSlice.actions.touchPagination({ page }));
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>): void => {
    dispatch(usersSlice.actions.touchPagination({ page: 0, rowsCount: parseInt(event.target.value, 10) }));
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <UsersListFiltersAndSorts />

      <Box className="min-h-[100px] flex flex-col relative">
        <TableContainer className="flex flex-1">
          <Table stickyHeader>
            <TableSortHeader
              columns={[
                {
                  id: 'first_name',
                  label: 'Name'
                },
                {
                  id: 'email',
                  label: 'Email'
                },
                {
                  id: 'phone',
                  label: 'Phone',
                  disableSorting: true
                },
                {
                  id: 'role',
                  label: 'Role'
                }
              ]}
              onRequestSort={handleRequestSort}
              order={order}
              orderBy={sort as string}
            />
            <UsersTableBody list={list} onActionsClick={handleClick} />
          </Table>
        </TableContainer>

        <TablePagination
          className="flex-shrink-0"
          component="div"
          variant="footer"
          count={total}
          page={page}
          rowsPerPage={rowsCount}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />

        <Popover
          className="mt-2"
          // id={popoverID}
          open={!!anchorEl}
          onClose={handleClose}
          onClick={handleClose}
          anchorEl={anchorEl}
        >
          {anchorEl && anchorEl.ariaLabel && <ListActionPopover id={anchorEl.ariaLabel} />}
        </Popover>

        {status === 'loading' && <CenterLoader className="bg-white" />}
        {status !== 'loading' && !list.length && (
          <Box className="absolute bg-white w-full h-full inset-0 flex overflow-hidden justify-center items-center z-20">
            <Box className="relative w-full h-full justify-center items-center flex">
              <HelpOutline className="w-[80px] -mr-5 h-full text-gray-300" />
              <Typography className="relative z-10 bg-white text-gray-300 font-bold uppercase" variant="h5">
                No data...
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

import { RequestStatus, UploadFileMeta } from '@app/store/interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CANDIDATES } from '@slices/candidates/interface';
import { EVENTS } from '@slices/events/interface';
import { createCandidate, deleteCandidate, getCandidate, getCandidates, removeCandidateFile, updateCandidate, updateCandidateStep, uploadCandidateFiles } from './thunks';

const initialState: CANDIDATES.CandidatesSlice = {
  status: 'unset',
  responseMessage: null,

  candidate: {
    status: 'unset',
    files_upload_meta: null,
    files_upload_status: 'unset',
    step_status: 'unset',
    event_status: 'unset',
    data: null
  },

  candidates: {
    list: {
      data: [],
      total: 0
    },
    order: 'asc',
    page: 0,
    rowsCount: 25,
    search: '',
    sort: 'created_at'
  }
};

export const candidatesSlice = createSlice({
  name: 'candidates',
  initialState,
  reducers: {
    clearResponseMessages: (state) => {
      state.responseMessage = null;
    },
    touchSearchActions: (store, { payload }: { payload: CANDIDATES.SearchActionsPayload }) => {
      store.candidates.search = payload.search ?? '';
      if (payload.sort) {
        store.candidates.sort = payload.sort;
      }
      if (payload.order) {
        store.candidates.order = payload.order;
      }
    },
    touchPagination: (store, { payload }: { payload: Partial<Record<'page' | 'rowsCount', number>> }) => {
      if (typeof payload.page === 'number') {
        store.candidates.page = payload.page;
      }
      if (typeof payload.rowsCount === 'number') {
        store.candidates.rowsCount = payload.rowsCount;
      }
    },
    clearSelectedCandidate: (state) => {
      state.candidate.data = null;
      state.candidate.status = 'unset';
    },
    addEvent: (state, { payload }: { payload: EVENTS.Event & EVENTS.EventRelations }) => {
      if (state.candidate?.data?.events) {
        state.candidate.data.events.push(payload);
      }
    },
    changeEventStatus: (state, { payload }: PayloadAction<RequestStatus>) => {
      state.candidate.event_status = payload;
    },
    editEvent: (state, { payload }: { payload: EVENTS.Event & EVENTS.EventRelations }) => {
      if (state.candidate?.data?.events) {
        state.candidate.data.events = state.candidate.data.events.map((event) => (event.id === payload.id ? { ...event, ...payload } : event));
      }
    },
    deleteEvent: (state, { payload }: { payload: string }) => {
      if (state.candidate?.data?.events) {
        state.candidate.data.events = state.candidate.data.events.filter((event) => event.id !== payload);
      }
    },
    updateFileUploadStatus: (state, { payload }: { payload: UploadFileMeta | null }) => {
      state.candidate.files_upload_meta = payload;
    }
  },
  extraReducers: (builder) => {
    //CREATE
    builder.addCase(createCandidate.pending, (state) => {
      state.candidate.status = 'loading';
    });
    builder.addCase(createCandidate.fulfilled, (state, { payload }) => {
      state.responseMessage = {
        type: 'success',
        data: [
          {
            message: 'Candidate successfully added'
          }
        ]
      };
      state.candidate.data = payload;
      state.candidate.status = 'success';
    });
    builder.addCase(createCandidate.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.candidate.status = 'error';
    });

    //UPLOAD FILES
    builder.addCase(uploadCandidateFiles.pending, (state) => {
      state.candidate.files_upload_status = 'loading';
    });
    builder.addCase(uploadCandidateFiles.fulfilled, (state) => {
      state.responseMessage = {
        type: 'info',
        data: [
          {
            message: 'Files successfully uploaded'
          }
        ]
      };
      state.candidate.files_upload_status = 'success';
      state.candidate.files_upload_meta = null;
    });
    builder.addCase(uploadCandidateFiles.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.candidate.files_upload_status = 'error';
      state.candidate.files_upload_meta = null;
    });

    //UPDATE
    builder.addCase(updateCandidate.pending, (state) => {
      state.candidate.status = 'loading';
    });
    builder.addCase(updateCandidate.fulfilled, (state, { payload }) => {
      state.candidate.data = {
        ...state.candidate.data,
        ...payload
      };
      state.responseMessage = {
        type: 'success',
        data: [
          {
            message: 'Candidate successfully edited'
          }
        ]
      };
      state.candidate.status = 'success';
    });
    builder.addCase(updateCandidate.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.candidate.status = 'error';
    });

    //UPDATE STEP
    builder.addCase(updateCandidateStep.pending, (state) => {
      state.candidate.step_status = 'loading';
    });
    builder.addCase(updateCandidateStep.fulfilled, (state, { payload }) => {
      if (state.candidate.data) {
        state.candidate.data.steps = payload;
        state.candidate.step_status = 'success';
      }
    });
    builder.addCase(updateCandidateStep.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.candidate.step_status = 'error';
    });

    //GET MANY
    builder.addCase(getCandidates.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(getCandidates.fulfilled, (state, { payload }) => {
      state.candidates.list = {
        total: payload.total,
        data: payload.data.map((candidate) => ({
          ...candidate,
          department: candidate.vacancy?.department
        }))
      };
      state.status = 'success';
    });
    builder.addCase(getCandidates.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.status = 'error';
    });

    //GET ONE
    builder.addCase(getCandidate.pending, (state) => {
      state.candidate.status = 'loading';
    });
    builder.addCase(getCandidate.fulfilled, (state, { payload }) => {
      state.candidate.data = payload;
      state.candidate.status = 'success';
    });
    builder.addCase(getCandidate.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.candidate.status = 'error';
    });

    //DELETE
    builder.addCase(deleteCandidate.pending, (state) => {
      state.status = 'loading';
      state.candidate.status = 'loading';
    });
    builder.addCase(deleteCandidate.fulfilled, (state, { payload }) => {
      state.responseMessage = {
        type: 'success',
        data: [
          {
            message: 'The Candidate is successfully deleted'
          }
        ]
      };
      state.candidates.list.data = state.candidates.list.data.filter((candidate) => candidate.id !== payload.id);
      state.status = 'success';
      state.candidate.status = 'success';
    });
    builder.addCase(deleteCandidate.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.status = 'error';
      state.candidate.status = 'error';
    });

    //DELETE FILE
    builder.addCase(removeCandidateFile.pending, (state) => {
      state.candidate.files_upload_status = 'loading';
    });
    builder.addCase(removeCandidateFile.fulfilled, (state, { meta }) => {
      state.responseMessage = {
        type: 'info',
        data: [
          {
            message: 'The file is successfully deleted'
          }
        ]
      };
      if (state.candidate.data?.files) {
        state.candidate.data.files = state.candidate.data.files.filter((file) => file.id !== meta.arg.file_id);
      }
      state.candidate.files_upload_status = 'success';
    });
    builder.addCase(removeCandidateFile.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.candidate.files_upload_status = 'error';
    });
  }
});

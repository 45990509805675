import { useAppSelector } from '@app/store/store';
import AdditionalContactsList from '@components/AdditionalContactInfo/AdditionalContactsList';
import { GoBackButton } from '@components/Buttons/GoBack/GoBackButton';
import { SystemInfoCard } from '@components/Cards/SystemInfo/SystemInfoCard';
import { EventsSection } from '@components/Events/SectionCard/EventsSection';
import { ProfileHeader } from '@components/ProfileHeader/ProfileHeader';
import { CandidateInfoActions } from '@modules/candidates/components/CandidateInfoActions/CandidateInfoActions';
import { AttachmentsSection } from '@modules/candidates/pages/info/sections/AttachmentsSection';
import { ImportantInformationSection } from '@modules/candidates/pages/info/sections/ImportantInformationSection';
import { MainInfoSection } from '@modules/candidates/pages/info/sections/MainInfoSection';
import { StepsSection } from '@modules/candidates/pages/info/sections/StepsSection';
import Edit from '@mui/icons-material/Edit';
import { Box, Button, Card, CardHeader, Container, Divider, Skeleton } from '@mui/material';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

export const CandidateInfoPage: FC = () => {
  const { data, status, files_upload_status, event_status } = useAppSelector((store) => store.candidates.candidate);

  return (
    <Box>
      <Container className="p-0 max-w-[1200px]">
        <GoBackButton to=".." text="Back to candidates" />
        <Box className="flex items-start justify-between flex-wrap gap-4">
          <Box className="flex flex-col gap-4">
            {!!data && <ProfileHeader subtitleIsID email={data.email} title={`${data.first_name ?? ''} ${data.last_name ?? ''}`} subtitle={data.id} />}
            {!data && status === 'loading' && (
              <Box className="flex flex-wrap">
                <Skeleton variant="circular" className="mr-4 mb-2 w-[64px] h-[64px]" />
                <Box>
                  <Skeleton variant="text" width={210} height={40} className="mb-2 text-gray-900 font-black" />
                  <Skeleton variant="text" width={280} height={24} className="text-gray-900 font-black" />
                </Box>
              </Box>
            )}
          </Box>
          <Box className="flex gap-2 mb-4 lg:">
            <Button disabled={!data} variant="outlined" component={Link} to={`../${data?.id}/edit`} startIcon={<Edit />}>
              Edit
            </Button>
            <CandidateInfoActions disabled={!data} />
          </Box>
        </Box>
        <>
          {data && (
            <Box className="flex gap-4 items-start flex-col xl:flex-row">
              <Box className="flex-1 w-full">
                {!!(data.tags?.length || data.skills?.length) && <ImportantInformationSection data={data} />}

                <MainInfoSection data={data} />

                {!!data.contacts?.length && (
                  <Card className="shadow my-4">
                    <CardHeader title="Additional information" className="uppercase text-gray-600 pb-2" />
                    <Divider />

                    <AdditionalContactsList contacts={data.contacts ?? []} />
                  </Card>
                )}

                <SystemInfoCard created_at={data.created_at} updated_at={data.updated_at} />
              </Box>
              <Box className="flex-1  xl:my-4 mb-4 w-full xl:max-w-[360px] flex xl:flex-col gap-4 flex-col md:flex-col items-start">
                {data.steps && !!data.steps.length && <StepsSection />}
                <AttachmentsSection status={files_upload_status} candidate_id={data.id} files={data.files ?? []} />
                <EventsSection status={event_status} events={data.events ?? []} />
              </Box>
            </Box>
          )}
        </>
      </Container>
    </Box>
  );
};

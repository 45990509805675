import { useAppDispatch } from '@app/store/store';
import { removeStorageItem } from '@helpers/localStorage/localStorageService';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ExitToApp from '@mui/icons-material/ExitToApp';
import { Box, Divider, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { authSlice } from '@slices/auth/authSlice';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ClientAvatarBlock } from '../../AvatarBlock/AvatarBlock';

interface MainToolsPopoverProps {}

export const MainToolsPopover: FC<MainToolsPopoverProps> = () => {
  const dispatch = useAppDispatch();

  const exitHandler = async (): Promise<void> => {
    dispatch(authSlice.actions.syncLogout());
    removeStorageItem('token');

    window.location.reload();
  };
  return (
    <Box className="max-w-[300px] min-w-[200px] w-full">
      <ClientAvatarBlock className="p-4" />

      <Divider />

      <Box className="">
        <List>
          <ListItemButton component={Link} to="/profile" className="mx-2 whitespace-nowrap px-2">
            <ListItemIcon className="min-w-fit mr-2">
              <AccountCircle fontSize="small" />
            </ListItemIcon>
            <ListItemText className="uppercase font-bold">Profile</ListItemText>
          </ListItemButton>
        </List>

        <Divider />
        <ListItemButton className="m-2  whitespace-nowrap px-2" onClick={exitHandler}>
          <ListItemIcon className="text-red-500 min-w-fit mr-2">
            <ExitToApp fontSize="small" />
          </ListItemIcon>
          <ListItemText className="uppercase font-bold">Exit App</ListItemText>
        </ListItemButton>
      </Box>
    </Box>
  );
};

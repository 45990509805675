import { useAppSelector } from '@app/store/store';
import { useNotifications } from '@hooks/useNotifications';
import { Box, Container } from '@mui/material';
import { authSlice } from '@slices/auth/authSlice';
import React, { FC, useEffect } from 'react';
import { Outlet } from 'react-router';
import { useLocation, useNavigate } from 'react-router-dom';

export const AuthModule: FC = () => {
  const navigate = useNavigate();
  const { state }: { state: any } = useLocation();
  const { token: isAuth, responseMessage } = useAppSelector((store) => store.auth);
  useNotifications(responseMessage, authSlice.actions.clearResponseMessages);

  useEffect(() => {
    if (isAuth) {
      const fromPage = state?.from || '/';
      navigate(fromPage);
    }
  }, [isAuth]);

  return (
    <Box className="h-full overflow-auto flex py-10">
      {!isAuth && (
        <Container className="m-auto max-w-[600px]">
          <Outlet />
        </Container>
      )}
    </Box>
  );
};

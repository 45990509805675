import { createSlice } from '@reduxjs/toolkit';
import { TAGS } from 'src/store/slices/tags/interface';
import { createTag, deleteOneTag, getAllTags, getOneTag, updateTag } from './thunks';

const initialState: TAGS.tagsSlice = {
  status: 'unset',
  responseMessage: null,

  search: '',
  sort: '',

  tags: {
    data: [],
    total: 0
  },
  selectedTag: {
    status: 'unset',
    data: null
  }
};

export const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    touchSearchActions: (store, { payload }: Record<'payload', Record<'sort' | 'search', string>>) => {
      store.search = payload.search;
      store.sort = payload.sort;
    },
    setTagFromAll: (store, { payload }: Record<'payload', TAGS.Tag>) => {
      store.selectedTag.data = payload;
      store.selectedTag.status = 'success';
    },
    clearResponseMessages: (state) => {
      state.responseMessage = null;
    }
  },
  extraReducers: (builder) => {
    //GET ALL
    builder.addCase(getAllTags.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(getAllTags.fulfilled, (state, { payload }) => {
      state.tags = payload;
      state.status = 'success';
    });
    builder.addCase(getAllTags.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.status = 'error';
    });

    //GET ONE
    builder.addCase(getOneTag.pending, (state) => {
      state.selectedTag.status = 'loading';
    });
    builder.addCase(getOneTag.fulfilled, (state, { payload }) => {
      state.selectedTag.data = payload;
      state.selectedTag.status = 'success';
    });
    builder.addCase(getOneTag.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.selectedTag.status = 'error';
    });

    //CREATE
    builder.addCase(createTag.pending, (state) => {
      state.status = 'loading';
      state.selectedTag.status = 'loading';
    });
    builder.addCase(createTag.fulfilled, (state) => {
      state.status = 'success';
      state.selectedTag.status = 'success';
      state.responseMessage = {
        type: 'success',
        data: [
          {
            message: 'Tag successfully added'
          }
        ]
      };
    });
    builder.addCase(createTag.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.status = 'error';
      state.selectedTag.status = 'error';
    });

    //UPDATE ONE
    builder.addCase(updateTag.pending, (state) => {
      state.status = 'loading';
      state.selectedTag.status = 'loading';
    });
    builder.addCase(updateTag.fulfilled, (state, { payload }) => {
      state.selectedTag.data = payload;
      state.tags.data = [...state.tags.data.filter((tag) => tag.id !== tag.id), payload];
      state.responseMessage = {
        type: 'success',
        data: [
          {
            message: 'Tag successfully edited'
          }
        ]
      };
      state.status = 'unset';
      state.selectedTag.status = 'success';
    });
    builder.addCase(updateTag.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.selectedTag.status = 'error';
    });

    //DELETE ONE
    builder.addCase(deleteOneTag.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(deleteOneTag.fulfilled, (state, { payload }) => {
      state.selectedTag.data = null;
      state.tags.data = state.tags.data.filter((tag) => tag.id !== payload.id);
      state.tags.total--;
      state.responseMessage = {
        type: 'success',
        data: [
          {
            message: 'Tag successfully deleted'
          }
        ]
      };
      state.selectedTag.status = 'unset';
      state.status = 'success';
    });
    builder.addCase(deleteOneTag.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.status = 'error';
    });
  }
});

import { DepartmentsService } from '@API/services/departments/departmentsService';
import { AfterActionProps, BaseAsyncThunkOptions, ListResponse } from '@app/store/interface';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { DEPARTMENTS } from '@slices/departments/interface';
import { RequiredIn } from 'custom';

export const getAllDepartments = createAsyncThunk<ListResponse<DEPARTMENTS.Department[]>, undefined, BaseAsyncThunkOptions>('departments/getAll', async (_, thunkApi) => {
  const response = await DepartmentsService.getAll();

  if (response.ok) {
    return {
      data: response.data.data,
      total: response.data.total
    };
  } else {
    return thunkApi.rejectWithValue(response.error);
  }
});

export const getOneDepartment = createAsyncThunk<DEPARTMENTS.Department, string, BaseAsyncThunkOptions>('departments/getOne', async (id, thunkApi) => {
  const response = await DepartmentsService.getOne(id);

  if (response.ok) {
    return response.data.data;
  } else {
    return thunkApi.rejectWithValue(response.error);
  }
});

export const createDepartment = createAsyncThunk<DEPARTMENTS.Department, Omit<DEPARTMENTS.Department, 'id'> & AfterActionProps, BaseAsyncThunkOptions>(
  'departments/createTag',
  async ({ afterAction, ...data }, thunkApi) => {
    const response = await DepartmentsService.create(data);

    if (response.ok) {
      afterAction && afterAction();
      return response.data.data;
    } else {
      return thunkApi.rejectWithValue(response.error);
    }
  }
);

export const updateDepartment = createAsyncThunk<DEPARTMENTS.Department, RequiredIn<DEPARTMENTS.Department, 'id'> & AfterActionProps, BaseAsyncThunkOptions>(
  'departments/update',
  async ({ id, afterAction, ...data }, thunkApi) => {
    const response = await DepartmentsService.updateOne(id, data);

    if (response.ok) {
      afterAction && afterAction();
      return response.data.data;
    } else {
      return thunkApi.rejectWithValue(response.error);
    }
  }
);

export const deleteOneDepartment = createAsyncThunk<DEPARTMENTS.Department, Pick<DEPARTMENTS.Department, 'id'> & AfterActionProps, BaseAsyncThunkOptions>(
  'departments/deleteOne',
  async ({ id, afterAction }, thunkApi) => {
    const response = await DepartmentsService.deleteOne(id);

    if (response.ok) {
      afterAction && afterAction();
      return response.data.data;
    } else {
      return thunkApi.rejectWithValue(response.error);
    }
  }
);

import { SkillsService } from '@API/services/skills/skillsService';
import { AfterActionProps, BaseAsyncThunkOptions, ListResponse } from '@app/store/interface';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { SKILLS } from '@slices/skills/interface';
import { RequiredIn } from 'custom';

export const getAllSkills = createAsyncThunk<ListResponse<SKILLS.Skill[]>, undefined, BaseAsyncThunkOptions>('skills/getAll', async (_, thunkApi) => {
  const response = await SkillsService.getAll();

  if (response.ok) {
    return {
      data: response.data.data,
      total: response.data.total
    };
  } else {
    return thunkApi.rejectWithValue(response.error);
  }
});

export const getOneSkill = createAsyncThunk<SKILLS.Skill, string, BaseAsyncThunkOptions>('skills/getOne', async (id, thunkApi) => {
  const response = await SkillsService.getOne(id);

  if (response.ok) {
    return response.data.data;
  } else {
    return thunkApi.rejectWithValue(response.error);
  }
});

export const createSkill = createAsyncThunk<SKILLS.Skill, Omit<SKILLS.Skill, 'id'> & AfterActionProps, BaseAsyncThunkOptions>(
  'skills/createTag',
  async ({ afterAction, ...data }, thunkApi) => {
    const response = await SkillsService.create(data);

    if (response.ok) {
      afterAction && afterAction();
      return response.data.data;
    } else {
      return thunkApi.rejectWithValue(response.error);
    }
  }
);

export const updateSkill = createAsyncThunk<SKILLS.Skill, RequiredIn<SKILLS.Skill, 'id'> & AfterActionProps, BaseAsyncThunkOptions>(
  'skills/update',
  async ({ id, afterAction, ...data }, thunkApi) => {
    const response = await SkillsService.updateOne(id, data);

    if (response.ok) {
      afterAction && afterAction();
      return response.data.data;
    } else {
      return thunkApi.rejectWithValue(response.error);
    }
  }
);

export const deleteOneSkill = createAsyncThunk<SKILLS.Skill, Pick<SKILLS.Skill, 'id'> & AfterActionProps, BaseAsyncThunkOptions>(
  'skills/deleteOne',
  async ({ id, afterAction }, thunkApi) => {
    const response = await SkillsService.deleteOne(id);

    if (response.ok) {
      afterAction && afterAction();
      return response.data.data;
    } else {
      return thunkApi.rejectWithValue(response.error);
    }
  }
);

import { Close } from '@mui/icons-material';
import { Chip, ChipProps } from '@mui/material';
import { TAGS } from '@slices/tags/interface';
import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';

interface Props extends Omit<ChipProps, 'label'> {
  tag: TAGS.Tag;
  label?: ReactNode;
}

export const TagChip: FC<Props> = ({ tag, className, label, ...chipProps }) => {
  return (
    <Chip
      {...chipProps}
      component="span"
      deleteIcon={<Close className="fill-white" fontSize="small" />}
      className={classNames(
        `bg-${tag.color}-500`,
        `hover:bg-${tag.color}-500/50`,
        `border-${tag.color}-500`,
        'text-white',
        'border border-solid w-fit m-0.5 rounded-full',
        className
      )}
      label={label ?? tag.name}
    />
  );
};

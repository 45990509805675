import { useAppDispatch, useAppSelector } from '@app/store/store';
import { GoBackButton } from '@components/Buttons/GoBack/GoBackButton';
import { DepartmentsAddAndEditForm } from '@components/Forms/Departments/DepartmentsAddAndEditForm';
import { Box, Container, Paper, Typography } from '@mui/material';
import { departmentsSlice } from '@slices/departments/departmentsSlice';
import { getOneDepartment } from '@slices/departments/thunks';
import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';

export const DepartmentsEditPage: FC = () => {
  const { data: departments } = useAppSelector((store) => store.departments.departments);
  const dispatch = useAppDispatch();
  const params = useParams();

  useEffect(() => {
    if (params.id && departments.length) {
      const department = departments.find((item) => item.id === params.id);

      if (department) {
        dispatch(departmentsSlice.actions.setDepartmentFromAll(department));
        return;
      }
    }
    if (params.id) {
      dispatch(getOneDepartment(params.id));
    }
  }, []);

  return (
    <Box>
      <Container className="p-0 max-w-[1200px]">
        <GoBackButton to=".." text="Back to departments" />
        <Box>
          <Box className="flex flex-wrap mb-5">
            <Typography className="uppercase mb-2 text-gray-300 font-black" fontWeight="bold" variant="h4">
              Edit Department
            </Typography>
          </Box>
          <Paper className="p-4">
            <DepartmentsAddAndEditForm className="grid gap-4 grid-cols-2" mode="edit" />
          </Paper>
        </Box>
      </Container>
    </Box>
  );
};

import MoreVert from '@mui/icons-material/MoreVert';
import { Box, Card, Collapse, IconButton, Typography, useMediaQuery } from '@mui/material';
import { TAGS } from '@slices/tags/interface';
import classNames from 'classnames';
import React, { FC, MouseEvent, useState } from 'react';

interface ListComponentProps {
  onActionClick: (event: MouseEvent<HTMLButtonElement>) => void;
  item: TAGS.Tag;
}

export const TagsListComponent: FC<ListComponentProps> = ({ item, onActionClick }) => {
  const match = useMediaQuery('(max-width: 540px)');
  const [collapsed, setCollapsed] = useState(!match);

  const handleEdit = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onActionClick(event);
  };

  const handleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Card
      onClick={handleCollapsed}
      elevation={0}
      className={classNames(
        'group flex ml-4 cursor-pointer flex-col overflow-visible shadow-none bg-transparent border-none relative mx-2 transition-all',
        `hover:bg-${item.color}-100`
      )}
    >
      <Box className="py-2 pr-2 flex items-start justify-between">
        <span className={classNames('w-1 h-full -left-4 min-h-full inset-y-0 flex z-10 absolute rounded-full transition-all group-hover:w-2', `bg-${item.color}-500`)} />
        <Box className="pl-4">
          <Typography className="flex items-center" variant="subtitle1">
            <span className="mr-2">{item.name}</span>
          </Typography>
          <Collapse in={collapsed}>
            <Typography className="flex" variant="caption">
              {item.comment}
            </Typography>
          </Collapse>
        </Box>
        <Box className="">
          <IconButton size="small" aria-label={item.id} onClick={handleEdit}>
            <MoreVert fontSize="small" />
          </IconButton>
        </Box>
      </Box>
    </Card>
  );
};

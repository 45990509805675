import { useAppDispatch, useAppSelector } from '@app/store/store';
import { GoBackButton } from '@components/Buttons/GoBack/GoBackButton';
import { StepsAddAndEditForm } from '@components/Forms/Steps/StepsAddAndEditForm';
import { Box, Container, Paper, Typography } from '@mui/material';
import { stepsSlice } from '@slices/steps/stepsSlice';
import { getOneStep } from '@slices/steps/thukns';
import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';

export const StepsEditPage: FC = () => {
  const {
    selectedStep: { data },
    steps
  } = useAppSelector((store) => store.steps);
  const dispatch = useAppDispatch();
  const params = useParams();

  useEffect(() => {
    if (params.id && steps.data.length) {
      const step = steps.data.find((item) => item.id === params.id);

      if (step) {
        dispatch(stepsSlice.actions.setStepsFromAll(step));
        return;
      }
    }
    if (params.id) {
      dispatch(getOneStep(params.id));
    }
  }, []);

  return (
    <Box>
      <Container className="p-0 max-w-[1200px]">
        {data && (
          <>
            <GoBackButton to=".." text="Back to steps" />
            <Box>
              <Box className="flex flex-wrap mb-5">
                <Typography className="uppercase mb-2 text-gray-300 font-black" fontWeight="bold" variant="h4">
                  Edit step
                </Typography>
              </Box>
              <Paper className="p-4">
                <StepsAddAndEditForm className="grid gap-4 grid-cols-2" mode="edit" />
              </Paper>
            </Box>
          </>
        )}
      </Container>
    </Box>
  );
};

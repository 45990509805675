import { AdditionalContactsType, ContactResponseData } from '@app/store/interface';
import AbcOutlined from '@mui/icons-material/AbcOutlined';
import Instagram from '@mui/icons-material/Instagram';
import LinkedIn from '@mui/icons-material/LinkedIn';
import MailOutlined from '@mui/icons-material/MailOutlined';
import PhoneOutlined from '@mui/icons-material/PhoneOutlined';
import Telegram from '@mui/icons-material/Telegram';
import { Box, Divider, Link as ALink, ListItem, ListItemText, Typography } from '@mui/material';
import React, { useMemo } from 'react';

type ReduceReturnType<T> = Partial<Record<AdditionalContactsType, T[]>>;
export const normalizeContacts = <T extends ContactResponseData>(contacts: T[]) => {
  return contacts.reduce<ReduceReturnType<T>>(
    (acc, item) => ({
      ...acc,
      [item.type]: item.type in acc ? [...(acc[item.type] ?? []), item] : [item]
    }),
    {}
  );
};

interface Props {
  contacts: ContactResponseData[];
}

const AdditionalContactsList = ({ contacts = [] }: Props) => {
  const normalizedData = useMemo(() => normalizeContacts(contacts), [contacts]);

  return (
    <>
      {normalizedData.email && (
        <>
          <ListItem className="flex md:flex-nowrap flex-wrap hover:bg-gray-100/75 transition-all">
            <ListItemText className="md:w-full md:max-w-[180px] mr-4">
              <Box className="flex items-center">
                <MailOutlined className="fill-gray-500 mr-2" />
                <Typography variant="subtitle1">{normalizedData.email.length === 1 ? 'Additional email' : 'Additional email'}</Typography>
              </Box>
            </ListItemText>

            <ListItemText>
              <Typography variant="subtitle2" className="text-right md:text-left opacity-70">
                {normalizedData.email?.map((item) => (
                  <ALink key={item.id} underline="hover" className="mr-2 hover:opacity-70 transition-all text-black whitespace-nowrap" type="email" href={`mailto:${item.value}`}>
                    {item.value}
                  </ALink>
                ))}
              </Typography>
            </ListItemText>
          </ListItem>
          <Divider />
        </>
      )}

      {normalizedData.phone && (
        <>
          <ListItem className="flex md:flex-nowrap flex-wrap hover:bg-gray-100/75 transition-all">
            <ListItemText className="md:w-full md:max-w-[180px] mr-4">
              <Box className="flex items-center">
                <PhoneOutlined className="fill-gray-500 mr-2" />
                <Typography variant="subtitle1">Phones</Typography>
              </Box>
            </ListItemText>

            <ListItemText>
              <Typography variant="subtitle2" className="text-right md:text-left opacity-70">
                {normalizedData.phone?.map((item) => (
                  <ALink key={item.id} underline="hover" className="mr-2 hover:opacity-70 transition-all text-black whitespace-nowrap" type="tel" href={`tel:${item.value}`}>
                    {item.value}
                  </ALink>
                ))}
              </Typography>
            </ListItemText>
          </ListItem>
          <Divider />
        </>
      )}

      {normalizedData.telegram && (
        <>
          <ListItem className="flex md:flex-nowrap flex-wrap hover:bg-gray-100/75 transition-all">
            <ListItemText className="md:w-full md:max-w-[180px] mr-4">
              <Box className="flex items-center">
                <Telegram className="fill-gray-500 mr-2" />
                <Typography variant="subtitle1">Telegram</Typography>
              </Box>
            </ListItemText>

            <ListItemText>
              <Typography variant="subtitle2" className="text-right md:text-left opacity-70">
                {normalizedData.telegram?.map((item) => (
                  <ALink
                    key={item.id}
                    underline="hover"
                    className="mr-2 hover:opacity-70 transition-all text-black whitespace-nowrap"
                    type="text"
                    target="_blank"
                    href={`tg://resolve?domain=${item.value}`}
                  >
                    {item.value}
                  </ALink>
                ))}
              </Typography>
            </ListItemText>
          </ListItem>
          <Divider />
        </>
      )}

      {normalizedData.skype && (
        <>
          <ListItem className="flex md:flex-nowrap flex-wrap hover:bg-gray-100/75 transition-all">
            <ListItemText className="md:w-full md:max-w-[180px] mr-4">
              <Box className="flex items-center">
                <PhoneOutlined className="fill-gray-500 mr-2" />
                <Typography variant="subtitle1">Skype</Typography>
              </Box>
            </ListItemText>

            <ListItemText>
              <Typography variant="subtitle2" className="text-right md:text-left opacity-70">
                {normalizedData.skype?.map((item) => (
                  <ALink
                    key={item.id}
                    underline="hover"
                    className="mr-2 hover:opacity-70 transition-all text-black whitespace-nowrap"
                    type="text"
                    target="_blank"
                    href={`skype:${item.value}?userinfo`}
                  >
                    {item.value}
                  </ALink>
                ))}
              </Typography>
            </ListItemText>
          </ListItem>
          <Divider />
        </>
      )}

      {normalizedData.instagram && (
        <>
          <ListItem className="flex md:flex-nowrap flex-wrap hover:bg-gray-100/75 transition-all">
            <ListItemText className="md:w-full md:max-w-[180px] mr-4">
              <Box className="flex items-center">
                <Instagram className="fill-gray-500 mr-2" />
                <Typography variant="subtitle1">Instagram</Typography>
              </Box>
            </ListItemText>

            <ListItemText>
              <Typography variant="subtitle2" className="text-right md:text-left opacity-70">
                {normalizedData.instagram?.map((item) => (
                  <ALink
                    key={item.id}
                    underline="hover"
                    className="mr-2 hover:opacity-70 transition-all text-black whitespace-nowrap"
                    type="text"
                    target="_blank"
                    href={`instagram://user?username=${item.value}`}
                  >
                    {`${item.value} profile`}
                  </ALink>
                ))}
              </Typography>
            </ListItemText>
          </ListItem>
          <Divider />
        </>
      )}

      {normalizedData.viber && (
        <>
          <ListItem className="flex md:flex-nowrap flex-wrap hover:bg-gray-100/75 transition-all">
            <ListItemText className="md:w-full md:max-w-[180px] mr-4">
              <Box className="flex items-center">
                <PhoneOutlined className="fill-gray-500 mr-2" />
                <Typography variant="subtitle1">Number with Viber</Typography>
              </Box>
            </ListItemText>

            <ListItemText>
              <Typography variant="subtitle2" className="text-right md:text-left opacity-70">
                {normalizedData.viber?.map((item) => (
                  <ALink
                    key={item.id}
                    underline="hover"
                    className="mr-2 hover:opacity-70 transition-all text-black whitespace-nowrap"
                    type="text"
                    target="_blank"
                    href={`viber://add?number=${item.value}`}
                  >
                    {item.value}
                  </ALink>
                ))}
              </Typography>
            </ListItemText>
          </ListItem>
          <Divider />
        </>
      )}

      {normalizedData.linkedin && (
        <>
          <ListItem className="flex md:flex-nowrap flex-wrap hover:bg-gray-100/75 transition-all">
            <ListItemText className="md:w-full md:max-w-[180px] mr-4">
              <Box className="flex items-center">
                <LinkedIn className="fill-gray-500 mr-2" />
                <Typography variant="subtitle1">Linkedin</Typography>
              </Box>
            </ListItemText>

            <ListItemText>
              <Typography variant="subtitle2" className="text-right md:text-left opacity-70">
                {normalizedData.linkedin?.map((item) => (
                  <ALink
                    key={item.id}
                    underline="hover"
                    className="mr-2 hover:opacity-70 transition-all text-black whitespace-nowrap"
                    type="text"
                    target="_blank"
                    href={item.value ?? ''}
                  >
                    {item.value}
                  </ALink>
                ))}
              </Typography>
            </ListItemText>
          </ListItem>
          <Divider />
        </>
      )}

      {normalizedData.other && (
        <>
          <ListItem className="flex md:flex-nowrap flex-wrap hover:bg-gray-100/75 transition-all">
            <ListItemText className="md:w-full md:max-w-[180px] mr-4">
              <Box className="flex items-center">
                <AbcOutlined className="fill-gray-500 mr-2" />
                <Typography variant="subtitle1">Other links</Typography>
              </Box>
            </ListItemText>

            <ListItemText>
              <Typography variant="subtitle2" className="text-right md:text-left opacity-70">
                {normalizedData.other?.map((item) => (
                  <ALink
                    key={item.id}
                    underline="hover"
                    className="mr-2 hover:opacity-70 transition-all text-black whitespace-nowrap"
                    type="text"
                    target="_blank"
                    href={item.value ?? ''}
                  >
                    {item.value}
                  </ALink>
                ))}
              </Typography>
            </ListItemText>
          </ListItem>
          <Divider />
        </>
      )}
    </>
  );
};

export default AdditionalContactsList;

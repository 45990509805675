import { createSlice } from '@reduxjs/toolkit';
import { EVENTS } from '@slices/events/interface';
import { createEvent, deleteEvent, editEvent, getEvent } from './thunks';

const initialState: EVENTS.EventsSlice = {
  status: 'unset',
  viewData: null,
  responseMessage: null
};

export const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    clearResponseMessages: (state) => {
      state.responseMessage = null;
    },
    addViewData: (state, { payload }: { payload: EVENTS.Event & EVENTS.EventRelations }) => {
      state.viewData = payload;
    },
    clearViewData: (state) => {
      state.viewData = null;
    }
  },
  extraReducers: ({ addCase }) => {
    //DELETE EVENT
    addCase(deleteEvent.pending, (state) => {
      state.status = 'loading';
    });
    addCase(deleteEvent.fulfilled, (state) => {
      state.responseMessage = {
        type: 'success',
        data: [
          {
            message: 'The event is successfully deleted'
          }
        ]
      };
      state.status = 'success';
    });
    addCase(deleteEvent.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.status = 'error';
    });

    //CREATE EVENT
    addCase(createEvent.pending, (state) => {
      state.status = 'loading';
    });
    addCase(createEvent.fulfilled, (state) => {
      state.responseMessage = {
        type: 'success',
        data: [
          {
            message: 'Event successfully added'
          }
        ]
      };
      state.status = 'success';
    });
    addCase(createEvent.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.status = 'error';
    });

    //EDIT EVENT
    addCase(editEvent.pending, (state) => {
      state.status = 'loading';
    });
    addCase(editEvent.fulfilled, (state, { payload }) => {
      state.responseMessage = {
        type: 'success',
        data: [
          {
            message: 'Event successfully edited'
          }
        ]
      };
      state.viewData = {
        ...state.viewData,
        ...payload
      };
      state.status = 'success';
    });
    addCase(editEvent.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.status = 'error';
    });

    //GET ONE
    addCase(getEvent.pending, (state) => {
      state.status = 'loading';
    });
    addCase(getEvent.fulfilled, (state, { payload }) => {
      state.viewData = payload;
      state.status = 'success';
    });
    addCase(getEvent.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.status = 'error';
    });
  }
});

import { format } from 'date-fns';

export const dayOutDate = () => new Date().setHours(23, 59, 59, 999) - Date.now();
export const inMomentSelector = (start?: Date, end?: Date) => {
  if (start) {
    const startDelta = Number(format(new Date(start), 'T')) - Date.now();

    if (end) {
      const endDelta = Number(format(new Date(end), 'T')) - Date.now();
      if (startDelta <= 0 && endDelta > 0) {
        return 'now';
      }
    }
    if (startDelta > 0 && startDelta < dayOutDate()) {
      return 'today';
    }
    if (startDelta <= 0) {
      return 'past';
    }
    return 'future';
  }
};

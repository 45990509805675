import { useAppSelector } from '@app/store/store';
import { GoBackButton } from '@components/Buttons/GoBack/GoBackButton';
import { StepsViewer } from '@components/Steps/StepsViewer/StepsViewer';
import { VacancyInfoActions } from '@modules/vacancies/components/VacancyInfoActions/VacancyInfoActions';
import Edit from '@mui/icons-material/Edit';
import { Box, Button, Card, CardHeader, Container, Divider, List, ListItem, ListItemText, Typography } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';

export const VacancyInfoPage: FC = () => {
  const { data } = useAppSelector((store) => store.vacancies.vacancy);
  return (
    <Box>
      <Container className="p-0 max-w-[1200px]">
        <GoBackButton to=".." text="Back to vacancies" />
        <Box className="flex items-start justify-between flex-wrap">
          <Box className="flex flex-wrap mb-5">
            <Box>
              <Typography className="uppercase mb-2 text-gray-300 font-black" fontWeight="bold" variant="h4">
                Vacancy information
              </Typography>
            </Box>
          </Box>
          <Box className="flex gap-2 mb-4 lg:">
            <Button disabled={!data} variant="outlined" component={Link} to={`../${data?.id}/edit`} startIcon={<Edit />}>
              Edit
            </Button>
            <VacancyInfoActions />
          </Box>
        </Box>
        <>
          {data && (
            <Box className="flex gap-4 items-start flex-col xl:flex-row">
              <Box className="flex-1">
                <Card className="shadow xl:my-4 w-full">
                  <CardHeader title={<Typography variant="h6">Main information</Typography>} />
                  <Divider />

                  <List>
                    <ListItem className="flex flex-wrap">
                      <ListItemText className="w-full max-w-[180px] mr-4">
                        <Typography variant="subtitle1">Name</Typography>
                      </ListItemText>
                      <ListItemText>
                        <Typography variant="subtitle2" className="text-right xl:text-left opacity-70">
                          {data?.name ?? '-'}
                        </Typography>
                      </ListItemText>
                    </ListItem>

                    <Divider />

                    <ListItem className="flex flex-wrap">
                      <ListItemText className="w-full max-w-[180px] mr-4">
                        <Typography variant="subtitle1">Status</Typography>
                      </ListItemText>
                      <ListItemText>
                        <Typography variant="subtitle2" className="text-right xl:text-left opacity-70">
                          {data.status === 'open' && 'Opened'}
                          {data.status === 'close' && 'Closed'}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <Divider />

                    <ListItem className="flex flex-wrap">
                      <ListItemText className="w-full max-w-[180px] mr-4">
                        <Typography variant="subtitle1">Experience</Typography>
                      </ListItemText>
                      <ListItemText>
                        <Typography variant="subtitle2" className="text-right xl:text-left opacity-70">
                          {data?.experience_from === 0 && 'No experience'}
                          {data?.experience_from > 0 &&
                            `from ${data?.experience_from ?? '?'} to ${data?.experience_to ?? '?'} ${data?.experience_from > 1 ? 'years' : 'year'} of experience`}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <Divider />

                    <ListItem className="flex flex-wrap">
                      <ListItemText className="w-full max-w-[180px] mr-4">
                        <Typography variant="subtitle1">Department</Typography>
                      </ListItemText>
                      <ListItemText>
                        <Typography variant="subtitle2" className="text-right xl:text-left opacity-70">
                          {data?.department?.name ?? '-'}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <Divider />

                    <ListItem className="flex flex-wrap">
                      <ListItemText className="w-full max-w-[180px] mr-4">
                        <Typography variant="subtitle1">Description</Typography>
                      </ListItemText>
                      <ListItemText>
                        <Typography variant="subtitle2" className="opacity-70">
                          {data.description || '-'}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <Divider />

                    <ListItem className="flex flex-wrap">
                      <ListItemText className="w-full max-w-[180px] mr-4">
                        <Typography variant="subtitle1">Comment</Typography>
                      </ListItemText>
                      <ListItemText>
                        <Typography variant="subtitle2" className="opacity-70">
                          {data.comment || '-'}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </List>
                </Card>

                <Card className="shadow w-full">
                  <CardHeader title={<Typography variant="h6">Platforms</Typography>} />
                  <Divider />

                  <List>
                    {data.platforms?.map((platform) => (
                      <ListItem key={platform?.id} className="flex flex-wrap">
                        <ListItemText className="w-full">
                          <Typography variant="subtitle1">{platform?.name ?? ''}</Typography>
                        </ListItemText>
                      </ListItem>
                    ))}
                    {!data.platforms?.length && (
                      <ListItem className="flex flex-wrap">
                        <ListItemText className="w-full">
                          <Typography variant="subtitle1">No information</Typography>
                        </ListItemText>
                      </ListItem>
                    )}
                  </List>
                </Card>
              </Box>

              <Box className="flex-1 xl:my-4 mb-4 w-full xl:max-w-[300px] flex xl:flex-col gap-4 flex-col md:flex-row">
                <Card className="shadow w-full">
                  <CardHeader title={<Typography variant="h6">Steps</Typography>} />
                  <Divider />

                  {data?.steps && !!data?.steps?.length && <StepsViewer className="py-0 px-4" onlyView steps={data?.steps ?? []} />}
                  {!data?.steps?.length && (
                    <ListItem className="flex flex-wrap">
                      <ListItemText className="w-full">
                        <Typography variant="subtitle1">No information</Typography>
                      </ListItemText>
                    </ListItem>
                  )}
                </Card>
              </Box>
            </Box>
          )}
        </>
      </Container>
    </Box>
  );
};

import { useAppDispatch, useAppSelector } from '@app/store/store';
import { ColorSelect } from '@components/Fields/ColorSelect/ColorSelect';
import { InputCheckboxField } from '@components/Fields/InputCheckbox/InputCheckboxField';
import { InputTextField } from '@components/Fields/InputText/InputTextField';
import { FormWrapper } from '@components/Forms/FormWrapper';
import { StepsAddAndEditFormProps, StepsAddAndEditFormValues } from '@components/Forms/interface';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Typography } from '@mui/material';
import { createStep, updateStep } from '@slices/steps/thukns';
import { debounce } from 'lodash';
import React, { FC, FormEvent, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

const schema = yup.object({
  name: yup.string().required('Skill name is required field'),
  comment: yup.string().nullable()
});

export const StepsAddAndEditForm: FC<StepsAddAndEditFormProps> = ({ className, mode }) => {
  const { data: selectedStep, status } = useAppSelector((store) => store.steps.selectedStep);

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const methods = useForm<StepsAddAndEditFormValues>({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (mode === 'edit' && selectedStep) {
      methods.reset({
        name: selectedStep.name,
        comment: selectedStep.comment,
        default: selectedStep.default,
        finished: selectedStep.finished,
        color: selectedStep.color
      });
    }
  }, [mode, selectedStep, methods]);

  const handleReset = (e?: FormEvent): void => {
    e && e.preventDefault();
    navigate('/steps');
  };

  const submitRecipe: SubmitHandler<StepsAddAndEditFormValues> = (data) => {
    const debouncedNavigate = debounce(() => navigate('/steps'), 300);

    if (mode === 'create') {
      dispatch(
        createStep({
          ...data,
          afterAction: () => debouncedNavigate()
        })
      );
    }

    if (mode === 'edit') {
      if (params.id) {
        dispatch(
          updateStep({
            ...data,
            id: params.id,
            afterAction: () => debouncedNavigate()
          })
        );
      }
    }
  };

  return (
    <FormWrapper
      className={className}
      methods={methods}
      formProps={{
        onSubmit: methods.handleSubmit(submitRecipe),
        onReset: handleReset
      }}
    >
      <InputTextField className="col-span-2" name="name" label="Name" />
      <Box className="col-span-full">
        <ColorSelect className="mb-1" fullWidth name="color" label="Color" />
        <Typography className="text-gray-500" variant="caption">
          Step color to be displayed on the dashboard
        </Typography>
      </Box>
      <InputTextField className="col-span-2" multiline minRows={5} name="comment" label="Comment" />
      <Box>
        <InputCheckboxField name="default" label="Show selection by default" />
        <InputCheckboxField name="finished" label="Step is closing for a vacancy" />
      </Box>

      <Box className="flex col-span-full gap-2 lg:gap-4 flex-wrap flex-row-reverse">
        <LoadingButton loading={status === 'loading'} className="py-2 px-8 w-full lg:w-fit" variant="contained" type="submit">
          Save
        </LoadingButton>
        <Button className="py-2 px-8 w-full lg:w-fit" variant="outlined" color="error" type="reset">
          Cancel
        </Button>
      </Box>
    </FormWrapper>
  );
};

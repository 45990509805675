import { CreateUserDTO } from '@API/services/users/usersService';
import { ROLES, USER_STATUSES } from '@app/store/constants';
import { useAppDispatch, useAppSelector } from '@app/store/store';
import { AdditionalContactInfo } from '@components/AdditionalContactInfo/AdditionalContactInfo';
import { InputSelect } from '@components/Fields/InputSelect/InputSelect';
import { InputTextField } from '@components/Fields/InputText/InputTextField';
import { PasswordField } from '@components/Fields/Password/PasswordField';
import { FormWrapper } from '@components/Forms/FormWrapper';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, CardHeader, Divider } from '@mui/material';
import { addNewUser, updateUser } from '@slices/users/thunks';
import classNames from 'classnames';
import { debounce } from 'lodash';
import React, { FC, FormEvent, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { CreateAndEditUserFormProps, CreateAndEditUserFormValues } from '../interface';

const schema = {
  email: yup.string().email('Email must be a valid email').required('Email is required field'),
  first_name: yup.string().min(2, 'First name must be at least 2 characters').required('First Name is required field'),
  last_name: yup.string().nullable(),
  role: yup.string().required('Role is required field'),
  phone: yup.string().nullable(),
  status: yup.string().required('Status is required field'),
  comment: yup.string().nullable()
};

export const CreateAndEditUserForm: FC<CreateAndEditUserFormProps> = ({ mode, className }) => {
  const { status, data } = useAppSelector((store) => store.users.user);
  const { credentials } = useAppSelector((store) => store.profile);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const methods = useForm<CreateAndEditUserFormValues>({
    resolver: yupResolver(
      yup.object({
        ...schema,
        password: mode === 'create' ? yup.string().required('Password is required field') : yup.string()
      })
    )
  });

  const handleReset = (e: FormEvent): void => {
    e.preventDefault();
    if (data) {
      const { id, contacts, ...rest } = data;
      methods.reset({
        ...rest
        // contacts: contacts?.map(({ value, type }) => ({ type, id: value }))
      });
    }
  };

  const navigateHandler = (): void => {
    if (mode === 'edit') {
      if (data) {
        navigate(`/users/${data.id}`, { replace: true });
      }
    }

    if (mode === 'create') {
      navigate('/users', { replace: true });
    }
  };

  const submitRecipe: SubmitHandler<CreateAndEditUserFormValues> = async (data) => {
    const debouncedNavigate = debounce((id: string) => navigate(`/users/${id}`), 300);

    if (mode === 'create') {
      dispatch(
        addNewUser({
          ...data,
          afterActionWithParams: (id) => id && debouncedNavigate(id)
        })
      );
    }

    if (mode === 'edit') {
      const { contacts, ...body }: Partial<CreateUserDTO> = { ...data };
      if (params.id) {
        if (!body.password) {
          delete body.password;
        }

        dispatch(
          updateUser({
            ...body,
            contacts: contacts?.filter((item) => item?.type || item?.value),
            id: params.id,

            afterAction: () => params.id && debouncedNavigate(params.id)
          })
        );
      }
    }
  };

  useEffect(() => {
    if (data && mode === 'edit') {
      methods.reset({
        status: data.status,
        contacts: data.contacts?.map(({ value, type }) => ({ type, value })),
        comment: data.comment,
        last_name: data.last_name,
        email: data.email,
        first_name: data.first_name,
        role: data.role
      });
    }
  }, [status, data, methods]);

  return (
    <FormWrapper
      className={classNames('relative', className)}
      formProps={{
        onSubmit: methods.handleSubmit(submitRecipe),
        onReset: handleReset
      }}
      methods={methods}
    >
      <Box className="px-4 py-4 grid gap-4 xl:grid-cols-2 grid-cols-1">
        <InputTextField name="first_name" label="First name" />
        <InputTextField name="last_name" label="Last name" />
        <InputTextField name="email" label="Email" />
        <PasswordField disabled={credentials?.role !== 'admin' && mode === 'edit'} name="password" label="Password" />
        <InputSelect defaultValue={ROLES[0]} returnValue="value" optionLabelRefName="label" options={ROLES} className="" name="role" label="Role" />
        <InputSelect defaultValue={USER_STATUSES[0]} returnValue="value" optionLabelRefName="label" options={USER_STATUSES} className="" name="status" label="Status" />
      </Box>

      <CardHeader title="Additional contacts" className="uppercase text-gray-600 pb-2" />
      <Divider />

      <AdditionalContactInfo className="p-4 col-span-full" />
      <Box className="px-4 py-4 grid gap-4 xl:grid-cols-2 grid-cols-1">
        <InputTextField multiline minRows={5} fullWidth className="xl:col-span-2" name="comment" label="Commentaries" />
      </Box>
      <Box className="p-4 col-span-full gap-4 flex flex-row-reverse flex-wrap">
        <LoadingButton className="md:w-fit w-full" loading={status === 'loading'} variant="contained" type="submit">
          Save
        </LoadingButton>
        <Button className="md:w-fit w-full" onClick={navigateHandler} variant="outlined" color="error">
          cancel
        </Button>
      </Box>
    </FormWrapper>
  );
};

import { UserAvatarBlock } from '@components/AvatarBlock/AvatarBlock';
import { Box, Button, Link, Tooltip, Typography } from '@mui/material';
import { EVENTS } from '@slices/events/interface';
import React, { FC } from 'react';

export const MembersSection: FC<{ members: EVENTS.MembersType[] }> = ({ members }) => {
  return (
    <Box className="flex gap-1 items-start">
      <Typography className="text-gray-500 flex mt-1.5 items-center shrink-0" variant="subtitle2">
        <span className="text-gray-300 mr-1">Members: </span>
      </Typography>
      <Box className="flex-wrap flex gap-1">
        {members.map((user) => (
          <Tooltip arrow placement="top" title={user.role} key={`${user.id}-members`}>
            <Button
              component={Link}
              href={`${window.location.origin}/users/${user.id}`}
              target="_blank"
              className="group normal-case no-underline text-gray-500 transition-all hover:bg-gray-100 flex items-center px-2 py-1 border border-solid rounded-full border-gray-300"
            >
              {`${user.first_name} ${user.last_name ?? ''}`}
              <UserAvatarBlock
                className="ml-1 inline-flex"
                avatarProps={{
                  variant: 'circular',
                  className: 'bg-gray-100 group-hover:bg-white w-6 h-6 transition-all'
                }}
                email={user.email}
              />
            </Button>
          </Tooltip>
        ))}
      </Box>
    </Box>
  );
};

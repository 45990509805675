import { StyledChip } from '@components/Chips/StyledChip/StyledChip';
import { TagChip } from '@components/Chips/TagChip/TagChip';
import LibraryAddCheckOutlined from '@mui/icons-material/LibraryAddCheckOutlined';
import LocalOfferOutlined from '@mui/icons-material/LocalOfferOutlined';
import { Box, Card, Divider, ListItem, ListItemText, Typography } from '@mui/material';
import { CANDIDATES } from '@slices/candidates/interface';
import React, { FC } from 'react';
import { DefaultComponentProps } from 'custom';

interface ImportantInformationSectionProps extends DefaultComponentProps {
  data: CANDIDATES.Candidate;
}

export const ImportantInformationSection: FC<ImportantInformationSectionProps> = ({ data }) => {
  return (
    <Card className="shadow my-4">
      {!!data.tags?.length && (
        <>
          <ListItem className="flex flex-wrap">
            <ListItemText className="md:w-full md:max-w-[180px] mr-4">
              <Box className="flex items-center">
                <LocalOfferOutlined className="fill-gray-500 mr-2" />
                <Typography variant="subtitle1">Tags</Typography>
              </Box>
            </ListItemText>
            <ListItemText>
              <Typography variant="subtitle2" className="text-left">
                {!!data && !!data.tags && data.tags.map((tag) => <TagChip key={tag.id} tag={tag} />)}
              </Typography>
            </ListItemText>
          </ListItem>
          <Divider />
        </>
      )}

      {!!data.skills?.length && (
        <ListItem className="flex flex-wrap">
          <ListItemText className="md:w-full md:max-w-[180px] mr-4">
            <Box className="flex items-center">
              <LibraryAddCheckOutlined className="fill-gray-500 mr-2" />
              <Typography variant="subtitle1">Skills</Typography>
            </Box>
          </ListItemText>
          <ListItemText>
            <Typography variant="subtitle2" className="text-left">
              {!!data && !!data.skills && data.skills.map((skill) => <StyledChip key={skill.id} label={skill.name} />)}
            </Typography>
          </ListItemText>
        </ListItem>
      )}
    </Card>
  );
};

import { API } from '@API/constants';
import { fetchData } from '@helpers/fetchData/fetchData';

const path = `${API.origin}/auth`;

export interface LoginDTO {
  email: string;
  password: string;
}

export interface InviteUserDTO {
  email: string;
  role: string;
}

export interface RegistrationDTO {
  email: string;
  first_name: string;
  last_name: string;
  password: string;
  inviteToken?: string;
}

export interface AuthResponse {
  data: {
    id: string;
    token: string;
  };
}

export interface PasswordResetDto {
  new_password: string;
  token: string;
}

export class AuthService {
  public static async login<T>(payload: LoginDTO) {
    return fetchData<T>(path, 'POST', payload);
  }

  public static async passwordRestore<T>(payload: { email: string }) {
    return fetchData<T>(`${path}/password-restore`, 'POST', payload);
  }

  public static async passwordReset<T>({ token, ...rest }: PasswordResetDto) {
    return fetchData<T>(`${path}/password-reset?refreshToken=${token}`, 'POST', rest);
  }

  public static async invite<T>(payload: InviteUserDTO) {
    return fetchData<T>(`${path}/invite`, 'POST', payload);
  }

  public static async registration<T>(payload: Omit<RegistrationDTO, 'inviteToken'>, inviteToken?: string) {
    const variant = inviteToken ? `?inviteToken=${inviteToken}` : '';
    return fetchData<T>(`${path}/registration${variant}`, 'POST', payload);
  }
}

import { AuthResponse, AuthService, LoginDTO, PasswordResetDto, RegistrationDTO } from '@API/services/auth/AuthService';
import { AfterActionProps, BaseAsyncThunkOptions } from '@app/store/interface';
import { clearAllResponseMessages } from '@app/store/store';
import { addStorageItem, removeStorageItem } from '@helpers/localStorage/localStorageService';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const login = createAsyncThunk<AuthResponse, LoginDTO, BaseAsyncThunkOptions>('auth/login', async (data, thunkApi) => {
  const response = await AuthService.login<AuthResponse>(data);
  clearAllResponseMessages();
  if (response.ok) {
    addStorageItem('token', response.data.data.token);
    return response.data;
  } else {
    return thunkApi.rejectWithValue(response.error);
  }
});

export const logout = createAsyncThunk<undefined, undefined, BaseAsyncThunkOptions>('auth/logout', async (_, thunkApi) => {
  removeStorageItem(['token', 'userInfo']);
  return thunkApi.rejectWithValue([
    {
      message: ['Please Login again'],
      name: 'Unauthorized'
    }
  ]);
});

export const registration = createAsyncThunk<AuthResponse, RegistrationDTO, BaseAsyncThunkOptions>('auth/registration', async ({ inviteToken, ...data }, thunkApi) => {
  const response = await AuthService.registration<AuthResponse>(data, inviteToken);
  if (response.ok) {
    clearAllResponseMessages();
    addStorageItem('token', response.data.data.token);
    return response.data;
  } else {
    return thunkApi.rejectWithValue(response.error);
  }
});

export const resetPassword = createAsyncThunk<unknown, PasswordResetDto & AfterActionProps, BaseAsyncThunkOptions>(
  'auth/resetPassword',
  async ({ token, new_password, afterAction }, thunkApi) => {
    const response = await AuthService.passwordReset({ token, new_password });
    if (response.ok) {
      afterAction && afterAction();
      return;
    } else {
      return thunkApi.rejectWithValue(response.error);
    }
  }
);

//TODO: TEMP.
export const restorePassword = createAsyncThunk<any, { email: string } & AfterActionProps, BaseAsyncThunkOptions>(
  'auth/restorePassword',
  async ({ email, afterAction }, thunkApi) => {
    const response = await AuthService.passwordRestore<any>({ email });
    if (response.ok) {
      window.alert(response?.data?.data);
      afterAction && afterAction();
      return;
    } else {
      return thunkApi.rejectWithValue(response.error);
    }
  }
);

import { useAppDispatch, useAppSelector } from '@app/store/store';
import { useModal } from '@hooks/useModal';
import Delete from '@mui/icons-material/Delete';
import Public from '@mui/icons-material/Public';
import { List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { deletePlatform } from '@slices/platform/thunks';
import { FC } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

export const PlatformActionsPopover: FC = () => {
  const { data: platform } = useAppSelector((store) => store.platforms.platform);
  const params = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { openModal, closeModal } = useModal();

  const deleteHandler = () => {
    if (params.id && platform) {
      openModal({
        modalName: 'CONFIRM_MODAL',
        modalProps: {
          title: 'Delete platform',
          subtitle: <>This platform will be removed. This action cannot be undone</>,
          successAction: () => {
            dispatch(
              deletePlatform({
                id: params.id!,
                afterAction: () => {
                  closeModal('CONFIRM_MODAL');
                  navigate('/platforms');
                }
              })
            );
          }
        }
      });
    }
  };

  const openWebsite = () => {
    if (platform && platform.url) {
      window.open(platform.url);
    }
  };

  return (
    <List dense className="min-w-[160px]">
      <ListItemButton onClick={openWebsite} className="text-brand mx-2 whitespace-nowrap px-2">
        <ListItemIcon className="text-brand min-w-fit mr-2">
          <Public />
        </ListItemIcon>
        <ListItemText className="uppercase">
          <Typography>Web site</Typography>
        </ListItemText>
      </ListItemButton>

      <ListItemButton onClick={deleteHandler} className="text-red-600 mx-2 whitespace-nowrap px-2">
        <ListItemIcon className="text-red-600 min-w-fit mr-2">
          <Delete />
        </ListItemIcon>
        <ListItemText className="uppercase">
          <Typography>Delete</Typography>
        </ListItemText>
      </ListItemButton>
    </List>
  );
};

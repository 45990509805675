import { useAppDispatch, useAppSelector } from '@app/store/store';
import { CenterLoader } from '@components/Loaders/CenterLoader';
import { Box } from '@mui/material';
import { getVacancy } from '@slices/vacancies/thunks';
import { vacanciesSlice } from '@slices/vacancies/vacanciesSlice';
import { FC, useEffect } from 'react';
import { Outlet, useParams } from 'react-router';

export const VacancyContainer: FC = () => {
  const { data, status } = useAppSelector((store) => store.vacancies.vacancy);
  const params = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (params.id && params.id !== data?.id) {
      dispatch(getVacancy(params.id));
    }

    return () => {
      dispatch(vacanciesSlice.actions.clearSelectedVacancy());
    };
  }, []);

  return (
    <Box>
      <Outlet />
      {status === 'loading' && !data && <CenterLoader />}
    </Box>
  );
};

interface QueriesSchema {
  [key: string]: string | number | null | undefined;
}

export const queryStringBuilder = (schema: QueriesSchema): string => {
  const query = Object.entries(schema ?? {}).reduce(
    (acc, [key, value]) => (value === '' || value === undefined || value === null ? acc : [...acc, `${key}=${value}`]),
    [] as string[]
  );
  return query.length ? `?${query.join('&')}` : '';
};

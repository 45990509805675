import { useAppDispatch, useAppSelector } from '@app/store/store';
import { useModal } from '@hooks/useModal';
import { Event } from '@mui/icons-material';
import Delete from '@mui/icons-material/Delete';
import MailOutlined from '@mui/icons-material/MailOutlined';
import { Box, Divider, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { deleteCandidate } from '@slices/candidates/thunks';
import React, { FC } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

export const CandidateActionsPopover: FC = () => {
  const { data: candidate } = useAppSelector((store) => store.candidates.candidate);
  const params = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { openModal, closeModal } = useModal();

  const deleteHandler = () => {
    if (candidate) {
      openModal({
        modalName: 'CONFIRM_MODAL',
        modalProps: {
          title: 'Delete candidate',
          subtitle: 'This candidate will be removed. This action cannot be undone',
          successAction: () => {
            if (params.id) {
              dispatch(
                deleteCandidate({
                  id: params.id,
                  afterAction: () => {
                    closeModal('CONFIRM_MODAL');
                    navigate('/candidates');
                  }
                })
              );
            }
          }
        }
      });
    }
  };

  const handleInvite = () => {
    // if (params.id) dispatch(updateVacancy({ status: 'open', id: params.id }))
  };

  const handleEventCreation = (): void => {
    openModal({
      modalName: 'CREATE_EVENT_MODAL',
      modalProps: {
        sliceType: 'candidates'
      }
    });
  };

  return (
    <Box>
      <List dense className="min-w-[160px]">
        <ListItemButton onClick={handleInvite} className="text-sky-600 mx-2 whitespace-nowrap px-2">
          <ListItemIcon className="min-w-fit mr-2">
            <MailOutlined className="fill-sky-600" />
          </ListItemIcon>
          <ListItemText className="uppercase font-bold">Send mail</ListItemText>
        </ListItemButton>
        <ListItemButton onClick={handleEventCreation} className="text-sky-600 mx-2 whitespace-nowrap px-2">
          <ListItemIcon className="min-w-fit mr-2">
            <Event className="fill-sky-600" />
          </ListItemIcon>
          <ListItemText className="uppercase font-bold">Create event</ListItemText>
        </ListItemButton>
        <Divider className="my-2" />
        <ListItemButton onClick={deleteHandler} className="text-red-500 mx-2 whitespace-nowrap px-2">
          <ListItemIcon className="text-red-500 min-w-fit mr-2">
            <Delete />
          </ListItemIcon>
          <ListItemText className="uppercase font-bold">Delete</ListItemText>
        </ListItemButton>
      </List>
    </Box>
  );
};

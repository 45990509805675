import { createSlice } from '@reduxjs/toolkit';
import { DEPARTMENTS } from '@slices/departments/interface';
import { createDepartment, deleteOneDepartment, getAllDepartments, getOneDepartment, updateDepartment } from './thunks';

const initialState: DEPARTMENTS.DepartmentsSlice = {
  status: 'unset',
  responseMessage: null,

  search: '',
  sort: '',

  departments: {
    data: [],
    total: 0
  },

  selectedDepartment: {
    status: 'unset',
    data: null
  }
};

export const departmentsSlice = createSlice({
  name: 'departments',
  initialState,
  reducers: {
    touchSearchActions: (store, { payload }: Record<'payload', Record<'search', string>>) => {
      store.search = payload.search;
    },
    setDepartmentFromAll: (store, { payload }: Record<'payload', DEPARTMENTS.Department>) => {
      store.selectedDepartment.data = payload;
      store.selectedDepartment.status = 'success';
    },
    clearResponseMessages: (state) => {
      state.responseMessage = null;
    }
  },
  extraReducers: (builder) => {
    //GET ALL
    builder.addCase(getAllDepartments.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(getAllDepartments.fulfilled, (state, { payload }) => {
      state.departments = payload;
      state.status = 'success';
    });
    builder.addCase(getAllDepartments.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.status = 'error';
    });

    //GET ONE
    builder.addCase(getOneDepartment.pending, (state) => {
      state.selectedDepartment.status = 'loading';
    });
    builder.addCase(getOneDepartment.fulfilled, (state, { payload }) => {
      state.selectedDepartment.data = payload;
      state.selectedDepartment.status = 'success';
    });
    builder.addCase(getOneDepartment.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.selectedDepartment.status = 'error';
    });

    //CREATE
    builder.addCase(createDepartment.pending, (state) => {
      state.status = 'loading';
      state.selectedDepartment.status = 'loading';
    });
    builder.addCase(createDepartment.fulfilled, (state) => {
      state.status = 'success';
      state.selectedDepartment.status = 'success';
      state.responseMessage = {
        type: 'success',
        data: [
          {
            message: 'Skill successfully added'
          }
        ]
      };
    });
    builder.addCase(createDepartment.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.status = 'error';
      state.selectedDepartment.status = 'error';
    });

    //UPDATE ONE
    builder.addCase(updateDepartment.pending, (state) => {
      state.status = 'loading';
      state.selectedDepartment.status = 'loading';
    });
    builder.addCase(updateDepartment.fulfilled, (state, { payload }) => {
      state.selectedDepartment.data = payload;
      state.departments.data = [...state.departments.data.filter((skill) => skill.id !== skill.id), payload];
      state.responseMessage = {
        type: 'success',
        data: [
          {
            message: 'Skill successfully edited'
          }
        ]
      };
      state.status = 'unset';
      state.selectedDepartment.status = 'success';
    });
    builder.addCase(updateDepartment.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.selectedDepartment.status = 'error';
    });

    //DELETE ONE
    builder.addCase(deleteOneDepartment.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(deleteOneDepartment.fulfilled, (state, { payload }) => {
      state.selectedDepartment.data = null;
      state.departments.data = state.departments.data.filter((dep) => dep.id !== payload.id);
      state.departments.total--;
      state.responseMessage = {
        type: 'success',
        data: [
          {
            message: 'Skill successfully deleted'
          }
        ]
      };
      state.selectedDepartment.status = 'unset';
      state.status = 'success';
    });
    builder.addCase(deleteOneDepartment.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.status = 'error';
    });
  }
});

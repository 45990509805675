import { useAppDispatch, useAppSelector } from '@app/store/store';
import { InputTextField } from '@components/Fields/InputText/InputTextField';
import { FormWrapper } from '@components/Forms/FormWrapper';
import { DepartmentsAddAndEditFormProps, DepartmentsAddAndEditFormValues } from '@components/Forms/interface';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button } from '@mui/material';
import { createDepartment, updateDepartment } from '@slices/departments/thunks';
import { debounce } from 'lodash';
import React, { FC, FormEvent, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

const schema = yup.object({
  name: yup.string().required('Skill name is required field'),
  comment: yup.string().nullable()
});

export const DepartmentsAddAndEditForm: FC<DepartmentsAddAndEditFormProps> = ({ className, mode }) => {
  const { data: selectedDepartment, status } = useAppSelector((store) => store.departments.selectedDepartment);

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const methods = useForm<DepartmentsAddAndEditFormValues>({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (mode === 'edit' && selectedDepartment) {
      methods.reset({
        name: selectedDepartment.name,
        comment: selectedDepartment.comment
      });
    }
  }, [mode, selectedDepartment, methods]);

  const handleReset = (e?: FormEvent): void => {
    e && e.preventDefault();
    navigate('/departments');
  };

  const submitRecipe: SubmitHandler<DepartmentsAddAndEditFormValues> = (data) => {
    const debouncedNavigate = debounce(() => navigate('/departments'), 300);

    if (mode === 'create') {
      dispatch(
        createDepartment({
          ...data,
          afterAction: () => debouncedNavigate()
        })
      );
    }

    if (mode === 'edit') {
      if (params.id) {
        dispatch(
          updateDepartment({
            ...data,
            id: params.id,
            afterAction: () => debouncedNavigate()
          })
        );
      }
    }
  };

  return (
    <FormWrapper
      className={className}
      methods={methods}
      formProps={{
        onSubmit: methods.handleSubmit(submitRecipe),
        onReset: handleReset
      }}
    >
      <InputTextField className="col-span-2" name="name" label="Name" />
      <InputTextField className="col-span-2" multiline minRows={5} name="comment" label="Comment" />

      <Box className="flex col-span-full gap-2 lg:gap-4 flex-wrap flex-row-reverse">
        <LoadingButton loading={status === 'loading'} className="py-2 px-8 w-full lg:w-fit" variant="contained" type="submit">
          Save
        </LoadingButton>
        <Button className="py-2 px-8 w-full lg:w-fit" variant="outlined" color="error" type="reset">
          Cancel
        </Button>
      </Box>
    </FormWrapper>
  );
};

import { API } from '@API/constants';
import { ResponseBaseType, ResponseListBaseType } from '@API/services/interfaces';
import { ContactsItemDTO } from '@app/store/interface';
import { fetchData } from '@helpers/fetchData/fetchData';
import { CANDIDATES } from '@slices/candidates/interface';

const path = `${API.origin}/candidates`;
export interface CreateCandidateDTO {
  first_name: string;
  email: string;
  contacts: ContactsItemDTO[];
  last_name?: string | null;
  birth_date?: string | null;
  comment?: string | null;
  city?: string | null;
  ordering?: number;
  country?: string | null;
  platform_id?: string | null;
  vacancy_id?: string | null;
  department_id?: string | null;
  cash_currency?: string | null;
  cash_salary?: string | null;
  phones?: string[];
  websites?: string[];
  skills?: string[];
  tags?: string[];
  files?: File[];
}

export interface UpdateCandidateStepDTO extends Pick<UpdateCandidateDTO, 'steps'> {
  candidate_id: string;
  vacancy_id: string;
  comment?: string;
}
export interface UpdateCandidateDTO extends Partial<CreateCandidateDTO> {
  id: string;
  steps?: UpdateCandidateStepType[];
}
export type StepsFieldsStatuses = 'pending' | 'success' | 'reject' | 'unset';
export interface UpdateCandidateStepType {
  step_id: string;
  status: StepsFieldsStatuses;
  comment?: string;
  candidate_step_comment?: string;
}

export class CandidatesService {
  public static async getAll<T = CANDIDATES.Candidate[]>(query?: string) {
    return fetchData<ResponseListBaseType<T>>(`${path}${query ?? ''}`);
  }
  public static async getOne<T = CANDIDATES.Candidate>(query: string) {
    return fetchData<ResponseBaseType<T>>(`${path}/${query ?? ''}`);
  }
  public static async create<T = CANDIDATES.Candidate>(data: CreateCandidateDTO) {
    return fetchData<ResponseBaseType<T>>(`${path}`, 'POST', data);
  }
  public static async update<T = CANDIDATES.Candidate>({ id, ...data }: UpdateCandidateDTO) {
    return fetchData<ResponseBaseType<T>>(`${path}/${id}`, 'PATCH', data);
  }
  public static async delete<T = CANDIDATES.Candidate>(id: string) {
    return fetchData<ResponseBaseType<T>>(`${path}/${id}`, 'DELETE');
  }
}

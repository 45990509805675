import React, { FC } from 'react';
import { AvatarToolItem } from './Avatar/AvatarToolItem';

interface HeaderRightSectionProps {}

export const HeaderRightSection: FC<HeaderRightSectionProps> = () => {
  return (
    <>
      <AvatarToolItem />
    </>
  );
};

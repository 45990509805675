import { RootModal } from '@components/Modals/RootModal/RootModal';
import React, { createContext, FC, ReactNode, useState } from 'react';
import { modalsList, OpenModalType } from 'src/components/Modals/RootModal/modalsList';

type Modals = keyof typeof modalsList;

interface ModalsContextProps {
  children?: ReactNode;
}

export interface ModalsList {
  modalName: Modals;
  modalProps?: any;
}

interface ContextProps {
  openModals: ModalsList[];
  openModal: (params: OpenModalType) => void;
  closeModal: (modalName: Modals | Modals[]) => void;
}

/*
 * Контекст
 * */
export const ModalsContext = createContext<ContextProps>({
  openModals: [],
  openModal: () => {},
  closeModal: () => {}
});

/*
 * HOC.
 * Нужен для оборачивания рут компонента, внедрения рутовой модалки и хранения состояния
 * */
export const ModalsContextProvider: FC<ModalsContextProps> = ({ children }) => {
  const [openModals, setOpenModals] = useState<ModalsList[]>([]);

  const openModal = (params: OpenModalType): void => {
    setOpenModals([...openModals, params]);
  };

  const closeModal = (modalName: Modals | Modals[]): void => {
    const processed = Array.isArray(modalName) ? openModals.filter((modal) => !modalName.includes(modal.modalName)) : openModals.filter((modal) => modal.modalName !== modalName);
    setOpenModals(processed);
  };

  return (
    <ModalsContext.Provider value={{ openModals, openModal, closeModal }}>
      {children}
      <RootModal />
    </ModalsContext.Provider>
  );
};

import { ChangePasswordDTO } from '@API/services/profile/profileService';
import { useAppDispatch, useAppSelector } from '@app/store/store';
import { PasswordField } from '@components/Fields/Password/PasswordField';
import { FormWrapper } from '@components/Forms/FormWrapper';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Card, CardHeader, Divider, Typography } from '@mui/material';
import { changePassword } from '@slices/profile/thunks';
import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

const schema = yup
  .object({
    password: yup.string().required('New password is required field'),
    confirm_password: yup
      .string()
      .required('Confirm password is required field')
      .test('match', 'Passwords are not equal', function(new_pass) {
        const { password } = this.parent;
        return password === new_pass;
      })
  })
  .required();

export const ChangePasswordTab: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { status } = useAppSelector((store) => store.profile);

  const methods = useForm<ChangePasswordDTO & Record<'confirm_password', string>>({
    resolver: yupResolver(schema),
    mode: 'onChange'
  });

  const navigateHandler = (): void => {
    navigate('/', { replace: true });
  };

  const submitRecipe: SubmitHandler<ChangePasswordDTO> = (data) => {
    dispatch(
      changePassword({
        password: data.password
      })
    );
  };

  return (
    <Card className="shadow">
      <CardHeader title={<Typography variant="h6">Change Password</Typography>} />
      <Divider />
      <FormWrapper
        methods={methods}
        className=""
        formProps={{
          onSubmit: methods.handleSubmit(submitRecipe)
        }}
      >
        <Box className="px-4 py-8 grid gap-4 xl:grid-cols-2 grid-cols-1">
          <PasswordField className="" name="password" label="New password" />
          <PasswordField className="" name="confirm_password" label="Confirm new password" />
        </Box>

        <Divider />

        <Box className="p-4 flex gap-2 lg:gap-4 flex-wrap flex-row-reverse">
          <LoadingButton loading={status === 'loading'} className="lg:w-fit w-full py-2 px-8" variant="contained" type="submit">
            Save
          </LoadingButton>
          <Button className="lg:w-fit w-full py-2 px-8" onClick={navigateHandler} variant="outlined" color="error">
            cancel
          </Button>
        </Box>
      </FormWrapper>
    </Card>
  );
};

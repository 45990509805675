import { RequestStatus } from '@app/store/interface';
import { EventListItem } from '@components/Events/ListItem/ListItem';
import { RoundedLoader } from '@components/Loaders/RoundedLoader';
import { inMomentSelector } from '@helpers/date/datesHelpers';
import { useModal } from '@hooks/useModal';
import AddRounded from '@mui/icons-material/AddRounded';
import { Card, CardHeader, Divider, FormControlLabel, IconButton, Switch } from '@mui/material';
import { EVENTS } from '@slices/events/interface';
import { DefaultComponentProps } from 'custom';
import { sortBy } from 'lodash';
import React, { FC, useMemo, useState } from 'react';

interface EventsSectionProps extends DefaultComponentProps {
  events: (EVENTS.Event & EVENTS.EventRelations)[];
  status?: RequestStatus;
}

export const EventsSection: FC<EventsSectionProps> = ({ events, status }) => {
  const { openModal } = useModal();
  const [includePastEvents, setIncludePastEvents] = useState(false);
  const pastEvents = useMemo(() => events.filter(({ start, end }) => inMomentSelector(start, end) === 'past'), [events]);
  const actualEvents = useMemo(() => events.filter(({ start, end }) => inMomentSelector(start, end) !== 'past'), [events]);

  const addEventHandler = (): void => {
    openModal({
      modalName: 'CREATE_EVENT_MODAL',
      modalProps: {
        sliceType: 'candidates'
      }
    });
  };

  const includePastEventsHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setIncludePastEvents(event.target.checked);
  };

  return (
    <Card className="shadow w-full relative">
      <CardHeader
        action={
          <IconButton onClick={addEventHandler}>
            <AddRounded />
          </IconButton>
        }
        title="Events"
        subheader={!!pastEvents.length ? <FormControlLabel control={<Switch onChange={includePastEventsHandler} />} label="Include past" /> : undefined}
        className="uppercase text-gray-600 py-3"
      />
      {!!events.length && (
        <>
          <Divider />

          {actualEvents && sortBy(actualEvents, (value) => value.start).map((event) => <EventListItem sliceType="candidates" key={event.id} {...event} />)}
          {includePastEvents && <Divider />}
          {includePastEvents &&
            pastEvents &&
            sortBy(pastEvents, (value) => value.start)
              .reverse()
              .map((event) => <EventListItem sliceType="candidates" key={event.id} {...event} />)}
        </>
      )}
      {status === 'loading' && <RoundedLoader />}
    </Card>
  );
};

import { Box } from '@mui/material';
import { FC } from 'react';
import { Outlet } from 'react-router';

export const UsersModule: FC = () => {
  return (
    <Box className="flex flex-col h-full">
      <Outlet />
    </Box>
  );
};

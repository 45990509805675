import { CreateVacancyDTO, UpdateVacancyDTO, VacanciesService } from '@API/services/vacancies/vacanciesService';
import { AfterActionProps, BaseAsyncThunkOptions, ListResponse } from '@app/store/interface';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { VACANCIES } from '@slices/vacancies/interface';

export const addNewVacancy = createAsyncThunk<VACANCIES.Vacancy, CreateVacancyDTO & AfterActionProps<string>, BaseAsyncThunkOptions>(
  'vacancies/create',
  async ({ afterActionWithParams, ...payload }, thunkApi) => {
    const response = await VacanciesService.create(payload);
    if (response.ok) {
      afterActionWithParams && afterActionWithParams(response.data.data.id);
      return response.data.data;
    } else {
      return thunkApi.rejectWithValue(response.error);
    }
  }
);

export const updateVacancy = createAsyncThunk<VACANCIES.Vacancy, UpdateVacancyDTO & AfterActionProps<string>, BaseAsyncThunkOptions>(
  'vacancies/update',
  async ({ afterActionWithParams, id, ...payload }, thunkApi) => {
    const response = await VacanciesService.update(id, payload);
    if (response.ok) {
      afterActionWithParams && afterActionWithParams(response.data.data.id);
      return response.data.data;
    } else {
      return thunkApi.rejectWithValue(response.error);
    }
  }
);

export const getVacancies = createAsyncThunk<ListResponse<VACANCIES.VacancyListItem[]>, string | undefined, BaseAsyncThunkOptions>('vacancies/getMany', async (query, thunkApi) => {
  const response = await VacanciesService.getMany(query);
  if (response.ok) {
    return {
      data: response.data.data,
      total: response.data.total
    };
  } else {
    return thunkApi.rejectWithValue(response.error);
  }
});

export const getVacancy = createAsyncThunk<VACANCIES.Vacancy, string, BaseAsyncThunkOptions>('vacancies/getOne', async (query, thunkApi) => {
  const response = await VacanciesService.getOne(query);
  if (response.ok) {
    return response.data.data;
  } else {
    return thunkApi.rejectWithValue(response.error);
  }
});

export const deleteVacancy = createAsyncThunk<Omit<VACANCIES.Vacancy, 'steps' | 'user' | 'department' | 'platforms'>, { id: string } & AfterActionProps, BaseAsyncThunkOptions>(
  'vacancies/delete',
  async ({ id, afterAction }, { rejectWithValue, dispatch }) => {
    const response = await VacanciesService.delete(id);
    if (response.ok) {
      afterAction && afterAction();
      return response.data.data;
    } else {
      dispatch(getVacancies(''));
      return rejectWithValue(response.error);
    }
  }
);

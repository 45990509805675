import { CandidatesService, CreateCandidateDTO, UpdateCandidateDTO, UpdateCandidateStepDTO } from '@API/services/candidates/candidatesService';
import { FilesService } from '@API/services/files/FilesService';
import { RemoveCandidateFileDTO, UploadCandidatesFilesDTO } from '@API/services/files/interface';
import { VacanciesService } from '@API/services/vacancies/vacanciesService';
import { AfterActionProps, BaseAsyncThunkOptions, ListResponse } from '@app/store/interface';
import { compareSteps } from '@app/store/reduxHelpers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { candidatesSlice } from '@slices/candidates/candidatesSlice';
import { CANDIDATES } from '@slices/candidates/interface';
import { AxiosError, CanceledError } from 'axios';

const vacancySecondaryRequest = async (data: CANDIDATES.Candidate, afterAction?: () => void): Promise<CANDIDATES.Candidate> => {
  const vacancies = data.vacancy ? await VacanciesService.getOne(data.vacancy.id) : undefined;
  afterAction && afterAction();

  return vacancies?.ok
    ? {
        ...data,
        steps: compareSteps(vacancies.data.data.steps || [], data.candidate_step || []),
        vacancy: vacancies.data.data,
        department: vacancies.data.data.department
      }
    : {
        ...data
      };
};

export const uploadCandidateFiles = createAsyncThunk<unknown, UploadCandidatesFilesDTO & AfterActionProps, BaseAsyncThunkOptions>(
  'candidate/files-upload',
  async ({ files, candidate_id, afterAction }, { rejectWithValue, dispatch }) => {
    try {
      const response = await FilesService.uploadCandidateFiles(
        {
          candidate_id,
          files: files ?? []
        },
        ({ loaded, total }: { loaded: number; total: number }) => {
          dispatch(candidatesSlice.actions.updateFileUploadStatus({ loaded, total }));
        }
      );
      if (response.status >= 200 && response.status <= 399) {
        afterAction && afterAction();
        dispatch(getCandidate(candidate_id));
      }
    } catch (err: unknown | AxiosError) {
      if (err instanceof CanceledError) {
        return rejectWithValue([
          {
            name: 'Canceled',
            message: ['Upload canceled by user']
          }
        ]);
      }
      return rejectWithValue([
        {
          name: 'Uploading error',
          message: ['Uncaught server error, please try again']
        }
      ]);
    }
  }
);

export const removeCandidateFile = createAsyncThunk<unknown, RemoveCandidateFileDTO & AfterActionProps, BaseAsyncThunkOptions>(
  'candidate/files-remove',
  async ({ file_id, afterAction }, { rejectWithValue }) => {
    const response = await FilesService.removeCandidateFiles({
      file_id
    });
    if (response.ok) {
      afterAction && afterAction();
    } else {
      return rejectWithValue(response.error);
    }
  }
);

export const createCandidate = createAsyncThunk<CANDIDATES.Candidate, CreateCandidateDTO & AfterActionProps<string>, BaseAsyncThunkOptions>(
  'candidate/create',
  async ({ afterActionWithParams, files, ...data }, { rejectWithValue, dispatch }) => {
    const response = await CandidatesService.create(data);
    if (response.ok) {
      if (files && files.length) {
        dispatch(
          uploadCandidateFiles({
            candidate_id: response.data.data.id,
            files: files ?? [],

            afterAction: () => {
              afterActionWithParams && afterActionWithParams(response.data.data.id);
            }
          })
        );
      }

      return vacancySecondaryRequest(response.data.data, () => {
        if ((!files || !files.length) && afterActionWithParams) {
          afterActionWithParams(response.data.data.id);
        }
      });
    } else {
      return rejectWithValue(response.error);
    }
  }
);

export const updateCandidate = createAsyncThunk<CANDIDATES.Candidate, UpdateCandidateDTO & AfterActionProps<string>, BaseAsyncThunkOptions>(
  'candidate/update',
  async ({ afterActionWithParams, files, ...payload }, { rejectWithValue, dispatch }) => {
    const response = await CandidatesService.update(payload);
    if (response.ok) {
      if (files && files.length) {
        dispatch(
          uploadCandidateFiles({
            candidate_id: response.data.data.id,
            files: files,

            afterAction: () => {
              afterActionWithParams && afterActionWithParams(payload.id);
            }
          })
        );
      }

      const data = response.data.data;
      if ((!files || !files.length) && afterActionWithParams) {
        afterActionWithParams(response.data.data.id);
      }
      return vacancySecondaryRequest(data);
    } else {
      return rejectWithValue(response.error);
    }
  }
);

export const updateCandidateStep = createAsyncThunk<CANDIDATES.Candidate['steps'], UpdateCandidateStepDTO & AfterActionProps, BaseAsyncThunkOptions>(
  'candidate/updateStep',
  async ({ afterAction, ...data }, { rejectWithValue, getState }) => {
    const response = await CandidatesService.update({
      id: data.candidate_id,
      vacancy_id: data.vacancy_id,
      steps: data.steps
    });
    const steps = getState().candidates.candidate.data?.steps;

    if (response.ok) {
      afterAction && afterAction();
      return compareSteps(steps || [], response.data.data?.candidate_step || []);
    } else {
      return rejectWithValue(response.error);
    }
  }
);

export const getCandidates = createAsyncThunk<ListResponse<CANDIDATES.Candidate[]>, string | undefined, BaseAsyncThunkOptions>(
  'candidate/getMany',
  async (query, { rejectWithValue }) => {
    const response = await CandidatesService.getAll(query);

    if (response.ok) {
      return {
        data: response.data.data,
        total: response.data.total
      };
    } else {
      return rejectWithValue(response.error);
    }
  }
);

export const getCandidate = createAsyncThunk<CANDIDATES.Candidate, string, BaseAsyncThunkOptions>('candidate/getOne', async (query, { rejectWithValue }) => {
  const response = await CandidatesService.getOne(query);
  if (response.ok) {
    const data = response.data.data;
    return vacancySecondaryRequest(data);
  } else {
    return rejectWithValue(response.error);
  }
});

export const deleteCandidate = createAsyncThunk<CANDIDATES.Candidate, { id: string } & AfterActionProps, BaseAsyncThunkOptions>(
  'candidate/delete',
  async ({ id, afterAction }, { rejectWithValue }) => {
    const response = await CandidatesService.delete(id);

    if (response.ok) {
      afterAction && afterAction();
      return response.data.data;
    } else {
      return rejectWithValue(response.error);
    }
  }
);

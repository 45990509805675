import { FormWrapperProps } from '@components/Forms/interface';
import React, { FC } from 'react';
import { FormProvider } from 'react-hook-form';

export const FormWrapper: FC<FormWrapperProps> = ({ methods, children, className, formProps = {} }) => {
  return (
    <FormProvider {...methods}>
      <form className={className} {...formProps}>
        {children}
      </form>
    </FormProvider>
  );
};

import { createSlice } from '@reduxjs/toolkit';
import { PLATFORMS } from './interface';
import { createPlatform, deletePlatform, getPlatform, getPlatforms, updatePlatform } from './thunks';

const initialState: PLATFORMS.PlatformsSlice = {
  status: 'unset',
  responseMessage: null,

  platforms: {
    search: '',
    filters: [],
    rowsCount: 25,
    page: 0,

    list: {
      data: [],
      total: 0
    }
  },
  platform: {
    status: 'unset',
    data: null
  }
};

export const platformsSlice = createSlice({
  name: 'platforms',
  initialState,
  reducers: {
    touchSearchActions: (store, { payload }: { payload: { filters?: string[]; search?: string } }) => {
      if (payload.search !== undefined) {
        store.platforms.search = payload.search;
      }
      if (payload.filters !== undefined) {
        store.platforms.filters = payload.filters;
      }
    },
    touchPagination: (store, { payload }: { payload: Partial<Record<'page' | 'rowsCount', number>> }) => {
      if (typeof payload.page === 'number') {
        store.platforms.page = payload.page;
      }
      if (typeof payload.rowsCount === 'number') {
        store.platforms.rowsCount = payload.rowsCount;
      }
    },
    clearResponseMessages: (state) => {
      state.responseMessage = null;
    },
    clearSelectedPlatform: (state) => {
      state.platform.data = null;
      state.platform.status = 'unset';
    }
  },
  extraReducers: (builder) => {
    //GET ALL
    builder.addCase(getPlatforms.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(getPlatforms.fulfilled, (state, { payload }) => {
      state.platforms.list = payload;
      state.status = 'success';
    });
    builder.addCase(getPlatforms.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.status = 'error';
    });

    //GET ONE
    builder.addCase(getPlatform.pending, (state) => {
      state.platform.status = 'loading';
    });
    builder.addCase(getPlatform.fulfilled, (state, { payload }) => {
      state.platform.data = payload;
      state.platform.status = 'success';
    });
    builder.addCase(getPlatform.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.platform.status = 'error';
    });

    //CREATE
    builder.addCase(createPlatform.pending, (state) => {
      state.status = 'loading';
      state.platform.status = 'loading';
    });
    builder.addCase(createPlatform.fulfilled, (state) => {
      state.responseMessage = {
        type: 'success',
        data: [
          {
            message: 'Platform successfully added'
          }
        ]
      };
      state.status = 'success';
      state.platform.status = 'success';
    });
    builder.addCase(createPlatform.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.status = 'error';
      state.platform.status = 'error';
    });

    //UPDATE ONE
    builder.addCase(updatePlatform.pending, (state) => {
      state.platform.status = 'loading';
    });
    builder.addCase(updatePlatform.fulfilled, (state, { payload }) => {
      state.platform.data = payload;
      state.platforms.list.data = [...state.platforms.list.data.filter((platform) => platform.id !== payload.id), payload];
      state.responseMessage = {
        type: 'success',
        data: [
          {
            message: 'Platform successfully edited'
          }
        ]
      };
      state.platform.status = 'success';
    });
    builder.addCase(updatePlatform.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.platform.status = 'error';
    });

    //DELETE ONE
    builder.addCase(deletePlatform.pending, (state) => {
      state.status = 'loading';
      state.platform.status = 'loading';
    });
    builder.addCase(deletePlatform.fulfilled, (state, { payload }) => {
      state.platform.data = null;
      state.platforms.list.data = state.platforms.list.data.filter((platform) => platform.id !== payload.id);
      state.platforms.list.total--;
      state.responseMessage = {
        type: 'success',
        data: [
          {
            message: 'Platform successfully deleted'
          }
        ]
      };
      state.platform.status = 'success';
      state.status = 'success';
    });
    builder.addCase(deletePlatform.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.platform.status = 'error';
      state.status = 'error';
    });
  }
});

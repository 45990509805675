import { useAppDispatch, useAppSelector } from '@app/store/store';
import { RoundedLoader } from '@components/Loaders/RoundedLoader';
import { NoDataPlug } from '@components/Plugs/NoDataPlug';
import { DashboardHistoryStepperProps } from '@modules/dashboard/interface';
import { AccessTimeRounded, CircleOutlined } from '@mui/icons-material';
import { Box, Divider, Paper, Step, StepContent, StepLabel, Stepper, Tab, Tabs, Typography } from '@mui/material';
import { getAllHistory } from '@slices/dashboard/thunks';
import classNames from 'classnames';
import { format, isValid } from 'date-fns';
import { FC, Fragment, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export const DashboardHistoryStepper: FC<DashboardHistoryStepperProps> = ({ className }) => {
  const { list: historiesList, status: history_status } = useAppSelector((store) => store.dashboard.histories);
  const {
    steps: { data: stepsList },
    status: steps_status
  } = useAppSelector((store) => store.steps);
  const [history, setHistory] = useState<{ [key: string]: any }>({});
  const [valueHistory, setValueHistory] = useState('today');

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllHistory({ period: valueHistory }));
  }, [valueHistory, dispatch]);

  useEffect(() => {
    const compareHistory = historiesList.reduce((acc: { [key: string]: any }, item) => {
      const date = isValid(new Date(item.created_at)) && format(new Date(item.created_at), 'HH:mm');

      if (!date) {
        return acc;
      }

      if (acc[date]) {
        if (acc[date][item.candidate_id]) {
          acc[date][item.candidate_id] = [...acc[date][item.candidate_id], item];
        } else {
          acc[date] = { ...acc[date], [item.candidate_id]: [item] };
        }
      } else {
        acc[date] = { [item.candidate_id]: [item] };
      }

      return acc;
    }, {});

    setHistory(compareHistory);
  }, [historiesList]);

  const handleChangeHistory = useCallback((event: SyntheticEvent, newValue: string) => {
    setValueHistory(newValue);
  }, []);
  return (
    <Box className={classNames('inline-flex items-start', className)}>
      <Paper className="pb-4 shadow gap-4 w-full flex flex-col max-h-full h-full relative">
        <Tabs value={valueHistory} onChange={handleChangeHistory}>
          <Tab value="today" label="Today" />
          <Tab value="yesterday" label="Yesterday" />
        </Tabs>
        <Divider className="-mt-4 -mx-2 relative -top-[1px]" />

        <Box className="z-10 flex-1 basis-auto overflow-y-auto">
          <Stepper orientation="vertical" className="h-full pl-6 pr-4" connector={null}>
            {Object.keys(history).map((item, index) => (
              <Step active key={item + index} className="flex items-start">
                <Box>
                  {Object.keys(history[item]).map((candidateId, index) => (
                    <Fragment key={index}>
                      <StepLabel
                        icon={
                          <Box className="flex items-center">
                            <Typography className="flex gap-2 items-center mr-2 py-1 px-1 pr-2 rounded-full -ml-1.5 border-brand border-solid border-2 text-brand font-bold">
                              <AccessTimeRounded />
                              {item}
                            </Typography>
                          </Box>
                        }
                      >
                        <Typography
                          className={classNames('overflow-ellipsis whitespace-nowrap block py-1 px-4 border-solid border-2 rounded-full -ml-2 text-gray-700', {
                            'bg-gray-100 border-gray-100': !!history[item][candidateId][0].candidate,
                            'bg-red-100 border-red-300': !history[item][candidateId][0].candidate
                          })}
                        >
                          {!!history[item][candidateId][0].candidate && (
                            <Link className="text-gray-700 mx-1 no-underline hover:underline" to={`/candidates/${history[item][candidateId][0].candidate?.id ?? ''}`}>
                              <b>
                                {history[item][candidateId][0].candidate?.first_name} {history[item][candidateId][0].candidate?.last_name ?? ''}{' '}
                                <span className="font-normal">changed: </span>
                              </b>
                            </Link>
                          )}
                          {!history[item][candidateId][0].candidate && (
                            <span className="text-red-700 font-bold mx-1 no-underline">
                              deleted candidate <span className="font-normal">changed: </span>
                            </span>
                          )}
                        </Typography>
                      </StepLabel>
                      <StepContent className="pl-4 text-gray-500">
                        <Box className="py-0">
                          {history[item][candidateId].map((history: { relations: any; target: string; field: string; new_value: string; old_value: string }) => {
                            if (history.target) {
                              if (history.target === 'candidates_steps') {
                                return (
                                  <Box key={Math.random()} className="flex items-center my-2 -ml-[23px]">
                                    <CircleOutlined className="fill-gray-400 bg-white -mt-0.5 rounded-full w-[13px] h-[13px] mr-2" />
                                    <Typography className="text-gray-500" variant="subtitle2">
                                      The step
                                      <b className="uppercase"> {stepsList.find(({ id }) => id === history.relations[history.new_value]?.step_id)?.name} </b>
                                      status is changed to
                                      <b className="uppercase"> {history.relations[history.new_value]?.status} </b>
                                    </Typography>
                                  </Box>
                                );
                              } else if (history.target === 'contacts') {
                                return (
                                  <Box key={Math.random()} className="flex items-center my-2 -ml-[23px]">
                                    <CircleOutlined className="fill-gray-400 bg-white -mt-0.5 rounded-full w-[13px] h-[13px] mr-2" />
                                    <Typography className="text-gray-500" variant="subtitle2">
                                      <span className="capitalize underline">{history.relations[history.new_value].type}</span>
                                      <span> to </span>
                                      <b>{history.relations[history.new_value].value}</b>
                                    </Typography>
                                  </Box>
                                );
                              } else {
                                return (
                                  <Box key={Math.random()} className="flex items-center my-2 -ml-[23px]">
                                    <CircleOutlined className="bg-white fill-gray-400 rounded-full -mt-0.5 w-[13px] h-[13px] mr-2" />
                                    <Typography className="text-gray-500" variant="subtitle2">
                                      <span className="capitalize underline">{history.field.includes('_id') ? history.field.split('_id')[0] : history.field}</span>
                                      <span> to </span>
                                      <b>{history.relations[history.new_value]?.name}</b>
                                    </Typography>
                                  </Box>
                                );
                              }
                            } else {
                              return (
                                <Box key={Math.random()} className="flex items-center my-2 -ml-[23px]">
                                  <CircleOutlined className="bg-white fill-gray-400 rounded-full -mt-0.5 w-[13px] h-[13px] mr-2" />
                                  <Typography className="text-gray-500" variant="subtitle2">
                                    <span className="capitalize underline">{history.field.includes('_') ? history.field.split('_').join(' ') : history.field}</span>
                                    <span> to </span>
                                    <b>{history.new_value}</b>
                                  </Typography>
                                </Box>
                              );
                            }
                          })}
                          <Box className="mt-4">
                            <Typography component={Link} to={`/users/${history[item][candidateId][0].user.id}`} className="text-gray-500 no-underline group" variant="caption">
                              <span className="text-gray-300">Lead by: </span>
                              <span className="group-hover:underline">
                                {`${history[item][candidateId][0].user.first_name} ${history[item][candidateId][0].user.last_name ?? ''}`}
                              </span>
                            </Typography>
                          </Box>
                        </Box>
                      </StepContent>
                    </Fragment>
                  ))}
                </Box>
              </Step>
            ))}
            {historiesList && !historiesList.length && <NoDataPlug className="relative" />}
          </Stepper>
        </Box>
        {(steps_status === 'loading' || history_status === 'loading') && <RoundedLoader />}
      </Paper>
    </Box>
  );
};

import { useAppDispatch, useAppSelector } from '@app/store/store';
import { InputTextField } from '@components/Fields/InputText/InputTextField';
import { FormWrapper } from '@components/Forms/FormWrapper';
import { FiltersFormData, PlatformsSearchProps } from '@modules/platfom/interface';
import Search from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import { platformsSlice } from '@slices/platform/platformSlice';
import classNames from 'classnames';
import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';

export const PlatformsSearch: FC<PlatformsSearchProps> = ({ className }) => {
  const { filters, search } = useAppSelector((store) => store.platforms.platforms);
  const dispatch = useAppDispatch();
  const methods = useForm<FiltersFormData>({
    mode: 'onChange',
    defaultValues: {
      filters,
      search
    }
  });

  useEffect(() => {
    const subscription = methods.watch(({ search }) => {
      dispatch(platformsSlice.actions.touchSearchActions({ search }));
      // dispatch(candidatesSlice.actions.touchPagination({ page: 0 }));
    });
    return () => subscription.unsubscribe();
  }, [methods, dispatch]);

  return (
    <FormWrapper className={classNames(className, 'flex gap-2 flex-wrap mb-4')} methods={methods}>
      <InputTextField
        className="flex-1 min-w-[200px]"
        name="search"
        placeholder="Search platforms"
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          )
        }}
      />
    </FormWrapper>
  );
};

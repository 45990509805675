import { FilesService } from '@API/services/files/FilesService';
import { useAppDispatch, useAppSelector } from '@app/store/store';
import { FileCard } from '@components/Cards/File/FileCard';
import { CreateAndEditeEventForm } from '@components/Forms/Events/CreateAndEditeEventForm';
import { CenterLoader } from '@components/Loaders/CenterLoader';
import { EventDateSection } from '@components/Modals/Event/components/EventDateSection';
import { MembersSection } from '@components/Modals/Event/components/MembersSection';
import { EventModalParams } from '@components/Modals/Event/interface';
import { useModal } from '@hooks/useModal';
import DeleteOutlineRounded from '@mui/icons-material/DeleteOutlineRounded';
import EditRounded from '@mui/icons-material/EditRounded';
import EventIcon from '@mui/icons-material/Event';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Collapse, Divider, IconButton, Link, List, Paper, Typography } from '@mui/material';
import { eventsSlice } from '@slices/events/eventsSlice';
import { deleteEvent, getEvent } from '@slices/events/thunks';
import classNames from 'classnames';
import { format, isValid } from 'date-fns';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { BaseModalProps } from '../RootModal/interface';
import { ModalWrapper } from '../RootModal/RootModal';

interface EventModalProps extends BaseModalProps {
  data: EventModalParams['modalProps'];
}

export const EventModal: FC<EventModalProps> = ({ data: { sliceType, event_id, event_date }, ...modalProps }) => {
  const dispatch = useAppDispatch();
  const { closeModal } = useModal();
  const { viewData: data, status } = useAppSelector((store) => store.events);

  const owner = useMemo(() => {
    const candidate = data?.user_event?.find((users) => users.role === 'owner');
    return candidate ? data?.members?.find((member) => member.id === candidate.user_id) : null;
  }, [data?.members, data?.user_event]);
  const [deleteExpanded, setDeleteExpanded] = useState(true);
  const [editExpanded, setEditExpanded] = useState(true);

  useEffect(() => {
    dispatch(getEvent({ id: event_id }));

    return () => {
      dispatch(eventsSlice.actions.clearViewData());
    };
  }, []);

  const handleDelete = (): void => {
    if (data) {
      dispatch(
        deleteEvent({
          id: data.id,
          sliceType,
          event_date,
          afterAction: () => closeModal(['EVENT_VIEW_MODAL'])
        })
      );
    }
  };

  const editExpandingHandler = (): void => {
    setEditExpanded(!editExpanded);
    setDeleteExpanded(true);
  };
  const deleteExpandingHandler = (): void => {
    setDeleteExpanded(!deleteExpanded);
    setEditExpanded(true);
  };
  const successActionHandler = (): void => {
    setEditExpanded(true);
  };

  return (
    <ModalWrapper {...modalProps}>
      <Paper className="max-w-[600px] mx-2 shadow-2xl overflow-hidden relative">
        <Box className={classNames('bg-brand relative text-white p-6')}>
          <Box className={classNames('flex gap-2 items-start relative', { 'mb-2': data?.start || data?.end })}>
            <Box className={classNames('w-8 absolute h-full rounded-md shrink-0', `bg-${data?.color ?? 'gray'}-500`)} />
            <Typography className="leading-none font-bold uppercase ml-10" variant="h4">
              {data?.title ?? ''}
            </Typography>
          </Box>
          {(data?.start || data?.end) && (
            <Box className="flex items-center mb-4">
              <Typography variant="subtitle1" className="">
                {data?.start && isValid(new Date(data.start)) && format(new Date(data.start), 'iiii d')}
              </Typography>
              {data?.start && (
                <Typography variant="subtitle1" className="mx-1">
                  ·
                </Typography>
              )}
              <Typography variant="subtitle1">{data?.start && isValid(new Date(data.start)) ? format(new Date(data.start), 'HH:mm') : '--:--'}</Typography>
              <Divider className="w-4 mx-2 bg-white" />
              <Typography variant="subtitle1">{data?.end && isValid(new Date(data.end)) ? format(new Date(data.end), 'HH:mm') : '--:--'}</Typography>
            </Box>
          )}

          <Box className="flex gap-2 justify-end absolute bottom-2 right-4 z-10">
            {data?.moment !== 'past' && (
              <IconButton className={classNames('group hover:bg-white', { 'bg-white': !editExpanded })} disableRipple size="small" onClick={editExpandingHandler}>
                <EditRounded
                  className={classNames('group-hover:fill-brand', {
                    'fill-brand': !editExpanded,
                    'fill-white': editExpanded
                  })}
                  fontSize="small"
                />
              </IconButton>
            )}
            <IconButton onClick={deleteExpandingHandler} className={classNames('group hover:bg-white', { 'bg-white': !deleteExpanded })} disableRipple size="small">
              <DeleteOutlineRounded
                className={classNames('group-hover:fill-red-600', {
                  'fill-red-600': !deleteExpanded,
                  'fill-white': deleteExpanded
                })}
                fontSize="small"
              />
            </IconButton>
          </Box>

          <EventIcon className="absolute right-0 top-0 opacity-5 w-fit h-full transform rotate-45" />
        </Box>
        <Box className="p-6 flex flex-col">
          <Collapse in={deleteExpanded && editExpanded}>
            <Box className="gap-6 flex flex-col">
              {data && <EventDateSection start={data.start} end={data.end} />}

              {!!data?.members?.length && <MembersSection members={data.members} />}

              {data && !!data.files?.length && (
                <Box className="flex gap-1 items-start w-full">
                  <Typography className="text-gray-500 flex mt-1.5 items-center shrink-0" variant="subtitle2">
                    <span className="text-gray-300 mr-1">Attachments: </span>
                  </Typography>
                  <List className="py-0 w-full">
                    {data.files.map((file) => (
                      <FileCard
                        className="border-none px-2 w-full bg-gray-100"
                        key={file.id}
                        filename={`${file.name}${file.format}`}
                        size={file.size}
                        downloadable
                        downloadHandler={() =>
                          FilesService.downloadCandidateFile({
                            file_id: file.id,
                            filename: `${file.name}${file.format}`
                          })
                        }
                        created_at={file.created_at}
                        format={file.format}
                      />
                    ))}
                  </List>
                </Box>
              )}

              {data?.description && (
                <Box className="flex gap-1 items-start w-full">
                  <Typography className="text-gray-500 flex -mt-0.5 items-center shrink-0" variant="subtitle2">
                    <span className="text-gray-300 mr-1">Description: </span>
                  </Typography>
                  <Typography variant="body2">{data.description}</Typography>
                </Box>
              )}

              {owner && (
                <Typography className="text-gray-500" variant="subtitle2">
                  <span className="text-gray-300">Lead by: </span>
                  <Link target="_blank" className="text-gray-500 no-underline hover:underline" href={`${window.location.origin}/users/${owner.id}`}>
                    {`${owner.first_name} ${owner.last_name ?? ''}`}
                  </Link>
                </Typography>
              )}
            </Box>
            <Box className="flex gap-2 justify-end md:flex-nowrap flex-wrap">
              <Button disabled={!data?.meet_link} component={Link} href={data?.meet_link} target="_blank" className="py-2 px-4 w-full md:w-fit" variant="contained">
                go to meeting
              </Button>
            </Box>
          </Collapse>

          <Collapse in={!deleteExpanded}>
            <Typography className="mb-3" variant="subtitle1">
              Are you sure you want to delete this event?
            </Typography>
            <Box className="flex gap-2 justify-end md:flex-nowrap flex-wrap">
              <Button onClick={() => setDeleteExpanded(!deleteExpanded)} className="py-2 px-4 w-full md:w-fit" variant="outlined">
                cancel
              </Button>

              <LoadingButton onClick={handleDelete} loading={status === 'loading'} className="py-2 px-4 w-full md:w-fit" variant="contained" color="error">
                delete
              </LoadingButton>
            </Box>
          </Collapse>

          <Collapse in={!editExpanded}>
            <CreateAndEditeEventForm successAction={successActionHandler} sliceType={sliceType} mode="edit" cancelAction={successActionHandler} />
          </Collapse>
        </Box>
        {status === 'loading' && !data && <CenterLoader className="bg-white" />}
      </Paper>
    </ModalWrapper>
  );
};

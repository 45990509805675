import { COLORS_NAMES, TAGS_COLORS } from '@components/Fields/ColorSelect/constants';
import { FieldWrapperProps } from '@components/Fields/interface';
import { ListItemText, MenuItem, TextField, TextFieldProps, Typography } from '@mui/material';
import classNames from 'classnames';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

interface ColorSelectProps extends FieldWrapperProps, Omit<TextFieldProps, 'name' | 'label' | 'onChange'> {}

export const ColorSelect: FC<ColorSelectProps> = ({ validationOptions, name, className, ...fieldProps }) => {
  const methods = useFormContext();
  const { onChange, ...register } = methods?.register(name, validationOptions || {}) || {};
  const [state, setState] = useState(methods.getValues(name) || TAGS_COLORS.no_color);

  useEffect(() => {
    const subscription = methods.watch((fields) => {
      fields[name] && setState(fields[name]);
    });
    return () => subscription.unsubscribe();
  }, [methods.watch]);

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    methods.setValue(name, e.target.value);
  };

  const spawnList = useMemo(
    () =>
      (Object.keys(COLORS_NAMES) as (keyof typeof COLORS_NAMES)[]).map((color, index) => {
        return (
          <MenuItem key={index} value={color} className={classNames('flex items-center justify-between', `hover:bg-${color}-500/10`)}>
            <div className={classNames('w-4 h-4 mr-4 rounded-full', `bg-${color}-500`)}></div>
            <ListItemText className="capitalize">{COLORS_NAMES[color as keyof typeof TAGS_COLORS]}</ListItemText>
          </MenuItem>
        );
      }),
    []
  );

  return (
    <TextField
      {...fieldProps}
      className={classNames(className)}
      SelectProps={{
        value: state,
        renderValue: (value) => {
          return (
            <div className="flex items-center">
              <div className={classNames('w-4 h-4 mr-4 rounded-full', `bg-${value}-500`)}></div>
              <Typography className="capitalize">{COLORS_NAMES[value as keyof typeof TAGS_COLORS]}</Typography>
            </div>
          );
        }
      }}
      onChange={changeHandler}
      select
      error={!!methods?.formState.errors[name]}
      helperText={methods?.formState.errors[name]?.message ?? ''}
      {...register}
    >
      {spawnList}
    </TextField>
  );
};

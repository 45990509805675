/*
 * Hook for modal window controlling
 * */
import { useContext } from 'react';
import { ModalsContext } from '@components/Modals/ModalContext/ModalsContext';

export const useModal = () => {
  const { openModal, closeModal } = useContext(ModalsContext);
  return { openModal, closeModal };
};

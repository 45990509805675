import { useAppDispatch, useAppSelector } from '@app/store/store';
import { PlatformAddAndEditFormProps, PlatformAddAndEditFormValues } from '@components/Forms/interface';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button } from '@mui/material';
import { createPlatform, updatePlatform } from '@slices/platform/thunks';
import classNames from 'classnames';
import { debounce } from 'lodash';
import React, { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { InputTextField } from 'src/components/Fields/InputText/InputTextField';
import { FormWrapper } from 'src/components/Forms/FormWrapper';
import * as yup from 'yup';

const schema = yup.object({
  name: yup.string().required('Platform name is required field'),
  url: yup.string().url('URL address is not valid').required('URL is required field'),
  comment: yup.string().nullable()
});

export const PlatformAddAndEditForm: FC<PlatformAddAndEditFormProps> = ({ mode, className }) => {
  const { status, data: platform } = useAppSelector((store) => store.platforms.platform);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const methods = useForm<PlatformAddAndEditFormValues>({
    resolver: yupResolver(schema)
  });

  const submitRecipe: SubmitHandler<PlatformAddAndEditFormValues> = (data) => {
    const debouncedNavigate = debounce((id: string) => navigate(`/platforms/${id}`), 300);

    if (mode === 'create') {
      dispatch(
        createPlatform({
          name: data.name,
          url: data.url,
          comment: data.comment,

          afterActionWithParams: (id: string) => id && debouncedNavigate(id)
        })
      );
    }

    if (mode === 'edit') {
      if (params.id) {
        dispatch(
          updatePlatform({
            name: data.name,
            id: params.id,
            url: data.url,
            comment: data.comment,

            afterAction: () => params.id && debouncedNavigate(params.id)
          })
        );
      }
    }
  };

  const navigateHandler = (): void => {
    if (mode === 'edit') {
      if (platform) {
        navigate(`/platforms/${platform.id}`, { replace: true });
      }
    }

    if (mode === 'create') {
      navigate('/platforms', { replace: true });
    }
  };

  useEffect(() => {
    if (mode === 'edit' && platform) {
      methods.reset({
        name: platform.name,
        url: platform.url,
        comment: platform.comment || ''
      });
    }
  }, [mode, platform, methods]);

  return (
    <FormWrapper
      className={classNames(className, 'w-full')}
      methods={methods}
      formProps={{
        onSubmit: methods.handleSubmit(submitRecipe)
      }}
    >
      <InputTextField className="col-span-2 lg:col-span-1" name="name" label="Name" />
      <InputTextField className="col-span-2 lg:col-span-1" name="url" label="URL" />
      <InputTextField className="col-span-2" multiline minRows={5} name="comment" label="Comment" />

      <Box className=" col-span-full flex gap-2 lg:gap-4 flex-wrap flex-row-reverse">
        <LoadingButton loading={status === 'loading'} className="lg:w-fit w-full py-2 px-8" variant="contained" type="submit">
          Save
        </LoadingButton>
        <Button className="lg:w-fit w-full py-2 px-8" variant="outlined" onClick={navigateHandler} color="error">
          cancel
        </Button>
      </Box>
    </FormWrapper>
  );
};

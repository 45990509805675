import ArrowLeft from '@mui/icons-material/ArrowLeft';
import { Box, Button, Container, Typography } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

export const TermsAndConditions: FC = () => {
  const navigate = useNavigate();

  const navigationHandler = (): void => {
    if (window.history.length) {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  return (
    <Box className="">
      <Box className="bg-blue-700 min-h-[200px] mb-4 flex">
        <Container className="max-w-[952px] m-auto flex pb-[50px] flex-col justify-center">
          <Button type="button" className="text-white h-[50px] self-start" variant="text" onClick={navigationHandler} startIcon={<ArrowLeft />}>
            go back
          </Button>
          <Typography variant="h3" className="font-bold uppercase text-center text-white">
            Terms & Conditions
          </Typography>
        </Container>
      </Box>
      <Box className="">
        <Container className="max-w-[952px]">
          <Typography variant="body1" className="">
            Grill springy chickpeas in a cooker with bourbon for about an hour to chamfer their saltyness. Condensed milk soup is just not the same without butterscotch and al
            dente crushed escargots. Ubi est magnum planeta?
          </Typography>
        </Container>
      </Box>
    </Box>
  );
};

import { useLocation, useParams } from 'react-router-dom';

type ReturnValues = 'new' | 'edit' | 'info' | 'root';

export const useCurrentLocation = (locationPath: string): ReturnValues => {
  const { pathname } = useLocation();
  const params = useParams();

  switch (pathname) {
    case `/${locationPath}/${params.id}`:
      return 'info';
    case `/${locationPath}/new`:
      return 'new';
    case `/${locationPath}/${params.id}/edit`:
      return 'edit';
    default:
      return 'root';
  }
};

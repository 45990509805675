import { createSlice } from '@reduxjs/toolkit';
import { DASHBOARD } from '@slices/dashboard/interface';
import { getAllHistory, getAllStepsDashboard, getEventsFromDate } from '@slices/dashboard/thunks';

const initialState: DASHBOARD.DashboardSlice = {
  status: 'unset',
  responseMessage: null,
  events: {
    list: {},
    status: 'unset'
  },
  histories: {
    list: [],
    status: 'unset'
  },
  steps: {
    list: [],
    status: 'unset'
  }
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    clearResponseMessages: (state) => {
      state.responseMessage = null;
    }
  },
  extraReducers: (builder) => {
    //HISTORY
    builder.addCase(getAllHistory.pending, (state) => {
      state.histories.status = 'loading';
    });
    builder.addCase(getAllHistory.fulfilled, (state, { payload }) => {
      state.histories.list = payload;
      state.histories.status = 'success';
    });
    builder.addCase(getAllHistory.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.histories.status = 'error';
    });
    //EVENTS
    builder.addCase(getEventsFromDate.pending, (state) => {
      state.events.status = 'loading';
    });
    builder.addCase(getEventsFromDate.fulfilled, (state, { payload, meta }) => {
      state.events.list = {
        ...state.events.list,
        [meta.arg.query.split('?date=')[1]]: payload
      };
      state.events.status = 'success';
    });
    builder.addCase(getEventsFromDate.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.events.status = 'error';
    });

    //STEPS
    builder.addCase(getAllStepsDashboard.pending, (state) => {
      state.steps.status = 'loading';
    });
    builder.addCase(getAllStepsDashboard.fulfilled, (state, { payload }) => {
      state.steps.list = payload;
      state.steps.status = 'success';
    });
    builder.addCase(getAllStepsDashboard.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.steps.status = 'error';
    });
  }
});

import { API } from '@API/constants';
import { ResponseListBaseType } from '@API/services/interfaces';
import { fetchData } from '@helpers/fetchData/fetchData';
import { DASHBOARD } from '@slices/dashboard/interface';

const path = `${API.origin}/dashboard`;

export class DashboardService {
  public static getAll<T = DASHBOARD.Histories[]>(period: string) {
    return fetchData<ResponseListBaseType<T>>(`${path}/histories`, 'POST', { period });
  }
}

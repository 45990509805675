import { useAppSelector } from '@app/store/store';
import { Box, CircularProgress, Typography } from '@mui/material';
import { DefaultComponentProps } from 'custom';
import { FC } from 'react';
import { Transition, TransitionStatus } from 'react-transition-group';

interface GlobalLoaderProps extends DefaultComponentProps {}

const duration = 300;
const defaultStyle = {
  transition: `opacity ${duration}ms`,
  opacity: 0
};
const transitionStyles: Record<TransitionStatus, any> = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
  unmounted: { opacity: 0 }
};

export const GlobalLoader: FC<GlobalLoaderProps> = () => {
  const { status, loadingProgress } = useAppSelector((store) => store.global);
  return (
    <>
      <Transition in={status === 'loading'} timeout={300} mountOnEnter unmountOnExit>
        {(transitionStatus) => (
          <Box
            style={{
              zIndex: 9999,
              ...defaultStyle,
              ...transitionStyles[transitionStatus]
            }}
            className="fixed flex items-center justify-center inset-0 bg-white"
          >
            <Box className="relative">
              <CircularProgress variant="determinate" size={50} thickness={5} color="inherit" value={100} className="absolute left-0 opacity-10 fill-gray-100" />
              <CircularProgress variant="determinate" size={50} thickness={5} value={loadingProgress} className="" />
              <Typography
                component="div"
                color="text.secondary"
                variant="caption"
                className="absolute inset-0 flex items-center justify-center"
              >{`${loadingProgress}%`}</Typography>
            </Box>
          </Box>
        )}
      </Transition>
    </>
  );
};

import { VacanciesListActions } from '@modules/vacancies/components/VacancyListActions/VacanciesListActions';
import { VacanciesListTable } from '@modules/vacancies/components/Tables/VacanciesListTable';
import { Box, Paper, Typography } from '@mui/material';
import React, { FC } from 'react';

export const VacanciesListPage: FC = () => {
  return (
    <>
      <Box className="flex mb-4 flex-wrap items-center gap-4 justify-between">
        <Typography variant="h4" fontWeight="bold" textTransform="uppercase" className="text-gray-300 font-black">
          Vacancies
        </Typography>
        <VacanciesListActions />
      </Box>

      <Paper className="p-4 mb-2 shadow max-h-full flex flex-col overflow-hidden">
        <VacanciesListTable />
      </Paper>
    </>
  );
};

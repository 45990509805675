import { createSlice } from '@reduxjs/toolkit';
import { VACANCIES } from '@slices/vacancies/interface';
import { addNewVacancy, deleteVacancy, getVacancies, getVacancy, updateVacancy } from './thunks';

const initialState: VACANCIES.VacanciesSlice = {
  status: 'unset',
  responseMessage: null,

  vacancy: {
    status: 'unset',
    data: null
  },
  vacanciesList: {
    status: 'unset',
    order: 'asc',
    search: '',
    sort: 'created_at',
    rowsCount: 25,
    page: 0,

    list: {
      data: [],
      total: 0
    }
  },
  vacancyCRUD: {
    status: 'unset',
    information: {
      name: '',
      status: 'open',
      experience: [1, 2],
      department_id: '',
      city: '',
      country: '',
      description: '',
      comment: ''
    },
    platforms: [],
    steps: []
  }
};

export const vacanciesSlice = createSlice({
  name: 'vacancies',
  initialState,
  reducers: {
    clearResponseMessages: (state) => {
      state.responseMessage = null;
    },
    touchSearchActions: (store, { payload }: { payload: VACANCIES.SearchActionsPayload }) => {
      store.vacanciesList.search = payload.search ?? '';
      if (payload.sort) {
        store.vacanciesList.sort = payload.sort;
      }
      if (payload.order) {
        store.vacanciesList.order = payload.order;
      }
    },
    touchPagination: (store, { payload }: { payload: Partial<Record<'page' | 'rowsCount', number>> }) => {
      if (typeof payload.page === 'number') {
        store.vacanciesList.page = payload.page;
      }
      if (typeof payload.rowsCount === 'number') {
        store.vacanciesList.rowsCount = payload.rowsCount;
      }
    },
    clearSelectedVacancy: (state) => {
      state.vacancy.data = null;
      state.vacancy.status = 'unset';
    }
  },
  extraReducers: (builder) => {
    //GET ALL
    builder.addCase(getVacancies.pending, (state) => {
      state.vacanciesList.status = 'loading';
    });
    builder.addCase(getVacancies.fulfilled, (state, { payload }) => {
      state.vacanciesList.list = payload;
      state.vacanciesList.status = 'success';
    });
    builder.addCase(getVacancies.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.vacanciesList.status = 'error';
    });

    //CREATE
    builder.addCase(addNewVacancy.pending, (state) => {
      state.vacancyCRUD.status = 'loading';
    });
    builder.addCase(addNewVacancy.fulfilled, (state, { payload }) => {
      state.vacancy.data = payload;
      state.vacancy.status = 'success';
      state.vacancyCRUD.status = 'success';
      state.responseMessage = {
        type: 'success',
        data: [
          {
            message: `The vacancy "${payload.name}" successfully created`
          }
        ]
      };
    });
    builder.addCase(addNewVacancy.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.vacancyCRUD.status = 'error';
    });

    //UPDATE
    builder.addCase(updateVacancy.pending, (state) => {
      state.vacancy.status = 'loading';
    });
    builder.addCase(updateVacancy.fulfilled, (state, { payload }) => {
      state.vacancy.data = {
        ...state.vacancy.data,
        ...payload
      };
      state.vacancy.status = 'success';
      state.responseMessage = {
        type: 'success',
        data: [
          {
            message: `Vacancy "${payload.name}" successfully updated`
          }
        ]
      };
    });

    builder.addCase(updateVacancy.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.vacancy.status = 'error';
    });

    //GET ONE
    builder.addCase(getVacancy.pending, (state) => {
      state.vacancy.status = 'loading';
    });
    builder.addCase(getVacancy.fulfilled, (state, { payload }) => {
      state.vacancy.data = payload;
      state.vacancy.status = 'success';
    });
    builder.addCase(getVacancy.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.vacancy.status = 'error';
    });

    //DELETE ONE
    builder.addCase(deleteVacancy.pending, (state) => {
      state.vacancy.status = 'loading';
      state.vacanciesList.status = 'loading';
    });
    builder.addCase(deleteVacancy.fulfilled, (state, { payload }) => {
      state.responseMessage = {
        type: 'success',
        data: [
          {
            message: `The vacancy "${payload.name}" successfully deleted`
          }
        ]
      };
      state.vacanciesList.list.data = state.vacanciesList.list.data.filter((vacancy) => vacancy.id !== payload.id);
      state.vacancy.status = 'success';
      state.vacanciesList.status = 'success';
    });
    builder.addCase(deleteVacancy.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.vacancy.status = 'error';
      state.vacanciesList.status = 'error';
    });
  }
});

import { materialTheme } from '@app/theme.material';
import { ModalsContextProvider } from '@components/Modals/ModalContext/ModalsContext';
import { ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { FC } from 'react';
import { Outlet } from 'react-router';

const App: FC = () => {
  return (
    <ThemeProvider theme={materialTheme}>
      <SnackbarProvider
        preventDuplicate
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        maxSnack={3}
      >
        <ModalsContextProvider>
          <main className="App m-0 h-full bg-gray-100 overflow-hidden">
            <Outlet />
          </main>
        </ModalsContextProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;

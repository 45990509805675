import { API } from '@API/constants';
import { ResponseBaseType, ResponseListBaseType } from '@API/services/interfaces';
import { fetchData } from '@helpers/fetchData/fetchData';
import { SKILLS } from '@slices/skills/interface';

const path = `${API.origin}/skills`;

export class SkillsService {
  public static create<T = SKILLS.Skill>(dto: Omit<SKILLS.Skill, 'id'>) {
    return fetchData<ResponseBaseType<T>>(path, 'POST', dto);
  }

  public static getAll<T = SKILLS.Skill[]>() {
    return fetchData<ResponseListBaseType<T>>(path);
  }

  public static getOne<T = SKILLS.Skill>(id: string) {
    return fetchData<ResponseBaseType<T>>(`${path}/${id}`);
  }

  public static updateOne<T = SKILLS.Skill>(id: string, dto: Omit<Partial<SKILLS.Skill>, 'id'>) {
    return fetchData<ResponseBaseType<T>>(`${path}/${id}`, 'PATCH', dto);
  }

  public static deleteOne<T = SKILLS.Skill>(id: string) {
    return fetchData<ResponseBaseType<T>>(`${path}/${id}`, 'DELETE');
  }
}

import { useAppDispatch } from '@app/store/store';
import { CenterLoader } from '@components/Loaders/CenterLoader';
import { horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { KanbanStepContainer } from '@modules/kanban/components/Container/KanbanStepContainer';
import { KanbanBoardProps } from '@modules/kanban/interface';
import { Box } from '@mui/material';
import { getKanbanVacancy } from '@slices/kanban/thunks';
import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';

export const KanbanBoard: FC<KanbanBoardProps> = ({ columns, status }) => {
  const { vacancy_id } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (vacancy_id) {
      dispatch(getKanbanVacancy({ vacancy_id }));
    }
  }, [vacancy_id]);

  return (
    <>
      {!!vacancy_id && !!columns[vacancy_id] && (
        <SortableContext id="kanban_steps_board" items={columns[vacancy_id].steps} strategy={horizontalListSortingStrategy}>
          <Box className="absolute p-4 pb-2 inset-0 flex overflow-x-auto overflow-y-hidden h-full">
            {columns[vacancy_id].steps.map((step) => (
              <KanbanStepContainer vacancy_id={vacancy_id} columnData={step} key={step.id} />
            ))}
          </Box>
        </SortableContext>
      )}
      {status === 'loading' && <CenterLoader className="bg-white/50 z-50" />}
    </>
  );
};

import { TemplatesTable } from '@modules/templates/components/Tables/TemplatesTable';
import Add from '@mui/icons-material/Add';
import { Box, Button, Paper, Typography } from '@mui/material';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';

export const TemplatesListPage: FC = () => {
  return (
    <>
      <Box className="mb-4 flex flex-wrap items-center gap-4 justify-between">
        <Typography variant="h4" fontWeight="bold" textTransform="uppercase" className="text-gray-300 font-black">
          Templates
        </Typography>
        <Button startIcon={<Add />} variant="contained" component={NavLink} to="new">
          create
        </Button>
      </Box>

      <Paper className="p-4 mb-2 shadow max-h-full flex flex-col overflow-hidden">
        <TemplatesTable />
      </Paper>
    </>
  );
};

import { VacanciesTableBodyProps } from '@modules/vacancies/interface';
import { StepsSections } from '@modules/vacancies/components/StepsSections/StepsSections';
import MoreVert from '@mui/icons-material/MoreVert';
import { IconButton, Link, TableBody, TableCell, TableRow } from '@mui/material';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';

export const VacanciesTableBody: FC<VacanciesTableBodyProps> = ({ list, onActionsClick }) => {
  return (
    <TableBody>
      {list.map((item) => (
        <TableRow className="hover:bg-gray-100" key={item.id} hover tabIndex={-1}>
          {/*<TableCell padding="checkbox">*/}
          {/*  <Checkbox*/}
          {/*    onClick={(event) => handleSelect(event, item.id)}*/}
          {/*    name="checkbox-field"*/}
          {/*    checked={selected.includes(item.id)}*/}
          {/*  />*/}
          {/*</TableCell>*/}

          <TableCell className="overflow-ellipsis whitespace-nowrap">
            <Link underline="hover" className="font-bold text-black" component={NavLink} to={item.id}>
              {item.name ?? '-'}
            </Link>
          </TableCell>

          <TableCell>
            {item.status === 'open' && 'Opened'}
            {item.status === 'close' && 'Closed'}
          </TableCell>

          <TableCell>{item.department?.name}</TableCell>

          <TableCell>
            <StepsSections steps={item.steps} />
          </TableCell>

          <TableCell className="overflow-ellipsis whitespace-nowrap">{`${item.candidates} ${item.candidates === 1 ? 'candidate' : 'candidates'}`}</TableCell>

          <TableCell>
            <Link underline="hover" className="font-bold text-black" component={NavLink} to={`/users/${item.user.id}`}>{`${item.user?.first_name} ${
              item.user?.last_name ?? ''
            }`}</Link>
          </TableCell>

          <TableCell align="right">{item.updated_at && new Date(item.updated_at)?.toLocaleString('ru-Ru')}</TableCell>

          <TableCell align="right">{item.created_at && new Date(item.created_at)?.toLocaleString('ru-Ru')}</TableCell>

          <TableCell align="right">
            <IconButton aria-label={item.id} onClick={onActionsClick}>
              <MoreVert />
            </IconButton>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

import { GoBackButton } from '@components/Buttons/GoBack/GoBackButton';
import { CreateAndEditVacanciesForm } from '@components/Forms/Vacancies/CreateAndEditVacanciesForm';
import { Box, Container, Typography } from '@mui/material';
import { FC } from 'react';

export const VacancyAddPage: FC = () => {
  return (
    <Box>
      <Container className="p-0 max-w-[1200px]">
        <GoBackButton to=".." text="Back to vacancies" />
        <Box className="flex flex-wrap mb-5">
          <Box>
            <Typography className="uppercase mb-2 text-gray-300 font-black" fontWeight="bold" variant="h4">
              Add Vacancy
            </Typography>
          </Box>
        </Box>
        <CreateAndEditVacanciesForm mode="create" />
      </Container>
    </Box>
  );
};

import { DateTimePickerField } from '@components/Fields/DateTimePicker/DateTimePickerField';
import { DefaultFormProps } from '@components/Forms/interface';
import AccessTimeRounded from '@mui/icons-material/AccessTimeRounded';
import { Box, Typography } from '@mui/material';
import { DefaultComponentProps } from 'custom';
import { formatDistance, isValid } from 'date-fns';
import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';

interface DateRangeSectionProps extends DefaultComponentProps {
  form_mode: DefaultFormProps['mode'];
}

export const DateRangeSection: FC<DateRangeSectionProps> = ({ form_mode }) => {
  const { watch } = useFormContext();
  const startDate: Date = watch('start');
  const endDate: Date = watch('end');
  const isValidDate = isValid(new Date(startDate)) && isValid(new Date(endDate));

  return (
    <Box className="flex flex-col p-4 bg-gray-100 rounded-xl">
      <Box className="flex gap-1 mb-4 items-center">
        <AccessTimeRounded className="fill-gray-500" fontSize="small" />
        <Typography variant="subtitle2" className="text-gray-500">
          {startDate && endDate && isValidDate ? `Duration - ${formatDistance(new Date(startDate), new Date(endDate), { includeSeconds: true })}` : 'Choose date period'}
        </Typography>
      </Box>
      <Box className="flex gap-4 items-center flex-col md:flex-row w-full">
        <DateTimePickerField
          minDateTime={form_mode === 'create' ? new Date() : undefined}
          disablePast={form_mode === 'create'}
          textFieldProps={{ variant: 'outlined', label: 'Start date', className: 'w-full' }}
          name="start"
        />
        <DateTimePickerField
          minDateTime={form_mode === 'create' ? new Date(startDate) ?? undefined : undefined}
          disablePast={form_mode === 'create'}
          textFieldProps={{ variant: 'outlined', label: 'End date', className: 'w-full' }}
          name="end"
        />
      </Box>
    </Box>
  );
};

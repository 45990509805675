import { PlatformsTableBodyProps } from '@modules/platfom/interface';
import MoreVert from '@mui/icons-material/MoreVert';
import { IconButton, Link, Link as ExternalLink, TableBody, TableCell, TableRow } from '@mui/material';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';

export const PlatformsTableBody: FC<PlatformsTableBodyProps> = ({ list, onActionsClick }) => {
  return (
    <TableBody>
      {list.map((item) => (
        <TableRow className="hover:bg-gray-100" key={item.id} hover tabIndex={-1}>
          {/*<TableCell padding="checkbox">*/}
          {/*  <Checkbox*/}
          {/*    onClick={(event) => handleSelect(event, item.id)}*/}
          {/*    name="checkbox-field"*/}
          {/*    checked={selected.includes(item.id)}*/}
          {/*  />*/}
          {/*</TableCell>*/}

          {/* <TableCell className="max-w-[300px] text-ellipsis overflow-hidden"> */}
          {/*   <Avatar /> */}
          {/* </TableCell> */}

          <TableCell>
            <Link underline="hover" className="text-black font-bold" component={NavLink} to={item.id}>
              {item.name ?? '-'}
            </Link>
          </TableCell>

          <TableCell>
            <ExternalLink className="font-bold text-black" href={item.url} rel="nooper" underline="hover" target="_blank">
              {item.url}
            </ExternalLink>
          </TableCell>

          <TableCell align="right">
            <IconButton aria-label={item.id} onClick={onActionsClick}>
              <MoreVert />
            </IconButton>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

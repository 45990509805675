import { useAppDispatch, useAppSelector } from '@app/store/store';
import { TagChip } from '@components/Chips/TagChip/TagChip';
import { CenterLoader } from '@components/Loaders/CenterLoader';
import { NoDataPlug } from '@components/Plugs/NoDataPlug';
import { BaseTableBody } from '@components/Tables/BaseTableBody';
import { TableSortHeader } from '@components/Tables/TableSortHeader';
import { queryStringBuilder } from '@helpers/queryStringBuilder/queryStringBuilder';
import { useDebouncedRequest } from '@hooks/useDebouncedRequest';
import { CandidatesSearch } from '@modules/candidates/components/CandidatesListSearch/CandidatesSearch';
import { CandidatesListActionsPopover } from '@modules/candidates/components/Popovers/CandidatesListActionsPopover';
import { Box, Link, Popover, Table, TableContainer, TablePagination } from '@mui/material';
import { candidatesSlice } from '@slices/candidates/candidatesSlice';
import { CANDIDATES } from '@slices/candidates/interface';
import { getCandidates } from '@slices/candidates/thunks';
import { format, formatDistance, isValid } from 'date-fns';
import React, { ChangeEvent, FC, MouseEvent, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';

// <TableSortHeader
// columns={[
//     {
//       id: 'first_name',
//       label: 'Candidate name'
//     },
// {
//   id: 'email',
//     label: 'Email'
// },
// {
//   id: 'department',
//     label: 'Department'
// },
// {
//   id: 'vacancy',
//     label: 'Vacancy'
// },
// {
//   id: 'updated_at',
//     label: 'Updated at',
//   numeric: true
// },
// {
//   id: 'created_at',
//     label: 'Created at',
//   numeric: true
// }
// ]}

export const CandidateListTable: FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const {
    status,
    candidates: {
      sort,
      search,
      order,
      page,
      rowsCount,
      list: { data: list, total }
    }
  } = useAppSelector((store) => store.candidates);

  const query = useMemo(
    () =>
      queryStringBuilder({
        search,
        order: (order || '').toUpperCase(),
        sort_field: sort,
        page: page + 1,
        perPage: rowsCount
      }),
    [search, sort, order, page, rowsCount]
  );
  const dispatch = useAppDispatch();
  useDebouncedRequest(query, () => dispatch(getCandidates(query)), 500);

  const handleRequestSort = (_: MouseEvent<unknown>, property: keyof CANDIDATES.Candidate): void => {
    const isAsc = sort === property && order === 'asc';
    dispatch(candidatesSlice.actions.touchSearchActions({ order: isAsc ? 'desc' : 'asc', sort: property }));
    dispatch(candidatesSlice.actions.touchPagination({ page: 0 }));
  };

  const handleChangePage = (_: unknown, page: number): void => {
    dispatch(candidatesSlice.actions.touchPagination({ page }));
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>): void => {
    dispatch(candidatesSlice.actions.touchPagination({ page: 0, rowsCount: parseInt(event.target.value, 10) }));
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <CandidatesSearch />

      <Box className="min-h-[100px] flex flex-col relative">
        <TableContainer className="flex flex-1">
          <Table stickyHeader>
            <TableSortHeader
              columns={[
                {
                  id: 'first_name',
                  label: 'Candidate name'
                },
                {
                  id: 'tag',
                  label: 'Tags',
                  disableSorting: true
                },
                {
                  id: 'email',
                  label: 'Email'
                },
                {
                  id: 'department',
                  label: 'Department',
                  disableSorting: true
                },
                {
                  id: 'vacancy',
                  label: 'Vacancy'
                },
                {
                  id: 'updated_at',
                  label: 'Updated at',
                  numeric: true
                },
                {
                  id: 'created_at',
                  label: 'Created at',
                  numeric: true
                }
              ]}
              onRequestSort={handleRequestSort}
              order={order}
              orderBy={sort}
            />

            <BaseTableBody
              list={list}
              onActionsClick={handleClick}
              renderRowItems={(item) => [
                {
                  name: 'first_name',
                  label: (
                    <Link underline="hover" className="font-bold text-black" component={NavLink} to={item.id}>
                      {`${item.first_name} ${item.last_name}` ?? '-'}
                    </Link>
                  )
                },
                {
                  name: 'tag',
                  label: item.tags?.map((tag) => <TagChip key={tag.id} tag={tag} />)
                },
                {
                  name: 'email',
                  label: item.email ?? '-'
                },
                {
                  name: 'department',
                  label: item.department?.name ?? '-'
                },
                {
                  name: 'vacancy',
                  label: item.vacancy ? (
                    <Link underline="hover" className="font-bold text-black" component={NavLink} to={`/vacancies/${item.vacancy?.id}`}>
                      {item.vacancy?.name}
                    </Link>
                  ) : (
                    '-'
                  )
                },
                {
                  name: 'updated_at',
                  label: (item.updated_at && isValid(new Date(item.updated_at)) && `${formatDistance(new Date(), new Date(item.updated_at))} ago`) ?? '-',
                  cellProps: {
                    align: 'right'
                  }
                },
                {
                  name: 'created_at',
                  label: (item.created_at && isValid(new Date(item.created_at)) && format(new Date(item.created_at), 'dd MMMM yyyy, mm:HH')) ?? '-',
                  cellProps: {
                    align: 'right'
                  }
                }
              ]}
            />
          </Table>
        </TableContainer>

        <TablePagination
          className="flex-shrink-0"
          component="div"
          variant="footer"
          count={total}
          page={page}
          rowsPerPage={rowsCount}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />

        <Popover className="mt-2" open={!!anchorEl} onClose={handleClose} onClick={handleClose} anchorEl={anchorEl}>
          {anchorEl && anchorEl.ariaLabel && <CandidatesListActionsPopover id={anchorEl.ariaLabel} />}
        </Popover>

        {status === 'loading' && <CenterLoader className="bg-white/50" />}
        {status !== 'loading' && !list.length && <NoDataPlug />}
      </Box>
    </>
  );
};

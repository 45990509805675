import { useAppDispatch, useAppSelector } from '@app/store/store';
import { CandidateFileUploadToastHOC } from '@app/utils/HOCs/CandidateFileUploadToastHOC';
import { FileUpload } from '@components/Fields/FileUpload/FileUpload';
import { FormWrapper } from '@components/Forms/FormWrapper';
import { CenterLoader } from '@components/Loaders/CenterLoader';
import { CandidateUploadFileModalParams } from '@components/Modals/FileUpload/interface';
import { BaseModalProps } from '@components/Modals/RootModal/interface';
import { ModalWrapper } from '@components/Modals/RootModal/RootModal';
import { useModal } from '@hooks/useModal';
import { Box, Paper, Typography } from '@mui/material';
import { getCandidate, uploadCandidateFiles } from '@slices/candidates/thunks';
import classNames from 'classnames';
import { useSnackbar } from 'notistack';
import React, { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

interface FileUploadModalProps extends BaseModalProps {
  data: CandidateUploadFileModalParams['modalProps'];
}

export const CandidateFileUploadModal: FC<FileUploadModalProps> = ({ data, onClose, ...modalProps }) => {
  const { data: candidate_data, status, files_upload_meta, files_upload_status } = useAppSelector((store) => store.candidates.candidate);
  const { enqueueSnackbar } = useSnackbar();

  const { id: candidate_params_id } = useParams();
  const methods = useForm<{ files: File[] }>();
  const dispatch = useAppDispatch();
  const { closeModal } = useModal();

  useEffect(() => {
    if (!candidate_data && candidate_params_id) {
      dispatch(getCandidate(candidate_params_id));
    }
  }, [candidate_params_id, candidate_data]);

  const onCloseHandler = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (files_upload_status === 'loading') {
      enqueueSnackbar('message', {
        persist: true,
        content: (key, message) => <CandidateFileUploadToastHOC key={key} message={message} />
      });
    }
    return onClose && onClose(event, reason);
  };

  const submitRecipe: SubmitHandler<{ files: File[] }> = ({ files }): void => {
    if (files && files.length && candidate_data) {
      dispatch(
        uploadCandidateFiles({
          files: (files as File[]) || null,
          candidate_id: candidate_data.id,
          afterAction: () => {
            closeModal('CANDIDATE_UPLOAD_FILE_MODAL');
          }
        })
      );
    }
  };

  return (
    <ModalWrapper {...modalProps} onClose={onCloseHandler}>
      <FormWrapper
        methods={methods}
        formProps={{
          onSubmit: methods.handleSubmit(submitRecipe)
        }}
      >
        <Paper className="max-w-[600px] mx-2 shadow-2xl overflow-hidden relative">
          <Box className={classNames('bg-brand relative text-white p-6')}>
            <Typography className="leading-none font-bold uppercase" variant="h4">
              Upload new file
            </Typography>
            <Typography variant="subtitle2" className="opacity-70">
              You may upload additional candidate files
            </Typography>
          </Box>
          <Box className="p-6 flex flex-col">
            <FileUpload
              submitButtonProps={{
                disabled: !methods.watch('files')?.length,
                variant: 'contained'
              }}
              enableSubmit
              name="files"
              fileUploadStatus={files_upload_status}
              fileUploadProgressData={files_upload_meta}
            />
          </Box>
          {status === 'loading' && !data && <CenterLoader className="bg-white" />}
        </Paper>
      </FormWrapper>
    </ModalWrapper>
  );
};

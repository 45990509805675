import { EventsService } from '@API/services/events/EventsService';
import { CreateEventDTO } from '@API/services/events/interfaces';
import { AfterActionProps, BaseAsyncThunkOptions } from '@app/store/interface';
import { queryStringBuilder } from '@helpers/queryStringBuilder/queryStringBuilder';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { candidatesSlice } from '@slices/candidates/candidatesSlice';
import { getEventsFromDate } from '@slices/dashboard/thunks';
import { EVENTS } from '@slices/events/interface';
import { usersSlice } from '@slices/users/usersSlice';

export const getEvent = createAsyncThunk<EVENTS.Event & EVENTS.EventRelations, { id: string } & AfterActionProps, BaseAsyncThunkOptions>(
  'events/getOne',
  async ({ id }, { rejectWithValue }) => {
    const response = await EventsService.getOne<EVENTS.Event & EVENTS.EventRelations>(id);

    if (response.ok) {
      return response.data.data;
    } else {
      return rejectWithValue(response.error);
    }
  }
);

export const createEvent = createAsyncThunk<unknown, CreateEventDTO & AfterActionProps & Record<'sliceType', EVENTS.SliceType>, BaseAsyncThunkOptions>(
  'events/create',
  async ({ afterAction, afterActionWithParams, sliceType, ...dto }, { rejectWithValue, dispatch }) => {
    if (sliceType === 'candidates') {
      dispatch(candidatesSlice.actions.changeEventStatus('loading'));
    }
    const response = await EventsService.create<EVENTS.Event & EVENTS.EventRelations>(dto);

    if (response.ok) {
      if (afterAction) {
        afterAction();
      }
      if (sliceType === 'candidates') {
        dispatch(candidatesSlice.actions.addEvent(response.data.data));
        dispatch(candidatesSlice.actions.changeEventStatus('success'));
      }
      if (sliceType === 'users') {
        dispatch(usersSlice.actions.addEvent(response.data.data));
      }
    } else {
      if (sliceType === 'candidates') {
        dispatch(candidatesSlice.actions.changeEventStatus('error'));
      }
      return rejectWithValue(response.error);
    }
  }
);

export const editEvent = createAsyncThunk<
  EVENTS.Event & EVENTS.EventRelations,
  Partial<CreateEventDTO> & { id: string } & AfterActionProps & Record<'sliceType', EVENTS.SliceType>,
  BaseAsyncThunkOptions
  >('events/edit', async ({ afterAction, sliceType, ...dto }, { rejectWithValue, dispatch }) => {
  const response = await EventsService.edit<EVENTS.Event & EVENTS.EventRelations>(dto);

  if (response.ok) {
    if (afterAction) {
      afterAction();
    }
    if (sliceType === 'candidates') {
      dispatch(candidatesSlice.actions.editEvent(response.data.data));
    }
    if (sliceType === 'users') {
      dispatch(usersSlice.actions.addEvent(response.data.data));
    }
    return response.data.data;
  } else {
    return rejectWithValue(response.error);
  }
});

export const deleteEvent = createAsyncThunk<unknown, { id: string; event_date?: string } & AfterActionProps & Record<'sliceType', EVENTS.SliceType>, BaseAsyncThunkOptions>(
  'events/delete',
  async ({ afterAction, sliceType, event_date, ...rest }, { rejectWithValue, dispatch }) => {
    const response = await EventsService.delete(rest.id);

    if (response.ok) {
      if (afterAction) {
        afterAction();
      }
      if (sliceType === 'candidates') {
        dispatch(candidatesSlice.actions.deleteEvent(rest.id));
      }
      if (sliceType === 'users') {
        dispatch(usersSlice.actions.deleteEvent(rest.id));
      }
      if (sliceType === 'dashboard' && event_date) {
        dispatch(getEventsFromDate({ query: queryStringBuilder({ date: event_date }) }));
      }
    } else {
      return rejectWithValue(response.error);
    }
  }
);

import { Box, Divider, Tab, TabProps, Tabs, TabsProps } from '@mui/material';
import classNames from 'classnames';
import { DefaultComponentProps } from 'custom';
import React, { FC, ReactNode, useMemo, useState } from 'react';

export interface TabContextContentType {
  tabBody: ReactNode;
  tabHeader: TabProps;
}

interface TabContextProps extends DefaultComponentProps {
  content?: TabContextContentType[];
  divider?: boolean;
  tabProps?: Omit<TabProps, 'children'>;
  tabsProps?: TabsProps;
  tabsContainerClassNames?: string;
  tabsHeaderClassNames?: string;
}

const a11yProps = (index: number) => ({
  'id': `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`
});

// interface TabPanelProps {
//   children?: React.ReactNode;
//   index: number;
//   value: number;
// }

// function TabPanel(props: TabPanelProps) {
//   const { children, value, index, ...other } = props
//
//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box className="">
//           {children}
//         </Box>
//       )}
//     </div>
//   )
// }
/*
 * TODO:
 *   Сейчас элементы выбираются по индексу массива, для унификации и уменьшения пропсов.
 *   При необходимости можно рендер по @value
 * */

export const TabContext: FC<TabContextProps> = ({ content, divider, tabsContainerClassNames, className, tabsHeaderClassNames, tabProps = {}, tabsProps = {} }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const changeTabHandler = (e: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const headers = useMemo(() => {
    if (content) {
      return content.map(({ tabHeader: { value, ...item } }, index) => (
        <Tab {...tabProps} className="normal-case font" key={index} {...a11yProps(index)} {...item} value={index} />
      ));
    }
  }, [content]);

  const bodies = useMemo(() => content && content[selectedTab].tabBody, [selectedTab]);
  return (
    <Box className={className}>
      <Box className={classNames('border-b border-solid border-x-0 border-t-0 border-gray-300 mb-4', tabsHeaderClassNames)}>
        <Tabs {...tabsProps} value={selectedTab} onChange={changeTabHandler}>
          {headers}
        </Tabs>
        {divider && <Divider className="relative -top-[1px]" />}
      </Box>
      <Box className={tabsContainerClassNames}>{bodies}</Box>
    </Box>
  );
};

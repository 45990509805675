import { useAppDispatch, useAppSelector } from '@app/store/store';
import { useModal } from '@hooks/useModal';
import Delete from '@mui/icons-material/Delete';
import { Box, Divider, FormControlLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Switch, Typography } from '@mui/material';
import { deleteVacancy, updateVacancy } from '@slices/vacancies/thunks';
import { FC, SyntheticEvent } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

export const VacancyActionsPopover: FC = () => {
  const { data: vacancy, status } = useAppSelector((store) => store.vacancies.vacancy);
  const params = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { openModal, closeModal } = useModal();

  const deleteHandler = () => {
    if (params.id && vacancy) {
      openModal({
        modalName: 'CONFIRM_MODAL',
        modalProps: {
          title: 'Delete vacancy',
          subtitle: 'This vacancy will be removed. This action cannot be undone',
          successAction: () => {
            dispatch(
              deleteVacancy({
                id: params.id!,
                afterAction: () => {
                  closeModal('CONFIRM_MODAL');
                  navigate('/vacancies');
                }
              })
            );
          }
        }
      });
    }
  };

  const handleBlocked = () => {
    if (params.id) {
      dispatch(updateVacancy({ status: 'close', id: params.id }));
    }
  };

  const handleActivate = () => {
    if (params.id) {
      dispatch(updateVacancy({ status: 'open', id: params.id }));
    }
  };

  const handleSwitch = (_: SyntheticEvent, checked: boolean): void => {
    if (checked) {
      handleActivate();
    } else {
      handleBlocked();
    }
  };

  return (
    <Box>
      <List dense className="min-w-[160px]">
        <ListItem className="px-4">
          <FormControlLabel disabled={status === 'loading'} onChange={handleSwitch} checked={vacancy?.status === 'open'} control={<Switch size="small" />} label="Active" />
        </ListItem>

        <Divider className="my-2" />
        <ListItemButton onClick={deleteHandler} className="text-red-500 mx-2 whitespace-nowrap px-2">
          <ListItemIcon className="text-red-500 min-w-fit mr-2">
            <Delete />
          </ListItemIcon>
          <ListItemText className="uppercase text-red-500">
            <Typography>Delete</Typography>
          </ListItemText>
        </ListItemButton>
      </List>
    </Box>
  );
};

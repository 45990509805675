import { RegistrationForm } from '@components/Forms/Auth/RegistrationForm';
import { Box, Divider, Paper, Typography } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';

export const RegistrationPage: FC = () => {
  const [searchParams] = useSearchParams();
  const [token, setToken] = useState<string | undefined>();
  const navigate = useNavigate();

  useEffect(() => {
    const param = searchParams.get('inviteToken');
    if (!param) {
      navigate('/auth/login');
    } else {
      setToken(param);
    }
  }, [searchParams]);

  return (
    <Paper className="shadow overflow-hidden">
      <Box className="text-start bg-brand text-white mb-10 px-6 pt-6 pb-4">
        <Typography variant="h4" fontWeight="bold" textTransform="uppercase" className="font-black">
          Registration
        </Typography>
        <Typography className="opacity-50" variant="caption">
          Register on the internal platform
        </Typography>
      </Box>

      <RegistrationForm inviteToken={token} className="grid px-6 grid-cols-1 gap-4" />

      <Divider className="my-5 mx-6" />
      <Typography className="text-gray-500 px-6 pb-4 text-sm">
        <NavLink className="no-underline hover:underline text-gray-500" to="../login">
          Have an account?
        </NavLink>
      </Typography>
    </Paper>
  );
};

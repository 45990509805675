import { useAppSelector } from '@app/store/store';
import { InputTextField } from '@components/Fields/InputText/InputTextField';
import { FormWrapper } from '@components/Forms/FormWrapper';
import { CandidateStepCommentFormData, ClosingStepConfirmationModalProps } from '@components/Modals/CandidateStepComment/interface';
import { ModalWrapper } from '@components/Modals/RootModal/RootModal';
import { useModal } from '@hooks/useModal';
import InsertCommentOutlined from '@mui/icons-material/InsertCommentOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Collapse, Paper, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

export const CandidateStepConfirmationModal: FC<ClosingStepConfirmationModalProps> = ({ data, ...modalProps }) => {
  const { closeModal } = useModal();
  const step_status = useAppSelector((store) => store.candidates.candidate.step_status);
  const methods = useForm<CandidateStepCommentFormData>();
  const [expanded, setExpanded] = useState(data.mode === 'rejected');

  const expandHandler = (): void => {
    setExpanded(!expanded);
  };

  const closeModalHandler = () => {
    closeModal('CANDIDATE_STEP_CONFIRMATION_MODAL');
  };

  const submitRecipe: SubmitHandler<CandidateStepCommentFormData> = async ({ comment }) => data.successAction(comment);

  return (
    <ModalWrapper {...modalProps}>
      <Paper className="max-w-[600px] mx-2 shadow-2xl overflow-hidden bg-transparent">
        <Box className="bg-brand relative text-white p-6">
          <Typography className="font-bold uppercase opacity-80 mb-4" variant="h4">
            Do you confirm this action?
          </Typography>
          {data.content?.title && (
            <Typography className="opacity-70 mb-1" variant="subtitle2">
              {data.content?.title}
            </Typography>
          )}
          {data.content?.subtitle && (
            <Typography className="opacity-50" variant="caption">
              {data.content?.subtitle}
            </Typography>
          )}
          <InsertCommentOutlined className="absolute right-0 top-0 opacity-5 w-fit h-full transform rotate-45" />
        </Box>
        <FormWrapper
          className="flex flex-col p-6 bg-white"
          methods={methods}
          formProps={{
            onSubmit: methods.handleSubmit(submitRecipe)
          }}
        >
          <Collapse in={expanded}>
            <Box className="grid gap-4 mb-6">
              <InputTextField multiline minRows={3} name="comment" label="Comment" />
            </Box>
          </Collapse>
          <Box className="flex gap-2 md:flex-nowrap flex-wrap">
            <Button className="py-2 px-4 w-full md:w-1/2" onClick={expanded ? closeModalHandler : expandHandler} variant="outlined" color={expanded ? 'error' : 'primary'}>
              {expanded ? 'Cancel' : 'Add comment'}
            </Button>
            <LoadingButton className="py-2 px-4 w-full md:w-1/2" loading={step_status === 'loading'} type="submit" variant="contained">
              Confirm
            </LoadingButton>
          </Box>
        </FormWrapper>
      </Paper>
    </ModalWrapper>
  );
};

import { EventListItemProps } from '@components/Events/interface';
import { MomentIndicator } from '@components/Events/MomentIndicator/MomentIndicator';
import { inMomentSelector } from '@helpers/date/datesHelpers';
import { useModal } from '@hooks/useModal';
import { Box, Card, CardActionArea, Divider, Typography } from '@mui/material';
import classNames from 'classnames';
import { format, isValid } from 'date-fns';
import { FC, useMemo } from 'react';

export const EventListItem: FC<EventListItemProps> = (props) => {
  const { color, start, end, title, user_event, members, sliceType, event_date, className } = props;
  const { openModal } = useModal();

  const owner = useMemo(() => {
    const candidate = user_event?.find((users) => users.role === 'owner');
    return candidate ? members?.find((member) => member.id === candidate.user_id) : null;
  }, [members, user_event]);
  const moment = useMemo(() => inMomentSelector(start, end), []);

  const handleOpenModal = (): void => {
    openModal({
      modalName: 'EVENT_VIEW_MODAL',
      modalProps: {
        successAction: () => {},
        event_id: props.id,
        sliceType,
        event_date
      }
    });
  };

  return (
    <Card
      elevation={0}
      onClick={handleOpenModal}
      className={classNames(
        'group flex cursor-pointer flex-col cursor-pointer overflow-visible shadow-none bg-transparent  border-none relative mx-2 my-4  transition-all',
        { 'opacity-50': moment === 'past' },
        className
      )}
    >
      <CardActionArea className="rounded-r-xl">
        <Box className="py-2 pr-2">
          <span
            className={classNames('w-1 h-full min-h-full inset-y-0 flex z-10 absolute rounded-full transition-all', { 'group-hover:w-2': moment !== 'past' }, `bg-${color}-500`)}
          />
          <Box className="pl-4 flex items-center text-gray-700 mb-1 justify-between gap-2">
            {(!!start || !!end) && (
              <Box className="flex items-center mb-1">
                <Typography variant="subtitle1">{start && isValid(new Date(start)) ? format(new Date(start), 'HH:mm') : '--:--'}</Typography>
                <Divider className="w-4 mx-2" />
                <Typography variant="subtitle1">{end && isValid(new Date(end)) ? format(new Date(end), 'HH:mm') : '--:--'}</Typography>
              </Box>
            )}
            <MomentIndicator startDate={start} endDate={end} />
          </Box>
          <Box className="pl-4">
            <Typography className="flex justify-between" variant="subtitle1">
              {title}
            </Typography>
            {owner && (
              <Typography className="text-gray-500" variant="subtitle2">
                <span className="text-gray-300">Lead by: </span>
                {`${owner.first_name} ${owner.last_name ?? ''}`}
              </Typography>
            )}
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  );
};

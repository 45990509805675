import { useAppDispatch, useAppSelector } from '@app/store/store';
import { InputTextField } from '@components/Fields/InputText/InputTextField';
import { FormWrapper } from '@components/Forms/FormWrapper';
import { SkillsAddAndEditFormProps, SkillsAddAndEditFormValues } from '@components/Forms/interface';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button } from '@mui/material';
import { createSkill, updateSkill } from '@slices/skills/thunks';
import { debounce } from 'lodash';
import React, { FC, FormEvent, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

const schema = yup.object({
  name: yup.string().required('Skill name is required field'),
  comment: yup.string()
});

export const SkillsAddAndEditForm: FC<SkillsAddAndEditFormProps> = ({ className, mode }) => {
  const { data: selectedSkill, status } = useAppSelector((store) => store.skills.selectedSkill);

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const methods = useForm<SkillsAddAndEditFormValues>({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (mode === 'edit' && selectedSkill) {
      methods.reset({
        name: selectedSkill.name,
        comment: selectedSkill.comment
      });
    }
  }, [mode, selectedSkill, methods]);

  const handleReset = (e?: FormEvent): void => {
    e && e.preventDefault();
    navigate('/skills');
  };

  const submitRecipe: SubmitHandler<SkillsAddAndEditFormValues> = (data) => {
    const debouncedNavigate = debounce(() => navigate('/skills'), 300);

    if (mode === 'create') {
      dispatch(
        createSkill({
          name: data.name,
          comment: data.comment,
          afterAction: () => debouncedNavigate()
        })
      );
    }

    if (mode === 'edit') {
      if (params.id) {
        dispatch(
          updateSkill({
            id: params.id,
            name: data.name,
            comment: data.comment,
            afterAction: () => debouncedNavigate()
          })
        );
      }
    }
  };

  return (
    <FormWrapper
      className={className}
      methods={methods}
      formProps={{
        onSubmit: methods.handleSubmit(submitRecipe),
        onReset: handleReset
      }}
    >
      <InputTextField className="col-span-2" name="name" label="Name" />
      <InputTextField className="col-span-2" multiline minRows={5} name="comment" label="Comment" />
      <Box className="flex col-span-full gap-2 lg:gap-4 flex-wrap flex-row-reverse">
        <LoadingButton loading={status === 'loading'} className="py-2 px-8 w-full lg:w-fit" variant="contained" type="submit">
          Save
        </LoadingButton>
        <Button className="py-2 px-8 w-full lg:w-fit" variant="outlined" color="error" type="reset">
          Cancel
        </Button>
      </Box>
    </FormWrapper>
  );
};

import { BaseAsyncThunkOptions } from '@app/store/interface';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAllDepartments } from '@slices/departments/thunks';
import { globalSlice } from '@slices/global/globalSlice';
import { getPlatforms } from '@slices/platform/thunks';
import { getSelfInfo } from '@slices/profile/thunks';
import { getAllSkills } from '@slices/skills/thunks';
import { getAllSteps } from '@slices/steps/thukns';
import { getAllTags } from '@slices/tags/thunks';
import { getUsers } from '@slices/users/thunks';
import { getVacancies } from '@slices/vacancies/thunks';

export const init = createAsyncThunk<unknown, undefined, BaseAsyncThunkOptions>('global/init', async (arg, { dispatch }) => {
  const promises = [
    dispatch(getSelfInfo()),
    dispatch(getAllTags()),
    dispatch(getAllDepartments()),
    dispatch(getAllSteps()),
    dispatch(getAllSkills()),
    dispatch(getPlatforms()),
    dispatch(getUsers()),
    dispatch(getVacancies()),
    dispatch(getAllSteps())
  ];

  const handleUpdateStatus = () => {
    dispatch(globalSlice.actions.updateLoadingProgress({ actionsCount: promises.length }));
  };

  promises.forEach((thunk) => thunk.finally(handleUpdateStatus));
  const result = await Promise.allSettled(promises);
  setTimeout(() => {
    dispatch(globalSlice.actions.clearLoadingProcess(0));
  }, 1000);
  return result;
});

import ArrowBack from '@mui/icons-material/ArrowBack';
import { Box, Button, Container, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

export const ErrorsPage404: FC = () => {
  const navigate = useNavigate();

  const goBackHandler = (): void => {
    if (!!window.history.length) {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  return (
    <Box className="flex w-full h-full">
      <Container className="m-auto text-center max-w-[600px]">
        <Typography variant="h2" className="font-bold">
          Error 404
        </Typography>
        <Typography variant="h3" className="">
          Page not found :(
        </Typography>
        <Button className="mt-5 px-5 py-2" startIcon={<ArrowBack />} onClick={goBackHandler}>
          Go back
        </Button>
      </Container>
    </Box>
  );
};

export const ErrorsPage500: FC = () => {
  return (
    <Box className="flex w-full h-full">
      <Container className="m-auto text-center max-w-[600px]">
        <Typography variant="h2" className="font-bold">
          Error 500
        </Typography>
        <Typography variant="h3" className="">
          Initial server error :(
        </Typography>
      </Container>
    </Box>
  );
};

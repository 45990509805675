import { UsersListTable } from '@modules/users/components/Tables/UsersListTable';
import { UsersListActions } from '@modules/users/components/UsersListActions/UsersListActions';
import { FC } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { UsersListModuleProps } from '@modules/users/interface';

export const UsersListPage: FC<UsersListModuleProps> = () => {
  return (
    <>
      <Box className="flex mb-4 flex-wrap items-center gap-4 justify-between">
        <Typography variant="h4" fontWeight="bold" textTransform="uppercase" className="text-gray-300 font-black">
          Users
        </Typography>
        <UsersListActions />
      </Box>

      <Paper className="p-4 mb-2 shadow max-h-full flex flex-col overflow-hidden">
        <UsersListTable />
      </Paper>
    </>
  );
};

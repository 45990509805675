import { RestorePasswordForm } from '@components/Forms/Auth/RestorePasswordForm';
import { Box, Paper, Typography } from '@mui/material';
import React, { FC } from 'react';

export const RestorePasswordPage: FC = () => {
  return (
    <Paper className="shadow overflow-hidden">
      <Box className="text-start bg-brand text-white mb-10 px-6 pt-6 pb-4">
        <Typography variant="h4" fontWeight="bold" textTransform="uppercase" className="font-black">
          Password Restore
        </Typography>
        <Typography className="opacity-50" variant="caption">
          Tell us your email so we can send you a password restore link
        </Typography>
      </Box>
      <RestorePasswordForm className="px-6 pb-6" />
    </Paper>
  );
};

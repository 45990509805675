import { normalizeContacts } from '@components/AdditionalContactInfo/AdditionalContactsList';
import { UserAvatarBlock } from '@components/AvatarBlock/AvatarBlock';
import { UsersTableBodyProps } from '@modules/users/interface';
import MoreVert from '@mui/icons-material/MoreVert';
import { IconButton, Link as ALink, Link, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { NavLink } from 'react-router-dom';

export const UsersTableBody: FC<UsersTableBodyProps> = ({ list, onActionsClick }) => {
  const users = useMemo(() => {
    return list.map((item) => {
      const normalizedData = normalizeContacts(item?.contacts ?? []);

      return (
        <TableRow className="hover:bg-gray-100" key={item.id} hover tabIndex={-1}>
          {/*<TableCell padding="checkbox">*/}
          {/*  <Checkbox*/}
          {/*    onClick={(event) => handleSelect(event, item.id)}*/}
          {/*    name="checkbox-field"*/}
          {/*    checked={selected.includes(item.id)}*/}
          {/*  />*/}
          {/*</TableCell>*/}

          <TableCell className="max-w-[300px] text-ellipsis overflow-hidden">
            <UserAvatarBlock
              photo={null}
              mainText={<Link underline="hover" className="font-bold text-black" component={NavLink} to={item.id}>{`${item.first_name ?? ''} ${item.last_name ?? ''}`}</Link>}
              email={item.email}
            />
          </TableCell>

          <TableCell>{item.email}</TableCell>

          <TableCell>
            <Typography variant="subtitle2" className="text-right md:text-left">
              {normalizedData.phone?.map((item) => (
                <ALink
                  key={item.id}
                  underline="hover"
                  className="mr-2 font-bold hover:opacity-70 transition-all text-black whitespace-nowrap"
                  type="tel"
                  href={`tel:${item.value}`}
                >
                  {item.value}
                </ALink>
              ))}
            </Typography>
          </TableCell>

          <TableCell align="right">{item.role ?? ''}</TableCell>

          <TableCell className="w-fit" align="right">
            <IconButton aria-label={item.id} onClick={onActionsClick}>
              <MoreVert />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    });
  }, [list]);
  return <TableBody>{users}</TableBody>;
};

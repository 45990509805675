import { createSlice } from '@reduxjs/toolkit';
import { changePassword, getSelfInfo, updateSelfInfo } from '@slices/profile/thunks';
import { PROFILE } from './interface';

const initialState: PROFILE.ClientData = {
  status: 'unset',
  responseMessage: null,

  credentials: null,
  payment: {
    activePlan: 'basic'
  }
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    clearResponseMessages: (state) => {
      state.responseMessage = null;
    }
  },
  extraReducers: (builder) => {
    // GET SELF INFO
    builder.addCase(getSelfInfo.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(getSelfInfo.fulfilled, (state, { payload }) => {
      state.status = 'success';
      state.credentials = payload;
    });
    builder.addCase(getSelfInfo.rejected, (state, { payload }) => {
      state.status = 'error';
      state.responseMessage = {
        type: 'error',
        data: payload
      };
    });

    // CHANGE PASSWORD
    builder.addCase(changePassword.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(changePassword.fulfilled, (state) => {
      state.status = 'success';
      state.responseMessage = {
        type: 'success',
        data: [
          {
            message: 'Password successfully updated'
          }
        ]
      };
    });
    builder.addCase(changePassword.rejected, (state, { payload }) => {
      state.status = 'error';
      state.responseMessage = {
        type: 'error',
        data: payload
      };
    });

    // UPDATE SELF INFO
    builder.addCase(updateSelfInfo.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(updateSelfInfo.fulfilled, (state, { payload }) => {
      state.credentials = {
        ...state.credentials,
        ...(payload || {})
      };
      state.status = 'success';
      state.responseMessage = {
        type: 'success',
        data: [
          {
            message: 'User update successfully done'
          }
        ]
      };
    });
    builder.addCase(updateSelfInfo.rejected, (state, { payload }) => {
      state.status = 'error';
      state.responseMessage = {
        type: 'error',
        data: payload
      };
    });
  }
});

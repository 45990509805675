import { useAppDispatch, useAppSelector } from '@app/store/store';
import { useModal } from '@hooks/useModal';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import { Divider, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { deleteOneTag } from '@slices/tags/thunks';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

interface TagsListActionsPopoverProps {
  id: string;
}

export const TagsListActionsPopover: FC<TagsListActionsPopoverProps> = ({ id }) => {
  const status = useAppSelector((store) => store.tags.status);
  const dispatch = useAppDispatch();
  const { openModal, closeModal } = useModal();

  const handleDelete = () => {
    openModal({
      modalName: 'CONFIRM_MODAL',
      modalProps: {
        title: 'Delete this tag?',
        subtitle: 'You will not be able to undo this action',
        loading: status,
        successAction: () => {
          dispatch(
            deleteOneTag({
              id,
              afterAction: () => {
                closeModal('CONFIRM_MODAL');
              }
            })
          );
        }
      }
    });
  };

  return (
    <List dense className="min-w-[160px]">
      <ListItemButton component={Link} to={`${id}/edit`} className="mx-2 whitespace-nowrap px-2">
        <ListItemIcon className="min-w-fit mr-2">
          <Edit />
        </ListItemIcon>
        <ListItemText className="uppercase font-bold">Edit</ListItemText>
      </ListItemButton>

      <Divider className="my-2" />

      <ListItemButton className="text-red-500 mx-2 whitespace-nowrap px-2" onClick={handleDelete}>
        <ListItemIcon className="text-red-500 min-w-fit mr-2">
          <Delete />
        </ListItemIcon>
        <ListItemText className="uppercase font-bold">Delete</ListItemText>
      </ListItemButton>
    </List>
  );
};

import { API } from '@API/constants';
import { DownloadCandidateFileDTO, RemoveCandidateFileDTO, UploadCandidatesFilesDTO } from '@API/services/files/interface';
import { fetchData } from '@helpers/fetchData/fetchData';
import { getStorageItem } from '@helpers/localStorage/localStorageService';
import axios from 'axios';

const path = `${API.origin}/files`;

export class FilesService {
  public static fileUploadAbortController = new AbortController();

  public static uploadCandidateFiles<T>(dto: UploadCandidatesFilesDTO, onUploadProgress?: (progressEvent: ProgressEvent) => void) {
    const token = getStorageItem('token');
    const formData = new FormData();
    formData.append('candidate_id', dto.candidate_id);
    dto.files?.forEach((file) => formData.append('files', file));
    return axios.post<T>(path, formData, {
      headers: {
        Authorization: token ? `Bearer ${token}` : ''
      },
      signal: this.fileUploadAbortController.signal,
      onUploadProgress
    });
  }

  public static removeCandidateFiles<T>({ file_id }: RemoveCandidateFileDTO) {
    return fetchData<T>(`${path}/${file_id}`, 'DELETE');
  }

  public static downloadCandidateFile<T>({ file_id, filename }: DownloadCandidateFileDTO) {
    return fetchData<T>(`${path}/${file_id}?filename=${filename}`, 'GET', {}, 'file');
  }
}

import { Alert, Card, CardHeader, Divider, Typography } from '@mui/material';
import { FC } from 'react';
import { PlanCards } from '@components/Cards/Plan/PlanCards';

export const PlanAndBillingTab: FC = () => {
  return (
    <Card className="shadow">
      <CardHeader title={<Typography variant="h6">Change Plan</Typography>} subheader="You can upgrade and downgrade whenever you want" />

      <Divider />

      <Alert className="m-4 border-blue-300 border-solid" variant="outlined" severity="info">
        This is an info alert — check it out!
      </Alert>

      <PlanCards />
    </Card>
  );
};

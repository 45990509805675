import { PlatformActionsPopover } from '@components/Popovers/PlatformActions/PlatformsActionsPopover';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { Button, Popover } from '@mui/material';
import { FC, MouseEvent, useState } from 'react';

export const PlatformActions: FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button endIcon={anchorEl ? <ArrowDropUp /> : <ArrowDropDown />} variant="contained" onClick={handleClick} aria-describedby="platform-actions">
        Actions
      </Button>
      <Popover className="mt-2" id="platform-actions" open={!!anchorEl} onClose={handleClose} onClick={handleClose} anchorEl={anchorEl}>
        <PlatformActionsPopover />
      </Popover>
    </>
  );
};

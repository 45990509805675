import { useAppDispatch, useAppSelector } from '@app/store/store';
import { RoundedLoader } from '@components/Loaders/RoundedLoader';
import { NoDataPlug } from '@components/Plugs/NoDataPlug';
import { DashboardStepsCounterProps } from '@modules/dashboard/interface';
import { Box, Divider, Paper, Tab, Tabs, Typography } from '@mui/material';
import { getAllStepsDashboard } from '@slices/dashboard/thunks';
import { STEPS } from '@slices/steps/interface';
import { getAllSteps } from '@slices/steps/thukns';
import classNames from 'classnames';
import { FC, SyntheticEvent, useCallback, useEffect, useState } from 'react';

export const DashboardStepsCounter: FC<DashboardStepsCounterProps> = ({ className }) => {
  const [steps, setSteps] = useState<STEPS.Step[]>([]);
  const [valueStep, setValueStep] = useState('today');

  const { list: stepsList, status: dashboard_steps_status } = useAppSelector((store) => store.dashboard.steps);
  const {
    steps: { data: stepsData },
    status: steps_status
  } = useAppSelector((store) => store.steps);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setSteps(Object.values(stepsData.concat(stepsList).reduce((acc, item) => ({ ...acc, [item.id]: item }), {})).reverse() as STEPS.Step[]);
  }, [stepsList, stepsData]);

  useEffect(() => {
    dispatch(getAllSteps());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllStepsDashboard({ period: valueStep }));
  }, [valueStep, dispatch]);

  const handleChangeStep = useCallback((event: SyntheticEvent, newValue: string) => {
    setValueStep(newValue);
  }, []);

  return (
    <Paper className={classNames('pb-4 shadow gap-4 w-full flex flex-col relative', className)}>
      <Tabs className="" value={valueStep} onChange={handleChangeStep}>
        <Tab value="today" label="Today" />
        <Tab value="yesterday" label="Yesterday" />
      </Tabs>
      <Divider className="-mt-4 -mx-2 relative -top-[1px]" />
      <Box className="z-10 flex-1 basis-auto overflow-y-auto px-4 min-h-0">
        <Box className="text-center grid gap-2 grid-rows-5 grid-cols-2">
          {steps.map(({ name, color, candidate_step }, index) => (
            <Paper
              key={index}
              className={classNames(`p-4 text-center shadow-none flex flex-col justify-center text-xs transition rounded-2xl hover:shadow-lg bg-${color}-300`, {
                'col-span-2': index === 0
              })}
            >
              {name === 'Отказанно' ? (
                <Typography className={classNames(`text-3xl text-${color}-700`)}>
                  {
                    steps?.filter(({ candidate_step }) => {
                      if (candidate_step?.some(({ status }) => status === 'reject')) {
                        return candidate_step.filter((step) => step.status === 'reject');
                      }
                    }).length
                  }
                </Typography>
              ) : (
                <Typography className={classNames(`text-3xl text-${color}-700`)}>{candidate_step?.filter((step) => step.status === 'pending').length}</Typography>
              )}
              <Typography className={classNames(`text-sm text-${color}-700`)}>{name}</Typography>
            </Paper>
          ))}
        </Box>
        {!steps.length && <NoDataPlug className="relative" />}
      </Box>
      {(dashboard_steps_status === 'loading' || steps_status === 'loading') && <RoundedLoader />}
    </Paper>
  );
};

import { Box, Fade, Modal } from '@mui/material';
import classNames from 'classnames';
import React, { FC, useContext, useMemo } from 'react';
import { ModalsContext } from '../ModalContext/ModalsContext';
import { BaseModalProps, RootModalProps } from './interface';
import { modalsList } from './modalsList';

interface ModalWrapperProps extends BaseModalProps {}

export const ModalWrapper: FC<ModalWrapperProps> = ({ children, className, ...modalProps }) => {
  return (
    <Modal {...modalProps} className={classNames(className, 'flex overflow-y-auto py-8')}>
      <Fade in appear={true} unmountOnExit timeout={300}>
        <Box className="max-w-[600px] m-auto relative w-full outline-0 min-w-fit min-h-fit">{children}</Box>
      </Fade>
    </Modal>
  );
};

export const RootModal: FC<RootModalProps> = ({}) => {
  const { openModals, closeModal } = useContext(ModalsContext);

  const handleClose = (modalName: keyof typeof modalsList): void => {
    closeModal(modalName);
  };

  //TODO: Добавить React.lazy() для рендеринга модальных окон
  const modals = useMemo(() => {
    return openModals.map((modal) => {
      const ModalComponent = modalsList[modal.modalName];
      return <ModalComponent key={modal.modalName} data={modal.modalProps} open onClose={() => handleClose(modal.modalName)} />;
    });
  }, [openModals]);

  return <>{modals}</>;
};

import { GoBackButton } from '@components/Buttons/GoBack/GoBackButton';
import { DepartmentsAddAndEditForm } from '@components/Forms/Departments/DepartmentsAddAndEditForm';
import { Box, Container, Paper, Typography } from '@mui/material';
import React, { FC } from 'react';

export const DepartmentsAddPage: FC = () => {
  return (
    <Box>
      <Container className="p-0 max-w-[1200px]">
        <GoBackButton to=".." text="Back to departments" />
        <Box>
          <Box className="flex flex-wrap mb-5">
            <Box>
              <Typography className="mb-1 uppercase mb-2 text-gray-300 uppercase font-black" variant="h4">
                new Department
              </Typography>
            </Box>
          </Box>
          <Paper className="p-4">
            <DepartmentsAddAndEditForm className="grid gap-4 grid-cols-2" mode="create" />
          </Paper>
        </Box>
      </Container>
    </Box>
  );
};

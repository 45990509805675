import { TagsService } from '@API/services/tags/TagsService';
import { AfterActionProps, BaseAsyncThunkOptions, ListResponse } from '@app/store/interface';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { TAGS } from '@slices/tags/interface';
import { RequiredIn } from 'custom';

export const getAllTags = createAsyncThunk<ListResponse<TAGS.Tag[]>, undefined, BaseAsyncThunkOptions>('tags/getAll', async (_, thunkApi) => {
  const response = await TagsService.getAll();

  if (response.ok) {
    return {
      data: response.data.data,
      total: response.data.total
    };
  } else {
    return thunkApi.rejectWithValue(response.error);
  }
});

export const getOneTag = createAsyncThunk<TAGS.Tag, string, BaseAsyncThunkOptions>('tags/getOne', async (id, thunkApi) => {
  const response = await TagsService.getOne(id);

  if (response.ok) {
    return response.data.data;
  } else {
    return thunkApi.rejectWithValue(response.error);
  }
});

export const createTag = createAsyncThunk<TAGS.Tag, Omit<TAGS.Tag, 'id'> & AfterActionProps, BaseAsyncThunkOptions>(
  'tags/createTag',
  async ({ afterAction, ...data }, thunkApi) => {
    const response = await TagsService.create(data);

    if (response.ok) {
      afterAction && afterAction();
      return response.data.data;
    } else {
      return thunkApi.rejectWithValue(response.error);
    }
  }
);

export const updateTag = createAsyncThunk<TAGS.Tag, RequiredIn<TAGS.Tag, 'id'> & AfterActionProps, BaseAsyncThunkOptions>(
  'tags/update',
  async ({ id, afterAction, ...data }, thunkApi) => {
    const response = await TagsService.updateOne(id, data);

    if (response.ok) {
      afterAction && afterAction();
      return response.data.data;
    } else {
      return thunkApi.rejectWithValue(response.error);
    }
  }
);

export const deleteOneTag = createAsyncThunk<TAGS.Tag, Pick<TAGS.Tag, 'id'> & AfterActionProps, BaseAsyncThunkOptions>('tags/deleteOne', async ({ id, afterAction }, thunkApi) => {
  const response = await TagsService.deleteOne(id);

  if (response.ok) {
    afterAction && afterAction();
    return response.data.data;
  } else {
    return thunkApi.rejectWithValue(response.error);
  }
});


import { useAppSelector } from '@app/store/store';
import { useEmailHashIcon } from '@hooks/useEmailHashIcon';
import { Avatar, AvatarProps, Box, Typography } from '@mui/material';
import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';

interface AvatarBlockProps {
  photo?: string | null;
  mainText?: ReactNode;
  secondaryText?: ReactNode;
  className?: string;
  email?: string;
  avatarProps?: AvatarProps;
}

export const ClientAvatarBlock: FC<Pick<AvatarBlockProps, 'className'>> = ({ className }) => {
  const { credentials } = useAppSelector((store) => store.profile);

  const { image } = useEmailHashIcon(credentials?.email, 'robohash');

  return (
    <Box className={classNames('flex', className || '')}>
      <Avatar className="mr-2" alt={credentials?.email} src={image} />
      <Box>
        <Typography className="flex flex-col">
          <span>{(credentials?.first_name || '') + ' ' + (credentials?.last_name || '')}</span>
          {<span className="opacity-50 text-sm">{credentials?.email || ''}</span>}
        </Typography>
      </Box>
    </Box>
  );
};

export const UserAvatarBlock: FC<AvatarBlockProps> = ({ className, secondaryText, mainText, photo, email, avatarProps = {} }) => {
  const { image } = useEmailHashIcon(email, 'robohash');

  return (
    <Box className={classNames('flex', className || '')}>
      <Avatar {...avatarProps} className={classNames({ 'mr-2': mainText || secondaryText }, avatarProps?.className)} alt={email || 'user photo'} src={photo || image} />
      {!mainText && !secondaryText ? null : (
        <Box className="flex justify-center flex-col overflow-hidden">
          <Typography className="flex flex-col whitespace-nowrap overflow-ellipsis">
            <span className="whitespace-nowrap">{mainText}</span>
            {secondaryText && <span className="opacity-50 text-sm cursor-auto overflow-ellipsis whitespace-nowrap">{secondaryText}</span>}
            {/*{email && !secondaryText && <span className="opacity-50 text-sm">{email}</span>}*/}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

import { useAppDispatch, useAppSelector } from '@app/store/store';
import { InputTextField } from '@components/Fields/InputText/InputTextField';
import { FormWrapper } from '@components/Forms/FormWrapper';
import { SearchVacanciesFormFields, VacanciesListFiltersAndSortsProps } from '@modules/vacancies/interface';
import Search from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import { vacanciesSlice } from '@slices/vacancies/vacanciesSlice';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';

export const VacanciesListFiltersAndSorts: FC<VacanciesListFiltersAndSortsProps> = ({ className }) => {
  const dispatch = useAppDispatch();
  const { search } = useAppSelector((store) => store.vacancies.vacanciesList);
  const methods = useForm<SearchVacanciesFormFields>({
    mode: 'onChange',
    defaultValues: {
      search
    }
  });

  useEffect(() => {
    const subscription = methods.watch(({ search }) => {
      dispatch(vacanciesSlice.actions.touchSearchActions({ search }));
      dispatch(vacanciesSlice.actions.touchPagination({ page: 0 }));
    });
    return () => subscription.unsubscribe();
  }, [methods, dispatch]);

  return (
    <FormWrapper methods={methods} className={'mb-4 flex gap-2 flex-wrap ' + className || ''}>
      <InputTextField
        className="flex-1 min-w-[200px]"
        name="search"
        placeholder="Search vacancies"
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          )
        }}
      />
    </FormWrapper>
  );
};

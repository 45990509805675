import { APP_ROUTES_LIST } from '@modules/navbar/constants';
import { LeftNavbarProps } from '@modules/navbar/interface';
import Close from '@mui/icons-material/Close';
import { Box, Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';
import classNames from 'classnames';
import React, { FC, useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

export const LeftNavbar: FC<LeftNavbarProps> = (props) => {
  const matches = useMediaQuery('(min-width: 1200px)');
  const { pathname } = useLocation();
  const { leftDrawerIsOpen, setLeftDrawerIsOpen } = props;

  const spawnLinks = useMemo(
    () =>
      APP_ROUTES_LIST.map(({ path, icon: Icon, title }) => (
        <ListItemButton
          key={path}
          className={classNames('text-white my-1', { 'bg-indigo-900': pathname.split('/')[1] === path.split('/')[0] })}
          component={NavLink}
          to={path}
          color=""
        >
          <ListItemIcon>
            <Icon className="text-white" />
          </ListItemIcon>
          <ListItemText primary={title} />
        </ListItemButton>
      )),
    [pathname]
  );

  return (
    <Box>
      <Drawer
        PaperProps={{
          className: 'bg-brand'
        }}
        variant={matches ? 'permanent' : 'temporary'}
        open={matches || leftDrawerIsOpen}
        onClick={setLeftDrawerIsOpen}
        onClose={setLeftDrawerIsOpen}
        ModalProps={{
          keepMounted: true
        }}
      >
        <Box className="flex justify-between items-center ml-4 my-2">
          <Typography className="text-white font-bold uppercase" variant="subtitle1">
            May Bee
          </Typography>
          {!matches && (
            <IconButton onClick={setLeftDrawerIsOpen}>
              <Close className="text-white" />
            </IconButton>
          )}
        </Box>

        <List>{spawnLinks}</List>
      </Drawer>
    </Box>
  );
};

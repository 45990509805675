import { useAppDispatch } from '@app/store/store';
import { useModal } from '@hooks/useModal';
import { CandidatesListActionsPopoverProps } from '@modules/candidates/interface';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import Info from '@mui/icons-material/Info';
import { Box, Divider, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { deleteCandidate } from '@slices/candidates/thunks';
import { FC } from 'react';
import { Link } from 'react-router-dom';

export const CandidatesListActionsPopover: FC<CandidatesListActionsPopoverProps> = ({ id }) => {
  const dispatch = useAppDispatch();
  const { openModal, closeModal } = useModal();

  const handleDelete = () => {
    openModal({
      modalName: 'CONFIRM_MODAL',
      modalProps: {
        title: 'Delete this candidate?',
        subtitle: 'You will not be able to undo this action',

        successAction: () => {
          dispatch(
            deleteCandidate({
              id,
              afterAction: () => {
                closeModal('CONFIRM_MODAL');
              }
            })
          );
        }
      }
    });
  };

  return (
    <Box>
      <List dense className="min-w-[160px]">
        <ListItemButton component={Link} to={`${id}/edit`} className="mx-2 whitespace-nowrap px-2">
          <ListItemIcon className=" min-w-fit mr-2">
            <Edit />
          </ListItemIcon>
          <ListItemText className="uppercase font-bold">
            <Typography>Edit</Typography>
          </ListItemText>
        </ListItemButton>

        <ListItemButton component={Link} to={`${id}`} className=" mx-2 whitespace-nowrap px-2">
          <ListItemIcon className=" min-w-fit mr-2">
            <Info />
          </ListItemIcon>
          <ListItemText className="uppercase font-bold">
            <Typography>View</Typography>
          </ListItemText>
        </ListItemButton>

        <Divider className="my-2" />

        <ListItemButton className="text-red-500 mx-2 whitespace-nowrap px-2" onClick={handleDelete}>
          <ListItemIcon className="text-red-500 min-w-fit mr-2">
            <Delete />
          </ListItemIcon>
          <ListItemText className="uppercase font-bold">Delete</ListItemText>
        </ListItemButton>
      </List>
    </Box>
  );
};

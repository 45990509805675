import { SystemInfoFields } from '@app/store/interface';
import MoreTimeOutlined from '@mui/icons-material/MoreTimeOutlined';
import UpdateOutlined from '@mui/icons-material/UpdateOutlined';
import { Box, Card, Divider, ListItem, ListItemText, Typography } from '@mui/material';
import classNames from 'classnames';
import { DefaultComponentProps } from 'custom';
import { format, formatDistance, isValid } from 'date-fns';
import React, { FC } from 'react';

interface SystemInfoCardProps extends DefaultComponentProps, Omit<SystemInfoFields, 'deleted_at'> {}

export const SystemInfoCard: FC<SystemInfoCardProps> = ({ className, updated_at, created_at }) => {
  return (
    <Card className={classNames('shadow my-4', className)}>
      <ListItem className="flex flex-wrap">
        <ListItemText className="md:w-full md:max-w-[180px] mr-4">
          <Box className="flex items-center">
            <MoreTimeOutlined className="fill-gray-500 mr-2" />
            <Typography variant="subtitle1">Create date</Typography>
          </Box>
        </ListItemText>
        <ListItemText>
          <Typography variant="subtitle2" className="text-right lg:text-left opacity-70">
            {created_at && isValid(new Date(created_at)) ? format(new Date(created_at), 'dd MMMM yyyy, mm:HH') : '-'}
          </Typography>
        </ListItemText>
      </ListItem>
      <Divider />

      <ListItem className="flex flex-wrap">
        <ListItemText className="md:w-full md:max-w-[180px] mr-4">
          <Box className="flex items-center">
            <UpdateOutlined className="fill-gray-500 mr-2" />
            <Typography variant="subtitle1">Last update</Typography>
          </Box>
        </ListItemText>
        <ListItemText>
          <Typography variant="subtitle2" className="text-right lg:text-left opacity-70">
            {updated_at && isValid(new Date(updated_at)) ? `${formatDistance(new Date(), new Date(updated_at))} ago` : '-'}
          </Typography>
        </ListItemText>
      </ListItem>
    </Card>
  );
};

import { CURRENCIES } from '@app/store/constants';
import { InputSelect } from '@components/Fields/InputSelect/InputSelect';
import { InputTextField } from '@components/Fields/InputText/InputTextField';
import { Box, Typography } from '@mui/material';
import React, { FC, useState } from 'react';

export const CandidateSalarySelect: FC = () => {
  const [currency, setCurrency] = useState(CURRENCIES[0]);

  return (
    <Box className="flex-1 flex gap-2">
      <InputTextField
        type="number"
        InputProps={{
          inputProps: {
            min: 0
          },
          startAdornment: (
            <>
              <Typography className="text-gray-500 mr-4">{currency?.label.toUpperCase() ?? '?'}</Typography>
            </>
          )
        }}
        className="w-full min-w-[120px]"
        name="cash_salary"
        label="Salary"
      />
      <InputSelect
        textFieldProps={{
          className: 'min-w-[120px]',
          placeholder: 'USD'
        }}
        returnValue="id"
        className="w-full min-w-[120px]"
        label="Currency"
        disableClearable
        defaultValue={CURRENCIES[0]}
        optionLabelRefName="value"
        options={CURRENCIES}
        onSelect={(o) => setCurrency(o)}
        name="cash_currency"
      />
    </Box>
  );
};

import { DatePickerFieldProps } from '@components/Fields/DatePicker/interface';
import { TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export const DatePickerField: FC<DatePickerFieldProps> = ({ name, textFieldProps = {}, defaultValue, ...pickerProps }) => {
  const methods = useFormContext();
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        name={name}
        control={methods.control}
        render={({ field, fieldState }) => (
          <DatePicker
            {...pickerProps}
            value={field.value ?? defaultValue ?? null}
            onChange={(value) => field.onChange(value)}
            renderInput={(props) => (
              <TextField
                {...field}
                {...props}
                {...textFieldProps}
                error={!!fieldState.error || props.error}
                helperText={(fieldState.error?.message || (props.error && 'Incorrect date format')) ?? ''}
              />
            )}
          />
        )}
      />
    </LocalizationProvider>
  );
};

import { CURRENCIES } from '@app/store/constants';
import AccountBalanceWalletOutlined from '@mui/icons-material/AccountBalanceWalletOutlined';
import BusinessOutlined from '@mui/icons-material/BusinessOutlined';
import CakeOutlined from '@mui/icons-material/CakeOutlined';
import ChatOutlined from '@mui/icons-material/ChatOutlined';
import FmdGoodOutlined from '@mui/icons-material/FmdGoodOutlined';
import MailOutline from '@mui/icons-material/MailOutline';
import PersonOutlined from '@mui/icons-material/PersonOutlined';
import TravelExploreOutlined from '@mui/icons-material/TravelExploreOutlined';
import WorkOutlineOutlined from '@mui/icons-material/WorkOutlineOutlined';
import { Box, Card, CardHeader, Divider, Link as ALink, ListItem, ListItemText, Typography } from '@mui/material';
import { CANDIDATES } from '@slices/candidates/interface';
import { DefaultComponentProps } from 'custom';
import { differenceInYears, format, isValid } from 'date-fns';
import React, { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';

interface MainInfoSectionProps extends DefaultComponentProps {
  data: CANDIDATES.Candidate;
}

export const MainInfoSection: FC<MainInfoSectionProps> = ({ data }) => {
  const calculateAge = useCallback(
    (dob: string): number => {
      const date = format(new Date(dob), 'T');
      return differenceInYears(new Date(), Number(date));
    },
    [data?.birth_date]
  );

  return (
    <Card className="shadow xl:my-4">
      <CardHeader title="Main information" className="uppercase text-gray-600 pb-2" />
      <Divider />

      <ListItem className="flex flex-wrap hover:bg-gray-100/75 transition-all">
        <ListItemText className="md:w-full md:max-w-[180px] mr-4">
          <Box className="flex items-center">
            <PersonOutlined className="fill-gray-500 mr-2" />
            <Typography variant="subtitle1">Name</Typography>
          </Box>
        </ListItemText>
        <ListItemText>
          <Typography variant="subtitle2" className="text-right md:text-left opacity-70">
            {`${data?.first_name ?? ''} ${data?.last_name ?? ''}` ?? '-'}
          </Typography>
        </ListItemText>
      </ListItem>

      <Divider />

      <ListItem className="flex flex-wrap hover:bg-gray-100/75 transition-all">
        <ListItemText className="md:w-full md:max-w-[180px] mr-4">
          <Box className="flex items-center">
            <MailOutline className="fill-gray-500 mr-2" />
            <Typography variant="subtitle1">Email</Typography>
          </Box>
        </ListItemText>
        <ListItemText>
          <Typography variant="subtitle2" className="text-right md:text-left opacity-70">
            {data.email ?? '-'}
          </Typography>
        </ListItemText>
      </ListItem>

      <Divider />

      <ListItem className="flex flex-wrap hover:bg-gray-100/75 transition-all">
        <ListItemText className="md:w-full md:max-w-[180px] mr-4">
          <Box className="flex items-center">
            <CakeOutlined className="fill-gray-500 mr-2" />
            <Typography variant="subtitle1">Birthday</Typography>
          </Box>
        </ListItemText>
        <ListItemText>
          <Typography variant="subtitle2" className="text-right flex gap-2 md:text-left opacity-70">
            <span>{data.birth_date && isValid(new Date(data.birth_date)) && format(new Date(data.birth_date), 'd MMMM yyyy')}</span>
            <span> - </span>
            <span>{data.birth_date && isValid(new Date(data.birth_date)) && calculateAge(data.birth_date) && `${calculateAge(data.birth_date)} years`}</span>
          </Typography>
        </ListItemText>
      </ListItem>

      <Divider />

      <ListItem className="flex flex-wrap hover:bg-gray-100/75 transition-all">
        <ListItemText className="md:w-full md:max-w-[180px] mr-4">
          <Box className="flex items-center">
            <AccountBalanceWalletOutlined className="fill-gray-500 mr-2" />
            <Typography variant="subtitle1">Salary</Typography>
          </Box>
        </ListItemText>
        <ListItemText>
          <Typography variant="subtitle2" className="opacity-70 text-right md:text-left">
            {!!data.cash_salary && `${data.cash_salary ?? ''} ${data.cash_salary ? CURRENCIES.find((item) => item.value === data?.cash_currency)?.label : ''}`}
          </Typography>
        </ListItemText>
      </ListItem>
      <Divider />

      <ListItem className="flex flex-wrap hover:bg-gray-100/75 transition-all">
        <ListItemText className="md:w-full md:max-w-[180px] mr-4">
          <Box className="flex items-center">
            <FmdGoodOutlined className="fill-gray-500 mr-2" />
            <Typography variant="subtitle1">Location</Typography>
          </Box>
        </ListItemText>
        <ListItemText>
          <Typography variant="subtitle2" className="text-right md:text-left opacity-70">
            {`${data.city ?? ''} ${data.city && data.country ? `(${data.country})` : data.country ?? ''}`}
          </Typography>
        </ListItemText>
      </ListItem>
      <Divider />

      <ListItem className="flex flex-wrap hover:bg-gray-100/75 transition-all">
        <ListItemText className="md:w-full md:max-w-[180px] mr-4">
          <Box className="flex items-center">
            <BusinessOutlined className="fill-gray-500 mr-2" />
            <Typography variant="subtitle1">Department</Typography>
          </Box>
        </ListItemText>
        <ListItemText>
          <Typography variant="subtitle2" className="opacity-70 text-right md:text-left">
            {!!data.department && data.department.name}
          </Typography>
        </ListItemText>
      </ListItem>
      <Divider />

      <ListItem className="flex flex-wrap hover:bg-gray-100/75 transition-all">
        <ListItemText className="md:w-full md:max-w-[180px] mr-4">
          <Box className="flex items-center">
            <TravelExploreOutlined className="fill-gray-500 mr-2" />
            <Typography variant="subtitle1">Platform</Typography>
          </Box>
        </ListItemText>
        <ListItemText>
          <Typography variant="subtitle2" className="opacity-70 text-right md:text-left">
            {!!data.platform && (
              <ALink underline="hover" target="_blank" className="mr-2 hover:opacity-70 transition-all text-black no-underline" href={data.platform.url}>
                {data.platform.name}
              </ALink>
            )}
          </Typography>
        </ListItemText>
      </ListItem>
      <Divider />

      <ListItem className="flex flex-wrap hover:bg-gray-100/75 transition-all">
        <ListItemText className="md:w-full md:max-w-[180px] mr-4">
          <Box className="flex items-center">
            <WorkOutlineOutlined className="fill-gray-500 mr-2" />
            <Typography variant="subtitle1">Vacancy</Typography>
          </Box>
        </ListItemText>
        <ListItemText>
          <Typography variant="subtitle2" className="opacity-70 text-right md:text-left">
            {!!data.vacancy && (
              <Link target="_blank" className="mr-2 hover:opacity-70 transition-all text-black no-underline" to={`/vacancies/${data.vacancy.id}`}>
                {data.vacancy.name}
              </Link>
            )}
          </Typography>
        </ListItemText>
      </ListItem>
      <Divider />

      <ListItem className="flex flex-wrap hover:bg-gray-100/75 transition-all">
        <ListItemText className="md:w-full md:max-w-[180px] mr-4">
          <Box className="flex items-center">
            <ChatOutlined className="fill-gray-500 mr-2" />
            <Typography variant="subtitle1">Comment</Typography>
          </Box>
        </ListItemText>
        <ListItemText>
          <Typography variant="subtitle2" className="opacity-70">
            {data.comment || '-'}
          </Typography>
        </ListItemText>
      </ListItem>
    </Card>
  );
};

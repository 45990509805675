import { createSlice } from '@reduxjs/toolkit';
import { SKILLS } from '@slices/skills/interface';
import { createSkill, deleteOneSkill, getAllSkills, getOneSkill, updateSkill } from './thunks';

const initialState: SKILLS.SkillsSlice = {
  status: 'unset',
  responseMessage: null,

  search: '',
  sort: '',

  skills: {
    data: [],
    total: 0
  },

  selectedSkill: {
    status: 'unset',
    data: null
  }
};

export const skillsSlice = createSlice({
  name: 'skills',
  initialState,
  reducers: {
    touchSearchActions: (store, { payload }: Record<'payload', Record<'search', string>>) => {
      store.search = payload.search;
    },
    setSkillFromAll: (store, { payload }: Record<'payload', SKILLS.Skill>) => {
      store.selectedSkill.data = payload;
      store.selectedSkill.status = 'success';
    },
    clearResponseMessages: (state) => {
      state.responseMessage = null;
    }
  },
  extraReducers: (builder) => {
    //GET ALL
    builder.addCase(getAllSkills.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(getAllSkills.fulfilled, (state, { payload }) => {
      state.skills = payload;
      state.status = 'success';
    });
    builder.addCase(getAllSkills.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.status = 'error';
    });

    //GET ONE
    builder.addCase(getOneSkill.pending, (state) => {
      state.selectedSkill.status = 'loading';
    });
    builder.addCase(getOneSkill.fulfilled, (state, { payload }) => {
      state.selectedSkill.data = payload;
      state.selectedSkill.status = 'success';
    });
    builder.addCase(getOneSkill.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.selectedSkill.status = 'error';
    });

    //CREATE
    builder.addCase(createSkill.pending, (state) => {
      state.status = 'loading';
      state.selectedSkill.status = 'loading';
    });
    builder.addCase(createSkill.fulfilled, (state) => {
      state.status = 'success';
      state.selectedSkill.status = 'success';
      state.responseMessage = {
        type: 'success',
        data: [
          {
            message: 'Skill successfully added'
          }
        ]
      };
    });
    builder.addCase(createSkill.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.status = 'error';
      state.selectedSkill.status = 'error';
    });

    //UPDATE ONE
    builder.addCase(updateSkill.pending, (state) => {
      state.status = 'loading';
      state.selectedSkill.status = 'loading';
    });
    builder.addCase(updateSkill.fulfilled, (state, { payload }) => {
      state.selectedSkill.data = payload;
      state.skills.data = [...state.skills.data.filter((skill) => skill.id !== skill.id), payload];
      state.responseMessage = {
        type: 'success',
        data: [
          {
            message: 'Skill successfully edited'
          }
        ]
      };
      state.status = 'unset';
      state.selectedSkill.status = 'success';
    });
    builder.addCase(updateSkill.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.selectedSkill.status = 'error';
    });

    //DELETE ONE
    builder.addCase(deleteOneSkill.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(deleteOneSkill.fulfilled, (state, { payload }) => {
      state.selectedSkill.data = null;
      state.skills.data = state.skills.data.filter((tag) => tag.id !== payload.id);
      state.skills.total--;
      state.responseMessage = {
        type: 'success',
        data: [
          {
            message: 'Skill successfully deleted'
          }
        ]
      };
      state.selectedSkill.status = 'unset';
      state.status = 'success';
    });
    builder.addCase(deleteOneSkill.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.status = 'error';
    });
  }
});

import { API } from '@API/constants';
import { CreateEventDTO } from '@API/services/events/interfaces';
import { ResponseBaseType, ResponseListBaseType } from '@API/services/interfaces';
import { fetchData } from '@helpers/fetchData/fetchData';

const path = `${API.origin}/events`;

export class EventsService {
  public static getMany<T>(query?: string) {
    return fetchData<ResponseListBaseType<T>>(`${path}${query ?? ''}`);
  }

  public static getOne<T>(id?: string) {
    return fetchData<ResponseBaseType<T>>(`${path}/${id ?? ''}`);
  }

  public static create<T>(dto: CreateEventDTO) {
    return fetchData<ResponseBaseType<T>>(path, 'POST', dto);
  }

  public static edit<T>({ id, ...dto }: Partial<CreateEventDTO> & { id: string }) {
    return fetchData<ResponseBaseType<T>>(`${path}/${id}`, 'PATCH', dto);
  }

  public static delete<T>(id: string) {
    return fetchData<ResponseBaseType<T>>(`${path}/${id}`, 'DELETE');
  }
}

import { ChangePasswordDTO, ProfileService, UpdateProfileDTO } from '@API/services/profile/profileService';
import { AfterActionProps, BaseAsyncThunkOptions } from '@app/store/interface';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { PROFILE } from '@slices/profile/interface';

export const getSelfInfo = createAsyncThunk<PROFILE.Profile, AfterActionProps | undefined, BaseAsyncThunkOptions>('profile/get', async (arg, thunkApi) => {
  const response = await ProfileService.getProfile();
  arg?.afterAction && arg.afterAction();

  if (response.ok) {
    return response.data.data;
  } else {
    return thunkApi.rejectWithValue(response.error);
  }
});

export const updateSelfInfo = createAsyncThunk<PROFILE.Profile, Partial<UpdateProfileDTO>, BaseAsyncThunkOptions>('profile/update', async (data, thunkApi) => {
  const response = await ProfileService.updateProfile(data);

  if (response.ok) {
    return response.data.data;
  } else {
    return thunkApi.rejectWithValue(response.error);
  }
});

export const changePassword = createAsyncThunk<unknown, ChangePasswordDTO & AfterActionProps, BaseAsyncThunkOptions>(
  'profile/changePassword',
  async ({ afterAction, ...data }, thunkApi) => {
    const response = await ProfileService.changePassword(data);

    if (response.ok) {
      afterAction && afterAction();
      return;
    } else {
      return thunkApi.rejectWithValue(response.error);
    }
  }
);


import Add from '@mui/icons-material/Add';
import Mail from '@mui/icons-material/Mail';
import { Box, Button } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useModal } from '@hooks/useModal';

export const UsersListActions: FC = () => {
  const { openModal } = useModal();

  const inviteHandler = (): void => {
    openModal({ modalName: 'SEND_INVITE_MODAL' });
  };

  return (
    <Box className="flex gap-4">
      <Button variant="outlined" startIcon={<Mail />} onClick={inviteHandler}>
        invite
      </Button>
      <Button component={Link} variant="contained" to="new" startIcon={<Add />}>
        Create
      </Button>
    </Box>
  );
};

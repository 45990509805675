import { ResponseMessageFormats } from '@app/store/interface';
import { useAppDispatch } from '@app/store/store';
import Close from '@mui/icons-material/Close';
import { Box, IconButton, Typography } from '@mui/material';
import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import classNames from 'classnames';
import { throttle } from 'lodash';
import { SnackbarKey, useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo } from 'react';

export const useNotifications = (responseMessage: ResponseMessageFormats | null, clearMessagesAction?: ActionCreatorWithoutPayload) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const throttledClean = useCallback(
    throttle(() => clearMessagesAction && dispatch(clearMessagesAction()), 1000, { trailing: false }),
    []
  );

  const handleClose = (key: SnackbarKey) => {
    return () => closeSnackbar(key);
  };
  const action = (key: SnackbarKey) => (
    <IconButton className="group-hover:opacity-100 opacity-0 transition-all" size="small" onClick={handleClose(key)}>
      <Close fontSize="small" className="fill-white" />
    </IconButton>
  );
  const className = useMemo(() => 'rounded-xl group', []);

  useEffect(() => {
    if (responseMessage && responseMessage.type === 'error') {
      responseMessage.data?.forEach((item) => {
        enqueueSnackbar(
          <Box>
            <Typography variant="body2">{item.name}</Typography>
            {item.message && !!item.message.length && <Typography variant="caption">{item.message.join(', ')}</Typography>}
          </Box>,

          {
            className: classNames(className, 'flex items-start'),
            variant: 'error',
            onClose: throttledClean,
            action
          }
        );
      });
    }

    if (responseMessage && responseMessage.type === 'warning') {
      responseMessage.data?.forEach((item) => {
        enqueueSnackbar(
          <Box>
            <Typography variant="body2">{item.name}</Typography>
            {item.message && !!item.message.length && <Typography variant="caption">{item.message.join(', ')}</Typography>}
          </Box>,

          {
            className: classNames(className, 'flex items-start'),
            variant: 'warning',
            onClose: throttledClean,
            action
          }
        );
      });
    }

    if (responseMessage && responseMessage.type === 'success') {
      responseMessage.data?.forEach((item) => {
        enqueueSnackbar(item.message || item.name, {
          className,
          variant: 'success',
          onClose: throttledClean,
          action
        });
      });
    }

    if (responseMessage && responseMessage.type === 'info') {
      responseMessage.data?.forEach((item) => {
        enqueueSnackbar(item.message || item.name, {
          className,
          variant: 'info',
          onClose: throttledClean,
          action
        });
      });
    }
  }, [responseMessage]);
};

import { TableHeadCell } from '@app/store/interface';
import { TemplatesColumns, TemplatesTableHeaderProps } from '@modules/templates/interface';
import { TableCell, TableHead, TableRow } from '@mui/material';
import classNames from 'classnames';
import { FC } from 'react';

const head: TableHeadCell<TemplatesColumns>[] = [
  {
    id: 'logo',
    label: 'Logo'
  },
  {
    id: 'name',
    label: 'Name'
  },
  {
    id: 'url',
    label: 'Email'
  }
];

export const TemplatesTableHeader: FC<TemplatesTableHeaderProps> = () => {
  return (
    <TableHead>
      <TableRow>
        {/*<TableCell padding="checkbox">*/}
        {/*  <Checkbox*/}
        {/*    color="primary"*/}
        {/*    indeterminate={numSelected > 0 && numSelected < rowCount}*/}
        {/*    checked={rowCount > 0 && numSelected === rowCount}*/}
        {/*    onChange={onSelectAllClick}*/}
        {/*    inputProps={{*/}
        {/*      'aria-label': 'select all desserts',*/}
        {/*    }}*/}
        {/*  />*/}
        {/*</TableCell>*/}

        {head.map((headCell) => (
          <TableCell
            className={classNames({ 'w-[40px]': headCell.id === 'logo' }, 'uppercase py-1')}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
        <TableCell colSpan={1} size="small" align="right" className="font-bold w-10" />
      </TableRow>
    </TableHead>
  );
};

import { arrayMove } from '@dnd-kit/sortable';
import { createSlice } from '@reduxjs/toolkit';
import { KANBAN } from '@slices/kanban/interface';
import { changeCandidateKanbanStep, changeKanbanStepOrder, getKanbanVacancy, getKanbanVacancyStepCandidates } from '@slices/kanban/thunks';

const initialState: KANBAN.KanbanSlice = {
  status: 'unset',
  loading_column_ids: [],
  responseMessage: null,
  selected_vacancy_id: '',
  vacancies: {}
};

export const kanbanSlice = createSlice({
  name: 'kanban',
  initialState,
  reducers: {
    clearResponseMessages: (state) => {
      state.responseMessage = null;
    },
    setVacancyID: (state, { payload }: { payload: string }) => {
      state.selected_vacancy_id = payload;
    },
    moveTicketBetweenContainers: (state, { payload }: { payload: { overContainerIndex: number; activeContainerIndex: number; ticketID: string; vacancyID: string } }) => {
      const { vacancyID, ticketID, overContainerIndex, activeContainerIndex } = payload;
      const activeContainer = state.vacancies[vacancyID].steps[activeContainerIndex].candidates;
      const overContainer = state.vacancies[vacancyID].steps[overContainerIndex].candidates;
      const ticketData = activeContainer.find((candidate) => candidate.id === ticketID);

      if (!ticketData) {
        return;
      }

      state.vacancies[vacancyID].steps[activeContainerIndex].candidates = activeContainer.filter((candidate) => candidate.id !== ticketID);
      overContainer.unshift(ticketData);
    }
  },
  extraReducers: ({ addCase }) => {
    // GET VACANCY
    addCase(getKanbanVacancy.pending, (state) => {
      state.status = 'loading';
    });
    addCase(getKanbanVacancy.fulfilled, (state, { payload: { steps, candidates, candidate_step, ...payload } }) => {
      // const vacancyHasBeenLoaded = state.vacancies[payload.id]

      const emptySteps = {
        ...payload,
        steps: steps.map(({ candidate_step, candidates, ...step }) => ({ ...step, candidates: [] })).sort((a, b) => (a.ordering < b.ordering ? -1 : 1))
      };
      state.selected_vacancy_id = payload.id;
      state.vacancies = {
        ...state.vacancies,
        [payload.id]: state.vacancies[payload.id] ?? emptySteps
      };
      state.status = 'success';
    });
    addCase(getKanbanVacancy.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.status = 'error';
    });

    // GET STEP
    addCase(getKanbanVacancyStepCandidates.pending, (state, { meta }) => {
      state.loading_column_ids.push(meta.arg.step_id);
    });
    addCase(getKanbanVacancyStepCandidates.fulfilled, (state, { payload, meta }) => {
      const vacancy = state.vacancies[meta.arg.vacancy_id];
      if (!vacancy) {
        return;
      }

      const stepIndex = vacancy.steps.findIndex((step) => step.id === meta.arg.step_id);
      if (stepIndex === -1) {
        return;
      }

      if (meta.arg.addData) {
        vacancy.steps[stepIndex].candidates = [...vacancy.steps[stepIndex].candidates, ...payload];
      } else {
        vacancy.steps[stepIndex].candidates = payload;
      }

      state.loading_column_ids = state.loading_column_ids.filter((id) => id !== meta.arg.step_id);
    });
    addCase(getKanbanVacancyStepCandidates.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.status = 'error';
    });

    // UPDATE ORDER
    addCase(changeKanbanStepOrder.pending, (state, { meta }) => {
      state.status = 'loading';
      const { vacancy_id, active_index, over_index } = meta.arg;

      if (active_index === undefined || over_index === undefined || !vacancy_id) {
        return;
      }
      const vector: 'left' | 'right' = active_index < over_index ? 'left' : 'right';
      const vacancy = state.vacancies[vacancy_id];
      vacancy.steps.map((step, step_index) => {
        if (step_index === active_index) {
          return {
            ...step,
            ordering: vacancy.steps[over_index].ordering
          };
        }

        if (vector === 'right' && step_index > active_index && step_index <= over_index) {
          return {
            ...step,
            ordering: --step.ordering
          };
        }

        if (vector === 'left' && step_index < active_index && step_index >= over_index) {
          return {
            ...step,
            ordering: ++step.ordering
          };
        }
        return step;
      });
      vacancy.steps = arrayMove(vacancy.steps, active_index, over_index);
    });
    addCase(changeKanbanStepOrder.fulfilled, (state) => {
      state.status = 'success';
    });
    addCase(changeKanbanStepOrder.rejected, (state, { payload, meta }) => {
      const { vacancy_id, cached_data } = meta.arg;
      if (!vacancy_id) {
        return;
      }
      const vacancy = state.vacancies[vacancy_id];
      state.status = 'error';
      vacancy.steps = cached_data ?? [];
      state.responseMessage = {
        type: 'error',
        data: payload
      };
    });

    // CHANGE CANDIDATE STEP
    addCase(changeCandidateKanbanStep.pending, (state, { meta }) => {
      const { steps } = meta.arg;
      if (!steps) {
        return;
      }

      steps.forEach((step) => state.loading_column_ids.push(step.step_id));
    });
    addCase(changeCandidateKanbanStep.fulfilled, (state, { meta }) => {
      const { steps } = meta.arg;
      if (!steps) {
        return;
      }

      state.loading_column_ids = [];
    });
    addCase(changeCandidateKanbanStep.rejected, (state, { payload }) => {
      state.responseMessage = {
        type: 'error',
        data: payload
      };
    });
  }
});

import { useAppSelector } from '@app/store/store';
import { useEmailHashIcon } from '@hooks/useEmailHashIcon';
import { Avatar, Popover } from '@mui/material';
import React, { FC, MouseEvent, useMemo, useState } from 'react';
import { MainToolsPopover } from '../../Popovers/MainToolsPopover/MainToolsPopover';

interface AvatarToolItemProps {}

export const AvatarToolItem: FC<AvatarToolItemProps> = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const { credentials, status } = useAppSelector((store) => store.profile);

  const { email } = useMemo(() => {
    if (status === 'success' && credentials) {
      return credentials;
    } else {
      return {
        email: ''
      };
    }
  }, [credentials, status]);

  const { image } = useEmailHashIcon(email, 'robohash');

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <Avatar className="cursor-pointer" onClick={handleClick} aria-describedby="user-info" alt={email} src={image} />
      <Popover className="mt-2" id="user-info" onClose={handleClose} onClick={handleClose} anchorEl={anchorEl} open={!!anchorEl}>
        <MainToolsPopover />
      </Popover>
    </>
  );
};

import { useAppSelector } from '@app/store/store';
import { InputSelect } from '@components/Fields/InputSelect/InputSelect';
import { VACANCIES } from '@slices/vacancies/interface';
import React, { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  mode: 'edit' | 'create';
}

export const CandidateVacancyPlatform: FC<Props> = ({}) => {
  const {
    status,
    vacanciesList: {
      list: { data: vacancies }
    }
  } = useAppSelector((store) => store.vacancies);
  const { setValue, watch } = useFormContext();
  const selected: (VACANCIES.Vacancy & VACANCIES.VacancyRelations) | null = watch('vacancy', null);

  useEffect(() => {
    if (!selected) {
      setValue('platform', null);
    }
  }, [selected]);

  return (
    <>
      <InputSelect className="flex-1 md:w-full" optionLabelRefName="name" options={vacancies} name="vacancy" label="Vacancy" loading={status === 'loading'} />

      <InputSelect
        disabled={!selected}
        className="flex-1 md:w-full"
        optionLabelRefName="name"
        options={selected?.platforms ?? []}
        name="platform"
        label="Platform"
        loading={status === 'loading'}
      />
    </>
  );
};

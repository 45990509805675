import { useAppDispatch, useAppSelector } from '@app/store/store';
import { UserAvatarBlock } from '@components/AvatarBlock/AvatarBlock';
import { FileCard } from '@components/Cards/File/FileCard';
import { StyledChip } from '@components/Chips/StyledChip/StyledChip';
import { ColorSelect } from '@components/Fields/ColorSelect/ColorSelect';
import { TAGS_COLORS } from '@components/Fields/ColorSelect/constants';
import { InputMultiselect } from '@components/Fields/InputMultiselect/InputMultiselect';
import { InputTextField } from '@components/Fields/InputText/InputTextField';
import { DateRangeSection } from '@components/Forms/Events/sections/DateRangeSection';
import { FormWrapper } from '@components/Forms/FormWrapper';
import { EventFormProps, EventFormValues } from '@components/Forms/interface';
import { yupResolver } from '@hookform/resolvers/yup';
import { useModal } from '@hooks/useModal';
import { Attachment, CheckCircle } from '@mui/icons-material';
import AddRounded from '@mui/icons-material/AddRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Collapse, List, Typography } from '@mui/material';
import { createEvent, editEvent } from '@slices/events/thunks';
import classNames from 'classnames';
import { sortBy } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';

const schema = yup.object({
  meet_link: yup.string().url()
});

export const CreateAndEditeEventForm: FC<EventFormProps> = ({ cancelAction, successAction, mode, className }) => {
  const dispatch = useAppDispatch();
  const { closeModal } = useModal();

  const { status: profile_status, credentials: profile_data } = useAppSelector((store) => store.profile);
  const { data: candidate_data } = useAppSelector((store) => store.candidates.candidate);
  const { viewData: event_data, status: event_status } = useAppSelector((store) => store.events);
  const {
    status: users_list_status,
    usersList: { data: users_list }
  } = useAppSelector((store) => store.users.usersList);
  const [commentExpanded, setCommentExpanded] = useState(!event_data?.description);
  const [filesExpanded, setFilesExpanded] = useState(mode === 'create');

  const methods = useForm<EventFormValues>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      start: mode === 'create' ? new Date(Date.now() + 60000) : undefined,
      end: mode === 'create' ? new Date(Date.now() + 60000 * 30) : undefined,
      files: []
    }
  });
  const watchAttachments = methods.watch('files');

  useEffect(() => {
    if (users_list && profile_data && users_list_status === 'success' && profile_status === 'success') {
      methods.setValue('members', [profile_data]);
    }
    methods.setValue('color', TAGS_COLORS.gray);
  }, [profile_status, users_list_status, profile_data, users_list]);

  useEffect(() => {
    if (event_data && mode === 'edit') {
      methods.reset({
        color: event_data.color,
        start: event_data.start || null,
        end: event_data.end || null,
        description: event_data.description ?? '',
        meet_link: event_data.meet_link ?? '',
        members: event_data.members,
        title: event_data.title ?? '',
        files: event_data.files?.map((file) => file.id) ?? []
      });
      setCommentExpanded(!event_data?.description);
    }
  }, [methods, event_data, mode]);

  const submitRecipe: SubmitHandler<EventFormValues> = ({ start, end, ...data }): void => {
    if (mode === 'edit' && event_data) {
      dispatch(
        editEvent({
          id: event_data?.id,
          sliceType: 'candidates',
          candidate_id: event_data.candidate_id,
          start: start ? new Date(start) : start,
          end: end ? new Date(end) : end,
          ...data,
          members: data.members?.map((user) => user.id) ?? [],
          afterAction: () => {
            successAction && successAction();
          }
        })
      );
    }

    if (mode === 'create' && candidate_data) {
      dispatch(
        createEvent({
          sliceType: 'candidates',
          candidate_id: candidate_data.id,
          title: data.title,
          start: start ? new Date(start) : start,
          end: end ? new Date(end) : end,
          color: data.color,
          members: data.members?.map((user) => user.id) ?? [],
          description: data.description,
          meet_link: data.meet_link,
          files: data.files,
          afterAction: () => {
            closeModal('CREATE_EVENT_MODAL');
          }
        })
      );
    }
  };

  const attachFileHandler = (id: string) => {
    const attachments = methods.getValues('files');
    if (!attachments) {
      return;
    }

    const fileIsAttached: boolean = attachments.includes(id);
    const payload: string[] = fileIsAttached ? attachments.filter((fileID) => fileID !== id) : [...attachments, id];
    return () => methods.setValue('files', payload);
  };

  return (
    <FormWrapper
      className={classNames('flex flex-col gap-4 bg-white', className)}
      methods={methods}
      formProps={{
        onSubmit: methods.handleSubmit(submitRecipe)
      }}
    >
      <InputTextField required placeholder="Add title" label="Event name" name="title" className="w-full" />

      <InputTextField placeholder="Add meeting link" label="Meeting link" name="meet_link" className="w-full" />

      <DateRangeSection form_mode={mode} />

      <ColorSelect name="color" label="Event color" placeholder="Choose color" />

      <Box className={classNames('flex gap-2')}>
        <Box className="w-full">
          <InputMultiselect
            textFieldProps={{
              className: 'w-full'
            }}
            chipComponent={(data, { className, ...chipControlProps }) => (
              <StyledChip
                {...chipControlProps}
                // disabled={data.role === 'owner'}
                className={classNames(className, 'h-[40px] px-0.5 bg-gray-100 transition-all')}
                label={
                  <Box className="flex flex-col">
                    <Typography variant="subtitle2" className="text-gray-500 -mb-1">
                      {`${data.first_name} ${data.last_name}`}
                    </Typography>
                    <Typography variant="caption" className="text-gray-400 -mt-0.5">
                      {data.role}
                    </Typography>
                  </Box>
                }
                avatar={<UserAvatarBlock email={data.email} avatarProps={{ className: 'w-full h-full bg-white' }} />}
              />
            )}
            optionComponent={(data) => <UserAvatarBlock email={data.email} mainText={`${data.first_name} ${data.last_name}`} secondaryText={data.role} />}
            className="w-full"
            label="Members"
            loading={users_list_status === 'loading'}
            name="members"
            options={users_list}
            optionLabelRefName="first_name"
            optionValueRefName="id"
          />
        </Box>
      </Box>

      <Box
        className={classNames('flex flex-col gap-2 mb-4', {
          'items-center': commentExpanded,
          'items-start': !commentExpanded
        })}
      >
        <Box className="w-full">
          <Collapse in={commentExpanded}>
            <Button size="small" startIcon={<AddRounded fontSize="small" />} className="text-gray-500" onClick={() => setCommentExpanded(!commentExpanded)}>
              add description
            </Button>
          </Collapse>
          <Collapse className="w-full" in={!commentExpanded}>
            <InputTextField className="w-full mb-2" label="Description" placeholder="Add short description" name="description" multiline minRows="3" />
          </Collapse>
        </Box>
        <Box className="w-full">
          <Collapse in={filesExpanded}>
            <Button size="small" startIcon={<AddRounded fontSize="small" />} className="text-gray-500" onClick={() => setFilesExpanded(!filesExpanded)}>
              attach file
            </Button>
          </Collapse>
          <Collapse className="w-full" in={!filesExpanded}>
            <Box className="p-4 bg-gray-100 rounded-xl">
              <Box className="flex gap-1 mb-4 items-center">
                <Attachment fontSize="small" className="fill-gray-500" />
                <Typography className="text-gray-500" variant="subtitle1">
                  Select candidate files
                </Typography>
              </Box>
              <List className="py-0 flex flex-col gap-2">
                {candidate_data &&
                  candidate_data.files &&
                  sortBy(candidate_data.files, (value) => value.created_at)
                    .reverse()
                    .map((file) => (
                      <Box key={file.id} className="flex item-center">
                        <Collapse orientation="horizontal" in={watchAttachments?.includes(file.id)}>
                          <CheckCircle className="fill-lime-600 mt-2 mr-2" />
                        </Collapse>
                        <FileCard
                          onClick={attachFileHandler(file.id)}
                          className={classNames('transition-all w-full cursor-pointer my-0', {
                            'bg-white': watchAttachments?.includes(file.id),
                            'bg-gray-100': !watchAttachments?.includes(file.id)
                          })}
                          filename={`${file.name}${file.format}`}
                          size={file.size}
                          created_at={file.created_at}
                          format={file.format}
                        />
                      </Box>
                    ))}
              </List>
            </Box>
          </Collapse>
        </Box>
      </Box>
      <Box className="flex gap-2 justify-end md:flex-nowrap flex-wrap">
        <Button className="py-2 px-4 w-full md:w-fit" onClick={cancelAction} variant="outlined" color="error">
          Cancel
        </Button>
        <LoadingButton className="py-2 px-4 w-full md:w-fit" loading={event_status === 'loading'} type="submit" variant="contained">
          {mode === 'edit' && 'Edit'}
          {mode === 'create' && 'Create'}
        </LoadingButton>
      </Box>
    </FormWrapper>
  );
};

import { CreateAndEditUserForm } from '@components/Forms/Users/CreateAndEditUserForm';
import { Box, Card, CardHeader, Divider } from '@mui/material';
import { FC } from 'react';

export const NewUserPage: FC = () => {
  return (
    <Box className="relative min-h-[200px] mb-4">
      <Card className="shadow">
        <CardHeader title="Contact information" className="uppercase text-gray-600 pb-2" />
        <Divider />
        <Divider />
        <CreateAndEditUserForm mode="create" />
      </Card>
    </Box>
  );
};

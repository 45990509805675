import { useAppDispatch, useAppSelector } from '@app/store/store';
import { CenterLoader } from '@components/Loaders/CenterLoader';
import { SkillsListActionsPopover } from '@components/Popovers/SkillsActions/SkillsListActionsPopover';
import { SkillsSearch } from '@modules/skills/components/Search/SkillsSearch';
import { SkillsListComponentProps } from '@modules/skills/interface';
import Add from '@mui/icons-material/Add';
import HelpOutline from '@mui/icons-material/HelpOutline';
import MoreVert from '@mui/icons-material/MoreVert';
import { Box, Button, Card, Collapse, IconButton, List, Paper, Popover, Typography, useMediaQuery } from '@mui/material';
import { SKILLS } from '@slices/skills/interface';
import { getAllSkills } from '@slices/skills/thunks';
import classNames from 'classnames';
import React, { FC, MouseEvent, useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';

const ListComponent: FC<SkillsListComponentProps> = ({ item, onActionClick }) => {
  const match = useMediaQuery('(max-width: 540px)');
  const [collapsed, setCollapsed] = useState(!match);

  const handleEdit = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onActionClick(event);
  };

  const handleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Card
      onClick={handleCollapsed}
      elevation={0}
      className={classNames('group flex ml-4 flex-col overflow-visible shadow-none bg-transparent border-none relative mx-2 cursor-pointer transition-all hover:bg-gray-100')}
    >
      <Box className="py-2 pr-2 flex items-start justify-between">
        <span className={classNames('w-1 h-full -left-4 bg-gray-500 min-h-full inset-y-0 flex z-10 absolute rounded-full transition-all group-hover:w-2')} />
        <Box className="pl-4">
          <Typography className="flex items-center" variant="subtitle1">
            <span className="mr-2">{item.name}</span>
          </Typography>
          <Collapse in={collapsed}>
            <Typography className="flex" variant="caption">
              {item.comment}
            </Typography>
          </Collapse>
        </Box>
        <Box className="">
          <IconButton size="small" aria-label={item.id} onClick={handleEdit}>
            <MoreVert fontSize="small" />
          </IconButton>
        </Box>
      </Box>
    </Card>
  );
};

export const SkillsListPage: FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { skills, status, search } = useAppSelector((store) => store.skills);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllSkills());
  }, []);

  const filterHandler = (item: SKILLS.Skill) => {
    if (search === '') {
      return item;
    }

    if (item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
      return item;
    }
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };
  const list = useMemo(() => skills.data.filter(filterHandler), [search, skills]);

  return (
    <Box className="max-h-full flex flex-col overflow-hidden">
      <Box className="flex mb-4 flex-wrap items-center gap-4 justify-between">
        <Typography variant="h4" fontWeight="bold" textTransform="uppercase" className="text-gray-300 font-black">
          Skills
        </Typography>
        <Button startIcon={<Add />} variant="contained" component={NavLink} to="new">
          create
        </Button>
      </Box>
      <Paper className="p-4 mb-2 shadow max-h-full flex flex-col overflow-hidden">
        <SkillsSearch />

        <Box className="overflow-y-auto relative">
          <List className="relative flex flex-col py-0 gap-4 min-h-[100px]">
            {list.map((item) => (
              <ListComponent item={item} key={item.id} onActionClick={handleClick} />
            ))}

            {status !== 'loading' && !list.length && (
              <Box className="bg-white w-full h-full inset-0 flex overflow-hidden justify-center items-center z-20">
                <Box className="relative w-full h-full justify-center items-center flex">
                  <HelpOutline className="w-[80px] -mr-5 h-full text-gray-300" />
                  <Typography className="relative z-10 bg-white text-gray-300 font-bold uppercase" variant="h5">
                    No data...
                  </Typography>
                </Box>
              </Box>
            )}
          </List>

          {status === 'loading' && <CenterLoader />}
        </Box>

        <Popover className="mt-2" open={!!anchorEl} onClose={handleClose} onClick={handleClose} anchorEl={anchorEl}>
          {anchorEl && anchorEl.ariaLabel && <SkillsListActionsPopover id={anchorEl.ariaLabel} />}
        </Popover>
      </Paper>
    </Box>
  );
};

import { API } from '@API/constants';
import { ResponseBaseType } from '@API/services/interfaces';
import { ContactsItemDTO } from '@app/store/interface';
import { fetchData } from '@helpers/fetchData/fetchData';
import { PROFILE } from '@slices/profile/interface';

const path = `${API.origin}/profiles`;

export interface ChangePasswordDTO {
  password: string;
}

export interface UpdateProfileDTO {
  first_name: string;
  last_name: string;
  email: string;
  contacts: ContactsItemDTO[];
}

export class ProfileService {
  public static async getProfile<T = PROFILE.Profile>() {
    return fetchData<ResponseBaseType<T>>(path);
  }

  public static async updateProfile<T = PROFILE.Profile>(data: Partial<UpdateProfileDTO>) {
    return fetchData<ResponseBaseType<T>>(path, 'PATCH', data);
  }

  public static async changePassword<T>(data: ChangePasswordDTO) {
    return fetchData<ResponseBaseType<T>>(path, 'PATCH', data);
  }
}

import { CandidateActionsPopover } from '@modules/candidates/components/Popovers/CandidateActionsPopover';
import { CandidateInfoActionsProps } from '@modules/candidates/interface';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import { Button, Popover } from '@mui/material';
import React, { FC, MouseEvent, useState } from 'react';

export const CandidateInfoActions: FC<CandidateInfoActionsProps> = ({ disabled }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button disabled={disabled} endIcon={anchorEl ? <ArrowDropUp /> : <ArrowDropDown />} variant="contained" onClick={handleClick} aria-describedby="vacancy-actions">
        Actions
      </Button>
      <Popover className="mt-2" id="vacancy-actions" open={!!anchorEl} onClose={handleClose} onClick={handleClose} anchorEl={anchorEl}>
        <CandidateActionsPopover />
      </Popover>
    </>
  );
};

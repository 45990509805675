import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { FormControl, FormControlProps, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import classNames from 'classnames';
import React, { FC, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FieldWrapperProps } from '../interface';

interface FieldProps extends FieldWrapperProps, FormControlProps {}

export const PasswordField: FC<FieldProps> = ({ name, label, validationOptions, ...formControl }) => {
  const [showPassword, setShowPassword] = useState(false);

  const methods = useFormContext();
  const register = methods?.register(name, validationOptions || {}) || {};

  const handleClickShowPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  return (
    <FormControl {...formControl} error={!!methods?.formState.errors[name]} variant="outlined">
      {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
      <OutlinedInput
        id={name}
        type={showPassword ? 'text' : 'password'}
        {...register}
        endAdornment={
          <InputAdornment className={classNames({ 'opacity-50': formControl.disabled })} position="end">
            <IconButton
              size="small"
              disabled={formControl.disabled}
              color={!!methods?.formState.errors[name] ? 'error' : 'default'}
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
            </IconButton>
          </InputAdornment>
        }
        label={label}
      />
      {!!methods?.formState.errors[name] && <FormHelperText>{methods?.formState.errors[name]?.message ?? ''}</FormHelperText>}
    </FormControl>
  );
};

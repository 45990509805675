import { useAppDispatch, useAppSelector } from '@app/store/store';
import { InputSelect } from '@components/Fields/InputSelect/InputSelect';
import { KanbanVacancyCard } from '@modules/kanban/components/VacancyCard/KanbanVacancyCard';
import { Search } from '@mui/icons-material';
import { Box, Container, InputAdornment, Typography } from '@mui/material';
import { kanbanSlice } from '@slices/kanban/kanbanSlice';
import { getVacancies } from '@slices/vacancies/thunks';
import classNames from 'classnames';
import { ReactElement, useEffect } from 'react';

export const KanbanVacanciesPage = (): ReactElement => {
  const {
    status,
    list: { data }
  } = useAppSelector((store) => store.vacancies.vacanciesList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(kanbanSlice.actions.setVacancyID(''));
    init();
  }, []);

  const init = async (): Promise<void> => {
    data ? dispatch(getVacancies()) : await dispatch(getVacancies());
  };

  return (
    <Box className="relative h-full">
      <Box className="absolute inset-0 overflow-y-auto">
        <Container className="max-w-[900px]">
          <Box className="my-4 flex gap-4 items-center flex-wrap">
            <Typography variant="h4" fontWeight="bold" textTransform="uppercase" className="text-gray-300 shrink-0 text-center font-black">
              Vacancies Boards
            </Typography>
            <InputSelect
              textFieldProps={{
                placeholder: 'Select the vacancy',
                InputProps: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  )
                }
              }}
              className="w-full min-w-[280px]"
              returnValue="id"
              label="Vacancy"
              options={data ?? []}
              loading={status === 'loading'}
              optionLabelRefName="name"
              name="vacancy"
            />
          </Box>
          <Box className={classNames('py-4 h-full flex gap-4 flex-wrap')}>
            {data.map((vacancy) => (
              <KanbanVacancyCard key={vacancy.id} {...vacancy} />
            ))}
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

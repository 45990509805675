import { useAppDispatch, useAppSelector } from '@app/store/store';
import { StepsListActionsPopover } from '@components/Popovers/StepsActions/StepsListActionsPopover';
import { StepsDroppableList } from '@modules/steps/components/Drag&Drop/StepsDroppableList';
import { StepsSearch } from '@modules/steps/components/Search/StepsSearch';
import Add from '@mui/icons-material/Add';
import SortByAlpha from '@mui/icons-material/SortByAlpha';
import { Box, Button, Paper, Popover, Typography } from '@mui/material';
import { stepsSlice } from '@slices/steps/stepsSlice';
import { getAllSteps } from '@slices/steps/thukns';
import { FC, MouseEvent, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

export const StepsListPage: FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { ordering } = useAppSelector((store) => store.steps);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllSteps());
  }, [dispatch]);

  const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleOrdering = (): void => {
    dispatch(stepsSlice.actions.toggleOrdering());
  };

  return (
    <Box className="max-h-full flex flex-col overflow-hidden">
      <Box className="flex mb-4 flex-wrap items-center gap-4 justify-between">
        <Typography variant="h4" fontWeight="bold" textTransform="uppercase" className="text-gray-300 font-black">
          Steps
        </Typography>
        <Box className="flex gap-2 my-2">
          <Button startIcon={<SortByAlpha />} variant="outlined" onClick={handleOrdering}>
            {ordering && (
              <span className="flex h-3 w-3 absolute -top-1 -right-1">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-brand-500 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-brand-500"></span>
              </span>
            )}
            {ordering ? 'stop sorting' : 'sort'}
          </Button>
          <Button startIcon={<Add />} variant="contained" component={NavLink} to="new">
            create
          </Button>
        </Box>
      </Box>
      <Paper className="p-4 mb-2 shadow max-h-full flex flex-col overflow-hidden">
        <StepsSearch />

        <Box className="overflow-y-auto overflow-x-hidden">
          <StepsDroppableList popoverOpenAction={handleClick} />
        </Box>

        <Popover className="mt-2" open={!!anchorEl} onClose={handleClose} onClick={handleClose} anchorEl={anchorEl}>
          {anchorEl && anchorEl.ariaLabel && <StepsListActionsPopover id={anchorEl.ariaLabel} />}
        </Popover>
      </Paper>
    </Box>
  );
};

import { getStorageItem } from '@helpers/localStorage/localStorageService';
import { createSlice } from '@reduxjs/toolkit';
import { AUTH } from '@slices/auth/interface';
import { login, logout, registration, resetPassword, restorePassword } from '@slices/auth/thunks';

const initialState: AUTH.AuthSLiceState = {
  token: getStorageItem('token'),
  status: 'unset',
  responseMessage: null
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearResponseMessages: (state) => {
      state.responseMessage = null;
    },
    syncLogout: (state) => {
      state.token = null;
    }
  },
  extraReducers: (builder) => {
    //LOGIN
    builder.addCase(login.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.status = 'success';
      state.token = payload.data.token;
    });
    builder.addCase(login.rejected, (state, { payload }) => {
      state.status = 'error';
      state.responseMessage = {
        type: 'error',
        data: payload
      };
    });

    //REGISTRATION
    builder.addCase(registration.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(registration.fulfilled, (state, { payload }) => {
      state.status = 'success';
      state.token = payload.data.token;
    });
    builder.addCase(registration.rejected, (state, { payload }) => {
      state.status = 'error';
      state.responseMessage = {
        type: 'error',
        data: payload
      };
    });

    //LOGOUT
    builder.addCase(logout.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.status = 'success';
      state.token = null;
    });
    builder.addCase(logout.rejected, (state, { payload }) => {
      state.token = null;
      state.responseMessage = {
        type: 'error',
        data: payload
      };
      state.status = 'error';
    });

    //RESET PASSWORD
    builder.addCase(resetPassword.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(resetPassword.fulfilled, (state) => {
      state.responseMessage = {
        type: 'success',
        data: [
          {
            message: 'Password is successfully changed'
          }
        ]
      };
      state.status = 'success';
    });
    builder.addCase(resetPassword.rejected, (state, { payload }) => {
      state.status = 'error';
      state.responseMessage = {
        type: 'error',
        data: payload
      };
    });

    //RESTORE PASSWORD
    builder.addCase(restorePassword.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(restorePassword.fulfilled, (state) => {
      state.responseMessage = {
        type: 'success',
        data: [
          {
            message: 'Please check your mail'
          }
        ]
      };
      state.status = 'success';
    });
    builder.addCase(restorePassword.rejected, (state, { payload }) => {
      state.status = 'error';
      state.responseMessage = {
        type: 'error',
        data: payload
      };
    });
  }
});

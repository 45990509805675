import { EXPERIENCE_VARIANTS, VACANCY_STATUSES } from '@app/store/constants';
import { useAppDispatch, useAppSelector } from '@app/store/store';
import { InputMultiselect } from '@components/Fields/InputMultiselect/InputMultiselect';
import { InputSelect } from '@components/Fields/InputSelect/InputSelect';
import { InputTextField } from '@components/Fields/InputText/InputTextField';
import { FormWrapper } from '@components/Forms/FormWrapper';
import { CreateAndEditVacanciesFormProps, CreateAndEditVacanciesFormValues } from '@components/Forms/interface';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Card, CardHeader, Divider, Typography } from '@mui/material';
import { addNewVacancy, updateVacancy } from '@slices/vacancies/thunks';
import { debounce } from 'lodash';
import React, { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

const schema = yup.object({
  information: yup.object({
    name: yup.string().required('Name is required field'),
    status: yup.string().required('Status is required field'),
    comment: yup.string(),
    city: yup.string().required('City is required field'),
    country: yup.string().required('Country is required field'),
    department_id: yup.string().required('Department is required field'),
    description: yup.string().required('Description is required field'),
    experience: yup.array().min(2).required()
  }),
  platforms: yup.array().min(1).required(),
  steps: yup.array().min(1).required()
});
export const CreateAndEditVacanciesForm: FC<CreateAndEditVacanciesFormProps> = ({ mode }) => {
  const {
    platforms: {
      platforms: {
        list: { data: platforms }
      },
      status: platforms_status
    },
    vacancies: {
      vacancy: { data: vacancy, status }
    },
    departments: {
      departments: { data: departments }
    },
    steps: {
      status: steps_status,
      steps: { data: steps }
    }
  } = useAppSelector((store) => store);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();
  const methods = useForm<CreateAndEditVacanciesFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {},
    mode: 'onChange'
  });

  const watchDateStart = methods.watch('information.experience.0', 0);
  const watchDateEnd = methods.watch('information.experience.1', 0);

  const navigateHandler = (): void => {
    navigate(mode === 'edit' && vacancy ? `/vacancies/${vacancy.id}` : '/vacancies', { replace: true });
  };

  const submitRecipe: SubmitHandler<CreateAndEditVacanciesFormValues> = async (data) => {
    const debouncedNavigate = debounce((id: string) => navigate(`/vacancies/${id}`), 300);

    if (mode === 'create') {
      dispatch(
        addNewVacancy({
          ...data.information,
          steps: data.steps.map((item) => item.id),
          platforms: data.platforms.map((item) => item.id),

          afterActionWithParams: (id) => id && debouncedNavigate(id)
        })
      );
    }

    if (mode === 'edit') {
      if (params.id) {
        dispatch(
          updateVacancy({
            ...data.information,
            id: params.id,
            steps: data.steps.map((item) => item.id),
            platforms: data.platforms.map((item) => item.id),

            afterActionWithParams: (id) => id && debouncedNavigate(id)
          })
        );
      }
    }
  };

  useEffect(() => {
    if (vacancy && mode === 'edit' && status !== 'loading') {
      methods.reset({
        information: {
          name: vacancy?.name ?? '',
          status: vacancy?.status ?? '',
          comment: vacancy?.comment ?? '',
          city: vacancy?.city ?? '',
          country: vacancy?.country ?? '',
          department_id: vacancy?.department?.id ?? '',
          description: vacancy?.description ?? '',
          experience: [vacancy?.experience_from || 0, vacancy.experience_to || 0]
        },
        steps: vacancy.steps,
        platforms: vacancy.platforms
      });
    }
  }, [status, methods, vacancy, mode]);

  useEffect(() => {
    if (mode === 'create' && status !== 'loading') {
      methods.setValue(
        'steps',
        steps.filter((step) => step.default)
      );
    }
  }, [steps]);

  return (
    <FormWrapper
      className="flex gap-4"
      methods={methods}
      formProps={{
        onSubmit: methods.handleSubmit(submitRecipe)
      }}
    >
      <Card className="mb-4 shadow w-full">
        <CardHeader title={<Typography variant="h6">Main information</Typography>} />
        <Divider />

        <Box className="px-4 py-8 grid gap-4 xl:grid-cols-2 grid-cols-1">
          <InputTextField className="col-span-full" name="information.name" label="Name" />

          <InputSelect
            options={VACANCY_STATUSES}
            defaultValue={VACANCY_STATUSES[0]}
            returnValue="id"
            optionLabelRefName="label"
            name="information.status"
            label="Status"
          />

          <InputSelect returnValue="id" loading={status === 'loading'} options={departments} optionLabelRefName="name" name="information.department_id" label="Department" />

          <InputTextField className="flex-1" name="information.country" label="Country" />

          <InputTextField className="" name="information.city" label="City" />

          <Box className="col-span-full">
            <Typography className="mb-4">Experience</Typography>
            <Box className="flex md:items-center gap-2 md:flex-row flex-col">
              <InputSelect
                getOptionDisabled={(option) => !!watchDateEnd && option.value > watchDateEnd}
                returnValue="value"
                defaultValue={EXPERIENCE_VARIANTS[0]}
                optionLabelRefName="label"
                className="flex-1 md:w-full"
                options={EXPERIENCE_VARIANTS}
                label="from"
                name="information.experience.0"
              />
              <Divider className="xl:w-10 md:w-5 w-full" />
              <InputSelect
                getOptionDisabled={(option) => !!watchDateStart && option.value < watchDateStart}
                returnValue="value"
                defaultValue={EXPERIENCE_VARIANTS[0]}
                optionLabelRefName="label"
                className="flex-1 md:w-full"
                options={EXPERIENCE_VARIANTS}
                label="to"
                name="information.experience.1"
              />
            </Box>
          </Box>

          <InputMultiselect
            optionLabelRefName="name"
            optionValueRefName="id"
            checkboxOption
            loading={steps_status === 'loading'}
            name="steps"
            textFieldProps={{
              placeholder: 'Steps'
            }}
            options={steps}
            label="Steps"
            className="col-span-full"
          />

          <InputMultiselect
            className="col-span-full"
            optionLabelRefName="name"
            optionValueRefName="id"
            loading={platforms_status === 'loading'}
            checkboxOption
            name="platforms"
            textFieldProps={{
              placeholder: 'Platforms'
            }}
            options={platforms}
            label="Platforms"
          />

          <InputTextField multiline minRows={3} fullWidth name="information.description" label="Description" className="xl:col-span-2" />

          <InputTextField multiline minRows={5} fullWidth className="xl:col-span-2" name="information.comment" label="Commentaries" />
        </Box>

        <Divider />

        <Box className="p-4 gap-4 flex flex-row-reverse flex-wrap">
          <LoadingButton loading={status === 'loading'} className="lg:w-fit w-full py-2 px-8" variant="contained" type="submit">
            Save
          </LoadingButton>
          <Button className="lg:w-fit w-full py-2 px-8" variant="outlined" onClick={navigateHandler} color="error">
            Cancel
          </Button>
        </Box>
      </Card>
    </FormWrapper>
  );
};
